import React from 'react';
import { Route, Switch } from 'react-router-dom';

import TranslatorJobs from './TranslatorJobs';

function TranslatorJobsRouter(props) {
  return (
    <Switch>
      <Route path="/offers" render={() => <TranslatorJobs forReservations {...props} />} />
      <Route render={() => <TranslatorJobs {...props} />} />
    </Switch>
  );
}

export default TranslatorJobsRouter;
