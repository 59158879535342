import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import '@amzn/awsui-components-react/index.css';

import Body from './Body';
import Header from './Header';
import { Toaster } from '../toast';

import ErrorAlert from '../ErrorAlert';
import { termsOfServiceModifiedTimestamp } from './TermsOfService';
import TermsOfService from './TermsOfService';

import { isAdminRole } from '../../utils/UserRoles';
import initialMetricsPublisher from '../../utils/metricsPublisher';
import { publishCountForMethod } from '@amzn/ts-ui-metrics';

const Centered = styled('div')`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export class App extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.authenticate();
    this.props.onGetFeatures();
    this.props.onGetTranslationSkills();
    this.props.onGetMediaTypes();
    this.props.onGetSubjects();
    this.props.onLoadMyProfile();
  }

  componentDidMount() {
    // The static router doesn't have this, so just use it if it's available in the active router
    if (this.props.history.listen) {
      this.unlisten = this.props.history.listen((location, action) => {
        const actionMetricsPublisher = publishCountForMethod(
          initialMetricsPublisher,
          'LocationChanged'
        );
        actionMetricsPublisher.publishStringTruncate(
          'Location',
          `${location.pathname}${location.search}${location.hash}`
        );
      });
    }
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  render() {
    const {
      session,
      onSignOut,
      availableLocales,
      translationSkills,
      mediaTypes,
      subjects,
      fetchAvailableLocales,
      features,
      onAcceptTermsOfService,
      profile,
    } = this.props;

    if (!session) {
      // TODO: loading spinner
      return <div />;
    } else if (session.error) {
      return (
        <Centered>
          <ErrorAlert title="Error logging you in" errorInfo={session.error} />
        </Centered>
      );
    } else if (
      !session.sysAdminAccess &&
      !isAdminRole(session.role) &&
      (!session.acceptedTermsOfServiceTimestamp ||
        session.acceptedTermsOfServiceTimestamp < termsOfServiceModifiedTimestamp)
    ) {
      return (
        <TermsOfService
          session={session}
          onAcceptTermsOfService={onAcceptTermsOfService}
          onCancelTermsOfService={onSignOut}
          termsUpdated={session.acceptedTermsOfServiceTimestamp != null}
          showAcceptUI
        />
      );
    }

    // unroll redux state to make it less confusing downstream
    const globalState = {
      translationSkills: translationSkills && translationSkills.skills,
      mediaTypes: mediaTypes && mediaTypes.mediaTypes,
      subjects: subjects && subjects.subjects,
      availableLocales: availableLocales && availableLocales.items,
      profile: profile,
    };

    return (
      <Container className="awsui">
        <Header
          availableLocales={availableLocales}
          fetchAvailableLocales={fetchAvailableLocales}
          session={session}
        />
        <Toaster />
        <Body session={session} features={features} globalState={globalState} />
      </Container>
    );
  }
}

App.propTypes = {
  availableLocales: PropTypes.object,
  translationSkills: PropTypes.object,
  mediaTypes: PropTypes.object,
  subjects: PropTypes.object,
  fetchAvailableLocales: PropTypes.func,
  onSignOut: PropTypes.func,
  onGetFeatures: PropTypes.func,
  onGetTranslationSkills: PropTypes.func,
  onGetMediaTypes: PropTypes.func,
  onGetSubjects: PropTypes.func,
  onLoadMyProfile: PropTypes.func,
  profile: PropTypes.object.isRequired,
  session: PropTypes.object,
  features: PropTypes.object,
  authenticate: PropTypes.func,
  onAcceptTermsOfService: PropTypes.func,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

App.defaultProps = {
  onGetFeatures: Function.prototype,
  onGetTranslationSkills: Function.prototype,
  onGetMediaTypes: Function.prototype,
  onGetSubjects: Function.prototype,
  onAcceptTermsOfService: Function.prototype,
  authenticate: Function.prototype,
  onLoadMyProfile: Function.prototype,
  profile: Object.prototype,
  match: Object.prototype,
  location: Object.prototype,
  history: Object.prototype,
};

export default App;
