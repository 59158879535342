import GenericCrudActions from '../actions/GenericCrudActions';
import { SortOrder } from '../utils/Enums';

export const genericCrudInitialState = {
  pagesRequested: 0,
  sortField: null,
  sortOrder: SortOrder.UNSORTED,
  isFetching: false,
  fullyLoaded: false,
  lastUpdated: null,
  lastError: null,
  items: [],
  item: undefined,
  searchPattern: '',
};

const genericCrudReducer = type => {
  const actions = new GenericCrudActions(type);

  return (state = genericCrudInitialState, action) => {
    switch (action.type) {
      case actions.resetPageActionType():
        return {
          ...state,
          pagesRequested: 1,
          fullyLoaded: false,
          searchPattern: action.clearSearch ? '' : state.searchPattern,
        };
      case actions.requestReloadActionType():
        return {
          ...state,
          isFetching: true,
        };
      case actions.finishedFetchActionType():
        return {
          ...state,
          isFetching: false,
          items: action.items,
          lastUpdated: action.receivedAt,
          lastError: null,
        };
      case actions.failReloadActionType():
        return {
          ...state,
          isFetching: false,
          items: [],
          lastUpdated: action.receivedAt,
          lastError: action.error,
        };
      case actions.fetchOneActionType():
        return {
          ...state,
          isFetching: true,
          item: undefined,
        };
      case actions.finishedFetchOneActionType():
        return {
          ...state,
          isFetching: false,
          item: action.item,
          lastUpdated: action.receivedAt,
          lastError: null,
        };
      case actions.failedFetchOneActionType():
        return {
          ...state,
          isFetching: false,
          item: undefined,
          lastUpdated: action.receivedAt,
          lastError: action.error,
        };
      case actions.updatingActionType():
        return {
          ...state,
          item: action.item,
        };
      case actions.updatedActionType(): {
        let item = state.item;
        if ('id' in item && action.item.id === item.id) {
          item = action.item;
        }
        return {
          ...state,
          item,
        };
      }
      case actions.gridSortChangedActionType():
        return {
          ...state,
          sortOrder: action.order,
          sortField: action.field,
        };
      case actions.requestNextPageActionType():
        return {
          ...state,
          pagesRequested: state.pagesRequested + 1,
          isFetching: true,
        };
      case actions.searchActionType():
        return {
          ...state,
          searchPattern: action.searchPattern,
        };
      default:
        return state;
    }
  };
};

export default genericCrudReducer;
