import ActionTypes from '../actions/Locales';

const locales = (state = locales.initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOCALES_FETCH_START:
      return locales.initialState;
    case ActionTypes.LOCALES_FETCH_COMPLETE:
      return { ...state, items: action.items, lastUpdated: action.receivedAt };
    case ActionTypes.LOCALES_FETCH_ERROR:
      return {
        ...state,
        lastError: action.lastError,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
};

locales.initialState = {
  lastError: null,
  items: [],
  lastUpdated: null,
};

export default locales;
