import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  ColumnLayout,
  Form,
  FormField,
  FormSection,
  Select,
  Flash,
} from '@amzn/awsui-components-react/polaris';
import { css } from 'emotion';
import * as yup from 'yup';
import { Link, Redirect } from 'react-router-dom';
import { spinner } from 'react-icons-kit/fa/spinner';
import SvgIcon from 'react-icons-kit/SvgIcon';
import { PolarisFormik } from '@amzn/et-polaris-utils';

const formSpacing = css`
  padding-top: 14px;
  padding-bottom: 14px;
`;

class AddEditMediaTypeSubjectSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Per AWS UX recommendations, we do not start validating until after
      // the first form submission
      validating: false,
      lastError: null,
      finished: false,
    };
  }

  /**
   * Per AWS Specifications, only start validating after the initial submit.
   * @param e the event
   * @param handleSubmit formik submit event handler function.
   */
  enableValidation = (e, handleSubmit) => {
    e.preventDefault();
    this.setState({ validating: true, lastError: null });
    handleSubmit();
  };

  handleSubmit = (values, formik) => {
    const { onCreateOrUpdateEvent, mediaType } = this.props;
    const { skills } = this.props.translationSkills;

    // remove any skills who were not fully filled out
    Object.keys(skills).forEach(skill => {
      if (
        skill in values.skills &&
        (values.skills[skill].priceSchemeId == null || values.skills[skill].priceSchemeId === '')
      ) {
        delete values.skills[skill];
      }
    });

    onCreateOrUpdateEvent(mediaType, values)
      .then(() => {
        this.setState({ finished: true });
      })
      .catch(e => {
        this.setState({ lastError: 'Something went wrong' });
      });
  };

  generateValidationSchema = skills => {
    const shape = {
      subject: yup.string().required(),
    };

    const skillShape = {};
    // TODO enhance this validation so that the whole hierarchy is required.
    // TODO enhance validation so if user does not select priceSchemeId, but selects other stuff,
    //     it will require a price scheme.  Currently results in a cyclic dependency.
    Object.keys(skills).forEach(skill => {
      skillShape[skill] = yup.object().shape({
        priceSchemeId: yup.string(),
        weightSchemeId: yup.string().when('priceSchemeId', {
          is: x => (x || '').length > 0,
          then: yup.string().required(),
          otherwise: yup.string(),
        }),
        selfServiceEnabled: yup.bool().default(false),
      });
    });

    shape['skills'] = yup.object().shape(skillShape);

    return yup.object().shape(shape);
  };

  getEmptyValues = skills => {
    const emptyValues = { subject: '', skills: {} };
    Object.keys(skills).forEach(skill => {
      emptyValues.skills[skill] = {
        priceSchemeId: '',
        weightSchemeId: '',
        selfServiceEnabled: false,
      };
    });
    return emptyValues;
  };

  render() {
    const { mediaType, subject } = this.props;
    const { mediaTypes, isFetching: isMediaTypesFetching } = this.props.mediaTypes;
    const { subjects, isFetching: isSubjectsFetching } = this.props.subjects;
    const {
      items: mediaTypeSubjectSettings,
      lastUpdated: mediaTypeSubjectSettingsLastUpdated,
    } = this.props.mediaTypeSubjectSettings;
    const { fullyLoaded: priceSchemesLoaded } = this.props.priceSchemes;
    const { fullyLoaded: weightSchemesLoaded } = this.props.weightSchemes;
    const { skills } = this.props.translationSkills;
    const { validating, finished } = this.state;
    const schema = this.generateValidationSchema(skills);

    // if navigating directly to this page, the render may be called before
    // global app entries are fully loaded.
    if (isMediaTypesFetching || isSubjectsFetching) {
      return <SvgIcon icon={spinner} size={32} />;
    }

    // Force the user to enter this page through the main settings page, so
    // we don't have to load everything and wait on it.
    // The media type subject settings get reloaded when the submit button is
    // pressed and the object successfully updates, so instead, use last updated
    // to determine if they were ever loaded to begin with.
    if (
      !mediaTypeSubjectSettingsLastUpdated ||
      !priceSchemesLoaded ||
      !weightSchemesLoaded ||
      !(mediaType in mediaTypes) ||
      (subject !== undefined && !(subject in subjects))
    ) {
      return <Redirect to="/settings/mediaTypeSubject" />;
    }

    // We're done here, redirect back to other page.
    // TODO find a way to show the success banner on the other page.
    if (finished) {
      return <Redirect to="/settings/mediaTypeSubject" />;
    }

    let initialValues = this.getEmptyValues(skills);
    if (subject) {
      const item = mediaTypeSubjectSettings.find(x => x.mediaType === mediaType);
      if (item) {
        initialValues = { subject: subject, ...item.subjects[subject] };
      }
    }

    return (
      <PolarisFormik
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange={validating}
        onSubmit={this.handleSubmit}
        render={this.renderForm}
      />
    );
  }

  renderForm = ({ values, errors, touched, handleSubmit, handleChange, handleBlur }) => {
    const { lastError } = this.state;
    const { priceSchemes, weightSchemes, mediaType, subject } = this.props;
    const { skills } = this.props.translationSkills;
    const { mediaTypes } = this.props.mediaTypes;
    const { subjects } = this.props.subjects;
    const { items: mediaTypeSubjectSettings } = this.props.mediaTypeSubjectSettings;
    const isEdit = subject !== undefined;

    // Determine which subjects are already configured for this media type, if any
    const configuredSubjects = Object.keys(
      (mediaTypeSubjectSettings.find(x => x.mediaType === mediaType) || {}).subjects || {}
    );

    return (
      <>
        <form onSubmit={e => this.enableValidation(e, handleSubmit)}>
          <Form
            header={`${isEdit ? 'Edit' : 'Create'} Subject Settings for ${mediaTypes[mediaType]}`}
            actions={
              <div>
                <Link to={`/settings/mediaTypeSubject`}>
                  <Button id="Cancel" text="Cancel" variant="link" />
                </Link>
                <Button
                  id="Submit"
                  text="Submit"
                  variant="primary"
                  onClick={e => this.enableValidation(e, handleSubmit)}
                />
              </div>
            }
            errorText={
              Object.keys(errors).length !== 0
                ? 'The form contains errors. Fix them and resubmit.'
                : ''
            }
          >
            <FormSection header={`${isEdit ? subjects[subject] : 'New'} Subject Settings`}>
              {!isEdit && (
                <ColumnLayout>
                  <FormField
                    label="Subject"
                    hintText="The subject to create settings for."
                    errorText={errors.subject ? 'Subject is required.' : ''}
                  >
                    <Select
                      id="subject"
                      controlId="subject"
                      options={this.getSubjectOptions(
                        Object.assign(
                          {},
                          ...Object.entries(subjects)
                            .filter(([k, _]) => !configuredSubjects.includes(k))
                            .map(([k, v]) => ({ [k]: v }))
                        )
                      )}
                      enableFiltering={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormField>
                </ColumnLayout>
              )}
              {Object.keys(skills).map(skill => (
                <React.Fragment key={skill}>
                  <div className={formSpacing}>
                    <h2>{skills[skill]}</h2>
                    <ColumnLayout columns={3}>
                      <div data-awsui-column-layout-root="true">
                        <FormField
                          label="Pricing Scheme"
                          hintText="The pricing scheme to apply for this subject."
                          errorText={
                            ((errors.skills || {})[skill] || {}).priceSchemeId
                              ? 'Price Scheme is required.'
                              : ''
                          }
                        >
                          <Select
                            id={`skills.${skill}.priceSchemeId`}
                            controlId={`skills.${skill}.priceSchemeId`}
                            options={this.getPriceSchemeSelectOptions(priceSchemes)}
                            selectedId={(values.skills[skill] || {}).priceSchemeId}
                            enableFiltering={true}
                            loading={priceSchemes.isFetching}
                            loadingText="Loading Pricing Schemes"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormField>
                        <FormField
                          label="Weighting Scheme"
                          hintText="The weighting scheme to apply for this subject."
                          errorText={
                            ((errors.skills || {})[skill] || {}).weightSchemeId
                              ? 'Weighting Scheme is required.'
                              : ''
                          }
                        >
                          <Select
                            id={`skills.${skill}.weightSchemeId`}
                            controlId={`skills.${skill}.weightSchemeId`}
                            options={this.getWeightSchemeSelectOptions(weightSchemes)}
                            selectedId={(values.skills[skill] || {}).weightSchemeId}
                            enableFiltering={true}
                            loading={weightSchemes.isFetching}
                            loadingText="Loading Weighting Schemes"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormField>
                        <FormField label="Self Service" stretch={true}>
                          <Checkbox
                            id={`skills.${skill}.selfServiceEnabled`}
                            name={`skills.${skill}.selfServiceEnabled`}
                            label="Enabled"
                            checked={(values.skills[skill] || {}).selfServiceEnabled === true}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormField>
                      </div>
                    </ColumnLayout>
                  </div>
                  <hr />
                </React.Fragment>
              ))}
            </FormSection>
            {lastError && (
              <div className={formSpacing}>
                <Flash
                  type="error"
                  dismissible={true}
                  content="Please try again. If the issue persists, contact support."
                  header="Something went wrong."
                />
              </div>
            )}
          </Form>
        </form>
      </>
    );
  };

  getSubjectOptions = subjects => {
    return [
      { id: '', label: 'Choose a Subject' },
      ...Object.keys(subjects).map(subject => ({
        id: subject,
        label: subjects[subject],
      })),
    ];
  };

  getWeightSchemeSelectOptions = weightSchemes => {
    return [
      { id: '', label: 'Choose a Weight Scheme' },
      ...weightSchemes.items.map(scheme => ({
        id: scheme.id,
        label: scheme.name,
      })),
    ];
  };

  getPriceSchemeSelectOptions = priceSchemes => {
    return [
      { id: '', label: 'Choose a Price Scheme' },
      ...priceSchemes.items.map(scheme => ({
        id: scheme.id,
        label: scheme.name,
      })),
    ];
  };
}

AddEditMediaTypeSubjectSettings.propTypes = {
  session: PropTypes.object.isRequired,
  priceSchemes: PropTypes.object.isRequired,
  weightSchemes: PropTypes.object.isRequired,
  translationSkills: PropTypes.object.isRequired,
  mediaTypes: PropTypes.object.isRequired,
  mediaType: PropTypes.string.isRequired,
  subjects: PropTypes.object.isRequired,
  subject: PropTypes.string,
  mediaTypeSubjectSettings: PropTypes.object.isRequired,
  onCreateOrUpdateEvent: PropTypes.func,
};

AddEditMediaTypeSubjectSettings.defaultProps = {
  session: Object.prototype,
  priceSchemes: Object.prototype,
  weightSchemes: Object.prototype,
  mediaTypeSubjectSettings: Object.prototype,
  onCreateOrUpdateEvent: Function.prototype,
};

export default AddEditMediaTypeSubjectSettings;
