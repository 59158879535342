import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import SvgIcon from 'react-icons-kit/SvgIcon';
import styled, { css } from 'react-emotion';

import { theme } from '../../config/constants';
import { publishCountForMethod } from '@amzn/ts-ui-metrics';
import initialMetricsPublisher from '../../utils/metricsPublisher';

const Container = styled('div')`
  min-height: 100%;
  background: #fff;
  width: 250px;
  overflow: auto;
  border-right: 1px solid #aab7b8;
`;

const Items = styled('ul')`
  .awsui div & {
    margin: 0;
    padding: 0 40px;
    list-style: none;
    display: block;
  }
`;

const Item = styled('li')`
  .awsui div & {
    margin: 20px 0;
    padding: 0;
    list-style: none;
    display: block;

    & svg {
      vertical-align: -2px !important;
      margin-right: 0.5em;
    }
  }
`;

const PrivacyPolicy = styled('div')`
  padding: 10px 20px;
  flex-grow: 1;
  overflow: auto;
  font-size: 13px;
`;

const focusedNavLinkStyles = css``;

const activeNavLinkStyles = css`
  color: ${theme.brand};
  text-decoration: none;
`;

const className = css`
  .awsui div & {
    color: #16191f;

    &:active,
    &:focus {
      text-decoration: none;
      ${focusedNavLinkStyles};
    }

    &:hover {
      ${activeNavLinkStyles};
    }
  }
`;

const activeClassName = css`
  .awsui div & {
    font-weight: bold;

    &,
    &:active,
    &:focus {
      ${activeNavLinkStyles};
    }

    &:active,
    &:focus {
      ${focusedNavLinkStyles};
    }
  }
`;

class SideNav extends React.Component {
  static propTypes = {
    session: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
  };

  static EXTERNAL_PAGE = 'EXTERNAL_PAGE';
  static DIVIDER = 'DIVIDER';

  render() {
    const { items } = this.props;
    let dividerIndex = 0;
    return (
      <Container>
        <Items>
          {items.map(({ title, icon, path, content }) => {
            if (content === SideNav.DIVIDER) {
              return (
                <Item key={`side-divider-${dividerIndex++}`}>
                  <hr />
                </Item>
              );
            }

            const onClick = () => {
              const actionMetricsPublisher = publishCountForMethod(
                initialMetricsPublisher,
                'SideNavItemClick'
              );
              actionMetricsPublisher.publishStringTruncate('Path', path);
            };
            let Link = NavLink;
            let props = { className, activeClassName, to: path, onClick };
            if (content === SideNav.EXTERNAL_PAGE) {
              Link = 'a';
              props = { className, href: path, target: '_blank', onClick };
            }
            return (
              <Item key={path}>
                <Link {...props}>
                  <SvgIcon icon={icon} size={16} style={{ verticalAlign: 'bottom' }} />
                  {title}
                </Link>
              </Item>
            );
          })}
        </Items>
        <PrivacyPolicy>
          Amazon Europe Core Sarl of LUX 18 (38 avenue John F. Kennedy, L-1855 Luxembourg) is a data
          controller of information provided to or collected by it, including through Translator
          Portal. Such information will be processed in accordance with the{' '}
          <a href="https://www.amazon.co.uk/">Amazon.co.uk</a> Privacy Notice.
        </PrivacyPolicy>
      </Container>
    );
  }
}

export default SideNav;
