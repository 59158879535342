import { genericCrudInitialState } from './GenericCrudReducer';
import genericCrudReducer from './GenericCrudReducer';

export const certificationInitialState = {
  ...genericCrudInitialState,
  byUser: {},
};

const certification = () => {
  const genericCrudReducerInstance = genericCrudReducer('certification');

  return (state = certificationInitialState, action) => {
    return genericCrudReducerInstance(state, action);
  };
};

export default certification;
