import ApiClient from '../ApiClient';
import { SortOrder } from '../utils/Enums';

const SORT_ORDER_MAPPING = {
  [SortOrder.ASC]: 'asc',
  [SortOrder.DESC]: 'desc',
  // Should not happen
  [SortOrder.UNSORTED]: 'asc',
};

const ActionTypes = {
  // Reset the page size for the grid back to 1 page.
  TRANSLATOR_PAGE_RESET: 'TRANSLATOR_PAGE_RESET',
  // Request translators be reloaded from the server
  TRANSLATOR_RELOAD_REQUEST: 'TRANSLATOR_RELOAD_REQUEST',
  // Receive translators reload response from the server
  TRANSLATOR_RELOAD_RECEIVE: 'TRANSLATOR_RELOAD_RECEIVE',
  // Request translator reload failed.
  TRANSLATOR_RELOAD_RECEIVE_FAILURE: 'TRANSLATOR_RELOAD_RECEIVE_FAILURE',
  // Request the next page of translators from the server
  TRANSLATOR_NEXT_PAGE_REQUEST: 'TRANSLATOR_NEXT_PAGE_REQUEST',
  // Receive translators next page response from the server
  TRANSLATOR_NEXT_PAGE_RECEIVE: 'TRANSLATOR_NEXT_PAGE_RECEIVE',
  // Request translator next page failed.
  TRANSLATOR_NEXT_PAGE_RECEIVE_FAILURE: 'TRANSLATOR_NEXT_PAGE_RECEIVE_FAILURE',
  // Sent request to create a translator
  TRANSLATOR_CREATING: 'TRANSLATOR_CREATING',
  // Translator successfully created
  TRANSLATOR_CREATED: 'TRANSLATOR_CREATED',
  // Failed to create a Translator
  TRANSLATOR_CREATE_FAILED: 'TRANSLATOR_CREATE_FAILED',
  // Change translator sort column and order
  TRANSLATOR_GRID_SORT_CHANGED: 'TRANSLATOR_GRID_SORT_CHANGED',
  // Filter the translators based off of search criteria
  TRANSLATOR_SEARCH: 'TRANSLATOR_SEARCH',
  // Sent request to update a translator
  TRANSLATOR_UPDATING: 'TRANSLATOR_UPDATING',
  // Translator successfully created
  TRANSLATOR_UPDATED: 'TRANSLATOR_UPDATED',
  // Failed to create a Translator
  TRANSLATOR_UPDATE_FAILED: 'TRANSLATOR_UPDATE_FAILED',
};

export const PAGE_SIZE = 25;

function pageReset(clearSearch = false) {
  return {
    type: ActionTypes.TRANSLATOR_PAGE_RESET,
    clearSearch,
  };
}

function receiveTranslatorsReload(translators) {
  return {
    type: ActionTypes.TRANSLATOR_RELOAD_RECEIVE,
    translators: translators,
    receivedAt: Date.now(),
  };
}

function receiveTranslatorsReloadFailure(error) {
  return {
    type: ActionTypes.TRANSLATOR_RELOAD_RECEIVE_FAILURE,
    receivedAt: Date.now(),
    error,
  };
}

const validationError = new Error();
validationError.status = 520;
validationError.details = {};
validationError.details.errors = [{ message: 'Unexpected response from server' }];

function fetchTranslators(pageNum, pageSize, successFunc, failFunc) {
  return (dispatch, getState) => {
    const state = getState();
    try {
      const { sortField, sortOrder, searchPattern } = state.translators;
      let url = `/translator?pageSize=${pageSize}&pageNum=${pageNum}&searchPattern=${encodeURIComponent(
        `${searchPattern}`.trim()
      )}`;

      // determine sorting if required
      if (sortField !== null) {
        url += `&sortField=${sortField}&sortOrder=${SORT_ORDER_MAPPING[sortOrder]}`;
      }

      ApiClient.httpGet(url)
        .then(resp => {
          resp.constructor === Array
            ? dispatch(successFunc(resp))
            : dispatch(failFunc(validationError));
        })
        .catch(err => {
          dispatch(failFunc(err));
        });
    } catch (err) {
      dispatch(failFunc(err));
    }
  };
}

function requestReloadTranslators() {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.TRANSLATOR_RELOAD_REQUEST,
    });
    const state = getState();

    // Reload the grid as one big page from the back end in case the
    // translator we just created doesn't match filter criteria
    return dispatch(
      fetchTranslators(
        1,
        PAGE_SIZE * state.translators.pagesRequested,
        receiveTranslatorsReload,
        receiveTranslatorsReloadFailure
      )
    );
  };
}

function requestNextPageOfTranslators() {
  return {
    type: ActionTypes.TRANSLATOR_NEXT_PAGE_REQUEST,
  };
}

function receiveTranslatorsNextPage(translators) {
  return {
    type: ActionTypes.TRANSLATOR_NEXT_PAGE_RECEIVE,
    translators: translators,
    receivedAt: Date.now(),
  };
}

function receiveTranslatorsNextPageFailure(error) {
  return {
    type: ActionTypes.TRANSLATOR_NEXT_PAGE_RECEIVE_FAILURE,
    receivedAt: Date.now(),
    error,
  };
}

function translatorCreating() {
  return {
    type: ActionTypes.TRANSLATOR_CREATING,
  };
}

function translatorCreated(translator) {
  return {
    type: ActionTypes.TRANSLATOR_CREATED,
    translator,
  };
}

function translatorCreateFailed() {
  return {
    type: ActionTypes.TRANSLATOR_CREATE_FAILED,
  };
}

export function reloadTranslators(clearSearch = false) {
  return dispatch => {
    dispatch(pageReset(clearSearch));
    return dispatch(requestReloadTranslators());
  };
}

export function searchTranslators(searchPattern) {
  return dispatch => {
    dispatch({
      type: ActionTypes.TRANSLATOR_SEARCH,
      searchPattern,
    });
    dispatch(reloadTranslators());
  };
}

function translatorGridSortChanged(field, order) {
  return {
    type: ActionTypes.TRANSLATOR_GRID_SORT_CHANGED,
    field,
    order,
  };
}

function translatorUpdating() {
  return {
    type: ActionTypes.TRANSLATOR_UPDATING,
  };
}

function translatorUpdated(translator) {
  return {
    type: ActionTypes.TRANSLATOR_UPDATED,
    translator,
  };
}

function translatorUpdateFailed() {
  return {
    type: ActionTypes.TRANSLATOR_UPDATE_FAILED,
  };
}

export function loadMoreTranslators() {
  return (dispatch, getState) => {
    dispatch(requestNextPageOfTranslators());
    const state = getState();
    return dispatch(
      fetchTranslators(
        state.translators.pagesRequested,
        PAGE_SIZE,
        receiveTranslatorsNextPage,
        receiveTranslatorsNextPageFailure
      )
    );
  };
}

export function createNewTranslator(translator) {
  return dispatch => {
    dispatch(translatorCreating());
    return ApiClient.httpPost('/translator', translator)
      .then(resp => {
        dispatch(
          translatorCreated({
            ...translator,
            id: resp.id,
          })
        );

        return dispatch(requestReloadTranslators());
      })
      .catch(ex => {
        dispatch(translatorCreateFailed());
        throw ex;
      });
  };
}

export function sortGridBy(field, order) {
  return dispatch => {
    dispatch(translatorGridSortChanged(field, order));
    return dispatch(requestReloadTranslators());
  };
}

export function updateTranslator(translator) {
  return (dispatch, getState) => {
    dispatch(translatorUpdating());
    return ApiClient.httpPut('/translator/' + translator.id, translator)
      .then(resp => {
        dispatch(
          translatorUpdated({
            id: resp.id,
            ...translator,
          })
        );

        dispatch(requestReloadTranslators());
      })
      .catch(ex => {
        dispatch(translatorUpdateFailed());
        throw ex;
      });
  };
}

export default ActionTypes;
