import React from 'react';
import PropTypes from 'prop-types';
import { css, cx } from 'react-emotion';

import SortableColumnHeader from './SortableColumnHeader';
import { SortOrder } from '../utils/Enums';

class SortableColumnHeaders extends React.Component {
  getSortOrder(field) {
    const { sortField, sortOrder } = this.props;
    return sortField === field ? sortOrder : SortOrder.UNSORTED;
  }

  render() {
    const { columns, onSortChanged, scrollable } = this.props;

    return (
      <thead>
        <tr>
          {columns.map((column, i) => {
            const content = column.sortKey ? (
              <SortableColumnHeader
                key={column.title}
                title={column.title}
                sortName={column.sortKey}
                onClick={onSortChanged}
                sortOrder={this.getSortOrder(column.sortKey)}
              />
            ) : (
              column.title
            );
            const sticky = css`
              position: sticky;
              top: -41px;
            `;
            // TODO drop shadow
            const className = cx(scrollable && sticky, column.className);

            return (
              <th key={column.title || i} className={className}>
                {content}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  }
}

SortableColumnHeaders.propTypes = {
  columns: PropTypes.array.isRequired,
  onSortChanged: PropTypes.func.isRequired,
  sortField: PropTypes.string,
  sortOrder: PropTypes.number.isRequired,
  scrollable: PropTypes.bool,
};

export default SortableColumnHeaders;
