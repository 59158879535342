/**
 * Check if the user has the specified permission
 *
 * @param {Element|Object} context - React element (with a session prop) or
 *                                   session object containing the user's
 *                                   permissions
 * @param {string} permission - The requested permission, e.g. "admin:read"
 */

export default (context, permission) => {
  const session = context.props ? context.props.session : context;
  if (typeof session === 'undefined') {
    console.warn(
      "Component doesn't have a session prop! Perhaps you forgot to plumb it down or connect the component?"
    );
  } else {
    return session && session.permissions && session.permissions.indexOf(permission) >= 0;
  }
};
