import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

const createEditLink = jobs => {
  if (!jobs || jobs.length === 0) {
    return '';
  }
  let editLink = jobs[0].editLink;
  if (jobs.length > 1) {
    const splitUrl = editLink.split('?');
    const query = splitUrl.length > 1 ? splitUrl[1] + '&mode=jobPartQueue' : 'mode=jobPartQueue';

    let urlExceptQuery = splitUrl[0];
    let i = 1;
    while (i < jobs.length) {
      const jobPartId = jobs[i].jobUri.split('web:jobPart:')[1];
      urlExceptQuery = urlExceptQuery + '-' + jobPartId;
      i = i + 1;
    }
    editLink = urlExceptQuery + '?' + query;
  }
  return editLink;
};

const getJobPartId = job => {
  if (job.jobUri) {
    return job.jobUri.split('web:jobPart:')[1];
  }
  return '';
};

const isReserved = job => job.status === 'EMAILED' || job.status === 'RESERVED';

const isAccepted = job => job.status === 'ASSIGNED' || job.status === 'ACCEPTED';

const isExpired = (job, timeNow) =>
  (job &&
    job.dateReservationExpires &&
    isReserved(job) &&
    timeNow &&
    moment(job.dateReservationExpires).isBefore(timeNow)) ||
  false;

const isExclusiveOffer = job => job.assignedByUri !== job.freelancerUri;

const getFileCount = job =>
  job.isBundle && job.childOffers ? Object.values(job.childOffers).length : 1;

const calculateBundleValues = bundle => {
  const offers = Object.values(bundle.childOffers);

  const aggregateProperty = property => {
    return offers.reduce((sum, offer) => sum + (offer[property] || 0), 0);
  };

  const aggregateScopingReport = () => {
    const scopingReports = offers.map(offer => offer.scopingReport);
    const aggregatedReports = scopingReports.reduce((aggReports, report) => {
      report.forEach(entry => {
        const key = `${entry.matchType}|${entry.matchPercent}`;
        aggReports.hasOwnProperty(key)
          ? (aggReports[key].wordCount = aggReports[key].wordCount + entry.wordCount)
          : (aggReports[key] = entry);
        return aggReports;
      });
      return aggReports;
    }, {});
    return Object.values(aggregatedReports);
  };

  const getMinDate = typeOfDate => {
    return new Date(
      Math.min.apply(
        null,
        offers.map(offer => {
          return new Date(offer[typeOfDate]);
        })
      )
    ).toISOString();
  };

  const newValues = {
    freelancerUri: offers[0].freelancerUri,
    clientUri: offers[0].clientUri,
    clientName: offers[0].clientName,
    name: `Bundle of ${offers.length} jobs`,
    status: offers[0].status,
    subject: offers[0].subject,
    mediaType: offers[0].mediaType,
    translationSkill: offers[0].translationSkill,
    sourceLocale: offers[0].sourceLocale,
    targetLocale: offers[0].targetLocale,
    currency: offers[0].currency,
    dateDue: getMinDate('dateDue'),
    dateReservationExpires: getMinDate('dateReservationExpires'),
    assignedByUri: offers[0].assignedByUri,
    price: aggregateProperty('price'),
    workUnits: aggregateProperty('workUnits'),
    weightedWorkUnits: aggregateProperty('weightedWorkUnits'),
    scopingReport: aggregateScopingReport(),
    isBundle: true,
    editLink: createEditLink(offers),
    isPostMortemScoring: offers[0].isPostMortemScoring,
    parentOfferId: offers[0].parentOfferId,
  };

  Object.assign(bundle, newValues);
};

const createBundle = offers => {
  const bundle = {
    id: uuidv4(),
    isBundle: true,
    childOffers: offers.reduce((map, offer) => {
      map[offer.id] = offer;
      return map;
    }, {}),
  };

  calculateBundleValues(bundle);
  return bundle;
};

export const OfferUtils = {
  isReserved,
  isAccepted,
  isExpired,
  isExclusiveOffer,
  getFileCount,
  getJobPartId,
  createBundle,
  calculateBundleValues,
  createEditLink,
};
