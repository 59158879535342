import GenericCrudActions from './GenericCrudActions';
import ApiClient from '../ApiClient';

const validationError = new Error();
validationError.status = 520;
validationError.details = {};
validationError.details.errors = [{ message: 'Unexpected response from server' }];

class Certification extends GenericCrudActions {
  constructor() {
    super('certification', 'id');
  }

  // FIXME: GenericCrudActions does a reload in createNew, but this doesn't make sense for certifications
  //  (this actually causes an error because /certifications doesn't handle GETs) because we are only using
  //  it to save certifications, actual loading of certifications is done when the profile is loaded. Not reloading
  //  here fixes the immediate problem, but we need to simplify this logic and shouldn't be using redux for state that
  //  has no reason for being shared
  createNew(item) {
    return dispatch => {
      dispatch(this.creating());
      return ApiClient.httpPost(`/${this.type}`, item)
        .then(resp => {
          const newValue = {
            ...resp,
            id: resp[this.idField],
          };
          dispatch(this.created(newValue));

          // this is what we don't want to do for certifications
          //dispatch(this.reload());
          console.log('not reloading!');

          return newValue;
        })
        .catch(ex => {
          dispatch(this.createFailed());
          throw ex;
        });
    };
  }
}

export default Certification;
