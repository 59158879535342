import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import MediaTypeSubjectList from './MediaTypeSubjectList';
import AddEditMediaTypeSubjectSettings from './AddEditMediaTypeSubjectSettings';

function MediaTypeSubjectView(props) {
  return (
    <Switch>
      <Route
        path="/settings/mediaTypeSubject/:mediaType/create"
        render={({ match }) => (
          <AddEditMediaTypeSubjectSettings {...props} mediaType={match.params.mediaType} />
        )}
      />
      <Route
        path="/settings/mediaTypeSubject/:mediaType/:subject"
        render={({ match }) => (
          <AddEditMediaTypeSubjectSettings
            {...props}
            mediaType={match.params.mediaType}
            subject={match.params.subject}
          />
        )}
      />
      <Route render={() => <MediaTypeSubjectList {...props} />} />
    </Switch>
  );
}

MediaTypeSubjectView.propTypes = {
  session: PropTypes.object.isRequired,
  priceSchemes: PropTypes.object.isRequired,
  weightSchemes: PropTypes.object.isRequired,
  translationSkills: PropTypes.object.isRequired,
  mediaTypes: PropTypes.object.isRequired,
  subjects: PropTypes.object.isRequired,
};

MediaTypeSubjectView.defaultProps = {
  session: Object.prototype,
  priceSchemes: Object.prototype,
  weightSchemes: Object.prototype,
};

export default MediaTypeSubjectView;
