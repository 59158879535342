import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { paginateAll } from '../../utils/paginationHelper';
import { Container } from '@amzn/et-polaris-utils';
import {
  Button,
  ColumnLayout,
  Icon,
  Input,
  Modal,
  Select,
  Spinner,
} from '@amzn/awsui-components-react/polaris';
import ApiClient from '../../ApiClient';
import { Checkbox } from 'react-bootstrap';
import { inferEnvironment } from '@amzn/et-console-components';

const LIST_OFFERS_PAGE_SIZE = 25;
const VALID_NUMBER_OF_WORDS_REGEX = /^[1-9][0-9]*$/;
// We want to continue attempting to assign offers after a failure but we need to abort if we are only getting failures
const MAX_CONSECUTIVE_FAILED_ASSIGNMENTS = 25;

const getOptions = options => Object.keys(options).map(key => ({ label: options[key], id: key }));

// use TP compatible URI for client up front so we don't have to transform later, also add empty option at the beginning
const getClientOptions = options => {
  const clientNames = Object.keys(options).map(key => options[key]);
  const sortedClientNames = clientNames.sort((a, b) => a.localeCompare(b));
  return [{ label: '-', id: null }].concat(
    sortedClientNames.map(clientName => ({ label: clientName, id: `web:clientName:${clientName}` }))
  );
};

const getLanguageOptions = availableLocales =>
  availableLocales.map(locale => ({
    label: locale.name,
    labelTag: locale.code,
    id: locale.code,
  }));

const getSelect = (id, options, setter, invalid = false, loading = false, fetchError = false) => (
  <Select
    id={id}
    options={options}
    empty="No options"
    placeholder="Choose an option"
    selectedLabel="Selected"
    onChange={event => setter(event.detail.selectedOption.id)}
    invalid={invalid}
    loading={loading}
    statusType={fetchError ? 'error' : 'finished'}
    errorText="error loading options, try refreshing the page"
    enableFiltering={true}
  />
);

const keyValuePane = columns => (
  <div className="awsui-util-container">
    <ColumnLayout columns={1} variant="text-grid">
      <div data-awsui-column-layout-root="true" style={{ maxWidth: '500px' }}>
        {columns.map((column, columnIndex) => (
          <div key={columnIndex} style={{ padding: '0px', margin: '15px 0px 0px 0px' }}>
            {column.map(
              (item, itemIndex) =>
                item.value && (
                  <div key={itemIndex}>
                    <div className="awsui-util-label">{item.key}</div>
                    <div>{item.value}</div>
                  </div>
                )
            )}
          </div>
        ))}
      </div>
    </ColumnLayout>
  </div>
);

// only split out for testing
export const AssignToVendorView = ({
  globalState,
  apiClient,
  vendorsLoading,
  vendors,
  vendorsFetchError,
  clients,
  clientsLoading,
  clientsFetchError,
}) => {
  const [mediaType, setMediaType] = useState(null);
  const [subject, setSubject] = useState(null);
  const [translationSkill, setTranslationSkill] = useState(null);
  const [client, setClient] = useState(null);
  const [sourceLanguage, setSourceLanguage] = useState(null);
  const [targetLanguage, setTargetLanguage] = useState(null);
  const [vendor, setVendor] = useState(null);
  const [numberOfWords, setNumberOfWords] = useState(null);
  const [assignNextWorkflowStep, setAssignNextWorkflowStepStep] = useState(false);

  const [loading, setLoading] = useState(false);
  const [wordsAssigned, setWordsAssigned] = useState(0);
  const [submitted, setSubmitted] = useState(false);

  const [invalidMediaType, setInvalidMediaType] = useState(false);
  const [invalidSubject, setInvalidSubject] = useState(false);
  const [invalidTranslationSkill, setInvalidTranslationSkill] = useState(false);
  const [invalidSourceLanguage, setInvalidSourceLanguage] = useState(null);
  const [invalidTargetLanguage, setInvalidTargetLanguage] = useState(null);
  const [invalidVendor, setInvalidVendor] = useState(null);
  const [invalidNumberOfWords, setInvalidNumberOfWords] = useState(null);

  const listOffers = maxWords => {
    const filterCriteria = {
      mediaType,
      subject,
      translationSkill,
      sourceLocale: sourceLanguage,
      targetLocale: targetLanguage,
      status: 'OPEN',
      workUnits: maxWords,
    };
    if (client) {
      filterCriteria['clientUri'] = client;
    }

    const filterText = Object.keys(filterCriteria)
      .map(key => `${key}%3D${filterCriteria[key]}`)
      .join('|');
    // TODO: sorting?
    const url = `/offer?pageNum=0&pageSize=${LIST_OFFERS_PAGE_SIZE}&filterText=${filterText}`;

    return apiClient.httpGet(url);
  };

  const assignToVendor = offerId => {
    const url = `/vendor/${vendor}/${offerId}?assignNextWorkflowStep=${assignNextWorkflowStep}&offerReassignmentType=MANUAL_ASSIGN_TO_AGENCY`;
    return apiClient.httpPut(url).then(resp => {
      return resp;
    });
  };

  const assignLoop = async () => {
    let response;
    let done = false;
    let assigned = 0;
    let consecutiveFailedAssignmentCount = 0;
    do {
      response = await listOffers(numberOfWords - assigned);

      if (response.offers) {
        for (let i = 0; i < response.offers.length; i++) {
          const offer = response.offers[i];

          try {
            if (assigned + offer.workUnits > numberOfWords) {
              continue;
            }

            const assignResponse = await assignToVendor(offer.id);
            consecutiveFailedAssignmentCount = 0;
            if (assignResponse.wordsCount != null) {
              setWordsAssigned(state => state + assignResponse.wordsCount);
              assigned += assignResponse.wordsCount;
            }
            if (assigned >= numberOfWords) {
              break;
            }
          } catch (e) {
            console.warn(`Failed to assign offer ${offer.id} to ${vendor}; ${JSON.stringify(e)}`);
            consecutiveFailedAssignmentCount++;
            if (consecutiveFailedAssignmentCount >= MAX_CONSECUTIVE_FAILED_ASSIGNMENTS) {
              break;
            }
          }
        }
      }

      // done if out of pages, we've assigned enough words, or too many assignments have failed
      done =
        assigned >= numberOfWords ||
        response.offers.length === 0 ||
        consecutiveFailedAssignmentCount >= MAX_CONSECUTIVE_FAILED_ASSIGNMENTS;
    } while (!done);
  };

  const handleSubmit = () => {
    setInvalidMediaType(mediaType == null);
    setInvalidSubject(subject == null);
    setInvalidTranslationSkill(translationSkill == null);
    setInvalidSourceLanguage(sourceLanguage == null);
    setInvalidTargetLanguage(targetLanguage == null);
    setInvalidVendor(vendor == null);
    const numberOfWordsIsInvalid = VALID_NUMBER_OF_WORDS_REGEX.exec(numberOfWords) == null;
    setInvalidNumberOfWords(numberOfWordsIsInvalid);

    const allValid =
      mediaType != null &&
      subject != null &&
      translationSkill != null &&
      sourceLanguage != null &&
      targetLanguage != null &&
      vendor != null &&
      !numberOfWordsIsInvalid;

    if (allValid) {
      setLoading(true);
      setSubmitted(true);
      assignLoop()
        // using .finally causes problems with tests
        .then(() => setLoading(false))
        // TODO: UI error
        .catch(error => {
          setLoading(false);
          console.error(error);
        });
    }
  };

  const resetForm = () => {
    setSubmitted(false);
    setWordsAssigned(0);
  };

  const getModal = () => {
    const areEnoughWordsAssignedForSuccess = wordsAssigned >= numberOfWords * 0.9;
    const content = (
      <>
        Total Words Assigned: {wordsAssigned}
        {loading && <Spinner />}
        {!loading && (
          <Icon
            name={areEnoughWordsAssignedForSuccess ? 'status-positive' : 'status-warning'}
            variant={areEnoughWordsAssignedForSuccess ? 'success' : 'warning'}
          />
        )}
      </>
    );
    return (
      <div className="disable-modal-dismiss-button">
        <Modal
          content={content}
          visible={true}
          onDismiss={resetForm}
          header="Assigning"
          footer={
            <span className="awsui-util-f-r">
              <Button variant="primary" disabled={loading} text="Ok" onClick={resetForm} />
            </span>
          }
        />
      </div>
    );
  };

  const getFields = () => {
    const fields = [
      [
        {
          key: 'Media Type',
          value: getSelect(
            'mediaTypeSelect',
            getOptions(globalState.mediaTypes),
            setMediaType,
            invalidMediaType
          ),
        },
      ],
      [
        {
          key: 'Subject',
          value: getSelect(
            'subjectSelect',
            getOptions(globalState.subjects),
            setSubject,
            invalidSubject
          ),
        },
      ],
      [
        {
          key: 'Skill',
          value: getSelect(
            'translationSkillSelect',
            getOptions(globalState.translationSkills),
            setTranslationSkill,
            invalidTranslationSkill
          ),
        },
      ],
      [
        {
          key: 'Client',
          value: getSelect(
            'clientSelect',
            getClientOptions(clients),
            setClient,
            false,
            clientsLoading,
            clientsFetchError
          ),
        },
      ],
      [
        {
          key: 'Source Language',
          value: getSelect(
            'sourceLanguageSelect',
            getLanguageOptions(globalState.availableLocales),
            setSourceLanguage,
            invalidSourceLanguage
          ),
        },
      ],
      [
        {
          key: 'Target Language',
          value: getSelect(
            'targetLanguageSelect',
            getLanguageOptions(globalState.availableLocales),
            setTargetLanguage,
            invalidTargetLanguage
          ),
        },
      ],
      [
        {
          key: '# of Words',
          value: (
            <Input
              id="numberOfWordsInput"
              invalid={invalidNumberOfWords}
              onChange={event => setNumberOfWords(event.detail.value)}
            />
          ),
        },
      ],
      [
        {
          key: 'Agency',
          value: getSelect(
            'vendorSelect',
            getOptions(vendors),
            setVendor,
            invalidVendor,
            vendorsLoading,
            vendorsFetchError
          ),
        },
      ],
      [
        {
          key: '',
          value: (
            <Checkbox
              id="assignNextWorkflowStep-check"
              className="awsui-util-label"
              onChange={event => setAssignNextWorkflowStepStep(event.target.checked)}
            >
              {' '}
              Assign next workflow step
            </Checkbox>
          ),
        },
      ],
    ];

    return fields;
  };

  return (
    <>
      <Container
        title="Assign jobs to agency"
        description="Remove jobs from self-service and reassign them to an agency."
      >
        {keyValuePane(getFields())}
      </Container>
      <div className="awsui-util-action-stripe">
        <div className="awsui-util-action-stripe-title" />
        <div className="awsui-util-action-stripe-group">
          <Button text="Clear" variant="link" onClick={() => window.location.reload()} />
          <Button
            text="Submit"
            id="submitButton"
            variant="primary"
            onClick={() => handleSubmit()}
          />
        </div>
      </div>
      {submitted && getModal()}
    </>
  );
};

AssignToVendorView.propTypes = {
  apiClient: PropTypes.object,
  globalState: PropTypes.object,
  vendors: PropTypes.object,
  vendorsLoading: PropTypes.bool,
  vendorsFetchError: PropTypes.bool,
  clients: PropTypes.object,
  clientsLoading: PropTypes.bool,
  clientsFetchError: PropTypes.bool,
};

export const AssignToVendor = ({ globalState }) => {
  const [vendorsLoading, setVendorsLoading] = useState(true);
  const [vendors, setVendors] = useState({});
  const [vendorsFetchError, setVendorsFetchError] = useState(false);
  const [clientsLoading, setClientsLoading] = useState(true);
  const [clients, setClients] = useState({});
  const [clientsFetchError, setClientsFetchError] = useState(false);

  useEffect(() => {
    ApiClient.httpGet('/vendor')
      .then(response => {
        setVendors(response);
      })
      .catch(error => {
        console.error(error);
        setVendorsFetchError(true);
      })
      .finally(() => {
        setVendorsLoading(false);
      });

    paginateAll('/client')
      .then(response => {
        setClients(response);
      })
      .catch(error => {
        console.error(error);
        setClientsFetchError(true);
      })
      .finally(() => {
        setClientsLoading(false);
      });
  }, []);

  return AssignToVendorView({
    globalState,
    vendorsLoading,
    vendors,
    vendorsFetchError,
    clientsLoading,
    clients,
    clientsFetchError,
    apiClient: ApiClient,
  });
};

AssignToVendor.propTypes = {
  globalState: PropTypes.object,
};
