import { css } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Form, FormControl, Modal, Table } from 'react-bootstrap';
import { Link, Switch, Route } from 'react-router-dom';

import canDo from '../../utils/canDo';
import InfiniteScroll from '../InfiniteScroll.js';
import Spinner from '../Spinner';
import TranslatorDialog from './TranslatorDialog';
import ErrorAlert from '../ErrorAlert';
import { LegacyContainer } from '@amzn/et-polaris-utils';
import { displayStatus } from './TranslatorStatuses';

import SortableColumnHeaders from '../SortableColumnHeaders';
import ProfileView from './ProfileView';

function TranslatorView(props) {
  return (
    <Switch>
      <Route
        path="/translators/:userId"
        render={({ match }) => <ProfileView {...props} match={match} />}
      />
      <Route render={() => <Main {...props} />} />
    </Switch>
  );
}

TranslatorView.propTypes = {
  session: PropTypes.object.isRequired,
  translators: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  onCreateCertificationEvent: PropTypes.func.isRequired,
  onRemoveCertificationEvent: PropTypes.func.isRequired,
  availableLocales: PropTypes.object.isRequired,
};

TranslatorView.defaultProps = {
  profile: Object.prototype,
  onCreateCertificationEvent: Function.prototype,
  onRemoveCertificationEvent: Function.prototype,
};

export class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditModal: false,
      currentTranslator: null,
      searchPattern: '',
    };

    this.handleOpenEditModal = this.handleOpenEditModal.bind(this);
    this.handleCloseEditModal = this.handleCloseEditModal.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.updateSearchPattern = this.updateSearchPattern.bind(this);
    // Sort-related
    this.handleSortChange = this.handleSortChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.onReload(true);
  }

  handleOpenEditModal(translator) {
    this.setState({ showEditModal: true, currentTranslator: translator });
  }

  handleCloseEditModal() {
    this.setState({ showEditModal: false });
  }

  handleSearch(e) {
    e.preventDefault();
    const { searchPattern } = this.state;
    this.props.onSearch(searchPattern);
  }

  updateSearchPattern(e) {
    this.setState({ searchPattern: e.target.value });
  }

  handleSortChange(column, order) {
    this.props.onSortChange(column, order);
  }

  render() {
    const {
      translators: { items, isFetching, lastError, sortOrder, sortField, fullyLoaded },
    } = this.props;

    const columns = [
      {
        title: 'Username',
        sortKey: 'username',
      },
      {
        title: 'Name',
        sortKey: 'lastName',
      },
      {
        title: 'Email',
        sortKey: 'email',
      },
      {
        title: 'Status',
        sortKey: 'status',
      },
      {
        /* Empty column for action buttons */
        title: '',
        sortKey: null,
      },
    ];

    return (
      <LegacyContainer
        title="Manage Translators"
        rawButtons={
          <>
            <Button disabled={isFetching} onClick={() => this.props.onReload()}>
              Refresh
            </Button>
            <Modal show={this.state.showEditModal} onHide={this.handleCloseEditModal}>
              {this.state.currentTranslator && (
                <TranslatorDialog
                  closeHandler={this.handleCloseEditModal}
                  onUpdateEvent={this.props.onUpdateEvent}
                  translator={this.state.currentTranslator}
                  title={'Edit Translator'}
                />
              )}
            </Modal>
          </>
        }
      >
        <InfiniteScroll
          onInfiniteScroll={this.props.onLoadMore}
          isLoading={isFetching}
          fullyLoaded={fullyLoaded}
          fireOnChange={items.length}
        />
        <Form inline className="table-search" id="searchForm" onSubmit={this.handleSearch}>
          <FormControl onChange={this.updateSearchPattern} value={this.state.searchPattern} />{' '}
          <Button type="submit" className="btn-sm" disabled={isFetching}>
            Search
          </Button>
        </Form>
        {lastError && <ErrorAlert title="Failed to load Translators" errorInfo={lastError} />}
        {!lastError && !isFetching && items.length === 0 && (
          <Alert bsStyle="info">No translators</Alert>
        )}
        {items.length > 0 && (
          <Table striped condensed hover>
            <SortableColumnHeaders
              columns={columns}
              sortField={sortField}
              sortOrder={sortOrder}
              onSortChanged={this.handleSortChange}
            />
            <tbody>
              {[...items].map(translator => (
                <tr key={translator.id}>
                  <td>{translator.username}</td>
                  <td>
                    {translator.firstName} {translator.lastName}
                  </td>
                  <td>{translator.email}</td>
                  <td>{displayStatus(translator.status)}</td>

                  <td className="row-actions">
                    {canDo(this, 'translator:read') && (
                      <Link className="btn btn-xs btn-default" to={`/translators/${translator.id}`}>
                        Profile
                      </Link>
                    )}
                    {canDo(this, 'translator:write') && (
                      <Button
                        bsSize="xsmall"
                        onClick={() => this.handleOpenEditModal(translator)}
                        autoFocus
                      >
                        Edit
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {isFetching && (
          <div
            css={css`
              text-align: center;
            `}
          >
            <Spinner size={30} />
          </div>
        )}
      </LegacyContainer>
    );
  }
}

Main.propTypes = {
  onReload: PropTypes.func,
  onLoadMore: PropTypes.func,
  onSortChange: PropTypes.func,
  translators: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  mediaTypes: PropTypes.object.isRequired,
  subjects: PropTypes.object.isRequired,
  onSearch: PropTypes.func,
  onCreateEvent: PropTypes.func.isRequired,
  onUpdateEvent: PropTypes.func.isRequired,
};

Main.defaultProps = {
  onReload: Function.prototype,
  onLoadMore: Function.prototype,
  onSortChange: Function.prototype,
  session: Object.prototype,
  mediaTypes: Object.prototype,
  subjects: Object.prototype,
  onSearch: Function.prototype,
  onCreateEvent: Function.prototype,
  onUpdateEvent: Function.prototype,
};

export default TranslatorView;
