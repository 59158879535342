import ApiClient from '../ApiClient';
import { SortOrder } from '../utils/Enums';

const SORT_ORDER_MAPPING = {
  [SortOrder.ASC]: 'asc',
  [SortOrder.DESC]: 'desc',
  // Should not happen
  [SortOrder.UNSORTED]: 'asc',
};

const validationError = new Error();
validationError.status = 520;
validationError.details = {};
validationError.details.errors = [{ message: 'Unexpected response from server' }];

const PAGE_SIZE = 25;

class GenericCrudActions {
  constructor(type, idField = undefined) {
    this.type = type;
    this.prefix = type.toUpperCase();
    this.idField = idField ? idField : `${type}Id`;
  }

  actionType(suffix) {
    return this.prefix + suffix;
  }

  resetPageActionType() {
    return this.actionType('_RESET_PAGE');
  }

  requestReloadActionType() {
    return this.actionType('_REQUEST_RELOAD');
  }

  finishedFetchActionType() {
    return this.actionType('_FINISHED_FETCH');
  }

  failReloadActionType() {
    return this.actionType('_FAIL_RELOAD');
  }

  gridSortChangedActionType() {
    return this.actionType('_GRID_SORT_CHANGED');
  }

  requestNextPageActionType() {
    return this.actionType('_REQUEST_NEXT_PAGE');
  }

  searchActionType() {
    return this.actionType('_SEARCH');
  }

  creatingActionType() {
    return this.actionType('_CREATING');
  }

  createdActionType() {
    return this.actionType('_CREATED');
  }

  createFailedActionType() {
    return this.actionType('_CREATE_FAILED');
  }

  updatingActionType() {
    return this.actionType('_UPDATING');
  }

  updatedActionType() {
    return this.actionType('_UPDATED');
  }

  updateFailedActionType() {
    return this.actionType('_UPDATE_FAILED');
  }

  removingActionType() {
    return this.actionType('_REMOVING');
  }

  removedActionType() {
    return this.actionType('_REMOVED');
  }

  removeFailedActionType() {
    return this.actionType('_REMOVE_FAILED');
  }

  fetchOneActionType() {
    return this.actionType('_FETCH_ONE');
  }

  finishedFetchOneActionType() {
    return this.actionType('_FINISHED_FETCH_ONE');
  }

  failedFetchOneActionType() {
    return this.actionType('_FAILED_FETCH_ONE');
  }

  resetPage(clearSearch = false) {
    return {
      type: this.resetPageActionType(),
      clearSearch,
    };
  }

  requestReload() {
    return {
      type: this.requestReloadActionType(),
    };
  }

  requestNextPage() {
    return {
      type: this.requestNextPageActionType(),
    };
  }

  requestFetchOne(id) {
    return {
      type: this.fetchOneActionType(),
      id,
    };
  }

  finishedFetchOne(data) {
    return {
      type: this.finishedFetchOneActionType(),
      item: data,
      receivedAt: Date.now(),
    };
  }

  failedFetchOne(error) {
    return {
      type: this.failedFetchOneActionType(),
      receivedAt: Date.now(),
      error,
    };
  }

  finishedFetch(data) {
    return {
      type: this.finishedFetchActionType(),
      items: data,
      receivedAt: Date.now(),
    };
  }

  gridSortChanged(field, order) {
    return {
      type: this.gridSortChangedActionType(),
      field,
      order,
    };
  }

  failFetch(error) {
    return {
      type: this.failReloadActionType(),
      receivedAt: Date.now(),
      error,
    };
  }

  creating() {
    return {
      type: this.creatingActionType(),
    };
  }

  created(item) {
    return {
      type: this.createdActionType(),
      item,
    };
  }

  createFailed() {
    return {
      type: this.createFailedActionType(),
    };
  }

  updating(item) {
    return {
      type: this.updatingActionType(),
      item: item,
    };
  }

  updated(item) {
    return {
      type: this.updatedActionType(),
      item,
    };
  }

  updateFailed() {
    return {
      type: this.updateFailedActionType(),
    };
  }

  removing() {
    return {
      type: this.removingActionType(),
    };
  }

  removed(item) {
    return {
      type: this.removedActionType(),
      item,
    };
  }

  removeFailed() {
    return {
      type: this.removeFailedActionType(),
    };
  }

  resetAndReload(clearSearch) {
    return dispatch => {
      dispatch(this.resetPage(clearSearch));
      return dispatch(this.reload());
    };
  }

  reload() {
    return dispatch => {
      dispatch(this.requestReload());
      return dispatch(this.fetch());
    };
  }

  fetchOne(id) {
    return (dispatch, getState) => {
      dispatch(this.requestFetchOne(id));
      const state = getState();
      try {
        const { languagePair } = state['session'];
        const sourceLocale = languagePair.source;
        const targetLocale = languagePair.target;

        let url = `${this.type}/${id}?`;

        // check for locale params
        if (sourceLocale !== undefined) {
          url = `${url}sourceLocale=${encodeURIComponent(sourceLocale)}&`;
        }
        if (targetLocale !== undefined) {
          url = `${url}targetLocale=${encodeURIComponent(targetLocale)}&`;
        }

        ApiClient.httpGet(url)
          .then(resp => {
            dispatch(this.finishedFetchOne(resp));
          })
          .catch(err => {
            dispatch(this.failedFetchOne(err));
          });
      } catch (err) {
        dispatch(this.failedFetchOne(err));
      }
    };
  }

  fetch() {
    return (dispatch, getState) => {
      const state = getState();
      try {
        const { sortField, sortOrder, searchPattern, pageNum, pagesRequested } = state[this.type];
        const { languagePair } = state['session'];
        const sourceLocale = languagePair.source;
        const targetLocale = languagePair.target;

        let url = `${this.type}?`;

        // Reload the grid as one big page from the back end in case the
        // data we just created doesn't match filter criteria
        url = `${url}pageSize=${PAGE_SIZE * pagesRequested}&`;

        // check for locale params
        if (sourceLocale !== undefined) {
          url = `${url}sourceLocale=${encodeURIComponent(sourceLocale)}&`;
        }
        if (targetLocale !== undefined) {
          url = `${url}targetLocale=${encodeURIComponent(targetLocale)}&`;
        }

        // check for pagination params
        if (pageNum !== undefined) {
          url = `${url}pageNum=${pageNum}&`;
        }
        if (searchPattern !== undefined && searchPattern !== '') {
          url = `${url}searchPattern=${encodeURIComponent(searchPattern.trim())}&`;
        }

        // determine sorting if required
        if (sortField !== null && sortField !== undefined) {
          url += `sortField=${sortField}&sortOrder=${SORT_ORDER_MAPPING[sortOrder]}&`;
        }

        ApiClient.httpGet(url)
          .then(resp => {
            resp.constructor === Array
              ? dispatch(this.finishedFetch(resp))
              : dispatch(this.failFetch(validationError));
          })
          .catch(err => {
            dispatch(this.failFetch(err));
          });
      } catch (err) {
        dispatch(this.failFetch(err));
      }
    };
  }

  createNew(item) {
    return dispatch => {
      dispatch(this.creating());
      return ApiClient.httpPost(`/${this.type}`, item)
        .then(resp => {
          const newValue = {
            ...resp,
            id: resp[this.idField],
          };
          dispatch(this.created(newValue));

          dispatch(this.reload());

          return newValue;
        })
        .catch(ex => {
          dispatch(this.createFailed());
          throw ex;
        });
    };
  }

  update(item, reload = true) {
    return dispatch => {
      dispatch(this.updating(item));
      return ApiClient.httpPut(`/${this.type}/${item[this.idField]}`, item)
        .then(resp => {
          const update = dispatch(
            this.updated({
              id: resp[this.idField],
              ...resp,
            })
          );

          return reload ? dispatch(this.reload()) : update;
        })
        .catch(ex => {
          dispatch(this.updateFailed());
          throw ex;
        });
    };
  }

  remove(item) {
    return dispatch => {
      dispatch(this.removing());
      return ApiClient.httpDelete(`/${this.type}/${item[this.idField]}`, item)
        .then(resp => {
          dispatch(this.removed(item));

          return dispatch(this.reload());
        })
        .catch(ex => {
          dispatch(this.removeFailed());
          throw ex;
        });
    };
  }

  sortGridBy(field, order) {
    return dispatch => {
      dispatch(this.gridSortChanged(field, order));
      return dispatch(this.reload());
    };
  }

  loadMore() {
    return dispatch => {
      dispatch(this.requestNextPage());
      return dispatch(this.fetch());
    };
  }

  search(searchPattern) {
    return dispatch => {
      dispatch({
        type: this.searchActionType(),
        searchPattern,
      });
      dispatch(this.resetAndReload(false));
    };
  }
}

export default GenericCrudActions;
