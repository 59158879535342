const formatNumber = (number, options = {}) => {
  if (number || number === 0) {
    return number.toLocaleString('en-US', options);
  }
  return number;
};
export default formatNumber;

export const formatCurrency = (number, currency, maximumFractionDigits = 2) => {
  return formatNumber(Number.parseFloat(number), {
    style: 'currency',
    currency,
    maximumFractionDigits,
  });
};

export const formatPercent = number => {
  return formatNumber(number, { style: 'percent', maximumFractionDigits: 1 });
};
