import ActionTypes from '../actions/Features';

const features = (state = features.initialState, action) => {
  switch (action.type) {
    case 'FINISHED_GET_FEATURES': {
      return {
        ...state,
        ...action.features,
      };
    }
    case ActionTypes.FAILED_GET_FEATURES: {
      return {
        ...state,
        isFetching: false,
        lastError: action.error,
      };
    }
    default:
      return state;
  }
};

features.initialState = {
  // Default all features off
};

export default features;
