import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import GenericNextTokenCrudActions from '../../actions/GenericNextTokenCrudActions';
import PricingView from '../../components/Settings/Pricing/PricingView';

const pricingActions = new GenericNextTokenCrudActions('priceSchemes', 'id');

const mapStateToProps = state => {
  return {
    session: state.session,
    priceSchemes: state.priceSchemes,
    features: state.features,
    availableLocales: state.locales,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCreateEvent: priceScheme => {
      return dispatch(pricingActions.createNew(priceScheme));
    },
    onUpdateEvent: (reload = true) => priceScheme => {
      return dispatch(pricingActions.update(priceScheme, reload));
    },
    onRemoveEvent: priceScheme => {
      return dispatch(pricingActions.remove(priceScheme));
    },
    onReload: (clearSearch = false) => {
      return dispatch(pricingActions.resetAndReload(clearSearch));
    },
    onSortChange: (field, order) => {
      return dispatch(pricingActions.sortGridBy(field, order));
    },
    onLoadMore: () => {
      return dispatch(pricingActions.loadMore());
    },
    onFetchOne: priceSchemeId => {
      return dispatch(pricingActions.fetchOne(priceSchemeId));
    },
    onSearch: searchPattern => {
      return dispatch(pricingActions.search(searchPattern));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PricingView));
