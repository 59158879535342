import { css } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import getScrollYParent from '../utils/getScrollYParent';

export class InfiniteScroll extends React.Component {
  handleScroll = () => {
    if (!this.props.fullyLoaded && !this.props.isLoading) {
      const scrollTop = this.scrollNode.scrollTop;
      const scrollBottom = this.scrollNode.scrollHeight - this.scrollNode.offsetHeight - scrollTop;
      if (scrollBottom < 100) {
        this.props.onInfiniteScroll();
      }
    }
  };

  componentDidMount() {
    this.scrollNode = getScrollYParent(this.node);
    this.scrollNode.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleScroll);
  }

  componentWillUnmount() {
    this.scrollNode.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fireOnChange != null && prevProps.fireOnChange !== this.props.fireOnChange) {
      this.handleScroll();
    }
  }

  render() {
    return (
      <span
        className={css`
          position: absolute;
          z-index: -1;
        `}
        ref={node => (this.node = node)}
      />
    );
  }
}

InfiniteScroll.propTypes = {
  onInfiniteScroll: PropTypes.func,
  isLoading: PropTypes.bool,
  fullyLoaded: PropTypes.bool,
  fireOnChange: PropTypes.any, // if set, a value whose changes will trigger handleScroll
};

InfiniteScroll.defaultProps = {
  onInfiniteScroll: Function.prototype,
  isInfLoading: false,
  fullyLoaded: false,
};

export default InfiniteScroll;
