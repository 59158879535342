import React, { useEffect, useState } from 'react';

import {
  Header,
  Button,
  Box,
  BarChart,
  Container,
  SpaceBetween,
  MixedLineBarChartProps,
} from '@amzn/awsui-components-react-v3/polaris';
import { QualityScore, monthMap, avgScore } from '../../utils/linguistFeedbackUtils';

type BarChartDatum = MixedLineBarChartProps.Datum<string>;

const QualityScoreResultsView = ({
  barChartData,
  yearToDateScore,
}: {
  barChartData: BarChartDatum[];
  yearToDateScore: number;
}): JSX.Element => {
  return (
    <Container
      header={
        <Header variant="h2" description="Average quality score for the last 5 months">
          Quality Score Results
        </Header>
      }
    >
      {/* TODO: Make this reactive */}
      <SpaceBetween size="l">
        <BarChart
          series={[
            {
              title: 'Quality Score',
              type: 'bar',
              data: barChartData,
              valueFormatter: (e): string => e.toLocaleString('en-US') + '%',
            },
            {
              title: 'Quality Threshold',
              type: 'threshold',
              y: 95,
              valueFormatter: (e): string => e.toLocaleString('en-US') + '%',
            },
          ]}
          xDomain={barChartData.map(data => data.x)}
          yDomain={[Math.min(...barChartData.map(data => data.y)) - 5, 100]}
          i18nStrings={{
            filterLabel: 'Filter displayed data',
            filterPlaceholder: 'Filter data',
            filterSelectedAriaLabel: 'selected',
            legendAriaLabel: 'Legend',
            chartAriaRoleDescription: 'line chart',
            xTickFormatter: (e): string => e.split(',').join('\n'),
            yTickFormatter: undefined,
          }}
          ariaLabel={`Average quality score for the last ${barChartData.length} months`}
          errorText="Error loading quality score data"
          height={300}
          hideFilter
          hideLegend
          loadingText="Loading quality score chart"
          recoveryText="Retry"
          xScaleType="categorical"
          empty={
            <Box textAlign="center" color="inherit">
              ~<b>No data available</b>
              <Box variant="p" color="inherit">
                There is no data available
              </Box>
            </Box>
          }
          noMatch={
            <Box textAlign="center" color="inherit">
              <b>No matching data</b>
              <Box variant="p" color="inherit">
                There is no matching data to display
              </Box>
              <Button>Clear filter</Button>
            </Box>
          }
        />
        <Box textAlign="center">
          <Box variant="awsui-key-label">Year-To-Date Quality</Box>
          <Box id="year-to-date-score" variant="awsui-key-label">
            {yearToDateScore.toPrecision(4)}
          </Box>
        </Box>
      </SpaceBetween>
    </Container>
  );
};

type QualityScoreResultsProps = {
  qualityScores: Array<QualityScore>;
};

const QualityScoreResults = ({ qualityScores }: QualityScoreResultsProps): JSX.Element => {
  const [barChartData, setBarChartData] = useState<BarChartDatum[]>([]);
  const [yearToDateScore, setYearToDateScore] = useState<number>(0);
  useEffect(() => {
    setBarChartData(
      qualityScores
        .map(qualityScore => {
          return {
            x: monthMap.get(qualityScore.month) + ' ' + qualityScore.year,
            y: qualityScore.score,
          };
        })
        .slice(-5)
    );
    const currentDate = new Date();
    const currentYear: string = currentDate.getFullYear().toString();
    const scoresFromThisYear: QualityScore[] = qualityScores.filter(
      score => score.year === currentYear
    );
    setYearToDateScore(avgScore(scoresFromThisYear));
  }, [qualityScores]);

  return QualityScoreResultsView({ barChartData, yearToDateScore });
};

export default QualityScoreResults;
