import * as React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import styled from '@emotion/styled';

const HelpText = styled('div')`
  margin-bottom: 1em;
`;

class ImportPriceExample extends React.Component {
  render() {
    const exampleData = [
      {
        sourceLocale: 'en-US',
        targetLocale: 'es-MX',
        currency: 'USD',
        amount: '0.02',
      },
      {
        sourceLocale: 'en-UK',
        targetLocale: 'es-MX',
        currency: 'USD',
        amount: '0.12',
      },
    ];
    return (
      <div>
        <HelpText>
          Upload your price list in CSV format. <br /> <b>WARNING:</b> This action will overwrite
          all prices in this scheme.
        </HelpText>
        Example:
        <BootstrapTable data={exampleData}>
          <TableHeaderColumn dataField="sourceLocale" isKey={true}>
            sourceLocale
          </TableHeaderColumn>
          <TableHeaderColumn dataField="targetLocale">targetLocale</TableHeaderColumn>
          <TableHeaderColumn dataField="currency">currency</TableHeaderColumn>
          <TableHeaderColumn dataField="amount">amount</TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }
}

export default ImportPriceExample;
