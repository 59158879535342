import { css } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Button, Checkbox } from 'react-bootstrap';
import initialMetricsPublisher from '../../utils/metricsPublisher';
import { publishCountForMethod } from '@amzn/ts-ui-metrics';

const Content = styled('div')`
  max-width: 700px;
  margin: 0 auto;
`;

const AcceptSection = styled('div')`
  background-color: #ffa035;
  padding: 18px 35px 18px 35px;
  margin-top: 30px;
  max-width: 700px;
`;

export const termsOfServiceModifiedTimestamp = new Date('2018-01-30T18:00:00-07:00').getTime();

class TermsOfService extends React.Component {
  static propTypes = {
    onAcceptTermsOfService: PropTypes.func,
    onCancelTermsOfService: PropTypes.func,
    termsUpdated: PropTypes.bool,
    showAcceptUI: PropTypes.bool,
  };

  static defaultProps = {
    onAcceptTermsOfService: Function.prototype,
    onCancelTermsOfService: Function.prototype,
    termsUpdated: false,
    showAcceptUI: false,
  };

  constructor(props) {
    super(props);
    this.state = { isChecked: false };
    this.handleChecked = this.handleChecked.bind(this);
  }

  componentDidMount() {
    publishCountForMethod(initialMetricsPublisher, 'TermsOfServiceShown');
    document.body.style.overflow = 'visible';
  }

  handleChecked() {
    const newIsChecked = !this.state.isChecked;

    const actionMetricsPublisher = publishCountForMethod(
      initialMetricsPublisher,
      'TermsOfServiceAcceptToggle'
    );
    actionMetricsPublisher.publishCounter('Checked', newIsChecked ? 1 : 0);

    this.setState({ isChecked: newIsChecked });
  }

  render() {
    const { termsUpdated, showAcceptUI } = this.props;

    return (
      <Content>
        <h2>TERMS OF USE OF THIS PORTAL (“TERMS”)</h2>

        <p>
          This portal is operated by or on behalf of Amazon Europe Core, SARL of 38 avenue John F.
          Kennedy, L-1855 Luxemburg.
        </p>

        <p>
          The Terms set out below are in addition to other agreements you may have entered with
          Amazon Europe Core, SARL. In the event of a conflict between these Terms and such other
          agreements in relation to a matter covered by the Terms, the Terms shall prevail.
        </p>

        <p>
          We provide you access to this portal in connection with the translation-related services
          you are providing to us. In doing so, we will process data which could be personal data on
          you in relation to the services including your name, your contact details, the work that
          you have agreed to perform, data in relation to the performance of a work order or other
          work, correspondence with you, payment information and history and other relevant data
          (together, “Information”).
        </p>

        <p>
          We work to protect the security of your Information during transmission by using Secure
          Sockets Layer (SSL) software, which encrypts information you input.
        </p>

        <p>
          We keep your Information for as long as you are providing services to us. Laws also
          require us to retain your Information for periods of time and we may keep it to deal with
          claims.
        </p>

        <p>You can always contact your project manager if you have any questions.</p>

        <p>
          When you perform translation services for Amazon Europe Core, SARL through this portal,
          your work will be automatically saved by the portal and such work cannot be deleted. Once
          you accept to perform a specific work order on the portal:
        </p>

        <ol type="a">
          <li>
            you commit to provide the service as specified in the Service Agreement you signed with
            us
          </li>
          <li>
            you accept the pricing and timeframe for delivery of the work order in accordance with
            paragraphs 3.2 and 3.3 of the Service Agreement
          </li>
          <li>you will only get paid if you complete the work order in its entirety;</li>
          <li>
            if you have accepted a work order and then decide to reject it, you will not get paid;
          </li>
          <li>
            all segments that you confirm on the portal will be kept and stored by or on behalf of
            Amazon Europe Core SARL and may be used by it ; and
          </li>
          <li>
            any segment that you do not confirm but leave on the portal will be kept and stored by
            or on behalf of Amazon Europe Core SARL and may be used by it.
          </li>
        </ol>

        <p>
          The validity, construction and performance of these Terms are governed by, and shall be
          construed in accordance with, the laws of the Grand Duchy of Luxembourg.
        </p>

        {showAcceptUI && (
          <AcceptSection>
            {termsUpdated ? (
              <span>
                <p
                  id="updated-text"
                  css={css`
                    font-size: 20px;
                    margin-bottom: 18px;
                  `}
                >
                  The Terms of Use have been updated
                </p>
                <Checkbox
                  id="accept-check"
                  inline
                  css={css`
                    margin-right: 50px;
                  `}
                  checked={this.state.isChecked}
                  onChange={this.handleChecked}
                >
                  {' '}
                  I accept the new Terms of Use
                </Checkbox>
              </span>
            ) : (
              <Checkbox
                id="accept-check"
                inline
                css={css`
                  font-size: 15px;
                  margin-right: 50px;
                `}
                checked={this.state.isChecked}
                onChange={this.handleChecked}
              >
                {' '}
                I accept the Terms of Use
              </Checkbox>
            )}
            <Button
              id="accept-terms"
              css={css`
                font-size: 15px;
                margin-right: 25px;
              `}
              disabled={!this.state.isChecked}
              onClick={() => {
                publishCountForMethod(initialMetricsPublisher, 'TermsOfServiceAcceptClick');
                this.props.onAcceptTermsOfService();
              }}
            >
              Accept
            </Button>
            <Button
              id="cancel"
              onClick={() => {
                publishCountForMethod(initialMetricsPublisher, 'TermsOfServiceCancelClick');
                this.props.onCancelTermsOfService();
              }}
            >
              Cancel and sign out
            </Button>
          </AcceptSection>
        )}
      </Content>
    );
  }
}

export default TermsOfService;
