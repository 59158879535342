import React from 'react';
import { Tabs } from 'react-bootstrap';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const RoutingTabs = ({ path, id, children, ...rest }) => {
  const eventKeys = children.filter(c => c).map(c => c.props.eventKey);

  return (
    <Route path={path}>
      {({ match, history }) => {
        const matchingKey = eventKeys.find(x =>
          history.location.pathname.startsWith(`${path}/${x}`)
        );

        // redirect to the first tab if it's not a valid tab url
        if (!matchingKey) {
          return <Redirect replace to={`${path}/${eventKeys[0]}`} />;
        }

        return (
          <Tabs
            activeKey={matchingKey}
            onSelect={eventKey => history.push(`${path}/${eventKey}`)}
            {...rest}
            id={id}
          >
            {children}
          </Tabs>
        );
      }}
    </Route>
  );
};

RoutingTabs.propTypes = {
  path: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.array,
};

export default RoutingTabs;
