import tinycolor2 from 'tinycolor2';

export const theme = {};
theme.brand = '#eb5f07';
theme.brandComplement = '#111';
theme.brandBorder = tinycolor2(theme.brand)
  .darken(30)
  .toString();
theme.brandBorderDark = tinycolor2(theme.brand)
  .darken(40)
  .toString();
theme.brandBorderFocus = tinycolor2(theme.brand)
  .spin(-12)
  .darken(26)
  .saturate(18)
  .toString();
theme.brandBorderHover = tinycolor2(theme.brand)
  .darken(30)
  .toString();
theme.brandBackground = '#ec7211';
theme.brandBackgroundLight = tinycolor2(theme.brand)
  .lighten(10)
  .toString();
theme.brandBackgroundPale = tinycolor2(theme.brand)
  .lighten(20)
  .desaturate(10)
  .toString();
theme.brandBackgroundDark = '#eb5f07';
theme.brandBackgroundDarker = '#dd6b10';
theme.brandBackgroundSuperDark = tinycolor2(theme.brand)
  .darken(60)
  .toString();
theme.brandBackgroundError = '#e9cccc';
theme.brandShadow = tinycolor2(theme.brandBorderFocus)
  .setAlpha(0.5)
  .toString();
