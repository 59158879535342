import ActionTypes from '../actions/MediaTypes';

const mediaTypes = (state = mediaTypes.initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_MEDIA_TYPES: {
      return {
        ...state,
        isFetching: true,
        mediaTypes: {},
      };
    }
    case ActionTypes.FINISHED_GET_MEDIA_TYPES: {
      return {
        ...state,
        isFetching: false,
        mediaTypes: action.mediaTypes,
      };
    }
    case ActionTypes.FAILED_GET_MEDIA_TYPES: {
      return {
        ...state,
        isFetching: false,
        lastError: action.error,
      };
    }
    default:
      return state;
  }
};

mediaTypes.initialState = {
  isFetching: false,
  lastError: undefined,
  mediaTypes: {},
};

export default mediaTypes;
