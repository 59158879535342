import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Flashbar, Header, PropertyFilter, Table } from '@amzn/awsui-components-react-v3';
import InfiniteScroll from '../InfiniteScroll';
import { valueOrDash } from '../../utils/ReadableConverter';
import PropertyFilterI18nStrings from '../../utils/PropertyFilterI18nStrings';
import ApiClient from '../../ApiClient';
import { displayStatus } from './TranslatorStatuses';
import TranslatorDialogV2 from './TranslatorDialogV2';
import { keyLookupFromReadable, readableList } from '@amzn/et-console-components';
import Spinner from '@amzn/awsui-components-react-v3/polaris/spinner';
import Box from '@amzn/awsui-components-react-v3/polaris/box';

const PAGE_SIZE = 25;
const DEFAULT_AVAILABLE_OPERATORS = {
  sourceLocale: ['=', '!='],
  targetLocale: ['=', '!='],
  mediaType: ['=', '!='],
  subject: ['=', '!='],
  maxTranslationSkill: ['=', '!='],
};

const onUpdateEvent = (translator, setReload) => {
  return ApiClient.httpPut('/translator/' + translator.id, translator)
    .then(() => {
      setReload(true);
    })
    .catch(e => {
      Promise.reject(new Error(e.details.errors[0].message));
    });
};

const actionButtons = (item, openEditModal) => {
  return (
    <div className={'action-buttons'}>
      <div className={'row-actions'}>
        <Button id={'profile-button'} variant={'normal'} href={`/translator/${item.id}`}>
          Profile
        </Button>
        <Button id={'edit-button'} variant={'normal'} onClick={() => openEditModal(item)}>
          Edit
        </Button>
      </div>
    </div>
  );
};

export const TranslatorViewV2 = ({
  translators,
  isLoading,
  setReload,
  setLoadMore,
  fullyLoaded,
  error,
  certError,
  sortProps,
  setSortProps,
  setSearchPattern,
  setCertFilter,
  globalState,
}) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentTranslator, setCurrentTranslator] = useState(null);
  const [propertyFilterQuery, setPropertyFilterQuery] = useState({
    tokens: [],
    operation: 'and',
  });
  const [availableOperators, setAvailableOperators] = useState(DEFAULT_AVAILABLE_OPERATORS);
  const [defaultOperators, setDefaultOperators] = useState({
    sourceLocale: '=',
    targetLocale: '=',
    mediaType: '=',
    subject: '=',
    maxTranslationSkill: '=',
  });

  const empty =
    error || certError ? <div>Failed to load Translators</div> : <div>No translators</div>;

  const openEditModal = translator => {
    setShowEditModal(true);
    setCurrentTranslator(translator);
  };

  const prepareFilteringOptions = () => {
    const filteringOptions = [];
    const locales = globalState.availableLocales;
    for (const locale of locales) {
      filteringOptions.push(
        {
          propertyKey: 'sourceLocale',
          value: locale.code,
        },
        {
          propertyKey: 'targetLocale',
          value: locale.code,
        }
      );
    }

    const mediaTypes = readableList(globalState, 'mediaType');
    for (const mediaType of mediaTypes) {
      filteringOptions.push({
        propertyKey: 'mediaType',
        value: mediaType,
      });
    }

    const subjects = readableList(globalState, 'subject');
    for (const subject of subjects) {
      filteringOptions.push({
        propertyKey: 'subject',
        value: subject,
      });
    }

    const translationSkills = readableList(globalState, 'maxTranslationSkill');
    for (const skill of translationSkills) {
      filteringOptions.push({
        propertyKey: 'maxTranslationSkill',
        value: skill,
      });
    }
    return filteringOptions;
  };

  const onPropertyFilterChange = detail => {
    if (detail.tokens.some(token => token.propertyKey == null)) {
      const [freeTextTokens, tokens] = detail.tokens.reduce(
        ([freeText, cert], token) => {
          return token.propertyKey == null
            ? [[...freeText, token], cert]
            : [freeText, [...cert, token]];
        },
        [[], []]
      );
      // If there are more than one free text tokens, we want to remove the previous free text token
      // If not, we want to leave the previous token in it's original location.
      if (freeTextTokens.length > 1) {
        // Add the last free text token to the list of cert filter tokens
        tokens.push(freeTextTokens.pop());
        setPropertyFilterQuery({ ...detail, tokens: tokens });
      } else {
        setPropertyFilterQuery(detail);
      }
    } else {
      setPropertyFilterQuery(detail);
    }
    const addToMapOfArrays = (map, key, value) => {
      key in map || (map[key] = []);
      map[key].push(value);
    };

    const filterMap = {};
    const freeTextArray = [];
    const usedOperators = {};

    detail.tokens.forEach(token => {
      const key = token.propertyKey;
      let value = token.value;

      if (!key) {
        freeTextArray.push(value);
      } else if (key && value) {
        value = keyLookupFromReadable(value, globalState, key);
        addToMapOfArrays(filterMap, key, value);
        usedOperators.hasOwnProperty(key) || (usedOperators[key] = [token.operator]);
      }
    });

    setAvailableOperators({ ...DEFAULT_AVAILABLE_OPERATORS, ...usedOperators });
    setDefaultOperators({
      sourceLocale: usedOperators.hasOwnProperty('sourceLocale')
        ? usedOperators.sourceLocale[0]
        : '=',
      targetLocale: usedOperators.hasOwnProperty('targetLocale')
        ? usedOperators.targetLocale[0]
        : '=',
      mediaType: usedOperators.hasOwnProperty('mediaType') ? usedOperators.mediaType[0] : '=',
      subject: usedOperators.hasOwnProperty('subject') ? usedOperators.subject[0] : '=',
      maxTranslationSkill: usedOperators.hasOwnProperty('maxTranslationSkill')
        ? usedOperators.maxTranslationSkill[0]
        : '=',
    });

    if (Object.keys(filterMap).length > 0) {
      const filterArray = [];
      for (const key in filterMap) {
        filterArray.push(`${key}${usedOperators[key][0]}${filterMap[key].join('&')}`);
      }
      setCertFilter(filterArray.join('|'));
    } else {
      setCertFilter('');
    }
    freeTextArray.length > 0
      ? setSearchPattern(freeTextArray.pop())
      : setSearchPattern(prevPattern => (prevPattern == null ? prevPattern : null));
  };

  return (
    <>
      {currentTranslator && (
        <TranslatorDialogV2
          title={'Edit Translator'}
          translator={currentTranslator}
          closeHandler={() => {
            setShowEditModal(false);
          }}
          onUpdateEvent={updatedTranslator => onUpdateEvent(updatedTranslator, setReload)}
          isVisible={showEditModal}
        />
      )}
      <InfiniteScroll
        onInfiniteScroll={() => setLoadMore(true)}
        isLoading={isLoading}
        fullyLoaded={fullyLoaded}
        fireOnChange={translators.length}
      />
      {error && (
        <Flashbar
          items={[
            {
              header: 'Failed to load Translators',
              type: 'error',
              content: error,
              dismissible: false,
            },
          ]}
        />
      )}
      {certError && (
        <Flashbar
          items={[
            {
              header: 'Failed to load Certifications',
              type: 'error',
              content: certError,
              dismissible: false,
            },
          ]}
        />
      )}
      <Table
        header={
          <Header
            variant="h2"
            actions={
              <Button disabled={isLoading} onClick={() => setReload(true)}>
                Refresh
              </Button>
            }
          >
            Manage Translators
          </Header>
        }
        loadingText={'Loading Translators'}
        // We only want to show the table is loading on the initial fetch
        loading={isLoading && translators.length == 0}
        items={translators}
        empty={empty}
        sortingColumn={sortProps.sortingColumn}
        sortingDescending={sortProps.isDescending}
        onSortingChange={({ detail }) => {
          setSortProps({
            sortingColumn: detail.sortingColumn,
            isDescending: detail.isDescending,
          });
        }}
        filter={
          <PropertyFilter
            disabled={isLoading}
            i18nStrings={{
              ...PropertyFilterI18nStrings,
              filteringPlaceholder:
                'Filter translators by certification or name/email and press enter',
            }}
            query={propertyFilterQuery}
            onChange={({ detail }) => onPropertyFilterChange(detail)}
            hideOperations
            customGroupsText={[
              {
                properties: 'Certifications',
                values: 'Certifications values',
                property: 'Certification',
                group: 'certification',
              },
            ]}
            filteringOptions={prepareFilteringOptions()}
            filteringProperties={[
              {
                key: 'sourceLocale',
                operators: availableOperators.sourceLocale,
                defaultOperator: defaultOperators.sourceLocale,
                propertyLabel: 'Source Language',
                groupValuesLabel: 'Source Language Values',
                group: 'certification',
              },
              {
                key: 'targetLocale',
                operators: availableOperators.targetLocale,
                defaultOperator: defaultOperators.targetLocale,
                propertyLabel: 'Target Language',
                groupValuesLabel: 'Target Language Values',
                group: 'certification',
              },
              {
                key: 'mediaType',
                operators: availableOperators.mediaType,
                defaultOperator: defaultOperators.mediaType,
                propertyLabel: 'Media Type',
                groupValuesLabel: 'Media Type Values',
                group: 'certification',
              },
              {
                key: 'subject',
                operators: availableOperators.subject,
                defaultOperator: defaultOperators.subject,
                propertyLabel: 'Subject',
                groupValuesLabel: 'Subject Values',
                group: 'certification',
              },
              {
                key: 'maxTranslationSkill',
                operators: availableOperators.maxTranslationSkill,
                defaultOperator: defaultOperators.maxTranslationSkill,
                propertyLabel: 'Skill',
                groupValuesLabel: 'Skill Values',
                group: 'certification',
              },
            ]}
          />
        }
        columnDefinitions={[
          {
            id: 'username',
            header: 'Username',
            cell: item => valueOrDash(item.username),
            sortingField: 'username',
          },
          {
            id: 'name',
            header: 'Name',
            cell: item => `${item.firstName} ${item.lastName}`,
            sortingField: 'lastName',
          },
          {
            id: 'email',
            header: 'Email',
            cell: item => valueOrDash(item.email),
            sortingField: 'email',
          },
          {
            id: 'status',
            header: 'Status',
            cell: item => valueOrDash(displayStatus(item.status)),
            sortingField: 'status',
          },
          {
            id: 'actionButtons',
            header: '',
            cell: item => actionButtons(item, openEditModal),
          },
        ]}
        footer={
          isLoading && translators.length > 0 ? (
            <Box textAlign="center" color="inherit">
              <Spinner /> Loading More Translators
            </Box>
          ) : null
        }
      ></Table>
    </>
  );
};

TranslatorViewV2.propTypes = {
  translators: PropTypes.array,
  isLoading: PropTypes.bool,
  setReload: PropTypes.func,
  setLoadMore: PropTypes.func,
  fullyLoaded: PropTypes.bool,
  error: PropTypes.string,
  certError: PropTypes.string,
  sortProps: PropTypes.object,
  setSortProps: PropTypes.func,
  setSearchPattern: PropTypes.func,
  setCertFilter: PropTypes.func,
  globalState: PropTypes.object,
};

const fetchTranslators = async (
  pageNum,
  setTranslators,
  setError,
  searchPattern,
  sortProps,
  setLoading,
  setFullyLoaded,
  usersWithCerts,
  extrasLoaded,
  setExtrasLoaded,
  setPagesRequested
) => {
  setLoading(true);
  setError(null);
  let url = `/translator?pageSize=${PAGE_SIZE}`;

  if (searchPattern && searchPattern != '') {
    url += `&searchPattern=${encodeURIComponent(`${searchPattern}`.trim())}`;
  }

  if (sortProps.sortingColumn !== null) {
    url += `&sortField=${sortProps.sortingColumn.sortingField}&sortOrder=${
      sortProps.isDescending ? 'desc' : 'asc'
    }`;
  }
  if (usersWithCerts == null) {
    url += `&pageNum=${pageNum}`;
    ApiClient.httpGet(url)
      .then(resp => {
        if (resp.length < PAGE_SIZE) {
          setFullyLoaded(true);
        }
        setTranslators(translators => translators.concat(resp));
      })
      .catch(e => {
        setError(e.details.errors[0].message);
      })
      .finally(() => setLoading(false));
  } else if (usersWithCerts.length == 0) {
    setTranslators([]);
    setFullyLoaded(true);
    setLoading(false);
  } else {
    let newlyLoaded = [...extrasLoaded];
    let currentPage = pageNum;
    try {
      while (newlyLoaded.length < PAGE_SIZE) {
        const resp = await ApiClient.httpGet(`${url}&pageNum=${currentPage}`);
        newlyLoaded = newlyLoaded.concat(resp.filter(user => usersWithCerts.includes(user.id)));
        if (resp.length < PAGE_SIZE) {
          break;
        }
        currentPage++;
      }
      if (newlyLoaded.length < PAGE_SIZE) {
        setFullyLoaded(true);
      }
      setPagesRequested(currentPage);
      setExtrasLoaded(newlyLoaded.splice(PAGE_SIZE));
      setTranslators(translators => translators.concat(newlyLoaded));
    } catch (error) {
      setError(error.details?.errors[0]?.message || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  }
};

const fetchCerts = (certFilter, setLoadingCerts, setCertError, setUsersWithCerts) => {
  setLoadingCerts(true);
  setCertError(null);
  const url = `/certification/listUsers?filterText=${encodeURIComponent(certFilter)}`;
  ApiClient.httpGet(url)
    .then(resp => {
      // ids returned are in format web:User:<userId>. We want to extract userId.
      setUsersWithCerts(resp.map(userId => parseInt(userId.split(':')[2])));
    })
    .catch(e => {
      setCertError(e.details.errors[0].message || 'Something went wrong');
    })
    .finally(() => setLoadingCerts(false));
};

const Translator = ({ globalState }) => {
  const [searchPattern, setSearchPattern] = useState(null);
  const [certFilter, setCertFilter] = useState('');
  const [translators, setTranslators] = useState([]);
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [sortProps, setSortProps] = useState({
    sortingColumn: null,
    isDescending: false,
  });
  const [pagesRequested, setPagesRequested] = useState(1);
  const [usersWithCerts, setUsersWithCerts] = useState(null);
  const [loadingCerts, setLoadingCerts] = useState(false);
  const [certError, setCertError] = useState(null);
  const [extrasLoaded, setExtrasLoaded] = useState([]);

  useEffect(() => {
    if (reload || loadMore) {
      setPagesRequested(loadMore ? pagesRequested + 1 : 1);
      fetchTranslators(
        pagesRequested,
        setTranslators,
        setError,
        searchPattern,
        sortProps,
        setIsLoading,
        setFullyLoaded,
        usersWithCerts,
        extrasLoaded,
        setExtrasLoaded,
        setPagesRequested
      ).then(() => {
        setReload(false);
        setLoadMore(false);
      });
    }
  }, [reload, loadMore]);

  useEffect(() => {
    if (certFilter === '') {
      if (usersWithCerts != null) setUsersWithCerts(null);
    } else {
      fetchCerts(certFilter, setLoadingCerts, setCertError, setUsersWithCerts);
    }
  }, [certFilter]);

  useEffect(() => {
    setPagesRequested(1);
    setTranslators([]);
  }, [sortProps, searchPattern, certFilter]);

  useEffect(() => {
    fetchTranslators(
      pagesRequested,
      setTranslators,
      setError,
      searchPattern,
      sortProps,
      setIsLoading,
      setFullyLoaded,
      usersWithCerts,
      extrasLoaded,
      setExtrasLoaded,
      setPagesRequested
    );
  }, [sortProps, searchPattern, usersWithCerts]);

  return TranslatorViewV2({
    translators,
    isLoading: isLoading || loadingCerts,
    setReload,
    setLoadMore,
    fullyLoaded,
    error,
    certError,
    sortProps,
    setSortProps,
    setSearchPattern,
    setCertFilter,
    globalState: {
      availableLocales: globalState.availableLocales,
      mediaType: globalState.mediaTypes,
      subject: globalState.subjects,
      maxTranslationSkill: globalState.translationSkills,
    },
  });
};

Translator.propTypes = {
  globalState: PropTypes.object,
};

export default Translator;
