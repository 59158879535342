import GenericNextTokenCrudActions from '../actions/GenericNextTokenCrudActions';
import { SortOrder } from '../utils/Enums';
import genericCrudReducer from './GenericCrudReducer';

export const genericNextTokenCrudInitialState = {
  pagesRequested: 0,
  sortField: null,
  sortOrder: SortOrder.UNSORTED,
  isFetching: false,
  fullyLoaded: false,
  lastUpdated: null,
  lastError: null,
  items: [],
  item: undefined,
  searchPattern: '',
};

const genericNextTokenCrudReducer = type => {
  const actions = new GenericNextTokenCrudActions(type);

  return (state = genericNextTokenCrudInitialState, action) => {
    switch (action.type) {
      case actions.requestReloadActionType():
        return {
          ...state,
          items: [],
          nextToken: null,
          fullyLoaded: false,
          isFetching: true,
        };
      case actions.finishedFetchActionType():
        return {
          ...state,
          isFetching: false,
          //TODO: This is a pretty big change; append instead of replace
          items: [...state.items, ...action.items],
          fullyLoaded: !action.nextToken,
          nextToken: action.nextToken,
          lastUpdated: action.receivedAt,
          lastError: null,
        };
      case actions.finishedLoadAllActionType():
        return {
          ...state,
          isFetching: false,
          items: action.items,
          fullyLoaded: true,
          nextToken: null,
          lastUpdated: action.receivedAt,
          lastError: null,
        };
      case actions.failReloadActionType():
        return {
          ...state,
          isFetching: false,
          items: [],
          nextToken: null,
          lastUpdated: action.receivedAt,
          lastError: action.error,
        };
      default:
        return genericCrudReducer(type)(state, action);
    }
  };
};

export default genericNextTokenCrudReducer;
