import ActionTypes from '../actions/Profile';

const profile = (state = profile.initialState, action) => {
  switch (action.type) {
    case ActionTypes.PROFILE_RELOAD_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case ActionTypes.PROFILE_RELOAD_RECEIVE:
      return {
        ...state,
        user: action.profile,
        receivedAt: action.receivedAt,
        isFetching: false,
        lastError: null,
      };
    case ActionTypes.PROFILE_RELOAD_RECEIVE_FAILURE:
      return {
        ...state,
        lastError: action.error,
        receivedAt: action.receivedAt,
        isFetching: false,
      };
    default:
      return state;
  }
};

profile.initialState = {
  user: null,
  isFetching: true,
  lastError: null,
  receivedAt: null,
};

export default profile;
