import React from 'react';
import InfiniteScroll from '../../InfiniteScroll';
import PropTypes from 'prop-types';
import styled, { css, cx } from 'react-emotion';
import SvgIcon from 'react-icons-kit/SvgIcon';
import { infoCircle } from 'react-icons-kit/fa/infoCircle';
import {
  Alert,
  Button,
  Form,
  FormControl,
  Modal,
  OverlayTrigger,
  Table,
  Tooltip,
} from 'react-bootstrap';
import SortableColumnHeaders from '../../SortableColumnHeaders';
import canDo from '../../../utils/canDo';
import ErrorAlert from '../../ErrorAlert';
import { Container } from '@amzn/et-polaris-utils';
import Spinner from '../../Spinner';
import WeightSchemeDialog from './WeightSchemeDialog';
import { Link, Redirect } from 'react-router-dom';
import SettingsButtons from '../SettingsButtons';

const IconDiv = styled('div')`
  padding-left: 6px;
  display: inline-block;
`;

const columns = [
  {
    title: 'Name',
    sortKey: 'name',
  },
  {
    /* Empty column for action buttons */
    title: '',
    sortKey: null,
  },
];

export class Weighting extends React.Component {
  static propTypes = {
    onLoadMore: PropTypes.func,
    onReload: PropTypes.func,
    onSearch: PropTypes.func,
    onSortChange: PropTypes.func,
    onCreateEvent: PropTypes.func,
    onUpdateEvent: PropTypes.func,
    onRemoveEvent: PropTypes.func,
    session: PropTypes.object.isRequired,
    weightSchemes: PropTypes.object.isRequired,
    features: PropTypes.object,
  };

  static defaultProps = {
    onLoadMore: Function.prototype,
    onReload: Function.prototype,
    onSearch: Function.prototype,
    onSortChange: Function.prototype,
    onCreateEvent: Function.prototype,
    onUpdateEvent: Function.prototype,
    onRemoveEvent: Function.prototype,
    session: Object.prototype,
    weightSchemes: Object.prototype,
    features: Object.prototype,
  };

  constructor(props) {
    super(props);
    this.state = {
      showAddModal: false,
      showDeleteModal: false,
      weightSchemeToDelete: null,
      lastDeleteError: null,
      currentWeightScheme: null,
      searchPattern: '',
      shouldRedirect: false,
    };

    this.handleOpenAddModal = this.handleOpenAddModal.bind(this);
    this.handleCloseAddModal = this.handleCloseAddModal.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.updateSearchPattern = this.updateSearchPattern.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.onReload(true);
  }

  handleOpenAddModal() {
    this.setState({ showAddModal: true });
  }

  handleCloseAddModal(weightScheme) {
    this.setState({
      showAddModal: false,
      currentWeightScheme: weightScheme,
      shouldRedirect: weightScheme !== undefined,
    });
  }

  handleSearch(e) {
    e.preventDefault();
    const { searchPattern } = this.state;
    this.props.onSearch(searchPattern);
  }

  updateSearchPattern(e) {
    this.setState({ searchPattern: e.target.value });
  }

  render() {
    const handleShowDeleteConfirmModal = weightScheme => {
      this.setState({
        weightSchemeToDelete: weightScheme,
        lastDeleteError: null,
      });
    };

    const handleCloseDeleteConfirmModal = () => {
      this.setState({ weightSchemeToDelete: null });
    };

    const handleDoDeleteAndClose = () => {
      this.props
        .onRemoveEvent(this.state.weightSchemeToDelete)
        .then(() => {
          handleCloseDeleteConfirmModal();
        })
        .catch(e => {
          this.setState({ lastDeleteError: e });
        });
    };

    const {
      items,
      isFetching,
      lastError,
      sortOrder,
      sortField,
      fullyLoaded,
    } = this.props.weightSchemes;
    const { features, onReload } = this.props;
    const {
      weightSchemeToDelete,
      currentWeightScheme,
      lastDeleteError,
      shouldRedirect,
    } = this.state;

    // After the user has created a new weight and the dialog closes, we need
    // to send them to the weight scheme detail page.
    if (shouldRedirect) {
      return <Redirect to={`/settings/weighting/${currentWeightScheme.id}`} />;
    }

    return (
      <Container
        title="Weight Schemes"
        rawButtons={
          <SettingsButtons
            writePermission={canDo(this, 'pricing:write')}
            onCreate={this.handleOpenAddModal}
            onReload={onReload}
            createTitle="Add Weight Scheme"
            isFetching={isFetching}
          />
        }
      >
        <InfiniteScroll
          onInfiniteScroll={this.props.onLoadMore}
          isLoading={isFetching}
          fullyLoaded={fullyLoaded}
          fireOnChange={items.length}
        />

        <Modal show={this.state.showAddModal} onHide={this.handleCloseAddModal}>
          <WeightSchemeDialog
            closeHandler={this.handleCloseAddModal}
            onUpdateEvent={this.props.onCreateEvent}
            weightScheme={{ basePriceModifiers: [] }}
            title={'Add Weight Scheme'}
          />
        </Modal>
        {weightSchemeToDelete != null && (
          <Modal show={weightSchemeToDelete != null} onHide={handleCloseDeleteConfirmModal}>
            <Modal.Header closeButton>
              <Modal.Title>Delete Weight Scheme?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {lastDeleteError && (
                <ErrorAlert title="Failed to delete weight scheme" errorInfo={lastDeleteError} />
              )}
              Do you really want to remove the {"'"}
              {weightSchemeToDelete.name}
              {"'"} weight scheme?
            </Modal.Body>
            <Modal.Footer>
              <Button id="cancel-delete" onClick={handleCloseDeleteConfirmModal}>
                Cancel
              </Button>
              <Button bsStyle="primary" onClick={handleDoDeleteAndClose}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        {lastError && <ErrorAlert title="Failed to load weight schemes" errorInfo={lastError} />}

        {!lastError && !isFetching && items.length === 0 && (
          <Alert bsStyle="info">No weight schemes found.</Alert>
        )}

        {// FIXME: was this ever turned on?
        features.weightingSearch && (
          <Form
            inline
            className="table-search"
            id="searchForm"
            onSubmit={e => this.handleSearch(e)}
          >
            <FormControl
              onChange={e => this.updateSearchPattern(e)}
              value={this.state.searchPattern}
            />{' '}
            <Button type="submit" disabled={isFetching}>
              Search
            </Button>
          </Form>
        )}

        {items.length > 0 && (
          <Table striped condensed hover>
            <SortableColumnHeaders
              columns={columns}
              sortField={sortField}
              sortOrder={sortOrder}
              onSortChanged={this.props.onSortChange}
            />
            <tbody>
              {items.map(weightScheme => {
                return (
                  <tr key={weightScheme.id}>
                    <td>
                      {weightScheme.name}
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip">{weightScheme.description}</Tooltip>}
                      >
                        <IconDiv>
                          <SvgIcon icon={infoCircle} size={16} />
                        </IconDiv>
                      </OverlayTrigger>
                    </td>
                    <td className="row-actions">
                      {canDo(this.props.session, 'pricing:write') && (
                        <React.Fragment>
                          <Link
                            className="btn btn-xs btn-default"
                            to={`/settings/weighting/${weightScheme.id}`}
                          >
                            Edit
                          </Link>
                          <button
                            className={cx('btn', 'btn-xs', 'btn-danger')}
                            onClick={() => handleShowDeleteConfirmModal(weightScheme)}
                          >
                            Delete
                          </button>
                        </React.Fragment>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
        {isFetching && (
          <div
            css={css`
              text-align: center;
            `}
          >
            <Spinner size={30} />
          </div>
        )}
      </Container>
    );
  }
}

export default Weighting;
