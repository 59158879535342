// adapted from https://stackoverflow.com/questions/35939886/find-first-scrollable-parent
export default function getScrollYParent(element) {
  let style = getComputedStyle(element);
  const excludeStaticParent = style.position === 'absolute';
  const overflowRegex = /(auto|scroll)/;

  if (style.position === 'fixed') return document.body;
  while (element) {
    element = element.parentElement;
    style = getComputedStyle(element);
    if (excludeStaticParent && style.position === 'static') {
      continue;
    }
    if (overflowRegex.test(style.overflowY || style.overflow)) return element;
  }

  return document.body;
}
