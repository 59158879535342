import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Button, Col, ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import ErrorAlert from '../../ErrorAlert';
import applySchema from '../../../utils/applySchema';

const VALIDATION_SCHEMA = yup.object().shape({
  id: yup.string(),
  version: yup.number(),
  name: yup.string().required(),
  description: yup.string().required(),
});

const HelpText = styled('div')`
  margin-bottom: 1em;
`;

class WeightSchemeDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.renderForm = this.renderForm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values, formik) {
    const newWeightScheme = {
      ...this.props.weightScheme,
      ...applySchema(values, VALIDATION_SCHEMA),
    };
    return this.props.onUpdateEvent(newWeightScheme).then(
      weightScheme => {
        this.setState({
          lastError: null,
        });
        this.props.closeHandler(weightScheme);
      },
      err => {
        this.setState({ lastError: err });
      }
    );
  }

  renderForm({ values, errors, touched, handleSubmit, handleChange, handleBlur, setFieldValue }) {
    const { lastError } = this.state;
    const { closeHandler, title, weightScheme } = this.props;
    const saveEnabled = Object.keys(touched).length > 0 && Object.keys(errors).length === 0;

    const validationState = name => touched[name] && (errors[name] ? 'error' : 'success');

    const handleCancel = () => {
      closeHandler(undefined);
    };

    return (
      <div>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {lastError && <ErrorAlert title="Failed to save Weight Scheme" errorInfo={lastError} />}
          {weightScheme.id == null && (
            <HelpText id="help-text">
              Fill out this form and click Save. On the next screen you will edit the weights.
            </HelpText>
          )}
          <Form horizontal onSubmit={handleSubmit}>
            <FormGroup controlId="name" validationState={validationState('name')}>
              <Col componentClass={ControlLabel} sm={3}>
                Name
              </Col>
              <Col sm={8}>
                <FormControl
                  name="name"
                  type="text"
                  value={values.name}
                  placeholder="Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormControl.Feedback />
              </Col>
            </FormGroup>
            <FormGroup controlId="description" validationState={validationState('description')}>
              <Col componentClass={ControlLabel} sm={3}>
                Description
              </Col>
              <Col sm={8}>
                <FormControl
                  name="description"
                  componentClass="textarea"
                  value={values.description}
                  placeholder="Description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{ height: 200 }}
                />
                <FormControl.Feedback />
              </Col>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            bsStyle={'primary'}
            disabled={!('id' in weightScheme) && !saveEnabled}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Modal.Footer>
      </div>
    );
  }

  static getEmptyState() {
    return {
      name: '',
      description: '',
    };
  }

  render() {
    return (
      <Formik
        initialValues={
          'id' in this.props.weightScheme
            ? this.props.weightScheme
            : WeightSchemeDialog.getEmptyState()
        }
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={this.handleSubmit}
        render={this.renderForm}
      />
    );
  }
}

WeightSchemeDialog.propTypes = {
  closeHandler: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  weightScheme: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

WeightSchemeDialog.defaultProps = {
  closeHandler: Function.prototype,
  onUpdateEvent: Function.prototype,
};

export default WeightSchemeDialog;
