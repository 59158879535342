import ActionTypes from '../actions/Subjects';

const subjects = (state = subjects.initialState, action) => {
  switch (action.type) {
    case ActionTypes.FINISHED_GET_SUBJECTS: {
      return {
        ...state,
        subjects: action.subjects,
      };
    }
    case ActionTypes.FAILED_GET_SUBJECTS: {
      return {
        ...state,
        lastError: action.error,
      };
    }
    default:
      return state;
  }
};

subjects.initialState = {
  subjects: {},
};

export default subjects;
