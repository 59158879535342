import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Box,
  Flashbar,
  FormField,
  Header,
  Table,
  StatusIndicator,
  SpaceBetween,
  Modal,
  Pagination,
  CollectionPreferences,
} from '@amzn/awsui-components-react-v3/polaris';
import ApiClient from '../../ApiClient';
import { convertDate, convertDateTime, valueOrDash } from '../../utils/ReadableConverter';
import moment from 'moment';
import { useCollection } from '@amzn/awsui-collection-hooks';
import PropertyFilter from '@amzn/awsui-components-react-v3/polaris/property-filter';
import PropertyFilterI18nStrings from '../../utils/PropertyFilterI18nStrings';
import AddEditProjectPlanRowModal from './AddEditProjectPlanRowModal';

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 Projects' },
  { value: 20, label: '20 Projects' },
  { value: 30, label: '30 Projects' },
  { value: 50, label: '50 Projects' },
  { value: 100, label: '100 Projects' },
];

const dateCell = (date, showTime) => (
  <div title={date && moment(date).fromNow()} style={(date && { cursor: 'help' }) || {}}>
    {showTime ? convertDateTime(date) : convertDate(date)}
  </div>
);

const propertyFilteringProperties = [
  {
    key: 'client',
    propertyLabel: 'Client',
    operators: [':', '='],
    groupValuesLabel: 'Client Values',
  },
  {
    key: 'priority',
    propertyLabel: 'Priority',
    operators: [':', '='],
    groupValuesLabel: 'Priority Values',
  },
  {
    key: 'capacity',
    propertyLabel: 'Capacity',
    operators: [':', '='],
    groupValuesLabel: 'Capacity Values',
  },
  {
    key: 'sourceLanguage',
    propertyLabel: 'Source Language',
    operators: [':', '='],
    groupValuesLabel: 'Source Language Values',
  },
  {
    key: 'targetLanguage',
    propertyLabel: 'Target Language',
    operators: [':', '='],
    groupValuesLabel: 'Target Language Values',
  },
  {
    key: 'status',
    propertyLabel: 'Status',
    operators: [':', '='],
    groupValuesLabel: 'Status Values',
  },
];

const capacityCell = (status, text) => <StatusIndicator type={status}>{text}</StatusIndicator>;

// exporting so we can use in tests (e.g. know if a column should be sortable or not)
export const columnDefinitionsFunc = globalState => [
  {
    id: 'id',
    header: 'Id',
    cell: item => valueOrDash(item.id),
  },
  {
    id: 'client',
    header: 'Client',
    cell: item => valueOrDash(item.client),
  },
  {
    id: 'priority',
    header: 'Priority',
    cell: item => valueOrDash(item.priority),
    sortingField: 'priority',
  },
  {
    id: 'startDate',
    header: 'Start',
    cell: item => dateCell(item.startDate, false),
    sortingField: 'startDate',
  },
  {
    id: 'endDate',
    header: 'End',
    cell: item => dateCell(item.endDate, false),
    sortingField: 'endDate',
  },
  {
    id: 'available',
    header: 'Capacity',
    cell: item =>
      item.available ? capacityCell('success', 'Available') : capacityCell('error', 'Unavailable'),
    sortingField: 'available',
  },
  {
    id: 'sourceLanguage',
    header: 'Source Language',
    cell: item => valueOrDash(item.sourceLanguage),
  },
  {
    id: 'targetLanguage',
    header: 'Target Language',
    cell: item => valueOrDash(item.targetLanguage),
  },
  {
    id: 'totalVolume',
    header: 'Total Volume',
    cell: item => valueOrDash(item.totalVolume),
    sortingField: 'totalVolume',
  },
  {
    id: 'status',
    header: 'Status',
    cell: item => valueOrDash(item.status),
    sortingField: 'status',
  },
  {
    id: 'subject',
    header: 'Subject',
    cell: item => valueOrDash(globalState.subjects[item.subject] || item.subject),
  },
  {
    id: 'mediaType',
    header: 'Media Type',
    cell: item => valueOrDash(globalState.mediaTypes[item.mediaType] || item.mediaType),
  },
  {
    id: 'skill',
    header: 'Skill',
    cell: item => valueOrDash(globalState.translationSkills[item.skill] || item.skill),
  },
  {
    id: 'owner',
    header: 'Project Owner',
    cell: item => valueOrDash(item.owner),
  },
  {
    id: 'planForFreelancers',
    header: 'Plan for Freelancers',
    cell: item => valueOrDash(item.planForFreelancers),
  },
  {
    id: 'planForAgents',
    header: 'Plan for Agencies',
    cell: item => valueOrDash(item.planForAgents),
  },
];

export const PlanningView = ({
  globalState,
  loading,
  capacityReports,
  projectPlanID,
  flashBarItems,
  reloadCapacityReport,
  setCrIdToBeFetched,
  reloadProjectPlan,
}) => {
  const columnDefinitions = columnDefinitionsFunc(globalState);
  const empty = <div>no projects found</div>;

  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState({});
  const [planFile, setPlanFile] = useState(null);
  const [filterableCapacityReports, setFilterableCapacityReports] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState('');
  const [projectPlanRow, setProjectPlanRow] = useState({
    id: null,
    client: '',
    priority: '',
    startDate: '',
    endDate: '',
    sourceLanguage: '',
    targetLanguage: '',
    totalVolume: '',
    status: '',
    subject: '',
    mediaType: '',
    skill: '',
    owner: '',
    planForFreelancers: null,
    planForAgents: null,
  });

  const [preferences, setPreferences] = useState({
    pageSize: 100,
  });

  useEffect(() => {
    const convertedReports = capacityReports.reduce((convertedReport, report) => {
      report.sourceLanguage = valueOrDash(report.languageArc.split(':')[0]);
      report.targetLanguage = valueOrDash(report.languageArc.split(':')[1]);
      report.capacity = report.available ? 'Available' : 'Unavailable';
      return convertedReport.concat(report);
    }, []);

    setFilterableCapacityReports(convertedReports);
  }, [capacityReports]);

  useEffect(() => {
    if (showSuccessAlert) {
      setTimeout(() => setShowSuccessAlert(false), 6000);
    }
  }, [showSuccessAlert]);

  const {
    items,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(filterableCapacityReports, {
    propertyFiltering: {
      filteringProperties: propertyFilteringProperties,
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
  });

  const handleSubmit = async () => {
    setError({});
    setIsSubmitting(true);
    await ApiClient.multipartUpload('/postProjectPlan', { file: planFile })
      .then(() => {
        reloadProjectPlan(true);
        reloadCapacityReport(true);
      })
      .catch(error => {
        setError({
          header: 'Error uploading file',
          message: `There was a problem uploading the file: ${error.details.errors[0].message}`,
        });
      })
      .finally(() => {
        setShowUploadFileModal(false);
        setIsSubmitting(false);
      });
  };

  const handleDelete = async () => {
    setError({});
    setIsSubmitting(true);
    const projectsToBeDeleted = [];
    for (const row of selectedRows) {
      projectsToBeDeleted.push({ id: row.id });
    }
    const data = {
      projects: projectsToBeDeleted,
      projectPlanId: projectPlanID,
    };

    await ApiClient.httpPost('/deleteProjectPlanRows', data)
      .then(resp => {
        if (resp.error && resp.error !== '') {
          const lockedProjects = JSON.parse(resp.error).projectLockStatusList?.map(
            project => project.projectId
          );
          setError({
            header: 'Error deleting projects',
            message: `The following project(s) were not deleted as someone is currently editing them: ${lockedProjects.join(
              ', '
            )}`,
          });
          // only reload if one or more projects where deleted
          if (projectsToBeDeleted.length > lockedProjects.length) {
            setCrIdToBeFetched(resp.capacityReportId);
            reloadProjectPlan(true);
            reloadCapacityReport(true);
          }
        } else {
          setCrIdToBeFetched(resp.capacityReportId);
          reloadProjectPlan(true);
          reloadCapacityReport(true);
          setSuccessAlert(
            selectedRows.length > 1
              ? `${selectedRows.length} projects were successfully deleted`
              : 'One project was successfully deleted'
          );
          setShowSuccessAlert(true);
        }
      })
      .catch(error => {
        setError({
          header: 'Error deleting projects',
          message: `There was a problem deleting the selected projects: ${error.details.errors[0].message}`,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
        setSelectedRows([]);
        setShowDeleteModal(false);
      });
  };

  const prepareAddModal = () => {
    setProjectPlanRow({
      id: null,
      client: '',
      priority: '',
      startDate: '',
      endDate: '',
      sourceLanguage: '',
      targetLanguage: '',
      totalVolume: '',
      status: '',
      subject: '',
      mediaType: '',
      skill: '',
      owner: '',
      planForFreelancers: null,
      planForAgents: null,
    });
    setShowAddModal(true);
  };

  const handleAdd = async addProjectPlanRow => {
    setError({});
    setIsSubmitting(true);

    const data = {
      projects: [addProjectPlanRow],
      projectPlanId: projectPlanID,
    };
    await ApiClient.httpPost('/addProjectPlanRows', data)
      .then(resp => {
        setCrIdToBeFetched(resp.capacityReportId);
        reloadProjectPlan(true);
        reloadCapacityReport(true);
        setSuccessAlert('The project was successfully added');
        setShowSuccessAlert(true);
      })
      .catch(error => {
        setError({
          header: 'Error adding project',
          message: `There was a problem adding the project: ${error.details.errors[0].message}`,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
        setShowAddModal(false);
      });
  };

  const prepareUpdateModal = async () => {
    setError({});
    //Call the api to lock the row
    const data = {
      projects: [{ id: selectedRows[0].id }],
      projectPlanId: projectPlanID,
    };
    await ApiClient.httpPost('/lockProjectPlanRows', data)
      .then(resp => {
        setProjectPlanRow({
          id: selectedRows[0].id,
          client: selectedRows[0].client,
          priority: selectedRows[0].priority,
          startDate: selectedRows[0].startDate.split('T')[0],
          endDate: selectedRows[0].endDate.split('T')[0],
          sourceLanguage: selectedRows[0].languageArc.split(':')[0],
          targetLanguage: selectedRows[0].languageArc.split(':')[1],
          totalVolume: selectedRows[0].totalVolume,
          status: selectedRows[0].status,
          subject: selectedRows[0].subject,
          mediaType: selectedRows[0].mediaType,
          skill: selectedRows[0].skill,
          owner: selectedRows[0].owner,
          planForFreelancers: selectedRows[0].planForFreelancers,
          planForAgents: selectedRows[0].planForAgents,
        });

        ApiClient.httpGet('/projectPlans/latest')
          .then(resp => {
            const selectedRow = resp.projects.filter(project => project.id == selectedRows[0].id);
            if (
              selectedRow[0] &&
              (selectedRow[0].lockedBy == null ||
                parseInt(selectedRow[0].lockedBy) == globalState.profile.user.id ||
                selectedRow[0].lockedBy === '')
            ) {
              setShowUpdateModal(true);
            } else {
              setError({
                header: 'Error editing project',
                message: 'Someone else is currently editing this project',
              });
            }
          })
          .catch(error => {
            setError({
              header: 'Error editing project',
              message: `There was a problem validating that this project can be edited: ${error.details.errors[0].message}`,
            });
          });
      })
      .catch(error => {
        setError({
          header: 'Error locking project',
          message: `There was a problem locking this project for edit: ${error.details.errors[0].message}`,
        });
      });
  };

  const unlockRow = async () => {
    //Call the api to lock the row
    const data = {
      projects: [{ id: selectedRows[0].id }],
      projectPlanId: projectPlanID,
    };
    await ApiClient.httpPost('/unlockProjectPlanRows', data).catch(error => {
      setError({
        header: 'Error unlocking project',
        message: `Contact support: ${error.details.errors[0].message}`,
      });
    });
  };

  const handleUpdate = async updatedProjectPlanRow => {
    setError({});
    setIsSubmitting(true);

    const data = {
      projects: [updatedProjectPlanRow],
      projectPlanId: projectPlanID,
    };
    await ApiClient.httpPost('/editProjectPlanRows', data)
      .then(resp => {
        if (resp.error && resp.error !== '') {
          setError({
            header: 'Error updating project',
            message: 'Someone else is currently editing this project.',
          });
        } else {
          setCrIdToBeFetched(resp.capacityReportId);
          reloadProjectPlan(true);
          reloadCapacityReport(true);
          setSuccessAlert('The project was successfully updated');
          setShowSuccessAlert(true);
        }
      })
      .catch(error => {
        setError({
          header: 'Error updating project',
          message: `There was a problem updating the project: ${error.details.errors[0].message}`,
        });
      })
      .finally(() => {
        unlockRow();
        setIsSubmitting(false);
        setSelectedRows([]);
        setShowUpdateModal(false);
        /// unlock the row
      });
  };

  const handleFileChange = event => {
    setPlanFile(event.target.files[0]);
  };

  return (
    <>
      <Modal
        id="DeleteModal"
        onDismiss={() => {
          setShowDeleteModal(false);
        }}
        visible={showDeleteModal}
        closeAriaLabel="Close modal"
        size="medium"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                id="cancelDeleteButton"
                variant="link"
                onClick={() => {
                  setShowDeleteModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                id={'submitDeleteButton'}
                variant="primary"
                onClick={() => handleDelete()}
                loading={isSubmitting}
              >
                Delete
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={selectedRows.length == 1 ? 'Delete project?' : 'Delete projects?'}
      >
        {selectedRows.length > 0 ? (
          selectedRows.length > 1 ? (
            <Box variant="span">
              Delete{' '}
              <Box variant="span" fontWeight="bold">
                {selectedRows.length} projects
              </Box>{' '}
              permanently? This action cannot be undone.
            </Box>
          ) : (
            <Box variant="span">
              Delete project{' '}
              <Box variant="span" fontWeight="bold">
                {selectedRows[0].id}
              </Box>{' '}
              permanently? This action cannot be undone.
            </Box>
          )
        ) : null}
      </Modal>

      <AddEditProjectPlanRowModal
        mode={'Add'}
        globalState={globalState}
        handleSubmit={handleAdd}
        setShowModal={setShowAddModal}
        showModal={showAddModal}
        projectPlanRow={projectPlanRow}
        isSubmitting={isSubmitting}
      />

      <AddEditProjectPlanRowModal
        mode={'Edit'}
        globalState={globalState}
        handleSubmit={handleUpdate}
        setShowModal={setShowUpdateModal}
        showModal={showUpdateModal}
        projectPlanRow={projectPlanRow}
        unlockRow={unlockRow}
        isSubmitting={isSubmitting}
      />

      <Modal
        id="uploadPlansFileModal"
        onDismiss={() => {
          setShowUploadFileModal(false);
        }}
        visible={showUploadFileModal}
        closeAriaLabel="Close modal"
        size="medium"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                id="cancelButton"
                variant="link"
                onClick={() => {
                  setShowUploadFileModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                id={'submitButton'}
                variant="primary"
                disabled={planFile == null || isSubmitting}
                onClick={() => handleSubmit()}
                loading={isSubmitting}
              >
                Submit
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={<Header variant="h2">Upload a file to replace the project plan</Header>}
      >
        <FormField>
          <input type="file" name="planFile" accept=".csv" onChange={handleFileChange} />
        </FormField>
      </Modal>

      <SpaceBetween size="m" direction="vertical">
        {showSuccessAlert && (
          <Flashbar
            id={'successAlert'}
            items={[
              {
                type: 'success',
                content: successAlert,
                dismissible: true,
                onDismiss: () => setShowSuccessAlert(false),
              },
            ]}
          />
        )}
        <Flashbar items={flashBarItems} />
        {error.hasOwnProperty('header') && (
          <Flashbar
            id={'uploadErrorFlashbar'}
            items={[
              {
                header: error.header,
                type: 'error',
                content: error.message,
                dismissible: true,
                onDismiss: () => setError({}),
              },
            ]}
          />
        )}

        <div style={{ display: 'flex', float: 'right' }}>
          <Button
            id="replacePlanButton"
            variant="primary"
            onClick={() => setShowUploadFileModal(true)}
          >
            Replace Plan
          </Button>
        </div>

        <Table
          {...collectionProps}
          loadingText="Loading Planned Projects"
          columnDefinitions={columnDefinitions}
          loading={loading}
          items={items}
          selectedItems={selectedRows}
          onSelectionChange={event => setSelectedRows(event.detail.selectedItems)}
          selectionType={'multi'}
          ariaLabels={{
            selectionGroupLabel: 'Items selection',
            allItemsSelectionLabel: ({ selectedItems }) =>
              `${selectedItems.length} ${selectedItems.length === 1 ? 'item' : 'items'} selected`,
            itemSelectionLabel: ({ selectedItems }, item) => {
              return `is selected`;
            },
          }}
          wrapLines={false}
          header={
            <Header
              variant="h2"
              counter={
                selectedRows.length
                  ? '(' + selectedRows.length + '/' + items.length + ')'
                  : '(' + items.length + ')'
              }
              actions={
                <SpaceBetween size="xs" direction="horizontal">
                  <Button
                    id="editRowButton"
                    disabled={selectedRows.length !== 1}
                    onClick={() => prepareUpdateModal()}
                  >
                    Edit
                  </Button>
                  <Button
                    id="deleteRowButton"
                    disabled={selectedRows.length === 0}
                    onClick={() => setShowDeleteModal(true)}
                  >
                    Delete
                  </Button>
                  <Button id="addRowButton" variant="primary" onClick={() => prepareAddModal()}>
                    Add
                  </Button>
                </SpaceBetween>
              }
            >
              {' '}
              Planned Projects{' '}
            </Header>
          }
          stickyHeader={true}
          empty={empty}
          pagination={
            <Pagination
              {...paginationProps}
              ariaLabels={{
                nextPageLabel: 'Next page',
                previousPageLabel: 'Previous page',
                pageLabel: pageNumber => `Page ${pageNumber} of all pages`,
              }}
            />
          }
          filter={
            <PropertyFilter
              {...propertyFilterProps}
              i18nStrings={{
                ...PropertyFilterI18nStrings,
                filteringPlaceholder: 'Filter projects',
              }}
              countText={filteredItemsCount}
            />
          }
          preferences={
            <CollectionPreferences
              title="Preferences"
              confirmLabel="Confirm"
              cancelLabel="Cancel"
              preferences={preferences}
              onConfirm={({ detail }) => setPreferences(detail)}
              pageSizePreference={{
                title: 'Page size',
                options: PAGE_SIZE_OPTIONS,
              }}
            />
          }
        ></Table>
      </SpaceBetween>
    </>
  );
};

PlanningView.propTypes = {
  globalState: PropTypes.object,
  loading: PropTypes.bool,
  flashBarItems: PropTypes.array,
  capacityReports: PropTypes.array,
  projectPlanID: PropTypes.string,
  reloadCapacityReport: PropTypes.func,
  setCrIdToBeFetched: PropTypes.func,
  reloadProjectPlan: PropTypes.func,
};

const Planning = ({
  globalState,
  loading,
  capacityReport,
  projectPlanID,
  reloadCapacityReport,
  setCrIdToBeFetched,
  reloadProjectPlan,
  plansUpdatedTS,
  isLoadingTS,
}) => {
  const [capacityReports, setCapacityReports] = useState([]);
  const [flashBarItems, setFlashBarItems] = useState([]);
  const [intervalId, setIntervalId] = useState(0);
  const [warningInterval, setWarningInterval] = useState(0);

  useEffect(() => {
    if (!loading) {
      setFlashBarItems([]);
      if (capacityReport && capacityReport.clientCapacityReports) {
        setCapacityReports(capacityReport.clientCapacityReports);
      } else {
        setCapacityReports([]);
      }

      if (capacityReport && capacityReport.reportStatus == 'In-Progress') {
        setFlashBarItems([
          {
            content:
              'We are currently creating your capacity report and it will take a while longer. Please refresh the page.',
            type: 'warning',
            dismissible: true,
            dismissLabel: 'Dismiss',
            onDismiss: () => setFlashBarItems([]),
          },
        ]);
      }

      if (capacityReport == null || Object.keys(capacityReport).length === 0) {
        setFlashBarItems([
          {
            content:
              'No capacity report has been found for your organization. Please upload a project plan by clicking Replace Plan.',
            type: 'warning',
            dismissible: true,
            dismissLabel: 'Dismiss',
            onDismiss: () => setFlashBarItems([]),
          },
        ]);
      }
    }
  }, [capacityReport, loading]);

  useEffect(() => {
    // Check that the interval sending the warning is the active interval
    if (intervalId !== 0 && intervalId == warningInterval) {
      setFlashBarItems(
        [
          {
            content: 'Project Plan Dashboard is updated. Please refresh the page.',
            type: 'warning',
          },
        ].concat(flashBarItems)
      );
    }
  }, [intervalId, warningInterval]);

  useEffect(() => {
    if (intervalId !== 0) clearInterval(intervalId);
    if (!isLoadingTS) {
      const interval = setInterval(function() {
        //call the API
        const url = '/projectPlans/latest';
        ApiClient.httpGet(url)
          .then(resp => {
            if (plansUpdatedTS !== resp.processedTimestamp) {
              // create a flashbar warning
              setWarningInterval(interval);
              clearInterval(interval);
            }
          })
          .catch(() => {
            // create a flashbar error
            setFlashBarItems(
              [
                {
                  content: 'Cannot connect to server. Please Refresh the page.',
                  type: 'error',
                },
              ].concat(flashBarItems)
            );
          });
      }, 10000);
      setIntervalId(interval);
    }
  }, [plansUpdatedTS, isLoadingTS]);

  return PlanningView({
    globalState,
    loading,
    capacityReports,
    projectPlanID,
    flashBarItems,
    reloadCapacityReport,
    setCrIdToBeFetched,
    reloadProjectPlan,
  });
};

export default Planning;
