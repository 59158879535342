import { Spinner } from '@amzn/awsui-components-react-v3/polaris';
import { valueOrDash } from '../utils/ReadableConverter';
import { formatPercent } from '@amzn/et-console-components/';
import React from 'react';

export interface Props {
  segmentMetadata?: { segmentsCount: number; confirmedSegmentsCount };
}

export const ProgressView = (props: Props) => {
  const { segmentMetadata } = props;
  if (segmentMetadata === undefined) {
    return (
      <span className="awsui-util-status-inactive">
        <Spinner />
      </span>
    );
  } else if (segmentMetadata == null) {
    return '-';
  }
  const { confirmedSegmentsCount, segmentsCount } = segmentMetadata;
  return valueOrDash(formatPercent((confirmedSegmentsCount * 1.0) / segmentsCount));
};
