import ApiClient from '../ApiClient';

const ACTION_TYPES = {
  GET_MEDIA_TYPES: 'GET_MEDIA_TYPES',
  FINISHED_GET_MEDIA_TYPES: 'FINISHED_GET_MEDIA_TYPES',
  FAILED_GET_MEDIA_TYPES: 'FAILED_GET_MEDIA_TYPES',
};

function getMediaTypesEvent() {
  return {
    type: ACTION_TYPES.GET_MEDIA_TYPES,
  };
}

function finishedGetMediaTypesEvent(resp) {
  return {
    type: ACTION_TYPES.FINISHED_GET_MEDIA_TYPES,
    mediaTypes: resp,
  };
}

function failedGetMediaTypesEvent(error) {
  return {
    type: ACTION_TYPES.FAILED_GET_MEDIA_TYPES,
    error,
  };
}

export function getMediaTypes() {
  return dispatch => {
    dispatch(getMediaTypesEvent());
    return ApiClient.httpGet(`/mediaTypes`)
      .then(resp => {
        resp.constructor === Object
          ? dispatch(finishedGetMediaTypesEvent(resp))
          : dispatch(failedGetMediaTypesEvent(resp));
      })
      .catch(err => {
        dispatch(failedGetMediaTypesEvent(err));
      });
  };
}

export default ACTION_TYPES;
