import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Prompt } from 'react-router';
import styled from '@emotion/styled';
import { spinner } from 'react-icons-kit/fa/spinner';
import SvgIcon from 'react-icons-kit/SvgIcon';
import { Alert, Modal } from 'react-bootstrap';
import Button from '@amzn/awsui-components-react/polaris/button';
import WeightSchemeDialog from './WeightSchemeDialog';
import Grid from '../../Grid';
import LanguagePair from './LanguagePair';
import formatLocaleCode from '../../../utils/formatLocaleCode';
import PriceModifier from './PriceModifier';
import * as yup from 'yup';
import ErrorAlert from '../../ErrorAlert';
import { Container } from '@amzn/et-polaris-utils';
import LinkButton from '../../LinkButton';
import WeightSchemeImportDialog from './WeightSchemeImportDialog';
import { upload } from 'react-icons-kit/fa/upload.js';

// TODO move all of the common styles somewhere more centralized / reusable
const WeightSchemeName = styled('h3')`
  float: left;
`;
const WeightSchemeDescription = styled('div')`
  clear: both;
`;
const BackLink = styled('div')`
  margin: 1em 0;
`;
const NoSchemesPlaceholder = styled('div')`
  background-color: #f6f6f6;
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
`;
const AddRowButtonContainer = styled('div')`
  margin-top: 1em;
`;

const matchPercentPriceModifierSchema = yup.object().shape({
  name: yup
    .string()
    .matches(/p(0|[1-9][0-9]{0,2}|100|101)/, "Must be in the form 'p0', 'p100', etc")
    .required(),
  lowerBound: yup
    .number()
    .min(0)
    .max(1.01)
    .required(),
  percentage: yup
    .number()
    .min(0, 'Weights must be ≥ 0%')
    .max(1, 'Weights must be ≤ 100%')
    .required(),
});

const repetitionPriceModifierSchema = yup
  .object()
  .shape({
    percentage: yup
      .number()
      .min(0)
      .max(1)
      .default(0)
      .required(),
  })
  .required();

const basePriceModifierSchema = yup.object().shape({
  languagePair: yup
    .object()
    .shape({
      sourceLocale: yup
        .string()
        .required()
        .default('en-US'),
      targetLocale: yup
        .string()
        .required()
        .default('es-ES'),
    })
    .required(),
  matchPercentPriceModifiers: yup
    .array()
    .of(matchPercentPriceModifierSchema)
    .required(),
  repetitionPriceModifier: repetitionPriceModifierSchema,
});

/**
 * Return a copy of the object with the given leaf node changed.
 * Intermediate nodes will also be copies, but all other siblings/etc will
 * maintain object identity
 */
const updateLeafNode = (object, keys, value) => {
  keys = [...keys];
  const clone = object instanceof Array ? [...object] : { ...object };
  const key = keys.shift();
  clone[key] = keys.length > 0 ? updateLeafNode(clone[key], keys, value) : value;
  return clone;
};

export class WeightSchemeDetail extends React.Component {
  static propTypes = {
    weightSchemeId: PropTypes.string.isRequired,
    onFetchOne: PropTypes.func,
    weightSchemes: PropTypes.object.isRequired,
    onUpdateEvent: PropTypes.func,
    availableLocales: PropTypes.object.isRequired,
  };

  static defaultProps = {
    onFetchOne: Function.prototype,
    onUpdateEvent: Function.prototype,
  };

  constructor(props) {
    super(props);
    this.state = {
      showEditModal: false,
      showWeightSchemeImportModal: false,
      localWeightScheme: this.props.weightSchemes.item,
      hasChanged: false,
      lastError: null,
    };
  }

  handleOpenEditModal = weightScheme => {
    this.setState({ showEditModal: true });
  };

  handleCloseEditModal = () => {
    this.setState({ showEditModal: false });
  };

  handleOpenWeightSchemeImportModal = weightScheme => {
    this.setState({ showWeightSchemeImportModal: true });
  };

  handleCloseWeightSchemeImportModal = () => {
    this.setState({ showWeightSchemeImportModal: false });
  };

  setBasePriceModifiers = basePriceModifiers => {
    this.setState({
      hasChanged: true,
      localWeightScheme: {
        ...this.state.localWeightScheme,
        basePriceModifiers: basePriceModifiers,
      },
    });
  };

  saveMetadata = weightScheme => {
    //stash basePriceModifiers
    this.setState({
      stashedBasePriceModifiers: this.state.localWeightScheme.basePriceModifiers,
    });
    weightScheme.basePriceModifiers = this.props.weightSchemes.item.basePriceModifiers;

    //save metadata
    return this.props
      .onUpdateEvent(false)(weightScheme)
      .then(result =>
        this.setState({
          //recover base prices
          localWeightScheme: {
            ...result.item,
            basePriceModifiers: this.state.stashedBasePriceModifiers,
          },
          hasChanged:
            JSON.stringify(this.state.stashedBasePriceModifiers) !==
            JSON.stringify(weightScheme.basePriceModifiers),
          stashedBasePriceModifiers: null,
        })
      );
  };

  saveLocalChanges = () => {
    this.props
      .onUpdateEvent(false)(this.state.localWeightScheme)
      .then(result =>
        this.setState({
          localWeightScheme: result.item,
          lastError: null,
        })
      )
      .catch(err => {
        this.setState({ lastError: err });
      });
  };

  updateField = (path, value) => {
    const clone = { ...this.state.localWeightScheme };
    clone.basePriceModifiers = updateLeafNode(
      this.state.localWeightScheme.basePriceModifiers,
      path.split('.'),
      value
    );
    const newWeightScheme = JSON.stringify(clone);
    const existingWeightScheme = JSON.stringify(this.props.weightSchemes.item);
    this.setState({
      localWeightScheme: clone,
      hasChanged: newWeightScheme !== existingWeightScheme,
    });
  };

  addRow = () => {
    const { basePriceModifiers } = this.state.localWeightScheme;
    // Use yup default values to instantiate a new object with defaults from the schema.
    const newBasePriceModifier = basePriceModifierSchema.cast({});
    newBasePriceModifier.matchPercentPriceModifiers = [
      {
        name: 'p0',
        lowerBound: 0,
        percentage: 1,
      },
      {
        name: 'p50',
        lowerBound: 0.5,
        percentage: 1,
      },
      {
        name: 'p75',
        lowerBound: 0.75,
        percentage: 1,
      },
      {
        name: 'p85',
        lowerBound: 0.85,
        percentage: 1,
      },
      {
        name: 'p95',
        lowerBound: 0.95,
        percentage: 1,
      },
      {
        name: 'p100',
        lowerBound: 1,
        percentage: 1,
      },
      {
        name: 'p101',
        lowerBound: 1.01,
        percentage: 1,
      },
    ];
    this.setState({
      hasChanged: true,
      localWeightScheme: {
        ...this.state.localWeightScheme,
        basePriceModifiers: [...basePriceModifiers, newBasePriceModifier],
      },
    });
  };

  deleteRow = index => {
    const clone = { ...this.state.localWeightScheme };
    clone.basePriceModifiers.splice(index, 1);
    this.setState({
      hasChanged: true,
      localWeightScheme: clone,
    });
  };

  isWeightSchemeValid = () => {
    if (!this.state.localWeightScheme || !this.state.localWeightScheme.basePriceModifiers) {
      // probably just loading the page, so it doesn't really matter what we return.
      return true;
    }

    const { basePriceModifiers } = this.state.localWeightScheme;
    return basePriceModifiers.every(
      (basePriceModifier, index) => this.validateBasePriceModifier(basePriceModifier, index).valid
    );
  };

  validateBasePriceModifier = (basePriceModifier, index) => {
    const { basePriceModifiers } = this.state.localWeightScheme;

    const languagePairResult = this.validateLanguagePair(
      basePriceModifier.languagePair,
      index,
      basePriceModifiers
    );
    if (!languagePairResult.valid) {
      return languagePairResult;
    }

    const priceModifierResult = basePriceModifier.matchPercentPriceModifiers
      .map(mppm => this.validatePriceModifier(mppm))
      .find(result => !result.valid);
    if (priceModifierResult) {
      return priceModifierResult;
    }

    return this.validateRepetitionPriceModifier(basePriceModifier.repetitionPriceModifier);
  };

  validateLanguagePair = (languagePair, index, basePriceModifiers) => {
    try {
      basePriceModifierSchema.validateSyncAt('languagePair', languagePair);

      const locales = this.props.availableLocales.items;
      //Check for invalid locales.
      const availableLocaleCodes = locales.map(x => formatLocaleCode(x.code));

      if (
        !availableLocaleCodes.includes(languagePair.sourceLocale) ||
        !availableLocaleCodes.includes(languagePair.targetLocale)
      ) {
        return {
          valid: false,
          errorMsg: `Invalid locale input. Source=${languagePair.sourceLocale} Target=${languagePair.targetLocale}`,
        };
      }

      // ensure no duplicate language pairs
      if (
        basePriceModifiers
          .map(x => x.languagePair)
          .some((lp, i) => {
            return (
              i !== index &&
              lp.sourceLocale === languagePair.sourceLocale &&
              lp.targetLocale === languagePair.targetLocale
            );
          })
      ) {
        return { valid: false, errorMsg: 'Duplicate language pair.' };
      }

      return { valid: true };
    } catch (err) {
      return { valid: false, errorMsg: err.message };
    }
  };

  validatePriceModifier = priceModifier => {
    try {
      matchPercentPriceModifierSchema.validateSync(priceModifier);
      return { valid: true };
    } catch (err) {
      return { valid: false, errorMsg: err.message };
    }
  };

  validateRepetitionPriceModifier = priceModifier => {
    try {
      repetitionPriceModifierSchema.validateSync(priceModifier);
      return { valid: true };
    } catch (err) {
      return { valid: false, errorMsg: err.message };
    }
  };

  UNSAFE_componentWillMount() {
    const { onFetchOne, weightSchemeId } = this.props;

    // Since the page can be loaded directly via the URL, we cannot rely on
    // the particular weight scheme being available in the redux store.  We need
    // to load it and display a spinner until it is available.
    onFetchOne(weightSchemeId);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Once the weight scheme actually loads, we need to store it in the local state so
    // that we can modify it locally until the user presses save.
    if (this.props.weightSchemes.item !== prevProps.weightSchemes.item) {
      this.setState({
        localWeightScheme: this.props.weightSchemes.item,
        hasChanged: false,
      });
    }
  }

  render() {
    const { isFetching } = this.props.weightSchemes;
    const isWeightSchemeValid = this.isWeightSchemeValid();
    // Even though the 'item' is available in the redux store, we want to use
    // the one from local state, because we need to be able to make changes to it
    // without saving until the user presses the Save button.
    const { localWeightScheme, hasChanged, lastError } = this.state;
    const columns = [
      {
        title: 'Language Pair',
      },
      {
        title: '0-49%',
      },
      {
        title: '50-74%',
      },
      {
        title: '75-84%',
      },
      {
        title: '85-94%',
      },
      {
        title: '95-99%',
      },
      {
        title: '100%',
      },
      {
        title: '101%',
      },
      {
        title: 'Repetition',
      },
      {
        title: '', // Empty header for actions column
      },
    ].filter(c => c);

    // This is the ID that is used for the react key property for rows in the grid.
    // Two rows with the same ID will result in react warnings and some weird behavior.
    // Base prices do not have ID's, so we have to generate something unique.
    // Because we use the language pair, we need to use validation to ensure no two
    // rows have the same language pair.
    const getId = array => (item, index) => `${array.length}_${index}`;

    return (
      <div>
        <Prompt
          when={hasChanged}
          message="You have unsaved changes.  Are you sure you want to leave?"
        />
        <BackLink>
          <Link to="/settings/weighting">⬅ Back to List</Link>
        </BackLink>

        <Container
          legacyContent
          title="Weight Scheme Details"
          rawButtons={
            <>
              {localWeightScheme && !isFetching && (
                <Button
                  id="EditInfo"
                  onClick={() => this.handleOpenEditModal(localWeightScheme)}
                  autoFocus
                >
                  Edit Info
                </Button>
              )}
            </>
          }
        >
          {isFetching && <SvgIcon icon={spinner} size={32} />}
          {!isFetching && !localWeightScheme && (
            <Alert bsStyle="warning">Weight Scheme not found.</Alert>
          )}
          {localWeightScheme && !isFetching && (
            <>
              <Modal show={this.state.showEditModal} onHide={this.handleCloseEditModal}>
                {localWeightScheme && (
                  <WeightSchemeDialog
                    closeHandler={this.handleCloseEditModal}
                    onUpdateEvent={this.saveMetadata}
                    weightScheme={localWeightScheme}
                    title={'Edit Weight Scheme'}
                  />
                )}
              </Modal>

              <Modal
                show={this.state.showWeightSchemeImportModal}
                onHide={this.handleCloseWeightSchemeImportModal}
              >
                <WeightSchemeImportDialog
                  closeHandler={this.handleCloseWeightSchemeImportModal}
                  onUpdateEvent={this.setBasePriceModifiers}
                  weightScheme={localWeightScheme}
                  title={'Import Weights'}
                />
              </Modal>
              <WeightSchemeName>{localWeightScheme.name}</WeightSchemeName>
              <WeightSchemeDescription>{localWeightScheme.description}</WeightSchemeDescription>
            </>
          )}
        </Container>

        {localWeightScheme && !isFetching && (
          <Container
            legacyContent
            title="Weights"
            rawButtons={
              <>
                <Button
                  id={'importWeightsButton'}
                  onClick={() => this.handleOpenWeightSchemeImportModal(localWeightScheme)}
                  autoFocus
                >
                  <SvgIcon icon={upload} size={16} />
                  <span> Import</span>
                </Button>
                {hasChanged && (
                  <Button
                    variant="primary"
                    onClick={this.saveLocalChanges}
                    disabled={!isWeightSchemeValid}
                  >
                    Save Changes
                  </Button>
                )}
              </>
            }
          >
            {lastError && <ErrorAlert title="Failed to save weights" errorInfo={lastError} />}
            <Grid
              id="Weights"
              getId={getId(localWeightScheme.basePriceModifiers)}
              scrollable
              isFetching={isFetching}
              fullyLoaded={true}
              columns={columns}
              items={localWeightScheme.basePriceModifiers || []}
              datasetId={'1'}
              rowClassNames={[{ 'row-editable': true }, 'form-inline']}
              validateRow={this.validateBasePriceModifier}
              renderRow={(basePriceModifier, index) => (
                <>
                  <td>
                    <LanguagePair
                      value={basePriceModifier.languagePair}
                      availableLocaleCodes={this.props.availableLocales.items.map(x =>
                        formatLocaleCode(x.code)
                      )}
                      onChange={(type, value) => this.updateField(`${index}.${type}`, value)}
                      validate={this.isLanguagePairValid}
                    />
                  </td>
                  {basePriceModifier.matchPercentPriceModifiers.map(
                    (matchPercentPriceModifer, matchPercentPriceModiferIndex) => (
                      <td key={matchPercentPriceModiferIndex}>
                        <PriceModifier
                          value={matchPercentPriceModifer}
                          onChange={(type, value) =>
                            this.updateField(
                              `${index}.matchPercentPriceModifiers.${matchPercentPriceModiferIndex}.${type}`,
                              value
                            )
                          }
                          validate={this.isPriceModifierValid}
                        />
                      </td>
                    )
                  )}
                  <td>
                    <PriceModifier
                      value={{
                        ...basePriceModifier.repetitionPriceModifier,
                        name: 'repetition',
                      }}
                      onChange={(type, value) =>
                        this.updateField(`${index}.repetitionPriceModifier.${type}`, value)
                      }
                      validate={this.isRepetitionPriceModifierValid}
                    />
                  </td>
                  <td className="row-actions">
                    <button className="btn btn-xs btn-danger" onClick={() => this.deleteRow(index)}>
                      Delete
                    </button>
                  </td>
                </>
              )}
            />
            {(!localWeightScheme.basePriceModifiers ||
              localWeightScheme.basePriceModifiers.length === 0) && (
              <NoSchemesPlaceholder>
                <h3>This weight scheme is empty</h3>
                To get started, <LinkButton onClick={this.addRow}>add</LinkButton> a new row.
              </NoSchemesPlaceholder>
            )}
            <AddRowButtonContainer>
              <Button id="AddRow" onClick={this.addRow}>
                Add Row
              </Button>
            </AddRowButtonContainer>
          </Container>
        )}
      </div>
    );
  }
}

export default WeightSchemeDetail;
