import { css } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, DropdownButton, MenuItem, Modal } from 'react-bootstrap';
import ErrorAlert from '../ErrorAlert';
import { LegacyContainer, Container } from '@amzn/et-polaris-utils';
import Grid from '../Grid';
import CertificationDialog from './CertificationDialog';
import { spinner } from 'react-icons-kit/fa/spinner';
import SvgIcon from 'react-icons-kit/SvgIcon';
import toast from '../toast';

export class ProfileView extends React.Component {
  UNSAFE_componentWillMount() {
    const { match } = this.props;
    const userId = match.params.userId;
    this.props.onLoadProfile(userId);
  }

  render() {
    const { lastError, isFetching } = this.props.profile;

    if (lastError) {
      return <ErrorAlert title={`Failed to load profile`} errorInfo={lastError} />;
    }

    const handleReloadProfile = () => {
      const { match } = this.props;
      const userId = match.params.userId;
      this.props.onLoadProfile(userId);
    };

    return (
      <div>
        {isFetching && <SvgIcon icon={spinner} size={32} />}
        {!isFetching && (
          <div>
            <Header {...this.props} />
            <Main {...this.props} onReloadProfile={handleReloadProfile} />
          </div>
        )}
      </div>
    );
  }
}

ProfileView.propTypes = {
  profile: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  onLoadProfile: PropTypes.func.isRequired,
  onCreateCertificationEvent: PropTypes.func.isRequired,
  onChangeCertificationEvent: PropTypes.func.isRequired,
  onRemoveCertificationEvent: PropTypes.func.isRequired,
};

ProfileView.defaultProps = {
  profile: Object.prototype,
  onLoadProfile: Function.prototype,
  onCreateCertificationEvent: Function.prototype,
  onChangeCertificationEvent: Function.prototype,
  onRemoveCertificationEvent: Function.prototype,
};

function Header(props) {
  const { firstName, lastName } = props.profile.user;
  return (
    <header id="header" className="awsui-util-mb-xs">
      <h1>
        {firstName} {lastName}
      </h1>
    </header>
  );
}

Header.propTypes = {
  profile: PropTypes.object.isRequired,
};

Header.defaultProps = {
  profile: Object.prototype,
};

const columns = [
  {
    title: 'Language pair',
  },
  {
    title: 'Media Type',
  },
  {
    title: 'Subject',
  },
  {
    title: 'Maximum Skill Level',
  },
].filter(c => c);

export class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddModal: false,
      showDeleteModal: false,
      certificationToDelete: null,
      lastDeleteError: null,
    };
  }

  render() {
    const handleShowAddModal = () => {
      this.setState({ showAddModal: true });
    };

    const handleCloseAddModal = () => {
      this.setState({ showAddModal: false });
    };

    const handleShowDeleteConfirmModal = certification => {
      this.setState({
        certificationToDelete: certification,
        lastDeleteError: null,
      });
    };

    const handleCloseDeleteConfirmModal = () => {
      this.setState({ certificationToDelete: null });
    };

    const handleDoDeleteAndClose = () => {
      this.props
        .onRemoveCertificationEvent(this.state.certificationToDelete)
        .then(() => {
          handleCloseDeleteConfirmModal();
          // See comment below for certification status on why a page reload is required.
          this.props.onLoadProfile(this.props.profile.user.id);
        })
        .catch(e => {
          this.setState({ lastDeleteError: e });
        });
    };

    const handleChangeCertificationSkill = (certification, maxTranslationSkillId) => {
      this.props
        .onChangeCertificationEvent({ ...certification, maxTranslationSkillId })
        .then(() => {
          // Because we use GenericCrudActions for Certification actions, and we don't use
          // GenericCrudReducer to store certifications, we must force a reload after updating
          // the certification status.  Without this, the user will not see the new status until
          // the page is refreshed.
          // The GenericCrudActions/Reducer does not really work well for the certifications case
          // because it's not capable of scoping its lists to a single user.  It was designed more
          // for loading all jobs, for example.
          // IDEALLY, we only have to refresh the grid here, not the entire page.
          // TODO either enhance GenericCrudReducer (maybe extend it) to support scoping its lists to a single
          // user and add the list of certifications to the app store (it is not there today), or
          // stop using the GenericCrudActions/Reducer entirely for certifications and
          // implement it in ProfileActions/ProfileReducer.
          this.props.onLoadProfile(this.props.profile.user.id);
        })
        .catch(() => toast('There was an error updating the certification', 'danger'));
    };

    const { profile, availableLocales } = this.props;
    const { skills } = this.props.translationSkills;
    const { mediaTypes } = this.props.mediaTypes;
    const { subjects } = this.props.subjects;
    const { id, firstName, lastName, username, certifications } = profile.user;
    const { certificationToDelete, lastDeleteError } = this.state;

    return (
      <div>
        <Container title="Personal Information">
          <dl css={css({ dd: { marginBottom: '1em' } })}>
            <dt>Name</dt>
            <dd id="name">{firstName + ' ' + lastName}</dd>
            <dt>Username</dt>
            <dd id="username">{username}</dd>
          </dl>
        </Container>
        {!certifications && (
          <Alert bsStyle="warning">
            <strong>Unable to load certifications.</strong>
          </Alert>
        )}
        {certifications && (
          <LegacyContainer
            title="Certifications"
            rawButtons={
              <>
                <button onClick={handleShowAddModal} className="btn btn-primary pull-right">
                  Add Certification
                </button>
              </>
            }
          >
            <Modal show={this.state.showAddModal} onHide={handleCloseAddModal}>
              <CertificationDialog
                closeHandler={handleCloseAddModal}
                onCreateEvent={this.props.onCreateCertificationEvent}
                onReloadProfile={this.props.onReloadProfile}
                userId={id}
                translationSkills={skills}
                mediaTypes={mediaTypes}
                subjects={subjects}
                availableLocales={availableLocales}
              />
            </Modal>
            {certificationToDelete != null && (
              <Modal show={certificationToDelete != null} onHide={handleCloseAddModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Delete certification?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {lastDeleteError && (
                    <ErrorAlert
                      title="Failed to delete certification"
                      errorInfo={lastDeleteError}
                    />
                  )}
                  Do you really want to remove the
                  {` '${mediaTypes[certificationToDelete.mediaTypeId]} ➡ ${
                    subjects[certificationToDelete.subjectId]
                  }' `}
                  certification for {certificationToDelete.sourceLocale} ➡{' '}
                  {certificationToDelete.targetLocale}?
                </Modal.Body>
                <Modal.Footer>
                  <Button id="cancel-delete" onClick={handleCloseDeleteConfirmModal}>
                    Cancel
                  </Button>
                  <Button bsStyle="primary" onClick={handleDoDeleteAndClose}>
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>
            )}

            <Grid
              columns={columns}
              items={certifications}
              renderRow={certification => (
                <>
                  <td>
                    {certification.sourceLocale} ➡ {certification.targetLocale}
                  </td>
                  <td>{mediaTypes[certification.mediaTypeId]}</td>
                  <td>{subjects[certification.subjectId]}</td>
                  <td>{skills[certification.maxTranslationSkillId]}</td>
                  <td className="row-actions">
                    <span onClick={e => e.stopPropagation()}>
                      <DropdownButton
                        title="Change Max Skill Level"
                        id={`change-status-dropdown-${id}`}
                        bsSize="xsmall"
                      >
                        {Object.keys(skills).map(skill => (
                          <MenuItem
                            key={`${skill}-${id}`}
                            onClick={() => {
                              handleChangeCertificationSkill(certification, skill);
                            }}
                          >
                            {skills[skill]}
                          </MenuItem>
                        ))}
                      </DropdownButton>
                    </span>
                    <button
                      className="btn btn-xs btn-danger"
                      onClick={() => handleShowDeleteConfirmModal(certification)}
                    >
                      Delete
                    </button>
                  </td>
                </>
              )}
            />
          </LegacyContainer>
        )}
      </div>
    );
  }
}

Main.propTypes = {
  session: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  translationSkills: PropTypes.object.isRequired,
  mediaTypes: PropTypes.object.isRequired,
  subjects: PropTypes.object.isRequired,
  onLoadProfile: PropTypes.func.isRequired,
  onCreateCertificationEvent: PropTypes.func.isRequired,
  onChangeCertificationEvent: PropTypes.func.isRequired,
  onRemoveCertificationEvent: PropTypes.func.isRequired,
  onReloadProfile: PropTypes.func.isRequired,
  availableLocales: PropTypes.object.isRequired,
};

Main.defaultProps = {
  onLoadProfile: Function.prototype,
  onCreateCertificationEvent: Function.prototype,
  onChangeCertificationEvent: PropTypes.func.isRequired,
  onRemoveCertificationEvent: Function.prototype,
  onReloadProfile: Function.prototype,
};

export default ProfileView;
