import React from 'react';
import PropTypes from 'prop-types';
import { formatPercent } from '../../../utils/formatNumber';
import { editField, readField } from '../Pricing/editorCss';
import { FormControl } from 'react-bootstrap';

const PriceModifier = ({ value, onChange }) => (
  <div>
    <div className={readField} id={`readonlyPercentage_${value.name}`}>
      {formatPercent(value.percentage)}
    </div>{' '}
    <div className={editField}>
      <FormControl
        type="number"
        min={0}
        max={100}
        style={{ width: 65 }}
        onChange={e => onChange('percentage', e.target.value / 100)}
        value={value.percentage * 100}
      />{' '}
      %
    </div>
  </div>
);

PriceModifier.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PriceModifier;
