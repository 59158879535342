import GenericCrudActions from './GenericCrudActions';
import ApiClient from '../ApiClient';
import { SortOrder } from '../utils/Enums';

const SORT_ORDER_MAPPING = {
  [SortOrder.ASC]: 'asc',
  [SortOrder.DESC]: 'desc',
  // Should not happen
  [SortOrder.UNSORTED]: 'asc',
};

const validationError = new Error();
validationError.status = 520;
validationError.details = {};
validationError.details.errors = [{ message: 'Unexpected response from server' }];

class GenericNextTokenCrudActions extends GenericCrudActions {
  requestLoadAllActionType() {
    return this.actionType('_REQUEST_LOAD_ALL');
  }

  finishedLoadAllActionType() {
    return this.actionType('_FINISHED_LOAD_ALL');
  }

  requestLoadAll() {
    return {
      type: this.requestLoadAllActionType(),
    };
  }

  loadAll() {
    return dispatch => {
      dispatch(this.requestLoadAll());
      return dispatch(this.fetchAll());
    };
  }

  resetAndLoadAll(clearSearch) {
    return dispatch => {
      dispatch(this.resetPage(clearSearch));
      dispatch(this.requestLoadAll());
      return dispatch(this.fetchAll());
    };
  }

  finishedFetch(data) {
    return {
      type: this.finishedFetchActionType(),
      items: data.items,
      nextToken: data.nextToken,
      receivedAt: Date.now(),
    };
  }

  fetch() {
    return (dispatch, getState) => {
      const state = getState();
      try {
        if (!state.hasOwnProperty(this.type)) {
          throw new Error(`type '${this.type}' not present in state`);
        }
        const { sortField, sortOrder, searchPattern, nextToken } = state[this.type];
        const { languagePair } = state.session;
        const sourceLocale = languagePair.source;
        const targetLocale = languagePair.target;

        let url = `${this.type}?`;

        // check for locale params
        if (sourceLocale !== undefined) {
          url = `${url}sourceLocale=${encodeURIComponent(sourceLocale)}&`;
        }
        if (targetLocale !== undefined) {
          url = `${url}targetLocale=${encodeURIComponent(targetLocale)}&`;
        }

        // check for pagination params
        if (nextToken != null) {
          url = `${url}nextToken=${encodeURIComponent(nextToken)}&`;
        }
        if (searchPattern !== undefined && searchPattern !== '') {
          url = `${url}searchPattern=${encodeURIComponent(searchPattern.trim())}&`;
        }

        // determine sorting if required
        if (sortField !== null && sortField !== undefined) {
          url += `sortField=${sortField}&sortOrder=${SORT_ORDER_MAPPING[sortOrder]}&`;
        }

        ApiClient.httpGet(url)
          .then(resp => {
            resp.constructor === Object
              ? dispatch(this.finishedFetch(resp))
              : dispatch(this.failFetch(validationError));
          })
          .catch(err => {
            dispatch(this.failFetch(err));
          });
      } catch (err) {
        dispatch(this.failFetch(err));
      }
    };
  }

  finishedFetchAll(data) {
    return {
      type: this.finishedLoadAllActionType(),
      items: data.items,
      nextToken: data.nextToken,
      receivedAt: Date.now(),
    };
  }

  // Recursively load all items
  // This can't be done in the parent class because of how fetch works there ATM.
  // TODO: Refactor into parent when possible
  fetchAll(nextToken = null, everythingSoFar = []) {
    return (dispatch, getState) => {
      const state = getState();
      try {
        const { sortField, sortOrder, searchPattern } = state[this.type];
        const { languagePair } = state.session;
        const sourceLocale = languagePair.source;
        const targetLocale = languagePair.target;

        let url = `${this.type}?`;

        // check for locale params
        if (sourceLocale !== undefined) {
          url = `${url}sourceLocale=${encodeURIComponent(sourceLocale)}&`;
        }
        if (targetLocale !== undefined) {
          url = `${url}targetLocale=${encodeURIComponent(targetLocale)}&`;
        }

        // check for pagination params
        if (nextToken != null) {
          url = `${url}nextToken=${encodeURIComponent(nextToken)}&`;
        }
        if (searchPattern !== undefined && searchPattern !== '') {
          url = `${url}searchPattern=${encodeURIComponent(searchPattern.trim())}&`;
        }

        // determine sorting if required
        if (sortField !== null && sortField !== undefined) {
          url += `sortField=${sortField}&sortOrder=${SORT_ORDER_MAPPING[sortOrder]}&`;
        }

        ApiClient.httpGet(url)
          .then(resp => {
            if (resp.constructor !== Object) {
              dispatch(this.failFetch(validationError));
            } else if (resp.nextToken) {
              //TODO: Emit intermediate event with results so far
              this.fetchAll(resp.nextToken, everythingSoFar.concat(resp.items))(dispatch, getState);
            } else {
              dispatch(
                this.finishedFetchAll({
                  items: everythingSoFar.concat(resp.items),
                })
              );
            }
          })
          .catch(err => {
            dispatch(this.failFetch(err));
          });
      } catch (err) {
        dispatch(this.failFetch(err));
      }
    };
  }
}

export default GenericNextTokenCrudActions;
