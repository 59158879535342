import ApiClient from '../ApiClient';
import initialMetricsPublisher from '../utils/metricsPublisher';

const ActionTypes = {
  PROFILE_RELOAD_REQUEST: 'PROFILE_RELOAD_REQUEST',
  PROFILE_RELOAD_RECEIVE_FAILURE: 'PROFILE_RELOAD_RECEIVE_FAILURE',
  PROFILE_RELOAD_RECEIVE: 'PROFILE_RELOAD_RECEIVE',
};

const validationError = new Error();
validationError.status = 520;
validationError.details = {};
validationError.details.errors = [{ message: 'Unexpected response from server' }];

function receiveProfileReload(profile) {
  return {
    type: ActionTypes.PROFILE_RELOAD_RECEIVE,
    profile: profile,
    receivedAt: Date.now(),
  };
}

function receiveProfileReloadFailure(error) {
  return {
    type: ActionTypes.PROFILE_RELOAD_RECEIVE_FAILURE,
    receivedAt: Date.now(),
    error,
  };
}

function fetchProfile(userId) {
  return (dispatch, getState) => {
    try {
      const url = `/profile/${userId}`;

      ApiClient.httpGet(url)
        .then(resp => {
          resp.constructor === Object
            ? dispatch(receiveProfileReload(resp))
            : dispatch(receiveProfileReloadFailure(validationError));
        })
        .catch(err => {
          dispatch(receiveProfileReloadFailure(err));
        });
    } catch (err) {
      dispatch(receiveProfileReloadFailure(err));
    }
  };
}

function requestLoadProfile(userId) {
  return dispatch => {
    dispatch({
      type: ActionTypes.PROFILE_RELOAD_REQUEST,
    });

    return dispatch(fetchProfile(userId));
  };
}

export function loadProfile(userId) {
  return dispatch => {
    return dispatch(requestLoadProfile(userId));
  };
}

export function fetchMyProfile() {
  return (dispatch, getState) => {
    try {
      const url = `me/profile`;

      ApiClient.httpGet(url)
        .then(resp => {
          if (resp.constructor === Object) {
            // emit UX metrics: user is active (this API is always called as soon as user is on the page
            let metricsPublisher = initialMetricsPublisher.newChildActionPublisherForMethod(
              'UserIsActive'
            );
            metricsPublisher.publishString('UserId', resp.id);
            dispatch(receiveProfileReload(resp));
          } else {
            dispatch(receiveProfileReloadFailure(validationError));
          }
        })
        .catch(err => {
          dispatch(receiveProfileReloadFailure(err));
        });
    } catch (err) {
      dispatch(receiveProfileReloadFailure(err));
    }
  };
}

function requestLoadMyProfile() {
  return dispatch => {
    dispatch({
      type: ActionTypes.PROFILE_RELOAD_REQUEST,
    });

    return dispatch(fetchMyProfile());
  };
}

export function loadMyProfile() {
  return dispatch => {
    return dispatch(requestLoadMyProfile());
  };
}

export default ActionTypes;
