import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import PricingList from './PricingList';
import PriceSchemeDetail from './PriceSchemeDetail';

function PricingView(props) {
  return (
    <Switch>
      <Route
        path="/settings/pricing/:id"
        render={({ match }) => <PriceSchemeDetail {...props} priceSchemeId={match.params.id} />}
      />
      <Route render={() => <PricingList {...props} />} />
    </Switch>
  );
}

PricingView.propTypes = {
  onLoadMore: PropTypes.func,
  onReload: PropTypes.func,
  onSearch: PropTypes.func,
  onSortChange: PropTypes.func,
  onCreateEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onRemoveEvent: PropTypes.func,
  session: PropTypes.object.isRequired,
  priceSchemes: PropTypes.object.isRequired,
  features: PropTypes.object,
};

PricingView.defaultProps = {
  onLoadMore: Function.prototype,
  onReload: Function.prototype,
  onSearch: Function.prototype,
  onSortChange: Function.prototype,
  onCreateEvent: Function.prototype,
  onUpdateEvent: Function.prototype,
  onRemoveEvent: Function.prototype,
  session: Object.prototype,
  priceSchemes: Object.prototype,
  features: Object.prototype,
};

export default PricingView;
