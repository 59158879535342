import GenericNextTokenCrudActions from './GenericNextTokenCrudActions';
import ApiClient from '../ApiClient';

class MediaTypeSubjectSettingsActions extends GenericNextTokenCrudActions {
  constructor() {
    super('mediaTypeSubjectSettings');
  }

  createOrUpdate(mediaType, item, reload = true) {
    return dispatch => {
      dispatch(this.updating(item));
      return ApiClient.httpPut(`/${this.type}/${mediaType}/${item.subject}`, item)
        .then(resp => {
          const update = dispatch(
            this.updated({
              id: resp[this.idField],
              ...resp,
            })
          );

          return reload ? dispatch(this.reload()) : update;
        })
        .catch(ex => {
          dispatch(this.updateFailed());
          throw ex;
        });
    };
  }

  remove(mediaType, item) {
    return dispatch => {
      dispatch(this.removing());
      return ApiClient.httpDelete(`/${this.type}/${mediaType}/${item.subject}`, item)
        .then(resp => {
          dispatch(this.removed(item));

          return dispatch(this.reload());
        })
        .catch(ex => {
          dispatch(this.removeFailed());
          throw ex;
        });
    };
  }
}

export default MediaTypeSubjectSettingsActions;
