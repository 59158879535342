import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { infoCircle } from 'react-icons-kit/fa/infoCircle';
import { filesO } from 'react-icons-kit/fa/filesO';
import { fileO } from 'react-icons-kit/fa/fileO';
import { share } from 'react-icons-kit/fa/share';
import { shop } from 'react-icons-kit/ikons/shop';
import { gear } from 'react-icons-kit/fa/gear';
import { users } from 'react-icons-kit/fa/users';
import { spreadsheet } from 'react-icons-kit/iconic/spreadsheet';
import { barChart } from 'react-icons-kit/fa/barChart';

import Offers from '../Offer';
import HistoryOffers from '../translator/OfferHistory';
import { AssignToVendor } from '../AssignToVendor';
import TranslatorJobsRouter from '../../components/TranslatorJobsRouter';
import TranslatorContainer from '../../containers/TranslatorContainer';
import canDo from '../../utils/canDo';
import SideNav from './SideNav';
import CapacityManagement from '../CapacityManagement';
import { CreatePostMortemJobs } from '../CreatePostMortemJobs';
import { inferEnvironment } from '@amzn/et-console-components';
import Translator, { TranslatorViewV2 } from '../translator/TranslatorV2';
import ProfileV2 from '../translator/ProfileViewV2';
import LinguistFeedback from '../LinguistFeedback';
import { Settings } from '../Settings';

const Container = styled('div')`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

const Nav = styled('div')`
  flex-grow: 0;
  flex-shrink: 0;
  overflow: auto;
  position: relative;
  box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.5);
`;

const Content = styled('div')`
  padding: 40px;
  flex-grow: 1;
  overflow: auto;
  background-color: #f2f3f3;
`;

const getSections = (session, features, globalState) => {
  const sections = [];
  if (canDo(session, 'job:read')) {
    sections.push({
      path: '/offers',
      title: 'Offers',
      icon: filesO,
      content: <Offers globalState={globalState} />,
    });
  } else if (canDo(session, 'job:readOwn')) {
    if (inferEnvironment() !== 'prod') {
      sections.push({
        path: '/feedback',
        title: 'Feedback',
        icon: barChart,
        content: <LinguistFeedback profile={globalState?.profile} />,
      });
    }
    sections.push(
      {
        path: '/jobs',
        title: 'Current Jobs',
        icon: filesO,
        content: <TranslatorJobsRouter features={features} globalState={globalState} />,
      },
      {
        path: '/offers',
        title: 'Job Offers',
        icon: shop,
        content: <TranslatorJobsRouter features={features} globalState={globalState} />,
      }
    );
    sections.push({
      path: '/history',
      title: 'Job History',
      icon: shop,
      content: <HistoryOffers globalState={globalState} />,
    });
  }

  const capacityManagementEnabledOrgs = {
    staging: [3, 7, 80],
    prod: [3, 5],
  };

  if (
    !capacityManagementEnabledOrgs.hasOwnProperty(inferEnvironment()) ||
    capacityManagementEnabledOrgs[inferEnvironment()].includes(session.organizationId)
  ) {
    console.log(inferEnvironment());
    if (canDo(session, 'capacityManagement:read')) {
      sections.push({
        path: '/capacityManagement',
        title: 'Capacity Management',
        icon: fileO,
        content: <CapacityManagement globalState={globalState} />,
      });
    }
  }
  sections.push(
    {
      path: '/translators',
      title: 'Translators',
      icon: users,
      content: canDo(session, 'translator:read') && <Translator globalState={globalState} />,
    },
    {
      path: '/settings',
      title: 'Settings',
      icon: gear,
      content: canDo(session, 'pm:read') && <Settings session={session} features={features} />,
    },
    {
      path: 'https://amazontranslationlearn.com',
      title: 'Resources',
      icon: infoCircle,
      content: canDo(session, 'job:readOwn') && SideNav.EXTERNAL_PAGE,
    }
  );

  if (canDo(session, 'job:read')) {
    sections.push(
      {
        path: '#',
        content: SideNav.DIVIDER,
      },
      {
        path: '/agencyAssignment',
        title: 'Assign to agency',
        icon: share,
        content: <AssignToVendor globalState={globalState} />,
      }
    );
  }

  if (canDo(session, 'postMortemScoring:write')) {
    sections.push(
      {
        path: '#',
        content: SideNav.DIVIDER,
      },
      {
        path: '/createPostDeliveryJobs',
        title: 'Sampled Post-Delivery QA',
        icon: spreadsheet,
        content: <CreatePostMortemJobs />,
      }
    );
  }

  return sections.filter(({ content }) => content);
};

const Body = ({ session, features, globalState }) => {
  const sections = getSections(session, features, globalState);
  return (
    <Container>
      <Nav>
        <SideNav session={session} items={sections} features={features} />
      </Nav>
      <Content className="awsui">
        <Switch>
          <Route exact path="/" render={() => <Redirect push to={sections[0].path} />} />
          {sections.map(({ path, content }) => (
            <Route key={path} path={path}>
              {content}
            </Route>
          ))}
          <Route
            key={`/translator/:userId`}
            path={`/translator/:userId`}
            render={({ match }) => <ProfileV2 {...globalState} userId={match.params.userId} />}
          />
        </Switch>
      </Content>
    </Container>
  );
};

Body.propTypes = {
  session: PropTypes.object,
  features: PropTypes.object,
  globalState: PropTypes.object,
};

export default Body;
