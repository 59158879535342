import ApiClient from '../ApiClient';

// TODO: Copied and adapted from atms-web-ui. Clean up differences and add to et-console-components to be used by both?
export const paginateAll = async url => {
  let page = 0;
  const result = {};

  // support urls with existing query parameters
  if (url.includes('?')) {
    url = `${url}&`;
  } else {
    url = `${url}?`;
  }

  let keepPaging = true;
  while (keepPaging) {
    const pageResult = await ApiClient.httpGet(`${url}pageNum=${page}`);
    if (Object.keys(pageResult).length < 1) {
      keepPaging = false;
    } else {
      Object.assign(result, pageResult);
      page = page + 1;
    }
  }

  return result;
};
