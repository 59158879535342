import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  DatePicker,
  Form,
  FormField,
  Header,
  Input,
  SpaceBetween,
  Modal,
  Select,
  ColumnLayout,
} from '@amzn/awsui-components-react-v3/polaris';
import { Formik } from 'formik';
import * as yup from 'yup';

// const formatDate = date => {
//   return date.toISOString().split('T')[0];
// };

const prepareSelectOptions = elements => {
  const options = [];
  for (const [key, value] of Object.entries(elements)) {
    options.push({ label: value, value: key });
  }
  return options;
};

const AddEditProjectPlanRowModal = ({
  mode,
  setShowModal,
  showModal,
  handleSubmit,
  globalState,
  projectPlanRow,
  unlockRow,
  isSubmitting,
}) => {
  const VALIDATION_SCHEMA = yup.object({
    client: yup.string().required(),
    priority: yup
      .number()
      .min(1)
      .required(),
    startDate: yup.string().required('Start Date is required'),
    // .test('notInPast', 'Start Date must not be in the past', value => {
    //   return mode == 'Edit' || value >= formatDate(new Date());
    // }),
    endDate: yup.string().when('startDate', startDate => {
      return yup
        .string()
        .required('End Date is required')
        .test('afterStartDate', 'End Date must be after Start Date', value => {
          return startDate !== undefined ? value > startDate : true;
        })
        .test('longerThanYear', 'End Date must be within one year of the current date', value => {
          const oneYear = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
          return new Date(value) < oneYear;
        });
    }),
    sourceLanguage: yup.string().required(),
    targetLanguage: yup.string().required(),
    totalVolume: yup
      .number()
      .min(1, 'Volume must be greater than zero')
      .max(2147483647, 'Volume must not be greater than 2,147,483,647')
      .required('Volume is required'),
    subject: yup.string().required(),
    mediaType: yup.string().required(),
    skill: yup.string().required(),
    owner: yup.string().required(),
    status: yup.string().required(),
    planForFreelancers: yup
      .number()
      .min(0, 'Plan for freelancers must not be a negative number')
      .nullable(true),
    planForAgents: yup
      .number()
      .when(['totalVolume', 'planForFreelancers'], (totalVolume, planForFreelancers) => {
        return yup
          .number()
          .min(0, 'Plan for agencies must not be a negative number')
          .test(
            'plansEqualVolume',
            'Plan for freelancers and plan for agencies must combine to equal total volume',
            value => {
              return totalVolume > 0 && planForFreelancers >= 0 && planForFreelancers !== null
                ? planForFreelancers + value == totalVolume
                : true;
            }
          )
          .nullable(true);
      }),
  });

  const localesMap = globalState.availableLocales.reduce((map, obj) => {
    map[obj.code] = obj.name;
    return map;
  }, {});
  const dismissModal = () => {
    setShowModal(false);
    if (mode === 'Edit') {
      unlockRow();
    }
  };

  const submitClicked = async values => {
    const toTimestamp = strDate => {
      return strDate + 'T00:00:00.000000Z';
    };
    handleSubmit({
      id: projectPlanRow.id,
      client: values['client'],
      priority: parseInt(values['priority']),
      startDate: toTimestamp(values['startDate']),
      endDate: toTimestamp(values['endDate']),
      languageArc: values['sourceLanguage'] + ':' + values['targetLanguage'],
      totalVolume: parseInt(values['totalVolume']),
      status: values['status'],
      subject: values['subject'],
      mediaType: values['mediaType'],
      skill: values['skill'],
      owner: values['owner'],
      planForFreelancers: parseInt(values['planForFreelancers']),
      planForAgents: parseInt(values['planForAgents']),
    });
  };

  const renderForm = ({
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    submitCount,
    handleReset,
  }) => {
    const saveEnabled = Object.keys(touched).length > 0;

    return (
      <Modal
        id={mode + 'ProjectPlanRowModal'}
        onDismiss={() => {
          dismissModal();
          handleReset();
        }}
        visible={showModal}
        closeAriaLabel="Close modal"
        size="medium"
        header={<Header variant="h2">{mode} Project</Header>}
      >
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                id={'cancel' + mode + 'Button'}
                variant="link"
                onClick={() => {
                  dismissModal();
                  handleReset();
                }}
              >
                Cancel
              </Button>
              <Button
                id={'submit' + mode + 'Button'}
                variant="primary"
                disabled={!saveEnabled}
                onClick={handleSubmit}
                loading={isSubmitting}
              >
                Save
              </Button>
            </SpaceBetween>
          }
          errorText={
            Object.keys(errors).length > 0 && submitCount > 0
              ? 'All errors must be resolved before submission'
              : null
          }
        >
          <SpaceBetween direction="vertical" size="l">
            <FormField
              label="Client"
              id={'client'}
              errorText={
                touched.client && errors.client ? 'You must enter a valid client value' : null
              }
            >
              <Input
                placeholder={'Enter the Client'}
                onChange={({ detail }) => {
                  setFieldValue('client', detail.value);
                  setFieldTouched('client');
                }}
                value={values.client}
                type="text"
                id={mode + 'client'}
              />
            </FormField>
            <FormField
              label="Priority"
              id={'priority'}
              errorText={touched.priority && errors.priority ? 'You must select a priority' : null}
            >
              <Select
                placeholder={'Select the Priority'}
                selectedOption={
                  values.priority ? { label: values.priority, value: values.priority } : null
                }
                onChange={({ detail }) => {
                  setFieldValue('priority', detail.selectedOption.value);
                  setFieldTouched('priority');
                }}
                options={Array.from([1, 2, 3, 4, 5, 6, 7, 8, 9]).map(number => ({
                  label: number,
                  value: number,
                }))}
                id={mode + 'priority'}
              />
            </FormField>
            <ColumnLayout columns={2}>
              <FormField
                label="Start Date"
                id={'startDate'}
                errorText={touched.startDate && errors.startDate ? errors.startDate : null}
              >
                <DatePicker
                  onChange={({ detail }) => {
                    setFieldValue('startDate', detail.value);
                    setFieldTouched('startDate');
                  }}
                  value={values.startDate}
                  id={mode + 'startDate'}
                  openCalendarAriaLabel={selectedDate =>
                    'Choose Date' + (selectedDate ? `, selected date is ${selectedDate}` : '')
                  }
                  nextMonthAriaLabel="Next month"
                  placeholder="YYYY/MM/DD"
                  previousMonthAriaLabel="Previous month"
                  todayAriaLabel="Today"
                />
              </FormField>
              <FormField
                label="End Date"
                id={'endDate'}
                errorText={touched.endDate && errors.endDate ? errors.endDate : null}
              >
                <DatePicker
                  onChange={({ detail }) => {
                    setFieldValue('endDate', detail.value);
                    setFieldTouched('endDate');
                  }}
                  value={values.endDate}
                  id={mode + 'endDate'}
                  openCalendarAriaLabel={selectedDate =>
                    'Choose Date' + (selectedDate ? `, selected date is ${selectedDate}` : '')
                  }
                  nextMonthAriaLabel="Next month"
                  placeholder="YYYY/MM/DD"
                  previousMonthAriaLabel="Previous month"
                  todayAriaLabel="Today"
                />
              </FormField>
            </ColumnLayout>
            <FormField
              label="Volume (Words)"
              id={'totalVolume'}
              errorText={touched.totalVolume && errors.totalVolume ? errors.totalVolume : null}
            >
              <Input
                onChange={({ detail }) => {
                  setFieldValue('totalVolume', detail.value);
                  setFieldTouched('totalVolume');
                }}
                placeholder={'Enter the Total Volume in Words, eg: 10,000'}
                value={values.totalVolume}
                type="number"
                id={mode + 'totalVolume'}
              />
            </FormField>
            <FormField
              label={'Status'}
              id={'status'}
              errorText={touched.status && errors.status ? 'You must select a status' : null}
            >
              <Select
                selectedOption={
                  values.status
                    ? {
                        label: values.status,
                        value: values.status,
                      }
                    : null
                }
                options={[
                  { label: 'Pending Confirmation', value: 'Pending Confirmation' },
                  { label: 'Confirmed', value: 'Confirmed' },
                  { label: 'Ongoing', value: 'Ongoing' },
                  { label: 'Completed', value: 'Completed' },
                ]}
                selectedAriaLabel={'Selected'}
                onChange={({ detail }) => {
                  setFieldValue('status', detail.selectedOption.value);
                  setFieldTouched('status');
                }}
                placeholder={'Select the Status'}
                id={mode + 'status'}
              />
            </FormField>
            <FormField
              label="Source Language"
              id={'sourceLanguage'}
              errorText={
                touched.sourceLanguage && errors.sourceLanguage
                  ? 'You must select a source language'
                  : null
              }
            >
              <Select
                selectedOption={
                  values.sourceLanguage
                    ? {
                        label: localesMap[values.sourceLanguage],
                        value: values.sourceLanguage,
                      }
                    : null
                }
                onChange={({ detail }) => {
                  setFieldValue('sourceLanguage', detail.selectedOption.value);
                  setFieldTouched('sourceLanguage');
                }}
                options={Object.keys(localesMap).map(locale => ({
                  label: localesMap[locale],
                  value: locale,
                }))}
                placeholder={'Select the Source Language'}
                id={mode + 'sourceLanguage'}
                filteringType={'auto'}
              />
            </FormField>
            <FormField
              label="Target Language"
              id={'targetLanguage'}
              errorText={
                touched.targetLanguage && errors.targetLanguage
                  ? 'You must select a target language'
                  : null
              }
            >
              <Select
                selectedOption={
                  values.targetLanguage
                    ? {
                        label: localesMap[values.targetLanguage],
                        value: values.targetLanguage,
                      }
                    : null
                }
                onChange={({ detail }) => {
                  setFieldValue('targetLanguage', detail.selectedOption.value);
                  setFieldTouched('targetLanguage');
                }}
                options={Object.keys(localesMap).map(locale => ({
                  label: localesMap[locale],
                  value: locale,
                }))}
                placeholder={'Select the Target Language'}
                id={mode + 'targetLanguage'}
                filteringType={'auto'}
              />
            </FormField>
            <FormField
              label="Subject"
              id={'subject'}
              errorText={touched.subject && errors.subject ? 'You must select a subject' : null}
            >
              <Select
                id={mode + 'subject'}
                selectedOption={
                  values.subject
                    ? {
                        label: globalState.subjects[values.subject],
                        value: values.subject,
                      }
                    : null
                }
                options={prepareSelectOptions(globalState.subjects)}
                selectedAriaLabel="Selected"
                placeholder="Select the Subject"
                onChange={({ detail }) => {
                  setFieldValue('subject', detail.selectedOption.value);
                  setFieldTouched('subject');
                }}
                filteringType={'auto'}
              />
            </FormField>
            <FormField
              label="Media type"
              id={'mediaType'}
              errorText={
                touched.mediaType && errors.mediaType ? 'You must select a media type' : null
              }
            >
              <Select
                selectedOption={
                  values.mediaType
                    ? {
                        label: globalState.mediaTypes[values.mediaType],
                        value: values.mediaType,
                      }
                    : null
                }
                id={mode + 'mediaType'}
                onChange={({ detail }) => {
                  setFieldValue('mediaType', detail.selectedOption.value);
                  setFieldTouched('mediaType');
                }}
                options={prepareSelectOptions(globalState.mediaTypes)}
                selectedAriaLabel="Selected"
                placeholder="Select the Media Type"
                filteringType={'auto'}
              />
            </FormField>
            <FormField
              label="Skill"
              id={'skill'}
              errorText={touched.skill && errors.skill ? 'You must select a skill' : null}
            >
              <Select
                selectedOption={
                  values.skill
                    ? {
                        label: globalState.translationSkills[values.skill],
                        value: values.skill,
                      }
                    : null
                }
                id={mode + 'skill'}
                onChange={({ detail }) => {
                  setFieldValue('skill', detail.selectedOption.value);
                  setFieldTouched('skill');
                }}
                options={prepareSelectOptions(globalState.translationSkills)}
                selectedAriaLabel="Selected"
                placeholder="Select the Skill"
                filteringType={'auto'}
              />
            </FormField>
            <FormField
              label="Owner"
              id={'owner'}
              errorText={touched.owner && errors.owner ? 'You must enter a valid owner' : null}
            >
              <Input
                onChange={({ detail }) => {
                  setFieldValue('owner', detail.value);
                  setFieldTouched('owner');
                }}
                placeholder={'Enter the PM Owner'}
                value={values.owner}
                id={mode + 'owner'}
                type="text"
              />
            </FormField>
            {mode === 'Edit' && (
              <FormField
                label="Plan For Freelancers"
                id={'planForFreelancers'}
                errorText={
                  touched.planForFreelancers && errors.planForFreelancers
                    ? errors.planForFreelancers
                    : null
                }
              >
                <Input
                  onChange={({ detail }) => {
                    setFieldValue('planForFreelancers', detail.value);
                    setFieldTouched('planForFreelancers');
                  }}
                  value={values.planForFreelancers}
                  id={mode + 'planForFreelancers'}
                  type="number"
                  placeholder={'Enter the number of words that can be managed through freelancers'}
                />
              </FormField>
            )}
            {mode === 'Edit' && (
              <FormField
                label="Plan For Agencies"
                id={'planForAgents'}
                errorText={
                  touched.planForAgents && errors.planForAgents ? errors.planForAgents : null
                }
              >
                <Input
                  onChange={({ detail }) => {
                    setFieldValue('planForAgents', detail.value);
                    setFieldTouched('planForAgents');
                  }}
                  value={values.planForAgents}
                  id={mode + 'planForAgents'}
                  type="number"
                  placeholder={'Enter the number of words that need to be outsourced to agencies'}
                />
              </FormField>
            )}
          </SpaceBetween>
        </Form>
      </Modal>
    );
  };

  return (
    <Formik
      onSubmit={submitClicked}
      enableReinitialize={true}
      initialValues={projectPlanRow}
      validationSchema={VALIDATION_SCHEMA}
      validateOnChange={true}
      render={renderForm}
    />
  );
};

AddEditProjectPlanRowModal.propTypes = {
  mode: PropTypes.string,
  globalState: PropTypes.object,
  setShowModal: PropTypes.func,
  showModal: PropTypes.bool,
  handleSubmit: PropTypes.func,
  projectPlanRow: PropTypes.object,
  unlockRow: PropTypes.func,
  isSubmitting: PropTypes.bool,
};
export default AddEditProjectPlanRowModal;
