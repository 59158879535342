import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Container } from '@amzn/awsui-components-react-v3';
import styled from 'react-emotion';
import { CapacityManagementUtils } from '../../utils/capacityManagementUtils';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';

const GridColumn = styled('div')`
  .capacity-overview-grid > * {
    padding: 0;

    > * {
      padding: 0;
      margin: -1px;

      .week-label {
        height: auto;
        margin-top: 10px;
        writing-mode: vertical-lr;
        transform: rotate(180deg);
        border-color: transparent;
        font-size: medium;
        font-variant: diagonal-fractions;
        width: inherit;
        line-height: 0px;
      }

      & div {
        height: 75px;
        margin: 0px -1px;
        border: 1px solid white;
      }
    }
  }
`;

const StylizedGrid = styled('div')`
  padding-top: 8px;

  .stylized-grid > *:nth-child(2) {
    flex: auto;
  }

  .capacity-overview-grid {
    > * {
      flex: auto;
    }
  }

  .language-arc-title {
    line-height: 74.5px;
    font-size: small;
    margin: -1px 8px -1px 0px;
  }

  .language-label {
    font-size: medium;
    font-weight: bold;
  }

  .week-axis-label {
    font-size: medium;
    text-align: center;
    font-weight: bold;
  }

  .key-grid {
    width: min-content;
    padding-right: 50px;
    padding-left: 10px;

    [class*='awsui_grid-column']:first-child,
    [class*='awsui_grid-column']:nth-child(2) {
      margin-top: 15px;
    }

    [class*='awsui_grid-column'] {
      margin-top: 10px;
      padding: 0px;
    }

    .color-label {
      width: max-content;
      font-size: small;
    }

    .color-box {
      width: 80%;
      height: 100%;
    }
  }
`;

const Legend = () => (
  <div>
    <ul style={{ display: 'flex' }}>
      <il className={'legend-list-item'}>
        <span style={{ backgroundColor: '#d8d8d8' }} className={'legend-icon'}></span>
        No Gap
      </il>
      <il className={'legend-list-item'}>
        <span style={{ backgroundColor: '#0297ff' }} className={'legend-icon'}></span>
        Planned Gap
      </il>
      <il className={'legend-list-item'}>
        <span style={{ backgroundColor: '#ff2800' }} className={'legend-icon'}></span>
        Unplanned Gap
      </il>
    </ul>
  </div>
);

const popupDialog = (plannedVolume, volume, week, languageArc) => {
  const unplannedVolume = volume - plannedVolume;
  //the week needs to be incremented for display because it is 0 based
  return (
    <Fragment>
      <div>Week {week + 1}</div>
      <div>{CapacityManagementUtils.formatLanguageArc(languageArc)}</div>
      <div>Planned Gap: {plannedVolume} words</div>
      <div>Unplanned Gap: {unplannedVolume} words</div>
    </Fragment>
  );
};

const getColor = (volume, plannedVolume) => {
  switch (true) {
    case volume == 0:
      // light grey
      return '#d8d8d8';
    case plannedVolume >= volume:
      // blue rgb(2,151,255)
      return '#0297ff';
    case plannedVolume < volume:
      // red rgb(255,40,0)
      return '#ff2800';
  }
};

const languageArcTitles = languageArcs => {
  return (
    <div>
      {languageArcs.map(arc => (
        <div className={'language-arc-title'} key={arc}>
          {CapacityManagementUtils.formatLanguageArc(arc)}
        </div>
      ))}
    </div>
  );
};

const languageArcGrids = (languageArcsGaps, setSelectedArcWeekGaps, numOfWeeks) => {
  //the week number for display needs to be incremented by 1 because its 0 based and people do not like to read 0 based
  return (
    <div className={'language-arc-grids'}>
      {languageArcsGaps.map(([languageArc, arcGaps]) => (
        <GridColumn key={languageArc}>
          <Grid
            disableGutters
            className={'capacity-overview-grid'}
            id={`${languageArc.replace(/[:]/g, '')}-grid`}
          >
            {arcGaps.map((gap, index) => (
              <Tippy
                key={index}
                content={popupDialog(gap.plannedVolume, gap.volume, index, languageArc)}
                theme={'light-border'}
                placement={'bottom'}
                offset={[0, -20]}
              >
                <div
                  onClick={() => {
                    setSelectedArcWeekGaps({
                      languageArc: languageArc,
                      week: index,
                      gapDetails: gap.gapDetails,
                    });
                  }}
                  key={index}
                  style={{
                    backgroundColor: getColor(gap.volume, gap.plannedVolume),
                  }}
                ></div>
              </Tippy>
            ))}
          </Grid>
        </GridColumn>
      ))}
      <SpaceBetween size={'s'}>
        <GridColumn>
          <Grid className={'capacity-overview-grid'} disableGutters>
            {[...Array(numOfWeeks)].map((_, i) => (
              <div key={i} className={'week-label'}>
                {i + 1}
              </div>
            ))}
          </Grid>
        </GridColumn>
        <div className={'week-axis-label'}>Week</div>
      </SpaceBetween>
    </div>
  );
};

export const CapacityOverviewGrid = ({
  clientCapacityReports,
  setSelectedArcWeek,
  selectedArcWeek,
}) => {
  const [languageArcVolume, setLanguageArcVolume] = useState({});
  const [selectedArcWeekGaps, setSelectedArcWeekGaps] = useState([]);
  const [numOfWeeks, setNumOfWeeks] = useState(53);

  useEffect(() => {
    setSelectedArcWeek(selectedArcWeekGaps);
  }, [selectedArcWeekGaps]);

  useEffect(() => {
    if (clientCapacityReports) {
      let maxWeek = 52;
      // Instead of grouping by client we want to group by language arc.
      // Here we shift from map each language to an array of gaps
      // Each gap contains the gap volume, week number, skill, subject, and media type of that gap.
      const languageGaps = clientCapacityReports.reduce((gaps, report) => {
        let plannedTime = 0;
        if (report.hasOwnProperty('planForFreelancers')) plannedTime += report.planForFreelancers;
        if (report.hasOwnProperty('planForAgents')) plannedTime += report.planForAgents;
        const plannedPerWeek =
          report.gaps && plannedTime > 0 ? Math.ceil(plannedTime / report.gaps.length) : 0;
        const explodedGaps = report.gaps?.reduce((explodedGap, gap) => {
          if (gap.weekNumber > maxWeek) maxWeek = gap.weekNumber;
          return explodedGap.concat([
            {
              skill: report.skill,
              subject: report.subject,
              mediaType: report.mediaType,
              volume: gap.volume,
              week: gap.weekNumber,
              plannedVolume: Math.min(plannedPerWeek, gap.volume),
              priority: report.priority,
            },
          ]);
        }, []);
        gaps[report.languageArc] = gaps[report.languageArc]?.concat(explodedGaps) || explodedGaps;
        return gaps;
      }, {});
      setNumOfWeeks(maxWeek + 1);

      // For each language arc we will create an array representing gaps per week.
      // There may be more than one gap per week coming from different clients so
      // we will loop through each gap and add it to the corresponding week in the array.
      Object.entries(languageGaps).forEach(([languageArc, gaps]) => {
        const languageWeeks = Array(maxWeek + 1)
          .fill()
          .map(u => ({ volume: 0, gapDetails: [], plannedVolume: 0 }));
        gaps &&
          gaps.forEach(gap => {
            if (gap !== undefined) {
              //minus one from the week here because the weeks returned via the api are 1 based for the year, and
              //this array is 0 based
              languageWeeks[gap.week - 1].volume += gap.volume;
              languageWeeks[gap.week - 1].gapDetails.push(gap);
              languageWeeks[gap.week - 1].plannedVolume += gap.plannedVolume;
            }
          });

        // For each week we want to remove the duplicate skill/subject/mediaTypes
        // and combine the volumes of each combination.
        languageGaps[languageArc] = languageWeeks.reduce((collapsedGaps, week, index) => {
          collapsedGaps[index].gapDetails = Object.values(
            week.gapDetails.reduce((collapsedDetails, details) => {
              const key = `${details.skill}-${details.subject}-${details.mediaType}`;
              collapsedDetails[key]
                ? (collapsedDetails[key].volume += details.volume)
                : (collapsedDetails[key] = details);
              return collapsedDetails;
            }, {})
          );
          return collapsedGaps;
        }, languageWeeks);
      });

      if (
        selectedArcWeek.hasOwnProperty('languageArc') &&
        languageGaps.hasOwnProperty(selectedArcWeek.languageArc)
      ) {
        setSelectedArcWeekGaps({
          ...selectedArcWeek,
          gapDetails: languageGaps[selectedArcWeek.languageArc][selectedArcWeek.week].gapDetails,
        });
      }
      // We want to display the language arcs in alphabetically order so we are sorting them here.
      const orderedLanguageGaps = Object.keys(languageGaps)
        .sort()
        .reduce(function(sorting, key) {
          sorting[key] = languageGaps[key];
          return sorting;
        }, {});

      setLanguageArcVolume(orderedLanguageGaps);
    }
  }, [clientCapacityReports]);

  return (
    <Container
      id={'capacity-overview-grid-container'}
      header={
        <Header variant="h2" description="Click on a cell to view details">
          Capacity Overview
        </Header>
      }
    >
      <StylizedGrid>
        <SpaceBetween size={'s'}>
          <div className={'language-label'}>Language</div>
          <Grid className={'stylized-grid'}>
            {languageArcTitles(Object.keys(languageArcVolume))}
            {languageArcGrids(
              Object.entries(languageArcVolume),
              setSelectedArcWeekGaps,
              numOfWeeks
            )}
          </Grid>
          <Legend />
        </SpaceBetween>
      </StylizedGrid>
    </Container>
  );
};

CapacityOverviewGrid.propTypes = {
  clientCapacityReports: PropTypes.array,
  setSelectedArcWeek: PropTypes.func,
  selectedArcWeek: PropTypes.object,
};
