import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Container,
  ColumnLayout,
  Header,
  Grid,
  Multiselect,
  SpaceBetween,
  Spinner,
  Table,
  TokenGroup,
} from '@amzn/awsui-components-react-v3/polaris';
import { CapacityOverviewGrid } from './CapacityOverviewGrid';
import { CapacityManagementUtils } from '../../utils/capacityManagementUtils';
import { inferEnvironment } from '@amzn/et-console-components';
import { valueOrDash } from '../../utils/ReadableConverter';

const ValueWithLabel = ({ label, children }) => (
  <div>
    <Box margin={{ bottom: 'xxxs' }} color="text-label">
      {label}
    </Box>
    <div
      style={{
        padding: '20px 0px',
        fontSize: '40px',
        fontWeight: '100',
      }}
      className={'ec-summary-label-value'}
    >
      {children}
    </div>
  </div>
);

const calculateSummaryStats = (clientCapacityReports, setSummaryStats) => {
  const arcsWithGaps = [];
  let newHires = 0;
  const uniqueLanguageArcs = [];
  clientCapacityReports.forEach(report => {
    if (report.gaps && report.gaps.length > 0) {
      arcsWithGaps.push(report);
      newHires += report.newHires ? report.newHires : 0;
      if (!uniqueLanguageArcs.includes(report.languageArc)) {
        uniqueLanguageArcs.push(report.languageArc);
      }
    }
  });
  const gapInWords = arcsWithGaps.reduce(
    (words, arc) => words + arc['gaps'].reduce((words, gap) => words + (gap['volume'] || 0), 0),
    0
  );
  const addressedGap = arcsWithGaps.reduce(
    (words, arc) => words + (arc.planForFreelancers || 0) + (arc.planForAgents || 0),
    0
  );

  setSummaryStats({
    numArcsWithGaps: uniqueLanguageArcs.length,
    gapInWords: gapInWords,
    freelancersRequired: newHires,
    addressedGap: addressedGap,
  });
};

export const DrillDownDashboardView = (selectedArcWeekGaps, priorityFilter, globalState) => {
  const filteredGaps =
    priorityFilter && priorityFilter.length > 0
      ? selectedArcWeekGaps?.gapDetails?.filter(gap => priorityFilter.includes(gap.priority))
      : selectedArcWeekGaps?.gapDetails;
  return (
    <div>
      <Table
        header={
          <Header
            variant="h2"
            description="Click on a cell in the Capacity Overview to filter the details here."
          >
            {selectedArcWeekGaps.languageArc
              ? `Capacity Gaps for ${CapacityManagementUtils.formatLanguageArc(
                  selectedArcWeekGaps.languageArc
                )} ` + `(Week ${selectedArcWeekGaps.week})`
              : 'Capacity Gaps'}
          </Header>
        }
        columnDefinitions={[
          {
            id: 'skill',
            header: 'Skill',
            cell: item => valueOrDash(globalState.translationSkills[item.skill] || item.skill),
          },
          {
            id: 'subject',
            header: 'Subject',
            cell: item => valueOrDash(globalState.subjects[item.subject] || item.subject),
          },
          {
            id: 'mediaType',
            header: 'Media Type',
            cell: item => valueOrDash(globalState.mediaTypes[item.mediaType] || item.mediaType),
          },
          {
            id: 'capacityGap',
            header: 'Capacity Gap',
            cell: item => valueOrDash(item.volume),
          },
        ]}
        items={filteredGaps}
        sortingDisabled
        empty={
          selectedArcWeekGaps.languageArc ? (
            <Box textAlign="center" padding={{ top: 'm' }}>
              <h2>No Gaps Found</h2>
              <Box fontSize={'heading-m'} padding="s">
                No Gaps Found For Selected Languages and Week.
              </Box>
            </Box>
          ) : (
            <Box textAlign={'center'} padding={{ top: 'm' }}>
              <h2>No Cell Selected</h2>
              <Box fontSize={'heading-m'} padding="s">
                Click on a cell in the Capacity Overview to filter the details here.
              </Box>
            </Box>
          )
        }
      />
    </div>
  );
};

export const EstimatedCapacityView = ({
  summaryStats,
  isLoading,
  capacityReport,
  clientCapacityReports,
  selectedArcWeek,
  setSelectedArcWeek,
  priorityFilter,
  setPriorityFilter,
  globalState,
}) => {
  if (isLoading || (capacityReport && clientCapacityReports)) {
    const listOfPriorities = [
      ...new Set(capacityReport?.clientCapacityReports?.map(report => report.priority)),
    ].sort();
    return (
      <div>
        {!isLoading ? (
          <SpaceBetween size={'l'}>
            <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
              <Multiselect
                className={'priority-filter'}
                id={'priority-filter'}
                selectedOptions={priorityFilter.map(option => {
                  return { label: option, value: option };
                })}
                onChange={({ detail }) =>
                  setPriorityFilter(detail.selectedOptions.map(option => option.value))
                }
                deselectAriaLabel={e => `Remove ${e.label}`}
                options={listOfPriorities.map(priority => {
                  return {
                    label: priority,
                    value: priority,
                  };
                })}
                placeholder="Filter by Priority"
                selectedAriaLabel="Selected"
                hideTokens={true}
              />
              <TokenGroup
                onDismiss={({ detail: { itemIndex } }) => {
                  setPriorityFilter(priorityFilter => [
                    ...priorityFilter.slice(0, itemIndex),
                    ...priorityFilter.slice(itemIndex + 1),
                  ]);
                }}
                items={priorityFilter.map(token => {
                  return { label: token };
                })}
              />
            </Grid>
            <div id={'ec-summary'}>
              <Container header={<Header variant="h2">Summary</Header>}>
                <ColumnLayout columns={4} variant="text-grid">
                  <SpaceBetween size="l">
                    <ValueWithLabel label="Arcs with Capacity Gaps">
                      {summaryStats.numArcsWithGaps || '0'}
                    </ValueWithLabel>
                  </SpaceBetween>
                  <SpaceBetween size="l">
                    <ValueWithLabel label="Freelancers Required">
                      {summaryStats.freelancersRequired || '0'}
                    </ValueWithLabel>
                  </SpaceBetween>
                  <SpaceBetween size="l">
                    <ValueWithLabel label={'Total Gap (words)'}>
                      {summaryStats.gapInWords || '0'}
                    </ValueWithLabel>
                  </SpaceBetween>
                  <SpaceBetween size="l">
                    <ValueWithLabel label="Addressed (words)">
                      {summaryStats.addressedGap || '0'}
                    </ValueWithLabel>
                  </SpaceBetween>
                </ColumnLayout>
              </Container>
            </div>

            <Grid gridDefinition={[{ colspan: 7 }, { colspan: 5 }]}>
              <CapacityOverviewGrid
                clientCapacityReports={clientCapacityReports}
                setSelectedArcWeek={setSelectedArcWeek}
                selectedArcWeek={selectedArcWeek}
              />
              {DrillDownDashboardView(selectedArcWeek, priorityFilter, globalState)}
            </Grid>
          </SpaceBetween>
        ) : (
          <Spinner />
        )}
      </div>
    );
  } else if (
    capacityReport &&
    capacityReport.reportStatus &&
    capacityReport.reportStatus == 'In-Progress'
  ) {
    return (
      <Box textAlign="center" variant={'h1'} fontWeight={'bold'}>
        We are currently creating your capacity report and it will take a while longer.
        <br />
        Please refresh the page.
      </Box>
    );
  }

  return (
    <Box textAlign="center" variant={'h1'} fontWeight={'bold'}>
      No capacity report has been found for your organization.
      <br />
      Please go to the planning tab and upload a project plan in order to utilize this page.
    </Box>
  );
};

ValueWithLabel.propTypes = {
  label: PropTypes.string,
  children: PropTypes.string,
};

EstimatedCapacityView.propTypes = {
  capacityReport: PropTypes.object,
  clientCapacityReports: PropTypes.array,
  summaryStats: PropTypes.object,
  isLoading: PropTypes.bool,
  selectedArcWeek: PropTypes.object,
  setSelectedArcWeek: PropTypes.func,
  priorityFilter: PropTypes.array,
  setPriorityFilter: PropTypes.func,
  globalState: PropTypes.object,
};

const EstimatedCapacity = ({ isLoading, capacityReport, globalState }) => {
  const [summaryStats, setSummaryStats] = useState({});
  const [selectedArcWeek, setSelectedArcWeek] = useState({});
  const [priorityFilter, setPriorityFilter] = useState([]);
  const [clientCapacityReports, setClientCapacityReports] = useState(null);

  useEffect(() => {
    if (!isLoading && clientCapacityReports) {
      calculateSummaryStats(clientCapacityReports, setSummaryStats);
    }
  }, [clientCapacityReports, isLoading]);

  useEffect(() => {
    if (priorityFilter.length > 0) {
      const filteredReports = capacityReport.clientCapacityReports.filter(report =>
        priorityFilter.includes(report.priority)
      );
      setClientCapacityReports(filteredReports);
    } else if (capacityReport) {
      setClientCapacityReports(capacityReport.clientCapacityReports);
    }
  }, [capacityReport, priorityFilter]);

  return EstimatedCapacityView({
    summaryStats,
    isLoading,
    capacityReport,
    clientCapacityReports,
    selectedArcWeek,
    setSelectedArcWeek,
    priorityFilter,
    setPriorityFilter,
    globalState,
  });
};

export default EstimatedCapacity;
