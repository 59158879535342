import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  ButtonDropdown,
  Container,
  Header,
  Modal,
  SpaceBetween,
  Spinner,
  Table,
} from '@amzn/awsui-components-react-v3';
import ApiClient from '../../ApiClient';
import { valueOrDash } from '../../utils/ReadableConverter';
import CertificationDialogV2 from './CertificationDialogV2';
import Box from '@amzn/awsui-components-react-v3/polaris/box';

const onCreateEvent = certification => {
  return ApiClient.httpPost(`/certification`, certification)
    .then(resp => {
      return resp;
    })
    .catch(e => {
      Promise.reject(new Error(e.details.errors[0].message));
    });
};

const ProfileViewV2 = ({
  isLoading,
  user,
  mediaTypes,
  subjects,
  skills,
  availableLocales,
  setCertificationToUpdate,
  setReload,
  setDelete,
  error,
}) => {
  const [certificationToDelete, setCertificationToDelete] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);

  const getActionButtons = certification => {
    return (
      <div className={'row-actions'}>
        <span onClick={e => e.stopPropagation()}>
          <ButtonDropdown
            items={Object.keys(skills).map(skill => ({
              text: skills[skill],
              id: skill,
            }))}
            id={`change-status-dropdown-${user.id}`}
            expandToViewport
            onItemClick={({ detail }) =>
              setCertificationToUpdate({ ...certification, maxTranslationSkillId: detail.id })
            }
          >
            Change Max Skill Level
          </ButtonDropdown>
        </span>
        <Button
          className={'btn-danger'}
          id={'delete-certification-button'}
          onClick={() => {
            setCertificationToDelete(certification);
          }}
        >
          Delete
        </Button>
      </div>
    );
  };

  return (
    <div>
      {isLoading ? <Spinner /> : null}
      {error && (
        <Alert header={error.header} type={'error'}>
          {error.content}
        </Alert>
      )}
      {user && (
        <div>
          <SpaceBetween size={'xs'}>
            <Header variant="h1">
              {user.firstName} {user.lastName}
            </Header>
            <SpaceBetween size={'m'}>
              <Container header={<Header variant="h2">Personal Information</Header>}>
                <SpaceBetween size="s">
                  {/*todo: replace these divs with KeyValuePanes when it is upgraded to polarisV3*/}
                  <div>
                    <strong>Name</strong>
                    <div id={'name'}>
                      {user.firstName} {user.lastName}
                    </div>
                  </div>
                  <div>
                    <strong>Username</strong>
                    <div id={'username'}>{user.username}</div>
                  </div>
                </SpaceBetween>
              </Container>
              {!user.certifications ? (
                <Alert type={'warning'}>Unable to load certifications</Alert>
              ) : (
                <Table
                  id={'certifications-table'}
                  header={
                    <Header
                      variant={'h2'}
                      actions={
                        <Button
                          onClick={() => setShowAddModal(true)}
                          variant={'primary'}
                          id={'add-certification'}
                        >
                          Add Certification
                        </Button>
                      }
                    >
                      Certifications
                    </Header>
                  }
                  items={user.certifications}
                  loadingText={'Loading Profile Details and Certifications'}
                  loading={isLoading}
                  columnDefinitions={[
                    {
                      id: 'language-pair',
                      header: 'Language Pair',
                      cell: item => `${item.sourceLocale} ➡ ${item.targetLocale}`,
                    },
                    {
                      id: 'media-type',
                      header: 'Media Type',
                      cell: item => valueOrDash(mediaTypes[item.mediaTypeId]),
                    },
                    {
                      id: 'subject',
                      header: 'Subject',
                      cell: item => valueOrDash(subjects[item.subjectId]),
                    },
                    {
                      id: 'skill',
                      header: 'Maximum Skill Level',
                      cell: item => valueOrDash(skills[item.maxTranslationSkillId]),
                    },
                    {
                      id: 'action-buttons',
                      cell: item => getActionButtons(item),
                    },
                  ]}
                />
              )}
            </SpaceBetween>
          </SpaceBetween>
          <CertificationDialogV2
            onCreateEvent={certification => onCreateEvent(certification)}
            closeHandler={() => setShowAddModal(false)}
            translationSkills={skills}
            mediaTypes={mediaTypes}
            subjects={subjects}
            availableLocales={availableLocales}
            isVisible={showAddModal}
            userId={user.id}
            setReload={setReload}
          />
          {certificationToDelete && (
            <Modal
              visible={certificationToDelete != null}
              onDismiss={() => setCertificationToDelete(null)}
              closeAriaLabel={'Close'}
              header={'Delete certification?'}
              id={'delete-certification'}
              footer={
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      variant="normal"
                      id={'cancel-delete'}
                      onClick={() => setCertificationToDelete(null)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      id={'delete-button'}
                      onClick={() => {
                        setDelete(certificationToDelete);
                        setCertificationToDelete(null);
                      }}
                    >
                      Delete
                    </Button>
                  </SpaceBetween>
                </Box>
              }
            >
              {`Do you really want to remove the ${
                mediaTypes[certificationToDelete.mediaTypeId]
              } ➡ ${subjects[certificationToDelete.subjectId]} certification for ${
                certificationToDelete.sourceLocale
              } ➡ ${certificationToDelete.targetLocale}?`}
            </Modal>
          )}
        </div>
      )}
    </div>
  );
};

ProfileViewV2.propTypes = {
  isLoading: PropTypes.bool,
  user: PropTypes.object,
  mediaTypes: PropTypes.object,
  subjects: PropTypes.object,
  skills: PropTypes.object,
  availableLocales: PropTypes.array,
  setCertificationToUpdate: PropTypes.func,
  setReload: PropTypes.func,
  setDelete: PropTypes.func,
  error: PropTypes.object,
};

const loadUser = (setIsLoading, setError, userId, setUser) => {
  setIsLoading(true);
  setError(null);
  const url = `/profile/${userId}`;

  ApiClient.httpGet(url)
    .then(resp => {
      setUser(resp);
    })
    .catch(e => setError({ title: 'Failed to load Profile', content: e.details.errors[0].message }))
    .finally(() => setIsLoading(false));
};

const updateCertification = (certification, setReload, setError) => {
  setError(null);
  ApiClient.httpPut(`/certification/${certification.id}`, certification)
    .then(() => {
      setReload(true);
    })
    .catch(e => {
      setError({ title: 'Failed to update certification', content: e.details.errors[0].message });
    });
};

const deleteCertification = (certification, setReload, setError) => {
  setError(null);
  const url = `/certification/${certification.id}`;
  ApiClient.httpDelete(url, certification)
    .then(() => {
      setReload(true);
    })
    .catch(e => {
      setError({ title: 'Failed to delete certification', content: e.details.errors[0].message });
    });
};

const ProfileV2 = ({ userId, mediaTypes, subjects, translationSkills, availableLocales }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [reload, setReload] = useState(true);
  const [certificationToUpdate, setCertificationToUpdate] = useState(null);
  const [certificationToDelete, setCertificationToDelete] = useState(null);

  useEffect(() => {
    if (reload) {
      loadUser(setIsLoading, setError, userId, setUser);
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    if (certificationToUpdate) {
      updateCertification(certificationToUpdate, setReload, setError);
      setCertificationToUpdate(null);
    }
  }, [certificationToUpdate]);

  useEffect(() => {
    if (certificationToDelete) {
      deleteCertification(certificationToDelete, setReload, setError);
      setCertificationToDelete(null);
    }
  }, [certificationToDelete]);

  return ProfileViewV2({
    isLoading,
    user,
    mediaTypes,
    subjects,
    skills: translationSkills,
    availableLocales,
    setCertificationToUpdate,
    setReload,
    setDelete: setCertificationToDelete,
    error,
  });
};

ProfileV2.propTypes = {
  userId: PropTypes.number,
  mediaTypes: PropTypes.object,
  subjects: PropTypes.object,
  translationSkills: PropTypes.object,
  availableLocales: PropTypes.array,
};

export default ProfileV2;
