import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { applyMiddleware, createStore } from 'redux';
import translatorPortalApp, { initialState } from './reducers';

const middlewares = [
  thunkMiddleware, // lets us dispatch() functions
  process.env.NODE_ENV !== 'test' && createLogger(), // neat middleware that logs actions
].filter(m => m);

const store = createStore(translatorPortalApp, initialState, applyMiddleware(...middlewares));

export default store;
