import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Flashbar,
  Header,
  Modal,
  SpaceBetween,
  Table,
  Pagination,
  PropertyFilter,
  Input,
  FormField,
} from '@amzn/awsui-components-react-v3/polaris';
import ApiClient from '../../ApiClient';
import {
  convertDate,
  convertDateTime,
  convertLanguagePair,
  convertPrice,
  keyLookupFromReadable,
  readableList,
  readableLookup,
  valueOrDash,
} from '../../utils/ReadableConverter';
import AssignButton from './AssignButton';
import { userIdToFreelancerUri } from '../../utils/webToAssignmentMappings';
import moment from 'moment';
import ButtonDropdown from '@amzn/awsui-components-react-v3/polaris/button-dropdown';
import { ManageFiltersModal } from './ManageFiltersModal';
import { ProgressView } from '../ProgressView';
import { css } from '@emotion/core';

const PAGE_SIZE = 25;
const columnIdMap = {
  dueDate: 'dateDue',
  wordCount: 'workUnits',
  weightedWordCount: 'weightedWorkUnits',
  language: 'sourceLocale',
  skill: 'translationSkill',
  mediaType: 'mediaType',
};

let lastOfferFetchTime = Date.now();

export const fetchOffers = (
  page,
  setLoading,
  setLoadFailedMessage,
  setOffers,
  setTotalPages,
  filterText,
  sort
) => () => {
  setLoading(true);
  setLoadFailedMessage(null);
  //api is 0 based pages but the UI is 1 based pages
  let url = `/offer?pageNum=${page - 1}&pageSize=${PAGE_SIZE}`;
  if (filterText) {
    url += `&filterText=${encodeURIComponent(filterText)}`;
  }
  if (sort) {
    const sortColumn = columnIdMap[sort.columnId] || sort.columnId;
    url += `&sortColumn=${sortColumn}&sortDescending=${sort.descending === true}`;
  }
  const thisOfferFetchTime = Date.now();
  ApiClient.httpGet(url)
    .then(resp => {
      // don't update if current results are newer
      if (thisOfferFetchTime > lastOfferFetchTime) {
        setOffers(resp.offers);
        setTotalPages(resp.totalPages);
        lastOfferFetchTime = thisOfferFetchTime;
      }
    })
    .catch(error => {
      setLoadFailedMessage(error.details?.errors?.[0]?.message ?? 'Something went wrong');
    })
    .finally(() => setLoading(false));
};

// keep track of last fetch so we don't clobber new results with old results
let lastTranslatorFetchTime = Date.now();
let lastVendorFetchTime = Date.now();

export const fetchTranslators = (
  setTranslatorsLoading,
  setLoadFailedMessage,
  setTranslators
) => searchPattern => {
  const url = `/translator?searchPattern=${encodeURIComponent(`${searchPattern}`.trim())}`;

  const thisTranslatorFetchTime = Date.now();
  ApiClient.httpGet(url)
    .then(resp => {
      // don't update if current results are newer
      if (thisTranslatorFetchTime > lastTranslatorFetchTime) {
        setTranslators(resp);
        lastTranslatorFetchTime = thisTranslatorFetchTime;
      }
    })
    .catch(error => {
      setLoadFailedMessage(error.message || 'Something went wrong');
    });
};

export const fetchVendors = (
  setVendorsLoading,
  setLoadFailedMessage,
  setVendors
) => searchPattern => {
  const url = `/vendor`;

  const thisVendorFetchTime = Date.now();
  // TODO: Optimization: we don't need to call the vendors API every time since we're doing local filtering.
  //  We should call it once at page load time and then filter that list
  ApiClient.httpGet(url)
    .then(resp => {
      // don't update if current results are newer
      if (thisVendorFetchTime > lastVendorFetchTime) {
        const lowerSearchPattern = searchPattern.toLowerCase();
        setVendors(
          Object.entries(resp)
            .map(([id, name]) => {
              return { id, name };
            })
            .filter(v => v.name.toLowerCase().includes(lowerSearchPattern))
        );
        lastVendorFetchTime = thisVendorFetchTime;
      }
    })
    .catch(error => {
      setLoadFailedMessage(error.message || 'Something went wrong');
    });
};

const loadSegmentCounts = async jobPartIds => {
  if (jobPartIds.length === 0) {
    return {};
  }

  try {
    return await ApiClient.httpPost(`/getJobPartSegmentCounts`, { jobPartIds });
  } catch (e) {
    return jobPartIds.reduce((acc, id) => {
      return { ...acc, [id]: e.toString() };
    }, {});
  }
};

export const fetchSavedFilters = (setFilters, setLoadFailedMessage) => () => {
  ApiClient.httpGet('/offerFilter')
    .then(resp => {
      if (resp) {
        setFilters(resp);
      }
    })
    .catch(error => {
      setLoadFailedMessage(error.details.message || 'Error fetching saved filters');
    });
};

export const updateSavedFilters = (filters, setFlashbarMessages) => () => {
  ApiClient.httpPut('/offerFilter', { filters: filters }).catch(error => {
    const content = error.details.message || 'Something went wrong';
    setFlashbarMessages([
      {
        header: 'Error occurred saving filters',
        type: 'error',
        dismissible: true,
        content: content,
      },
    ]);
  });
};

export const validateFilterName = (filters, setSaveFilterNameError) => (filterText, name) => {
  let error = null;
  if (filterText == null || filterText == '') {
    error = 'A filter must be applied before saving.';
  } else if (!name.trim()) {
    error = 'Name cannot be blank.';
  } else if (filters.some(filter => filter.name === name)) {
    error = 'Name already used. Please select a different name.';
  }
  setSaveFilterNameError(error);
  return error;
};

export const handleSaveManagedFilters = (
  setFlashbarMessages,
  updateSavedFilters,
  setFilters
) => newFilters => {
  updateSavedFilters(newFilters, setFlashbarMessages)();
  setFilters(newFilters);
};

export const handleSaveFilter = (
  filters,
  setSaveFilterNameError,
  updateSavedFilters,
  setFlashbarMessages,
  setFilters,
  setShowSaveFilterModal
) => (filterText, saveFilterName) => {
  const doValidateFilterName = validateFilterName(filters, setSaveFilterNameError);
  const validationError = doValidateFilterName(filterText, saveFilterName);
  if (validationError == null) {
    const savedFiltersCopy = [...filters];
    savedFiltersCopy.push({ name: saveFilterName, filterText: filterText });
    updateSavedFilters(savedFiltersCopy, setFlashbarMessages)();
    setFilters(savedFiltersCopy);
    setShowSaveFilterModal(false);
  }
};

export const doAssignOffersToTranslator = (
  offers,
  translatorId,
  selectedItemIds,
  setFlashbarMessages,
  page,
  setAssignButtonLoading,
  setLoadFailedMessage,
  setOffers,
  setTotalPages,
  filterText,
  sort,
  retry = true
) => {
  setFlashbarMessages([]);
  setAssignButtonLoading(true);
  const url = '/offer';

  ApiClient.httpPost(url, {
    offers: selectedItemIds.map(id => {
      return {
        id,
        status: 'RESERVED',
        reason: 'ASSIGNED',
        freelancerUri: userIdToFreelancerUri(translatorId),
      };
    }),
  })
    .then(() => {
      const request = {
        recipient: translatorId,
        template: 'LinguistNudge',
      };

      ApiClient.httpPost('/emailMessage/send', request).catch(error => {
        console.error(error);
        const content = error.message || 'Something went wrong';

        setFlashbarMessages([
          {
            header: 'Failed to send notification email to linguist',
            type: 'error',
            dismissible: true,
            content,
          },
        ]);
      });

      fetchOffers(
        page,
        () => {},
        setLoadFailedMessage,
        setOffers,
        setTotalPages,
        filterText,
        sort
      )();
      setAssignButtonLoading(false);
    })
    .catch(error => {
      console.error(error);
      const content = error.message || 'Something went wrong';
      const resultsByOfferId = error.details.results
        .filter(r => r.updateStatus === 'FAILURE')
        .reduce((acc, val) => {
          acc[val.id] = val;
          return acc;
        }, {});
      const offersById = offers.reduce((acc, val) => {
        acc[val.id] = val;
        return acc;
      }, {});
      console.warn(
        content +
          ': ' +
          Object.entries(resultsByOfferId)
            .map(
              ([id, value]) =>
                `${offersById[id].name} (${offersById[id].jobPartId}): ${value.updateErrorMessage}`
            )
            .join('; ')
      );
      if (retry) {
        // Do a single automatic retry
        doAssignOffersToTranslator(
          offers,
          translatorId,
          Object.keys(resultsByOfferId),
          setFlashbarMessages,
          page,
          setAssignButtonLoading,
          setLoadFailedMessage,
          setOffers,
          setTotalPages,
          filterText,
          sort,
          false
        );
      } else {
        setFlashbarMessages([
          {
            header: 'Error occurred assigning offer(s)',
            type: 'error',
            dismissible: true,
            content: (
              <>
                <div>{content}:</div>
                {Object.entries(resultsByOfferId).map(([id, value]) => (
                  <div key={id} css={css({ paddingLeft: '5px' })}>
                    {`${offersById[id].name} (${offersById[id].jobPartId}): ${value.updateErrorMessage}`}
                  </div>
                ))}
              </>
            ),
            buttonText: 'Retry failed assignments',
            buttonClick: () =>
              doAssignOffersToTranslator(
                offers,
                translatorId,
                Object.keys(resultsByOfferId),
                setFlashbarMessages,
                page,
                setAssignButtonLoading,
                setLoadFailedMessage,
                setOffers,
                setTotalPages,
                filterText,
                sort
              ),
          },
        ]);
        setAssignButtonLoading(false);
      }
    });
};

export const doAssignOffersToVendor = (
  vendorId,
  selectedItemIds,
  assignNextWorkflow,
  setFlashbarMessages,
  page,
  setAssignButtonLoading,
  setLoadFailedMessage,
  setOffers,
  setTotalPages,
  filterText,
  sort,
  retry = true
) => {
  setFlashbarMessages([]);
  setAssignButtonLoading(true);

  const promises = selectedItemIds.map(offerId =>
    ApiClient.httpPut(
      `/vendor/${vendorId}/${offerId}?assignNextWorkflowStep=${assignNextWorkflow}&offerReassignmentType=MANUAL_ASSIGN_BY_USER`
    )
      .then(() => {
        return { status: 'SUCCESS' };
      })
      .catch(e => {
        return {
          offerId: offerId,
          status: 'FAILED',
          reason: e.message || 'Something went wrong',
        };
      })
  );
  Promise.all(promises).then(results => {
    const failedResults = results.filter(r => r.status === 'FAILED');

    if (failedResults.length > 0) {
      const content = failedResults.map(r => `${r.offerId}: ${r.reason}`).join('; ');
      const failedOfferIds = failedResults.map(r => r.offerId);
      if (retry) {
        // Do a single automatic retry
        doAssignOffersToVendor(
          vendorId,
          failedOfferIds,
          assignNextWorkflow,
          setFlashbarMessages,
          page,
          setAssignButtonLoading,
          setLoadFailedMessage,
          setOffers,
          setTotalPages,
          filterText,
          sort,
          false
        );
      } else {
        setFlashbarMessages([
          {
            header: 'Error occurred assigning offer',
            type: 'error',
            dismissible: true,
            content,
            buttonText: 'Retry failed assignments',
            buttonClick: () =>
              doAssignOffersToVendor(
                vendorId,
                failedOfferIds,
                assignNextWorkflow,
                setFlashbarMessages,
                page,
                setAssignButtonLoading,
                setLoadFailedMessage,
                setOffers,
                setTotalPages,
                filterText,
                sort
              ),
          },
        ]);
        setAssignButtonLoading(false);
      }
    } else {
      fetchOffers(
        page,
        () => {},
        setLoadFailedMessage,
        setOffers,
        setTotalPages,
        filterText,
        sort
      )();
      setAssignButtonLoading(false);
    }
  });
};

const assignOffersToTranslator = (
  offers,
  selectedItemIds,
  setFlashbarMessages,
  page,
  setAssignButtonLoading,
  setLoadFailedMessage,
  setOffers,
  setTotalPages,
  filterText,
  sort
) => translatorId => {
  doAssignOffersToTranslator(
    offers,
    translatorId,
    selectedItemIds,
    setFlashbarMessages,
    page,
    setAssignButtonLoading,
    setLoadFailedMessage,
    setOffers,
    setTotalPages,
    filterText,
    sort
  );
};

const assignOffersToVendor = (
  selectedItemIds,
  assignNextWorkflow,
  setFlashbarMessages,
  page,
  setAssignButtonLoading,
  setLoadFailedMessage,
  setOffers,
  setTotalPages,
  filterText,
  sort
) => vendorId => {
  doAssignOffersToVendor(
    vendorId,
    selectedItemIds,
    assignNextWorkflow,
    setFlashbarMessages,
    page,
    setAssignButtonLoading,
    setLoadFailedMessage,
    setOffers,
    setTotalPages,
    filterText,
    sort
  );
};

export const doReclaimOffers = (
  selectedItemIds,
  setFlashbarMessages,
  page,
  setReclaimButtonLoading,
  setLoadFailedMessage,
  setOffers,
  setTotalPages,
  filterText,
  sort
) => {
  setFlashbarMessages([]);
  setReclaimButtonLoading(true);
  const url = '/offer';

  ApiClient.httpPost(url, {
    offers: selectedItemIds.map(id => {
      return { id, status: 'OPEN', reason: 'RECLAIMED' };
    }),
  })
    .then(() => {
      fetchOffers(
        page,
        () => {},
        setLoadFailedMessage,
        setOffers,
        setTotalPages,
        filterText,
        sort
      )();
    })
    .catch(error => {
      console.error(error);
      const content = error.details.message || 'Something went wrong';
      const failedOfferIds = error.details.results
        .filter(r => r.updateStatus === 'FAILURE')
        .map(r => r.id);
      setFlashbarMessages([
        {
          header: 'Error occurred reclaiming offer',
          type: 'error',
          dismissible: true,
          content,
          buttonText: 'Retry reclaiming offers',
          buttonClick: () =>
            doReclaimOffers(
              failedOfferIds,
              setFlashbarMessages,
              page,
              setReclaimButtonLoading,
              setLoadFailedMessage,
              setOffers,
              setTotalPages,
              filterText,
              sort
            ),
        },
      ]);
    })
    .finally(() => {
      setReclaimButtonLoading(false);
    });
};

const reclaimOffers = (
  selectedItemIds,
  setFlashbarMessages,
  page,
  setReclaimButtonLoading,
  setLoadFailedMessage,
  setOffers,
  setTotalPages,
  filterText,
  sort
) => () => {
  doReclaimOffers(
    selectedItemIds,
    setFlashbarMessages,
    page,
    setReclaimButtonLoading,
    setLoadFailedMessage,
    setOffers,
    setTotalPages,
    filterText,
    sort
  );
};

const rightAlignedCell = content => <div style={{ textAlign: 'right' }}>{content}</div>;

const dateCell = (date, showTime) => (
  <div title={date && moment(date).fromNow()} style={(date && { cursor: 'help' }) || {}}>
    {showTime ? convertDateTime(date) : convertDate(date)}
  </div>
);

// exporting so we can use in tests (e.g. know if a column should be sortable or not)
export const columnDefinitionsFunc = (globalState, segmentMetadataByJob) => [
  {
    id: 'name',
    header: 'Name',
    cell: item => valueOrDash(item.name),
    sortingField: 'name',
  },
  {
    id: 'jobPartId',
    header: 'Job Part Id',
    cell: item => valueOrDash(item.jobPartId),
  },
  {
    id: 'status',
    header: 'Status',
    cell: item => valueOrDash(item.status),
    sortingField: 'status',
  },
  {
    id: 'progress',
    header: 'Progress',
    cell: item => <ProgressView segmentMetadata={segmentMetadataByJob[item.jobPartId]} />,
  },
  {
    id: 'dateCreated',
    header: 'Date Created',
    cell: item => dateCell(item.dateCreated, false),
    sortingField: 'dateCreated',
  },
  {
    id: 'autoReassignmentDue',
    header: 'AutoReassignment Date',
    cell: item => dateCell(item.autoReassignmentDue, true),
  },
  {
    id: 'dueDate',
    header: 'Due Date',
    cell: item => dateCell(item.dateDue, true),
    sortingField: 'dueDate',
  },
  {
    id: 'deadline',
    header: 'Deadline',
    cell: item => dateCell(item.deadline, true),
    sortingField: 'deadline',
  },
  {
    id: 'dateCompleted',
    header: 'Completed Date',
    cell: item => dateCell(item.dateCompleted, true),
    sortingField: 'dateCompleted',
  },
  {
    id: 'freelancerUsername',
    header: 'Freelancer',
    cell: item => valueOrDash(item.freelancerUsername),
  },
  {
    id: 'clientUri',
    header: 'Client',
    cell: item => valueOrDash(item.clientName),
    sortingField: 'clientUri',
  },
  {
    id: 'wordCount',
    header: 'Word Count',
    cell: item => rightAlignedCell(valueOrDash(item.workUnits)),
    sortingField: 'wordCount',
  },
  {
    id: 'weightedWordCount',
    header: 'Weighted',
    cell: item => rightAlignedCell(Math.round(item.weightedWorkUnits) || '-'),
    sortingField: 'weightedWordCount',
  },
  {
    id: 'price',
    header: 'Price',
    cell: item => convertPrice(item.price, item.currency),
    sortingField: 'price',
  },
  {
    id: 'language',
    header: 'Language',
    cell: item => convertLanguagePair(item.sourceLocale, item.targetLocale),
    sortingField: 'language',
  },
  {
    id: 'skill',
    header: 'Skill',
    cell: item => readableLookup(item.translationSkill, globalState, 'translationSkills'),
    sortingField: 'skill',
  },
  {
    id: 'mediaType',
    header: 'Media type',
    cell: item => readableLookup(item.mediaType, globalState, 'mediaTypes'),
    sortingField: 'mediaType',
  },
  {
    id: 'subject',
    header: 'Subject',
    cell: item => readableLookup(item.subject, globalState, 'subjects'),
    sortingField: 'subject',
  },
];

export const OffersView = ({
  loading,
  assignButtonLoading,
  reclaimButtonLoading,
  loadFailedMessage,
  offers,
  page,
  setCurrentPageIndex,
  setFilterText,
  totalPages,
  selectedItems,
  setSelectedItemIds,
  globalState,
  setSort,
  onAssignOffersToTranslator,
  onAssignOffersToVendor,
  onReclaimOffers,
  flashbarMessages,
  translators,
  doFetchTranslators,
  vendors,
  doFetchVendors,
  shouldAssignNextWorkflowStep,
  setAssignNextWorkflowStep,
  filters,
  saveFilterName,
  setSaveFilterName,
  doHandleSaveFilter,
  saveFilterNameError,
  showSaveFilterModal,
  setShowSaveFilterModal,
  doHandleSaveManagedFilters,
  filterText,
}) => {
  const [showReclaimModal, setShowReclaimModal] = useState(false);
  const [segmentMetadata, setSegmentMetadata] = useState({});
  const columnDefinitions = columnDefinitionsFunc(globalState, loading ? {} : segmentMetadata);
  const [sortingColumn, setSortingColumn] = useState(null);
  const [sortingDescending, setSortingDescending] = useState(false);
  const [query, setQuery] = useState({ tokens: [] });
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const empty = <div>no offers found</div>;

  const getFilteringOptions = (propertyKey, values) => {
    return values.map(value => {
      return { propertyKey: propertyKey, value: value };
    });
  };

  useEffect(() => {
    const jobPartIds = offers.map(o => o.jobPartId);
    console.info(`Loading segment metadata for job parts ${JSON.stringify(jobPartIds)}`);
    loadSegmentCounts(jobPartIds).then(response => setSegmentMetadata(response));
  }, [offers]);

  const onPropertyFilteringChange = event => {
    const addToMapOfArrays = (map, key, value) => {
      key in map || (map[key] = []);
      map[key].push(value);
    };

    const filterMap = {};

    setQuery(event.detail);

    event.detail.tokens.forEach(token => {
      const key = token.propertyKey;
      let value = token.value;

      if (token.operator == ':' && !key) {
        // User typed in text without selecting attribute, filter on name in this case
        addToMapOfArrays(filterMap, 'name', value);
      } else if (key && value) {
        if (key === 'translationSkill') {
          value = keyLookupFromReadable(value, globalState, 'translationSkills');
        } else if (key === 'mediaType') {
          value = keyLookupFromReadable(value, globalState, 'mediaTypes');
        } else if (key === 'subject') {
          value = keyLookupFromReadable(value, globalState, 'subjects');
        } else if (key === 'clientUri') {
          value = 'web:clientName:' + value;
        } else if (key === 'jobUri') {
          value = 'web:jobPart:' + value;
        }
        addToMapOfArrays(filterMap, key, value);
      }
    });

    const filterArray = [];
    for (const key in filterMap) {
      filterArray.push(`${key}=${filterMap[key].join(' ')}`);
    }

    setFilterText(filterArray.join('|'));
    //UI pages are 1 based, so start at page 1
    setCurrentPageIndex(1);
  };

  const parseQuery = filterText => {
    const tokens = [];

    const filterArray = filterText.split('|');
    filterArray.forEach(filter => {
      const splitFilter = filter.split('=');
      const key = splitFilter[0];

      if (key === 'clientUri') {
        let value = splitFilter[1].replace('web:clientName:', '');
        let values = value.split('web:clientName:');
        for (value in values) {
          tokens.push({
            value: values[value],
            propertyKey: key,
            operator: '=',
          });
        }
      } else {
        const values = splitFilter[1].split(' ');
        values.forEach(value => {
          if (key === 'translationSkill') {
            value = readableLookup(value, globalState, 'translationSkills');
          } else if (key === 'mediaType') {
            value = readableLookup(value, globalState, 'mediaTypes');
          } else if (key === 'subject') {
            value = readableLookup(value, globalState, 'subjects');
          }
          tokens.push({
            value: value,
            propertyKey: key,
            operator: '=',
          });
        });
      }
    });

    setQuery({
      tokens: tokens,
      operation: undefined,
    });
  };

  const onSortingChange = event => {
    setSort({
      columnId: event.detail.sortingColumn.id,
      descending: event.detail.isDescending,
    });
    //these properties are used by
    setSortingColumn(
      columnDefinitions.find(element => element.id === event.detail.sortingColumn.id)
    );
    setSortingDescending(event.detail.isDescending);
  };

  const onSelectionChange = event => {
    setSelectedItemIds(event.detail.selectedItems.map(item => item.id));
  };

  const onSaveFilter = event => {
    doHandleSaveFilter(filterText, saveFilterName);
  };

  const handleSaveManagedFilters = newFilters => {
    doHandleSaveManagedFilters(newFilters);
  };

  const onShowReclaimModal = event => {
    setShowReclaimModal(true);
  };

  const onHideReclaimModal = event => {
    setShowReclaimModal(false);
  };

  const handleSelectFilterClick = e => {
    if (e.detail.id === 'manageFilters') {
      setShowFiltersModal(true);
    } else if (e.detail.id === 'saveFilter') {
      setShowSaveFilterModal(true);
    } else {
      const i = parseInt(e.detail.id);
      const filterData = filters[i].filterText;
      setFilterText(filterData);
      parseQuery(filterData);
    }
    return;
  };

  const header = (
    <Header
      variant={'h2'}
      className="awsui-util-action-stripe-title"
      actions={
        <SpaceBetween direction={'horizontal'} size={'xs'}>
          <Button
            id="Reclaim"
            variant={'normal'}
            disabled={selectedItems.length === 0 || selectedItems.some(i => i.status === 'OPEN')}
            onClick={onShowReclaimModal}
            loading={reclaimButtonLoading}
          >
            Reclaim
          </Button>
          <AssignButton
            id="Assign"
            disabled={selectedItems.length === 0 || selectedItems.some(i => i.status !== 'OPEN')}
            availableTranslators={translators}
            availableVendors={vendors}
            onSearchChange={e => {
              doFetchTranslators(e);
              doFetchVendors(e);
            }}
            onSelectTranslator={onAssignOffersToTranslator}
            onSelectVendor={onAssignOffersToVendor}
            loading={assignButtonLoading}
            shouldAssignNextWorkflowStep={shouldAssignNextWorkflowStep}
            setAssignNextWorkflowStep={setAssignNextWorkflowStep}
          />
        </SpaceBetween>
      }
    >
      Offers
    </Header>
  );

  return (
    <>
      <Flashbar items={flashbarMessages} />
      {loadFailedMessage && (
        <Flashbar
          id={'loadFailedMessageFlashbar'}
          items={[
            {
              header: loadFailedMessage,
              type: 'error',
              content:
                'Reload the page to try again, if the problem persists please contact support.',
            },
          ]}
        />
      )}
      <Modal
        visible={showReclaimModal}
        header="Warning"
        onDismiss={onHideReclaimModal}
        footer={
          <Box float={'right'}>
            <SpaceBetween direction={'horizontal'} size={'xs'}>
              <Button variant="link" id="ReclaimCancel" onClick={onHideReclaimModal}>
                Cancel
              </Button>
              <Button
                variant="primary"
                id="ReclaimOk"
                onClick={() => {
                  onHideReclaimModal();
                  onReclaimOffers();
                }}
              >
                Ok
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        Reclaiming an offer sets the state to OPEN. Only reclaim offers if you understand the
        consequences. Are you sure you want to continue?
      </Modal>
      <ManageFiltersModal
        isVisible={showFiltersModal}
        setIsVisible={setShowFiltersModal}
        savedFilters={filters}
        handleSaveFilters={handleSaveManagedFilters}
      />
      <Modal
        id={'save-filter-modal'}
        onDismiss={() => setShowSaveFilterModal(false)}
        closeAriaLabel={'Close modal'}
        header={'Save current filter'}
        visible={showSaveFilterModal}
        placeHolder={'Filter name'}
        footer={
          <Box float={'right'}>
            <SpaceBetween direction={'horizontal'} size={'xs'}>
              <Button
                id={'save-filter-modal-cancel-button'}
                variant={'link'}
                onClick={() => setShowSaveFilterModal(false)}
              >
                Cancel
              </Button>
              <Button
                id={'save-filter-modal-accept-button'}
                variant={'primary'}
                onClick={onSaveFilter}
              >
                Ok
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <FormField errorText={saveFilterNameError}>
          <Input
            value={saveFilterName}
            onChange={({ detail }) => setSaveFilterName(detail.value)}
          />
        </FormField>
      </Modal>
      <Table
        id={'offer-table'}
        loadingText="Loading offers"
        columnDefinitions={columnDefinitions}
        loading={loading}
        items={offers}
        wrapLines={false}
        header={header}
        stickyHeader={true}
        empty={empty}
        pagination={
          <Pagination
            pagesCount={totalPages}
            onChange={({ detail }) => setCurrentPageIndex(detail.currentPageIndex)}
            currentPageIndex={page}
          />
        }
        onSortingChange={onSortingChange}
        sortingColumn={sortingColumn}
        sortingDescending={sortingDescending}
        trackBy={'id'}
        selectionType={'multi'}
        selectedItems={selectedItems}
        onSelectionChange={onSelectionChange}
        filter={
          <div className="filter-container">
            <div className="filter-actions">
              <ButtonDropdown
                id="filterActions"
                onItemClick={handleSelectFilterClick}
                items={[
                  {
                    id: 'selectFilter',
                    text: 'Apply saved filter',
                    items:
                      filters && filters.length > 0
                        ? filters.map((v, i) => ({
                            id: '' + i,
                            text: v.name,
                          }))
                        : [{ id: 'noSavedFilters', text: 'No saved filters', disabled: true }],
                  },
                  {
                    id: 'saveFilter',
                    text: 'Save current filter',
                  },
                  {
                    id: 'manageFilters',
                    text: 'Manage saved filters',
                  },
                ]}
              >
                Filter actions
              </ButtonDropdown>
            </div>
            <div className={'input-filter'}>
              <PropertyFilter
                i18nStrings={{
                  filteringPlaceholder: 'Filter offers',
                  groupValuesText: 'Values',
                  groupPropertiesText: 'Properties',
                  clearFiltersText: 'Clear filter',
                  removeTokenButtonAriaLabel: () => 'Remove token',
                  enteredTextLabel: text => `Use: "${text}"`,
                  applyActionText: 'Apply',
                  cancelActionText: 'Cancel',
                }}
                filteringOptions={getFilteringOptions('status', [
                  'OPEN',
                  'RESERVED',
                  'ACCEPTED',
                  'COMPLETED',
                  'CANCELED',
                  'ERRORED',
                ])
                  .concat(
                    getFilteringOptions(
                      'translationSkill',
                      readableList(globalState, 'translationSkills')
                    )
                  )
                  .concat(getFilteringOptions('mediaType', readableList(globalState, 'mediaTypes')))
                  .concat(getFilteringOptions('subject', readableList(globalState, 'subjects')))}
                query={query}
                onChange={onPropertyFilteringChange}
                hideOperations
                filteringProperties={[
                  {
                    groupValuesLabel: 'Name Values',
                    key: 'name',
                    propertyLabel: 'Name',
                    defaultOperator: ':',
                  },
                  {
                    groupValuesLabel: 'Id Values',
                    key: 'jobUri',
                    propertyLabel: 'Job Part Id',
                  },
                  {
                    groupValuesLabel: 'Status Values',
                    key: 'status',
                    propertyLabel: 'Status',
                  },
                  {
                    groupValuesLabel: 'Source Language Values',
                    key: 'sourceLocale',
                    propertyLabel: 'Source Language',
                    defaultOperator: ':',
                  },
                  {
                    groupValuesLabel: 'Target Language Values',
                    key: 'targetLocale',
                    propertyLabel: 'Target Language',
                    defaultOperator: ':',
                  },
                  {
                    groupValuesLabel: 'Skill Values',
                    key: 'translationSkill',
                    propertyLabel: 'Skill',
                  },
                  {
                    groupValuesLabel: 'Media Type Values',
                    key: 'mediaType',
                    propertyLabel: 'Media Type',
                  },
                  {
                    groupValuesLabel: 'Subject Values',
                    key: 'subject',
                    propertyLabel: 'Subject',
                  },
                  {
                    groupValuesLabel: 'Client Values',
                    key: 'clientUri',
                    propertyLabel: 'Client',
                    defaultOperator: ':',
                  },
                  {
                    groupValuesLabel: 'Start Date Completed Values',
                    key: 'startDateCompleted',
                    propertyLabel: 'Completed Date After',
                    defaultOperator: '>=',
                  },
                  {
                    groupValuesLabel: 'End Date Completed Values',
                    key: 'endDateCompleted',
                    propertyLabel: 'Completed Date Before',
                    defaultOperator: '<=',
                  },
                  {
                    groupValuesLabel: 'Work Units Values',
                    key: 'workUnits',
                    propertyLabel: 'Word Count',
                    defaultOperator: '<=',
                  },
                ]}
              />
            </div>
          </div>
        }
      ></Table>
    </>
  );
};

OffersView.propTypes = {
  loading: PropTypes.bool,
  assignButtonLoading: PropTypes.bool,
  reclaimButtonLoading: PropTypes.bool,
  loadFailedMessage: PropTypes.string,
  offers: PropTypes.array,
  page: PropTypes.number,
  setCurrentPageIndex: PropTypes.func,
  totalPages: PropTypes.number,
  globalState: PropTypes.object,
  setFilterText: PropTypes.func,
  selectedItems: PropTypes.array,
  setSelectedItemIds: PropTypes.func,
  setSort: PropTypes.func,
  onReclaimOffers: PropTypes.func,
  flashbarMessages: PropTypes.array,
  onAssignOffersToTranslator: PropTypes.func,
  onAssignOffersToVendor: PropTypes.func,
  translators: PropTypes.array,
  doFetchTranslators: PropTypes.func,
  vendors: PropTypes.array,
  doFetchVendors: PropTypes.func,
  shouldAssignNextWorkflowStep: PropTypes.bool,
  setAssignNextWorkflowStep: PropTypes.func,
  filters: PropTypes.array,
  saveFilterName: PropTypes.string,
  setSaveFilterName: PropTypes.func,
  handleSaveFilter: PropTypes.func,
  saveFilterNameError: PropTypes.string,
  showSaveFilterModal: PropTypes.bool,
  setShowSaveFilterModal: PropTypes.func,
  handleSaveManagedFilters: PropTypes.func,
};

const Offers = ({ globalState }) => {
  const [loading, setLoading] = useState(true);
  const [reclaimButtonLoading, setReclaimButtonLoading] = useState(false);
  const [assignButtonLoading, setAssignButtonLoading] = useState(false);
  const [loadFailedMessage, setLoadFailedMessage] = useState(null);
  //UI pages are 1 based, so start with page 1
  const [page, setCurrentPageIndex] = useState(1);
  const [offers, setOffers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [filterText, setFilterText] = useState(null);
  const [sort, setSort] = useState(null);
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [flashbarMessages, setFlashbarMessages] = useState([]);
  const [translators, setTranslators] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [shouldAssignNextWorkflowStep, setAssignNextWorkflowStep] = useState(false);
  const [filters, setFilters] = useState([]);
  const [saveFilterName, setSaveFilterName] = useState('');
  const [saveFilterNameError, setSaveFilterNameError] = useState(null);
  const [showSaveFilterModal, setShowSaveFilterModal] = useState(false);

  // Fix bug where Assign and Reclaim buttons get out of sync with current state of offers.
  // Store just the selected ids, then reconstitute with the actual offers.
  const selectedItems = offers.filter(i => selectedItemIds.includes(i.id));

  const doFetchOffers = fetchOffers(
    page,
    setLoading,
    setLoadFailedMessage,
    setOffers,
    setTotalPages,
    filterText,
    sort
  );

  const onAssignOffersToTranslator = assignOffersToTranslator(
    offers,
    selectedItemIds,
    setFlashbarMessages,
    page,
    setAssignButtonLoading,
    setLoadFailedMessage,
    setOffers,
    setTotalPages,
    filterText,
    sort
  );

  const onAssignOffersToVendor = assignOffersToVendor(
    selectedItemIds,
    shouldAssignNextWorkflowStep,
    setFlashbarMessages,
    page,
    setAssignButtonLoading,
    setLoadFailedMessage,
    setOffers,
    setTotalPages,
    filterText,
    sort
  );

  const onReclaimOffers = reclaimOffers(
    selectedItemIds,
    setFlashbarMessages,
    page,
    setReclaimButtonLoading,
    setLoadFailedMessage,
    setOffers,
    setTotalPages,
    filterText,
    sort
  );

  const doFetchTranslators = fetchTranslators(() => {}, setFlashbarMessages, setTranslators);

  const doFetchVendors = fetchVendors(() => {}, setFlashbarMessages, setVendors);

  const doFetchSavedFilters = fetchSavedFilters(setFilters, setLoadFailedMessage);

  const doHandleSaveManagedFilters = handleSaveManagedFilters(
    setFlashbarMessages,
    updateSavedFilters,
    setFilters
  );

  const doHandleSaveFilter = handleSaveFilter(
    filters,
    setSaveFilterNameError,
    updateSavedFilters,
    setFlashbarMessages,
    setFilters,
    setShowSaveFilterModal
  );

  useEffect(doFetchSavedFilters, []);

  useEffect(doFetchOffers, [page, filterText, sort]);

  return OffersView({
    loading,
    assignButtonLoading,
    reclaimButtonLoading,
    loadFailedMessage,
    offers,
    page,
    setCurrentPageIndex,
    setFilterText,
    totalPages,
    selectedItems,
    setSelectedItemIds,
    globalState,
    setSort,
    onAssignOffersToTranslator,
    onAssignOffersToVendor,
    onReclaimOffers,
    flashbarMessages,
    translators,
    doFetchTranslators,
    vendors,
    doFetchVendors,
    shouldAssignNextWorkflowStep,
    setAssignNextWorkflowStep,
    filters,
    saveFilterName,
    setSaveFilterName,
    doHandleSaveFilter,
    saveFilterNameError,
    showSaveFilterModal,
    setShowSaveFilterModal,
    doHandleSaveManagedFilters,
    filterText,
  });
};

export default Offers;
