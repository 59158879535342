import ApiClient from '../ApiClient';

const ACTION_TYPES = {
  GET_FEATURES: 'GET_FEATURES',
  FINISHED_GET_FEATURES: 'FINISHED_GET_FEATURES',
  FAILED_GET_FEATURES: 'FAILED_GET_FEATURES',
};

function getFeaturesEvent() {
  return {
    type: ACTION_TYPES.GET_FEATURES,
  };
}

function finishedGetFeaturesEvent(resp) {
  return {
    type: ACTION_TYPES.FINISHED_GET_FEATURES,
    features: resp,
  };
}

function failedGetFeaturesEvent(error) {
  return {
    type: ACTION_TYPES.FAILED_GET_FEATURES,
    error,
  };
}

export function getFeatures() {
  return dispatch => {
    dispatch(getFeaturesEvent());
    return ApiClient.httpGet(`/features`)
      .then(resp => {
        resp.constructor === Object
          ? dispatch(finishedGetFeaturesEvent(resp))
          : dispatch(failedGetFeaturesEvent(resp));
      })
      .catch(err => {
        dispatch(failedGetFeaturesEvent(err));
      });
  };
}

export default ACTION_TYPES;
