const formatLocaleCode = code => {
  const parts = code.split('_');
  for (let i = 1; i < parts.length; i++) {
    if (parts[i].length === 2) {
      parts[i] = parts[i].toUpperCase();
    } else {
      parts[i] =
        parts[i].substring(0, 1).toUpperCase() +
        parts[i].substring(1, parts[i].length).toLowerCase();
    }
  }
  return parts.join('-');
};

export default formatLocaleCode;
