import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Prompt } from 'react-router';
import styled from '@emotion/styled';
import { spinner } from 'react-icons-kit/fa/spinner';
import SvgIcon from 'react-icons-kit/SvgIcon';
import { Alert, Modal } from 'react-bootstrap';
import Button from '@amzn/awsui-components-react/polaris/button';
import PriceSchemeDialog from './PriceSchemeDialog';
import Grid from '../../Grid';
import LanguagePair from './LanguagePair';
import BasePrice from './BasePrice';
import formatLocaleCode from '../../../utils/formatLocaleCode';
import * as yup from 'yup';
import { reach } from 'yup';
import { CURRENCIES } from './constants';
import ErrorAlert from '../../ErrorAlert';
import { Container } from '@amzn/et-polaris-utils';
import LinkButton from '../../LinkButton';
import PriceSchemeImportDialog from './PriceSchemeImportDialog';
import { upload } from 'react-icons-kit/fa/upload.js';

const countDecimals = num => {
  if (Math.floor(num.valueOf()) === num.valueOf()) return 0;
  return num.toString().split('.')[1].length || 0;
};

// TODO move all of the common styles somewhere more centralized / reusable
const PriceSchemeName = styled('h3')`
  float: left;
`;
const PriceSchemeDescription = styled('div')`
  clear: both;
`;
const BackLink = styled('div')`
  margin: 1em 0;
`;
const NoSchemesPlaceholder = styled('div')`
  background-color: #f6f6f6;
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
`;
const AddRowButtonContainer = styled('div')`
  margin-top: 1em;
`;

const basePriceSchema = yup.object().shape({
  languagePair: yup.object().shape({
    sourceLocale: yup
      .string()
      .required()
      .default('en-US'),
    targetLocale: yup
      .string()
      .required()
      .default('es-ES'),
  }),
  price: yup.object().shape({
    amount: yup
      .number()
      .required()
      .positive('Price must be > 0')
      .default(0.01)
      // twice as much as freelancers are currently paid. Sanity check.
      // max price increased from 0.2 to 0.6. Check ATMS-15764.
      .max(0.6),
    currency: yup
      .string()
      .oneOf(CURRENCIES)
      .default(CURRENCIES[0]),
  }),
});

/**
 * Return a copy of the object with the given leaf node changed.
 * Intermediate nodes will also be copies, but all other siblings/etc will
 * maintain object identity
 */
const updateLeafNode = (object, keys, value) => {
  keys = [...keys];
  const clone = object instanceof Array ? [...object] : { ...object };
  const key = keys.shift();
  clone[key] = keys.length > 0 ? updateLeafNode(clone[key], keys, value) : value;
  return clone;
};

export class PriceSchemeDetail extends React.Component {
  static propTypes = {
    priceSchemeId: PropTypes.string.isRequired,
    onFetchOne: PropTypes.func,
    priceSchemes: PropTypes.object.isRequired,
    onUpdateEvent: PropTypes.func,
    availableLocales: PropTypes.object.isRequired,
  };

  static defaultProps = {
    onFetchOne: Function.prototype,
    onUpdateEvent: Function.prototype,
  };

  constructor(props) {
    super(props);
    this.state = {
      showEditModal: false,
      showPriceImportModal: false,
      localPriceScheme: this.props.priceSchemes.item,
      hasChanged: false,
      lastError: null,
    };
  }

  handleOpenEditModal = priceScheme => {
    this.setState({ showEditModal: true });
  };

  handleCloseEditModal = () => {
    this.setState({ showEditModal: false });
  };
  handleOpenPriceImportModal = priceScheme => {
    this.setState({ showPriceImportModal: true });
  };

  handleClosePriceImportModal = () => {
    this.setState({ showPriceImportModal: false });
  };

  setBasePrices = basePrices => {
    this.setState({
      hasChanged: true,
      localPriceScheme: {
        ...this.state.localPriceScheme,
        basePrices: basePrices,
      },
    });
  };

  saveMetadata = priceScheme => {
    //stash basePrices
    this.setState({
      stashedBasePrices: this.state.localPriceScheme.basePrices,
    });
    priceScheme.basePrices = this.props.priceSchemes.item.basePrices;

    //save metadata
    return this.props
      .onUpdateEvent(false)(priceScheme)
      .then(result =>
        this.setState({
          //recover base prices
          localPriceScheme: {
            ...result.item,
            basePrices: this.state.stashedBasePrices,
          },
          hasChanged:
            JSON.stringify(this.state.stashedBasePrices) !== JSON.stringify(priceScheme.basePrices),
          stashedBasePrices: null,
        })
      );
  };

  saveLocalChanges = () => {
    this.props
      .onUpdateEvent(false)(this.state.localPriceScheme)
      .then(result =>
        this.setState({
          localPriceScheme: result.item,
          lastError: null,
        })
      )
      .catch(err => {
        this.setState({ lastError: err });
      });
  };

  updateField = (path, value) => {
    const clone = { ...this.state.localPriceScheme };
    clone.basePrices = updateLeafNode(
      this.state.localPriceScheme.basePrices,
      path.split('.'),
      value
    );
    const newPriceScheme = JSON.stringify(clone);
    const existingPriceScheme = JSON.stringify(this.props.priceSchemes.item);
    this.setState({
      localPriceScheme: clone,
      hasChanged: newPriceScheme !== existingPriceScheme,
    });
  };

  addRow = () => {
    const { basePrices } = this.state.localPriceScheme;

    // Use yup default values to instantiate a new object with defaults from the schema.
    const newBasePrice = basePriceSchema.cast({});
    this.setState({
      hasChanged: true,
      localPriceScheme: {
        ...this.state.localPriceScheme,
        basePrices: [...basePrices, newBasePrice],
      },
    });
  };

  deleteRow = index => {
    const clone = { ...this.state.localPriceScheme };
    clone.basePrices.splice(index, 1);
    this.setState({
      hasChanged: true,
      localPriceScheme: clone,
    });
  };

  isPriceSchemeValid = () => {
    if (!this.state.localPriceScheme || !this.state.localPriceScheme.basePrices) {
      // probably just loading the page, so it doesn't really matter what we return.
      return true;
    }

    const { basePrices } = this.state.localPriceScheme;
    return basePrices.every((basePrice, index) => this.validateBasePrice(basePrice, index).valid);
  };

  validateBasePrice = (basePrice, index) => {
    const { basePrices } = this.state.localPriceScheme;

    const languagePairResult = this.validateLanguagePair(basePrice.languagePair, index, basePrices);
    if (!languagePairResult.valid) {
      return languagePairResult;
    }

    return this.validatePrice(basePrice.price);
  };

  validatePrice = price => {
    try {
      reach(basePriceSchema, 'price').validateSync(price);
      if (countDecimals(price.amount) > 3) {
        return { valid: false, errorMsg: 'Price can have at most 3 decimal places.' };
      }
      return { valid: true };
    } catch (err) {
      return { valid: false, errorMsg: err.message };
    }
  };

  validateLanguagePair = (languagePair, index, basePrices) => {
    try {
      basePriceSchema.validateSyncAt('languagePair', languagePair);

      // Check for invalid locales.
      const availableLocaleCodes = this.props.availableLocales.items.map(x =>
        formatLocaleCode(x.code)
      );

      if (
        !availableLocaleCodes.includes(languagePair.sourceLocale) ||
        !availableLocaleCodes.includes(languagePair.targetLocale)
      ) {
        return {
          valid: false,
          errorMsg: `Invalid locale input. Source=${languagePair.sourceLocale} Target=${languagePair.targetLocale}`,
        };
      }

      // ensure no duplicate language pairs
      if (
        basePrices
          .map(x => x.languagePair)
          .some((lp, i) => {
            return (
              i !== index &&
              lp.sourceLocale === languagePair.sourceLocale &&
              lp.targetLocale === languagePair.targetLocale
            );
          })
      ) {
        return { valid: false, errorMsg: 'Duplicate language pair' };
      }

      return { valid: true };
    } catch (err) {
      return { valid: false, errorMsg: err.message };
    }
  };

  UNSAFE_componentWillMount() {
    const { onFetchOne, priceSchemeId } = this.props;

    // Since the page can be loaded directly via the URL, we cannot rely on
    // the particular price scheme being available in the redux store.  We need
    // to load it and display a spinner until it is available.
    onFetchOne(priceSchemeId);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Once the price scheme actually loads, we need to store it in the local state so
    // that we can modify it locally until the user presses save.
    if (this.props.priceSchemes.item !== prevProps.priceSchemes.item) {
      this.setState({
        localPriceScheme: this.props.priceSchemes.item,
        hasChanged: false,
      });
    }
  }

  render() {
    const { isFetching } = this.props.priceSchemes;
    const isPriceSchemeValid = this.isPriceSchemeValid();
    // Even though the 'item' is available in the redux store, we want to use
    // the one from local state, because we need to be able to make changes to it
    // without saving until the user presses the Save button.
    const { localPriceScheme, hasChanged, lastError } = this.state;
    const columns = [
      {
        title: 'Language Pair',
      },
      {
        title: 'Price Per Word',
      },
      {
        title: '', // Empty header for actions column
      },
    ].filter(c => c);

    // This is the ID that is used for the react key property for rows in the grid.
    // Two rows with the same ID will result in react warnings and some weird behavior.
    // Base prices do not have ID's, so we have to generate something unique.
    // Because we use the language pair, we need to use validation to ensure no two
    // rows have the same language pair.
    const getId = array => (item, index) => `${array.length}_${index}`;

    return (
      <div>
        <Prompt
          when={hasChanged}
          message="You have unsaved changes.  Are you sure you want to leave?"
        />
        <BackLink>
          <Link to="/settings/pricing">⬅ Back to List</Link>
        </BackLink>

        <Container
          title="Price Scheme Details"
          rawButtons={
            <>
              {localPriceScheme && !isFetching && (
                <Button id="EditInfo" onClick={() => this.handleOpenEditModal(localPriceScheme)}>
                  Edit Info
                </Button>
              )}
            </>
          }
        >
          {isFetching && <SvgIcon icon={spinner} size={32} />}
          {!isFetching && !localPriceScheme && (
            <Alert bsStyle="warning">Price Scheme not found.</Alert>
          )}
          {localPriceScheme && !isFetching && (
            <>
              <Modal show={this.state.showEditModal} onHide={this.handleCloseEditModal}>
                <PriceSchemeDialog
                  closeHandler={this.handleCloseEditModal}
                  onUpdateEvent={this.saveMetadata}
                  priceScheme={localPriceScheme}
                  title={'Edit Price Scheme'}
                />
              </Modal>
              <Modal
                show={this.state.showPriceImportModal}
                onHide={this.handleClosePriceImportModal}
              >
                <PriceSchemeImportDialog
                  closeHandler={this.handleClosePriceImportModal}
                  onUpdateEvent={this.setBasePrices}
                  priceScheme={localPriceScheme}
                  title={'Import Prices'}
                />
              </Modal>
              <PriceSchemeName>{localPriceScheme.name}</PriceSchemeName>
              <PriceSchemeDescription>{localPriceScheme.description}</PriceSchemeDescription>
            </>
          )}
        </Container>
        {localPriceScheme && !isFetching && (
          <Container
            title="Prices"
            rawButtons={
              <>
                <Button
                  id={'importPriceButton'}
                  onClick={() => this.handleOpenPriceImportModal(localPriceScheme)}
                >
                  <SvgIcon icon={upload} size={16} />
                  <span> Import</span>
                </Button>
                {hasChanged && (
                  <Button
                    variant="primary"
                    onClick={this.saveLocalChanges}
                    disabled={!isPriceSchemeValid}
                  >
                    Save Changes
                  </Button>
                )}
              </>
            }
          >
            {lastError && <ErrorAlert title="Failed to save prices" errorInfo={lastError} />}
            <Grid
              id="Prices"
              getId={getId(localPriceScheme.basePrices)}
              scrollable
              isFetching={isFetching}
              fullyLoaded={true}
              columns={columns}
              items={localPriceScheme.basePrices || []}
              datasetId={'1'}
              rowClassNames={[{ 'row-editable': true }, 'form-inline']}
              validateRow={this.validateBasePrice}
              renderRow={(basePrice, index) => (
                <>
                  <td>
                    <LanguagePair
                      value={basePrice.languagePair}
                      availableLocaleCodes={this.props.availableLocales.items.map(x =>
                        formatLocaleCode(x.code)
                      )}
                      onChange={(type, value) => this.updateField(`${index}.${type}`, value)}
                    />
                  </td>
                  <td>
                    <BasePrice
                      value={basePrice.price}
                      onChange={(type, value) => this.updateField(`${index}.${type}`, value)}
                    />
                  </td>
                  <td className="row-actions">
                    <button className="btn btn-xs btn-danger" onClick={() => this.deleteRow(index)}>
                      Delete
                    </button>
                  </td>
                </>
              )}
            />
            {(!localPriceScheme.basePrices || localPriceScheme.basePrices.length === 0) && (
              <NoSchemesPlaceholder>
                <h3>This price scheme is empty</h3>
                To get started, <LinkButton onClick={this.addRow}>add</LinkButton> a new price.
              </NoSchemesPlaceholder>
            )}
            <AddRowButtonContainer>
              <Button id="AddRow" onClick={this.addRow}>
                Add Row
              </Button>
            </AddRowButtonContainer>
          </Container>
        )}
      </div>
    );
  }
}

export default PriceSchemeDetail;
