const UserRoles = {
  SYS_ADMIN: 'SYS_ADMIN',
  SYS_ADMIN_READ: 'SYS_ADMIN_READ',
  ADMIN: 'ADMIN',
  PROJECT_MANAGER: 'PROJECT_MANAGER',
  LINGUIST: 'LINGUIST',
  GUEST: 'GUEST',
  SUBMITTER: 'SUBMITTER',
};
export default UserRoles;

export const ROLE_NAMES = {
  SYS_ADMIN: 'System Administrator',
  SYS_ADMIN_READ: 'System Administrator (Read)',
  ADMIN: 'Admin',
  PROJECT_MANAGER: 'Project Manager',
  LINGUIST: 'Linguist',
  GUEST: 'Guest',
  SUBMITTER: 'Submitter',
};

export const getRoleName = role => {
  return ROLE_NAMES[role] || 'Unknown';
};

export const isAdminRole = role => {
  return (
    role === UserRoles.SYS_ADMIN || role === UserRoles.SYS_ADMIN_READ || role === UserRoles.ADMIN
  );
};
