import React from 'react';
import { Tab } from 'react-bootstrap';
import RoutingTabs from '../RoutingTabs';
import styled from '@emotion/styled';
import PricingContainer from '../../containers/Settings/PricingContainer';
import WeightingContainer from '../../containers/Settings/WeightingContainer';
import MediaTypeSubjectSettingsContainer from '../../containers/Settings/MediaTypeSubjectSettingsContainer';
import canDo from '../../utils/canDo';
import AutoReassignmentView from './AutoReassignment/AutoReassignmentView';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: Arial, sans-serif;
`;

function Header() {
  return (
    <header id="header">
      <h1>Settings</h1>
    </header>
  );
}

// FIXME: use non-any types once they have been established up-stream
type SettingsProps = {
  session: any;
  features: any;
};

export class Settings extends React.Component<SettingsProps> {
  render() {
    return (
      <Container>
        <Header />

        <RoutingTabs path="/settings" id="settingsTabs">
          {canDo(this.props.session, 'admin:read') && (
            <Tab
              title="Media Types & Subjects"
              eventKey="mediaTypeSubject"
              mountOnEnter
              unmountOnExit
            >
              <MediaTypeSubjectSettingsContainer />
            </Tab>
          )}
          {canDo(this.props.session, 'admin:read') && (
            <Tab title="Pricing" eventKey="pricing" mountOnEnter unmountOnExit>
              <PricingContainer />
            </Tab>
          )}
          {canDo(this.props.session, 'admin:read') && (
            <Tab title="Weighting" eventKey="weighting" mountOnEnter unmountOnExit>
              <WeightingContainer />
            </Tab>
          )}
          {canDo(this.props.session, 'offerAutoReassignmentConfigs:read') && (
            <Tab title="Dynamic Assignment" eventKey="autoReassignment" mountOnEnter unmountOnExit>
              <AutoReassignmentView />
            </Tab>
          )}
        </RoutingTabs>
      </Container>
    );
  }
}
