import React, { useEffect, useState } from 'react';
import {
  Header,
  Button,
  Box,
  Container,
  PieChart,
  TextContent,
} from '@amzn/awsui-components-react-v3/polaris';
import { getIssueRecommendation } from '../../utils/linguistFeedbackUtils';

interface Datum {
  title: string;
  value: number;
}

const ErrorCategoriesView = ({
  issueData,
  topIssue,
}: {
  issueData: Datum[];
  topIssue: string | undefined;
}): JSX.Element => {
  type KeyValuePair = {
    key: string;
    value: string;
  };

  return (
    <Container
      header={
        <Header
          variant="h2"
          description="Categories in which the linguist has made the most mistakes"
        >
          Top Error Categories
        </Header>
      }
    >
      {/* TODO: Make this reactive */}
      <PieChart
        data={issueData}
        detailPopoverContent={(datum, sum): KeyValuePair[] => [
          {
            key: 'Percentage',
            value: `${((datum.value / sum) * 100).toFixed(0)}%`,
          },

          {
            key: 'Recommendation',
            value: getIssueRecommendation(datum.title),
          },
        ]}
        i18nStrings={{
          detailsValue: 'Occurences',
          detailsPercentage: 'Percentage',
          filterLabel: 'Filter displayed data',
          filterPlaceholder: 'Filter data',
          filterSelectedAriaLabel: 'selected',
          detailPopoverDismissAriaLabel: 'Dismiss',
          legendAriaLabel: 'Legend',
          chartAriaRoleDescription: 'pie chart',
          segmentAriaRoleDescription: 'segment',
        }}
        ariaDescription="Pie chart showing how many resources are currently in which state."
        ariaLabel="Pie chart"
        detailPopoverSize="large"
        errorText="Error loading linguist data."
        hideFilter
        hideLegend
        loadingText="Loading chart"
        recoveryText="Retry"
        empty={
          <Box textAlign="center" color="inherit">
            <b>No data available</b>
            <Box variant="p" color="inherit">
              There is no data available
            </Box>
          </Box>
        }
        noMatch={
          <Box textAlign="center" color="inherit">
            <b>No matching data</b>
            <Box variant="p" color="inherit">
              There is no matching data to display
            </Box>
            <Button>Clear filter</Button>
          </Box>
        }
      />
      {topIssue && (
        <TextContent>
          <h3>
            The most common issue found in your translation is <strong>{topIssue}</strong>.{' '}
            {getIssueRecommendation(topIssue)}
          </h3>
        </TextContent>
      )}
    </Container>
  );
};

type ErrorCategoriesProps = {
  issueCounts: Map<string, number>;
};

const ErrorCategories = ({ issueCounts }: ErrorCategoriesProps): JSX.Element => {
  // issueData will contain the top 4 most common issues
  const [issueData, setIssueData] = useState<Datum[]>([]);
  const [topIssue, setTopIssue] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (issueCounts.size === 0) return;
    const issueCountAsArray: Datum[] = Array.from(issueCounts, ([key, value]) => {
      return { title: key, value: value };
    });
    // sort the array in decending order
    issueCountAsArray.sort((a, b): number => b.value - a.value);

    setIssueData(issueCountAsArray.slice(0, 5));
    // get the top issue
    setTopIssue(issueCountAsArray[0].title);
  }, [issueCounts]);

  return ErrorCategoriesView({ issueData, topIssue });
};

export default ErrorCategories;
