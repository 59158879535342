import React, { ReactElement, useEffect, useState } from 'react';
import { Modal, Table, Button } from '@amzn/awsui-components-react-v3';
import PropTypes from 'prop-types';
import Header from '@amzn/awsui-components-react-v3/polaris/header';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';

export const ManageFiltersModal = ({
  isVisible,
  setIsVisible,
  savedFilters,
  handleSaveFilters,
}) => {
  const [newSavedFilters, setNewSavedFilters] = useState([]);

  useEffect(() => {
    setNewSavedFilters(savedFilters);
  }, [savedFilters]);

  const prepDelete = index => {
    return () => {
      const newSavedFiltersCopy = [...newSavedFilters];
      newSavedFiltersCopy.splice(index, 1);
      setNewSavedFilters(newSavedFiltersCopy);
    };
  };

  const handleCancelClick = () => {
    setIsVisible(false);
  };

  const handleOkClick = () => {
    handleSaveFilters(newSavedFilters);
    setIsVisible(false);
  };

  const columnDef = [
    {
      id: 'name',
      header: 'Name',
      cell: item => item.filter.name,
    },
    {
      id: 'delete',
      header: '',
      cell: item => (
        <Box float="right">
          <Button onClick={prepDelete(item.index)}>Delete</Button>
        </Box>
      ),
    },
  ];

  return (
    <Modal
      id={'manage-filters-modal'}
      onDismiss={handleCancelClick}
      closeAriaLabel={'Close modal'}
      header={<Header variant={'h2'}>Manage saved filters</Header>}
      visible={isVisible}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button id={'manage-filters-modal-cancel'} variant="link" onClick={handleCancelClick}>
              Cancel
            </Button>
            <Button id={'manage-filters-modal-ok'} variant="primary" onClick={handleOkClick}>
              Ok
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <Table
        items={newSavedFilters ? newSavedFilters.map((v, i) => ({ index: i, filter: v })) : []}
        columnDefinitions={columnDef}
        header={'Saved filters'}
      />
    </Modal>
  );
};

ManageFiltersModal.propTypes = {
  isVisible: PropTypes.bool,
  setIsVisible: PropTypes.func,
  savedFilters: PropTypes.array,
  handleSaveFilters: PropTypes.func,
};
