interface YearMonthRange {
  startYear: string;
  startMonth: string;
  endYear: string;
  endMonth: string;
}
export type IssueCounts = Record<string, number>;
export interface QualityScore {
  month: string;
  year: string;
  score: number;
  sumOfScoresProdWordCount: number;
  sumOfWordCounts: number;
  issueCounts: IssueCounts;
  version: number;
}

export function avgScore(scores: QualityScore[]): number {
  const numerator: number = scores.reduce((a, b) => a + b.sumOfScoresProdWordCount, 0);
  const denominator: number = scores.reduce((a, b) => a + b.sumOfWordCounts, 0);
  return numerator / denominator;
}

function numberToMonth(month: number): string {
  return String(month).padStart(2, '0');
}

const monthMap: Map<string, string> = new Map();
monthMap.set('01', 'Jan');
monthMap.set('02', 'Feb');
monthMap.set('03', 'Mar');
monthMap.set('04', 'Apr');
monthMap.set('05', 'May');
monthMap.set('06', 'Jun');
monthMap.set('07', 'Jul');
monthMap.set('08', 'Aug');
monthMap.set('09', 'Sep');
monthMap.set('10', 'Oct');
monthMap.set('11', 'Nov');
monthMap.set('12', 'Dec');

/**
 * Will get dates in the form (MM) for month and (YYYY) for year.
 * @param defaultMonthOffset The month offset to use (default: 4)
 * @returns
 */
function getYearMonthRange(defaultMonthOffset = 4): YearMonthRange {
  const currentDate = new Date();
  // current month is a zero based value. Thus, the largest month is 11
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  let yearOffset = 0;
  // We will get either the last 5 months or the last year depending on which is
  // larger
  let previousMonth = currentMonth - Math.max(currentMonth, defaultMonthOffset);

  if (previousMonth < 0) {
    yearOffset = Math.ceil(Math.abs(previousMonth) / 12);
    previousMonth = 12 + previousMonth;
  }
  const previousYear = currentYear - yearOffset;
  const output: YearMonthRange = {
    startYear: previousYear.toString(),
    startMonth: numberToMonth(previousMonth + 1),
    endYear: String(currentYear),
    endMonth: numberToMonth(currentMonth + 1),
  };
  return output;
}

const proofreaderRecsMap: Map<string, string> = new Map();
proofreaderRecsMap.set(
  'accuracy:addition',
  'Do not include anything in the target that is not already present in the source. Always proofread your text once done as it might help spot additions.'
);
proofreaderRecsMap.set(
  'accuracy:mistranslation',
  'Take your time to understand what the source actually means and choose appropriate terms in your target language. If there are names, products, dates or places, make sure they match with the source.'
);
proofreaderRecsMap.set(
  'accuracy:omission',
  'Do not omit anything in the target that is present in the source. It might be important information our customers are looking for. Always proofread your text once done as it might help spot omissions.'
);
proofreaderRecsMap.set(
  'accuracy:untranslated',
  'Not everyone might understand the source language. Make sure you translate every word that needs to be translated, unless otherwise specified in the Style Guide or Term Base.'
);
proofreaderRecsMap.set(
  'design:markup',
  'Keep tags where they are supposed to be according to the source. You should only move them if needed to make them fit with your target language but do not misplace them, remove them or edit them.'
);
proofreaderRecsMap.set(
  'design:truncation-text-expansion',
  'Make sure text is not cut or does not exceed given limitations for the specific project.'
);
proofreaderRecsMap.set(
  'fluency:character-encoding',
  "Make sure variables and code present in the target always reflect the source exactly. The most common mistakes are capitalization errors or spaces added in between the code's characters, which result in breaking the code."
);
proofreaderRecsMap.set(
  'fluency:duplication',
  'Remember to proofread your segments in order to spot this issue.'
);
proofreaderRecsMap.set(
  'fluency:grammar',
  'Please check your language appendices as well as your feedback emails so that you can improve your quality of grammar. Also online research will help!'
);
proofreaderRecsMap.set(
  'fluency:grammatical-register',
  'Remember to check the relevant section in the Appendix to ensure you address the audience in the correct way.'
);
proofreaderRecsMap.set(
  'fluency:inconsistency',
  'Proofread your text to make sure you are always consistent throughout the whole job. For example, make sure you are not mixing singular and plural “you” within the same document.'
);
proofreaderRecsMap.set(
  'fluency:spelling',
  'Proofread and run the QA check (F9 in ATMS) in order to avoid misspelling words. Pay extra attention to capitalization as well.'
);
proofreaderRecsMap.set(
  'fluency:typography',
  'Pay attention to the use of punctuation; check the relevant Style Guide when in doubt. Remember to check for double spaces at the end of your translation and correct them.'
);
proofreaderRecsMap.set(
  'fluency:unintelligible',
  'Always make sure you proofread your file in order to avoid major fluency mistakes that make the text unclear to the reader.'
);
proofreaderRecsMap.set(
  'locale-convention:address-format',
  'Always follow the rules of your target language and of the Style Guides to use the correct address format.'
);
proofreaderRecsMap.set(
  'locale-convention:currency-format',
  'Always follow the rules of your target language and of the Style Guides to use the correct currency format.'
);
proofreaderRecsMap.set(
  'locale-convention:date-format',
  'Always follow the rules of your locale and of the Style Guides to use the correct date format.'
);
proofreaderRecsMap.set(
  'locale-convention:measurement-format',
  'Always follow the rules of your locale and of the Style Guides to use the correct measurement format. Pay extra attention to capitalization and spacing of measurement symbols.'
);
proofreaderRecsMap.set(
  'locale-convention:national-language-standard',
  'Always follow the language and spelling conventions of your locale (e.g. pants in US vs. trousers in UK).'
);
proofreaderRecsMap.set(
  'locale-convention:number-format',
  'Always follow the rules of your target language and of the Style Guides to use the correct number format. Pay extra attention to thousand and decimal separators.'
);
proofreaderRecsMap.set(
  'locale-convention:telephone-format',
  'Always follow the rules of your target language and of the Style Guides to use the correct telephone format.'
);
proofreaderRecsMap.set(
  'locale-convention:time-format',
  'Always follow the rules of your locale and of the Style Guides to use the correct time format.'
);
proofreaderRecsMap.set(
  'style:awkward',
  'Always read the Company Style section of the relevant Style Guide carefully to make sure you are following the correct instructions for the specific project you are working on. Different projects have different requirements.'
);
proofreaderRecsMap.set(
  'style:unidiomatic',
  'Make sure your translation always sounds natural and true to the target language. Avoid using calques or literal expressions. Perform a quick online search when in doubt.'
);
proofreaderRecsMap.set(
  'terminology:domain-terminology',
  'Make sure your chosen terminology reflects the appropriate use in your target language for that particular field. Perform a quick online search when in doubt.'
);
proofreaderRecsMap.set(
  'terminology:term-inconsistency',
  'Make sure you follow the same terminology throughout the text. Avoid using too many synonyms for the same word.'
);
proofreaderRecsMap.set(
  'terminology:termbase',
  'Always refer to the given Term Base (TB) for the specific project you are working on. If terms are present in the TB, they must be followed as they appear. Remember to give priority to the primary TB and check to which project it belongs in ATMS.'
);
proofreaderRecsMap.set(
  'verity:end-user-suitability',
  'Do not make culture-specific references that will not be understandable to the intended audience. Always keep in mind who is the audience that you are addressing.'
);
proofreaderRecsMap.set(
  'verity:legal-requirements',
  'Remember to double check the relevant section in the Style Guide to ensure that your translation meets all the needed legal requirements.'
);

export const getIssueRecommendation = (issueType: string): string => {
  const value = proofreaderRecsMap.get(issueType);
  if (typeof value === 'string') {
    return value;
  } else {
    return 'No Recommendation';
  }
};

export { getYearMonthRange, monthMap, proofreaderRecsMap };
