import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled, { css } from 'react-emotion';
import { Button } from 'react-bootstrap';
import withProps from 'recompose/withProps';

import extractApiErrors from '../utils/extractApiErrors';

const FadeOut = withProps({
  classNames: 'fadeOut',
  timeout: { enter: 0, exit: 200 },
})(styled(CSSTransition)`
  &.fadeOut-exit {
    opacity: 1;
  }

  &.fadeOut-exit.fadeOut-exit-active {
    opacity: 0;
    transition: opacity 200ms ease-in;
  }
`);

const toasters = [];

const addToaster = toaster => {
  toasters.push(toaster);
};

const removeToaster = toaster => {
  toasters.splice(toasters.indexOf(toaster), 1);
};

const Toast = ({ type = 'success', message, onClose }) => {
  return (
    <div
      className={css`
        padding: 0;
        margin: 0 auto;
        width: 500px;
        position: relative;
        text-align: center;
        color: #fff;
      `}
    >
      <div
        className={`alert alert-${type} ${css`
          padding: 0.25em 0.5em;
          margin: 2px 0;
        `}`}
      >
        {message}
        <Button className="close" onClick={onClose}>
          &times;
        </Button>
      </div>
    </div>
  );
};
Toast.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
};

export class Toaster extends React.Component {
  static defaultProps = {
    autoClose: 5000,
  };

  static propTypes = {
    autoClose: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  };

  constructor() {
    super();
    this.state = { items: {} };
  }

  componentDidMount() {
    addToaster(this);
    this.timer = setInterval(() => this.removeExpiredItems(), 200);
  }

  componentWillUnmount() {
    removeToaster(this);
    clearInterval(this.timer);
  }

  addItem(item) {
    const createdAt = new Date().getTime();
    item = { ...item, createdAt };
    const items = { ...this.state.items, [item.id]: item };
    this.setState({ items });
  }

  removeItem(id) {
    this.removeItems([id]);
  }

  removeItems(ids) {
    if (!ids.length) {
      return;
    }
    const items = { ...this.state.items };
    ids.forEach(id => {
      delete items[id];
    });
    this.setState({ items });
  }

  removeExpiredItems() {
    const { autoClose } = this.props;
    if (!autoClose) {
      return;
    }
    const { items } = this.state;
    const ids = [];
    const now = new Date().getTime();
    Object.keys(items).forEach(id => {
      const item = items[id];
      if (item.createdAt < now - autoClose) {
        ids.push(id);
      }
    });
    this.removeItems(ids);
  }

  render() {
    const { items } = this.state;
    return (
      <TransitionGroup
        exit={true}
        component="ul"
        className={css`
          z-index: 100;
          list-style: none;
          padding: 0;
          margin: 0;
          position: absolute;
          top: 70px;
          left: 0;
          right: 0;
        `}
      >
        {Object.keys(items).map(id => (
          <FadeOut key={id}>
            <li>
              <Toast {...items[id]} onClose={() => this.removeItem(id)} />
            </li>
          </FadeOut>
        ))}
      </TransitionGroup>
    );
  }
}

let toastId = 0;
/**
 * Publish a toast message to any toasters on the page
 *
 * @param {*} message - The message to embed in the toast. May be a string, or
 *                      any valid react child (e.g. an element)
 * @param {string} type - success | info | warning | danger - corresponds to
 *                        bootstrap alert types.
 */
const toast = (message, type = 'success') => {
  toast.fn(message, type);
};

toast.fn = (message, type = 'success') => {
  toastId++;
  toasters.forEach(toaster => {
    toaster.addItem({ type, message, id: toastId });
  });
};

toast.ajaxError = errorResponse => {
  const errors = extractApiErrors(errorResponse);
  toast.fn(
    <span>
      <strong>Error: </strong>
      {errors.length > 1 ? (
        <ul>
          {errors.map(error => (
            <li key={error}>{error}</li>
          ))}
        </ul>
      ) : (
        errors[0]
      )}
    </span>,
    'danger'
  );
};

export default toast;
