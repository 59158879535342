// TODO: this logic is duplicated in tp-web-service, if we really need this (we can't change tp-web-service to send
//  freelancerUris instead of ids) we may want to move to a common package

const URI_PREFIX = 'web:';
const USER_URI_PREFIX = URI_PREFIX + 'user:';

export const userIdToFreelancerUri = userId => {
  // currently, all users are from web's DB, so prefix the identifier
  return `${USER_URI_PREFIX}${userId}`;
};
