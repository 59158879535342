import React from 'react';
import PropTypes from 'prop-types';
import { ExpandableSection, Table, Button } from '@amzn/awsui-components-react/polaris';
import { css } from 'emotion';
import { Link } from 'react-router-dom';

const topSpacing = css`
  height: 20px;
`;

const alignLeft = css`
  float: left;
`;

const alignRight = css`
  float: right;
`;

const columnDefinitions = [
  {
    id: 'translationSkill',
    header: 'Translation Skill',
    cell: item => item.skill,
  },
  {
    id: 'priceScheme',
    header: 'Pricing',
    cell: item => (
      <a href={`/settings/pricing/${item.priceSchemeId}`} target="_blank" rel="noopener noreferrer">
        {item.priceSchemeName}
      </a>
    ),
  },
  {
    id: 'weightScheme',
    header: 'Weighting',
    cell: item => (
      <a
        href={`/settings/weighting/${item.weightSchemeId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {item.weightSchemeName}
      </a>
    ),
  },
  {
    id: 'selfServiceEnabled',
    header: 'Self Service',
    cell: item => (item.selfServiceEnabled ? 'Enabled' : 'Disabled'),
  },
];

class MediaTypeSubjectList extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.onLoadAll();
    this.props.onReloadPriceSchemes();
    this.props.onReloadWeightSchemes();
  }

  // we need to define our own sort order here since assignment doesn't currently
  // return this information (we can't rely on the iteration order over the skills
  // object object returned from assignment)
  sortSkillRows(tableRows) {
    const skillOrder = [
      'LIGHT_MTPE',
      'MTPE',
      'HUMAN_TRANSLATION',
      'REVISION',
      'SCORING',
      'SCORING_AND_REVISION',
      'QA',
      'QA_OF_QA',
    ];
    tableRows.sort(
      (row1, row2) => skillOrder.indexOf(row1.skillId) - skillOrder.indexOf(row2.skillId)
    );
  }

  render() {
    const { items } = this.props.mediaTypeSubjectSettings;
    const { items: priceSchemes } = this.props.priceSchemes;
    const { items: weightSchemes } = this.props.weightSchemes;
    const { mediaTypes } = this.props.mediaTypes;
    const { subjects } = this.props.subjects;
    const { skills } = this.props.translationSkills;

    return (
      <>
        <div className={topSpacing} />
        {Object.keys(mediaTypes).map(mediaType => {
          const foundItem = items.find(x => x.mediaType === mediaType) || {};
          return (
            <ExpandableSection
              key={mediaType}
              header={mediaTypes[mediaType]}
              variant="container"
              expanded={Object.keys(foundItem).length > 0}
            >
              {Object.entries(foundItem.subjects || {}).map(([subject, subjectEntry]) => {
                const tableRows = [];
                Object.entries(subjectEntry.skills || {}).forEach(([skill, skillEntry]) => {
                  tableRows.push({
                    // only for sorting purposes
                    skillId: skill,
                    skill: skills[skill],
                    priceSchemeName: (
                      priceSchemes.find(x => x.id === skillEntry.priceSchemeId) || {}
                    ).name,
                    weightSchemeName: (
                      weightSchemes.find(x => x.id === skillEntry.weightSchemeId) || {}
                    ).name,
                    ...skillEntry,
                  });
                });
                this.sortSkillRows(tableRows);
                return (
                  <ExpandableSection
                    key={subject}
                    header={
                      <React.Fragment>
                        <div className={alignLeft}>{subjects[subject]}</div>
                        <div className={alignRight}>
                          <Link to={`/settings/mediaTypeSubject/${mediaType}/${subject}`}>
                            <Button text="Edit" variant="normal" />
                          </Link>
                        </div>
                      </React.Fragment>
                    }
                    expanded={false}
                  >
                    <Table
                      columnDefinitions={columnDefinitions}
                      items={tableRows}
                      variant="borderless"
                      empty=""
                    />
                  </ExpandableSection>
                );
              })}

              <Link to={`/settings/mediaTypeSubject/${mediaType}/create`}>
                <Button text="Add Subject" variant="primary" />
              </Link>
            </ExpandableSection>
          );
        })}
      </>
    );
  }
}

MediaTypeSubjectList.propTypes = {
  session: PropTypes.object.isRequired,
  priceSchemes: PropTypes.object.isRequired,
  weightSchemes: PropTypes.object.isRequired,
  translationSkills: PropTypes.object.isRequired,
  mediaTypes: PropTypes.object.isRequired,
  subjects: PropTypes.object.isRequired,
  mediaTypeSubjectSettings: PropTypes.object.isRequired,
  onReloadPriceSchemes: PropTypes.func,
  onReloadWeightSchemes: PropTypes.func,
  onLoadAll: PropTypes.func,
};

MediaTypeSubjectList.defaultProps = {
  session: Object.prototype,
  priceSchemes: Object.prototype,
  weightSchemes: Object.prototype,
  mediaTypeSubjectSettings: Object.prototype,
  onReloadPriceSchemes: Function.prototype,
  onReloadWeightSchemes: Function.prototype,
  onLoadAll: Function.prototype,
};

export default MediaTypeSubjectList;
