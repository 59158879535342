import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import App from '../containers/AppContainer';
import TermsOfService from './App/TermsOfService';
import initialMetricsPublisher from '../utils/metricsPublisher';
import { publishAtmsUserMetrics, publishBrowserMetrics } from '@amzn/ts-ui-metrics';
import { inferEnvironment } from '@amzn/et-console-components';

const Root = ({ store }) => {
  useEffect(() => {
    publishAtmsUserMetrics(initialMetricsPublisher, inferEnvironment() + 'Session');
    publishBrowserMetrics(initialMetricsPublisher);
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/terms" component={TermsOfService} />
          <Route path="/" component={App} />
        </Switch>
      </Router>
    </Provider>
  );
};

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

export default Root;
