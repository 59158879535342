import { combineReducers } from 'redux';
import features from './Features';
import locales from './Locales';
import profile from './Profile';
import session from './Session';
import translators from './Translators';
import certification from './Certification';
import translationSkills from './TranslationSkills';
import mediaTypes from './MediaTypes';
import subjects from './Subjects';
import genericCrudReducer, { genericCrudInitialState } from './GenericCrudReducer';
import genericNextTokenCrudReducer, {
  genericNextTokenCrudInitialState,
} from './GenericNextTokenCrudReducer';

export const initialState = {
  features: features.initialState,
  locales: locales.initialState,
  session: session.initialState,
  translators: translators.initialState,
  priceSchemes: genericNextTokenCrudInitialState,
  weightSchemes: genericNextTokenCrudInitialState,
  jobRule: genericCrudInitialState,
  profile: profile.initialState,
  certification: certification.initialState,
  translationSkills: translationSkills.initialState,
  mediaTypes: mediaTypes.initialState,
  subjects: subjects.initialState,
  mediaTypeSubjectSettings: genericNextTokenCrudInitialState,
};

const translatorPortalApp = combineReducers({
  features,
  locales,
  session,
  translators,
  priceSchemes: genericNextTokenCrudReducer('priceSchemes'),
  weightSchemes: genericNextTokenCrudReducer('weightSchemes'),
  jobRule: genericCrudReducer('jobRule'),
  profile,
  certification,
  translationSkills,
  mediaTypes,
  subjects,
  mediaTypeSubjectSettings: genericNextTokenCrudReducer('mediaTypeSubjectSettings'),
});

export default translatorPortalApp;
