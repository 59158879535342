import { css } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import { OfferCard } from './offer-card';
import { Alert, Button, Spinner } from '@amzn/awsui-components-react/polaris';
import pluralize from 'pluralize';
import { withRouter } from 'react-router-dom';
import { OfferUtils } from '../utils/offerUtils';

import styled from '@emotion/styled';
import initialMetricsPublisher from '../utils/metricsPublisher';
import { publishCountForMethod } from '@amzn/ts-ui-metrics';
import { inferEnvironment } from '@amzn/et-console-components';

const OfferDescription = styled('div')`
  float: left;
  display: inline;
  marginbottom: 15px;
`;

const DoubleFontSize = styled.div(props => ({
  'font-size': '200%',
  color: props.color,
}));

const StatsItem = styled('div')`
  padding: 10px;
`;

const TwoColumnsGrid = styled('div')`
  display: grid;
  grid-template-columns: auto auto;
`;

const Alerts = props => {
  const { alerts } = props;
  return (
    <div
      css={css`
        awsui-alert {
          margin-bottom: 15px;
        }
      `}
    >
      {alerts.map((alert, index) => (
        <Alert
          key={index}
          header={alert.header}
          content={alert.content}
          type={alert.type}
          onDismiss={alert.onDismiss}
          dismissible
        />
      ))}
    </div>
  );
};

Alerts.propTypes = {
  alerts: PropTypes.array,
};

const STATUS_COLOR = {
  SUCCESS: '#1d8102',
  WARN: '#eb5f07',
  ERROR: '#d13212',
  INAVTIVE: '#808080',
};

const Header = props => {
  const {
    forReservations,
    assignedJobsCount,
    maxAssignedJobsCount,
    acceptedWeightedWorkUnits,
    maxAcceptedWeightedWorkUnits,
    numOffers,
    isFetching,
    jobs,
    updateStatusForAll,
    isUpdatingOfferMap,
    profile,
  } = props;
  const header = forReservations ? 'Job offers' : 'Current jobs';
  let jobsStatusColor = STATUS_COLOR.SUCCESS;
  let weightedWordsStatusColor = STATUS_COLOR.SUCCESS;
  if (forReservations) {
    if (acceptedWeightedWorkUnits >= maxAcceptedWeightedWorkUnits) {
      jobsStatusColor = STATUS_COLOR.INAVTIVE;
      weightedWordsStatusColor = STATUS_COLOR.ERROR;
    } else if (assignedJobsCount >= maxAssignedJobsCount) {
      jobsStatusColor = STATUS_COLOR.ERROR;
      weightedWordsStatusColor = STATUS_COLOR.INAVTIVE;
    }
  }
  const areOffersUpdating = () => {
    const iterator = isUpdatingOfferMap.values();
    let updateStatus = iterator.next();
    while (!updateStatus.done) {
      if (updateStatus.value) {
        return true;
      }
      updateStatus = iterator.next();
    }
    return false;
  };

  const openAll = () => {
    window.open(OfferUtils.createEditLink(jobs));
  };

  const isUserCertifiedForStringContent = () => {
    if (profile.lastError != null || profile.user == null) return false;
    for (const certification of profile.user.certifications) {
      if (certification.mediaTypeId === 'SOFTWARE' && certification.subjectId === 'GENERAL')
        return true;
    }
    return false;
  };

  return (
    <header id="header">
      <OfferDescription>
        <h1 style={{ padding: '0px' }}>{header}</h1>
        {!isFetching && forReservations && (
          <h5 id="offers-description" style={{ paddingTop: '0px' }}>
            Showing {pluralize('job offer', numOffers, true)}
            <br />
            Offers are reserved for 5 minutes
          </h5>
        )}
      </OfferDescription>
      {!forReservations && numOffers > 0 && !isFetching && (
        <div style={{ float: 'right' }}>
          <Button
            key="CompleteAll"
            id={`Complete-All`}
            text="Complete All"
            loading={areOffersUpdating()}
            onClick={() => {
              const actionMetricsPublisher = publishCountForMethod(
                initialMetricsPublisher,
                'CompleteAllOffersButtonClick'
              );
              actionMetricsPublisher.publishCounter('NumOffers', jobs.length);

              updateStatusForAll(jobs, 'COMPLETED');
            }}
          />
          {isUserCertifiedForStringContent() && (
            <Button
              key="OpenAll"
              id={`Open-All`}
              text="Open All"
              disabled={areOffersUpdating()}
              variant="primary"
              onClick={() => {
                const actionMetricsPublisher = publishCountForMethod(
                  initialMetricsPublisher,
                  'OpenAllOffersButtonClick'
                );
                actionMetricsPublisher.publishCounter('NumOffers', jobs.length);

                openAll();
              }}
            />
          )}
        </div>
      )}
      <div style={{ float: 'right' }}>
        {!isFetching && forReservations && (
          <TwoColumnsGrid class="grid-container">
            <StatsItem>
              <div>Jobs</div>
              <DoubleFontSize id="freelancer-stats-assigned-jobs" color={jobsStatusColor}>
                {assignedJobsCount}
              </DoubleFontSize>
              <div id="freelancer-stats-max-jobs">out of {maxAssignedJobsCount}</div>
            </StatsItem>
            <StatsItem>
              <div>Weighted Words</div>
              <DoubleFontSize id="freelancer-stats-words" color={weightedWordsStatusColor}>
                {Math.round(acceptedWeightedWorkUnits).toLocaleString()}
              </DoubleFontSize>
              <div id="freelancer-stats-max-words">
                out of {maxAcceptedWeightedWorkUnits.toLocaleString()}
              </div>
            </StatsItem>
          </TwoColumnsGrid>
        )}
      </div>
    </header>
  );
};

Header.propTypes = {
  forReservations: PropTypes.bool,
  assignedJobsCount: PropTypes.number,
  maxAssignedJobsCount: PropTypes.number,
  acceptedWeightedWorkUnits: PropTypes.number,
  maxAcceptedWeightedWorkUnits: PropTypes.number,
  numOffers: PropTypes.number,
  isFetching: PropTypes.bool,
  jobs: PropTypes.array,
  updateStatusForAll: PropTypes.func,
  isUpdatingOfferMap: PropTypes.object,
  profile: PropTypes.object,
};

const TranslatorJobsView = withRouter(
  ({
    forReservations,
    fetchStatsAndOffers,
    updateStatus,
    jobs,
    segmentMetadataByJob,
    assignedJobsCount,
    maxAssignedJobsCount,
    acceptedWeightedWorkUnits,
    maxAcceptedWeightedWorkUnits,
    isFetching,
    isUpdatingOfferMap,
    isUpdatingOfferForClaim,
    loadError,
    inflightStatusUpdates,
    alerts,
    globalState,
    timeNow,
    history,
    updateStatusForAll,
    postMortemScoringParentOfferMetadata,
    postMortemScoringParentJobMetadata,
  }) => {
    const rejectAndReloadOffers = () => {
      const rejectPromises = jobs
        .filter(job => !OfferUtils.isExclusiveOffer(job))
        .map(job => updateStatus(job, 'OPEN'));
      Promise.all(rejectPromises).then(fetchStatsAndOffers);
    };

    const showJobsPage = () => {
      history.push('/jobs');
    };

    let selfServiceOffers = [];
    let exclusiveOffers = [];
    let allSelfServiceOffersExpired = false;
    let freelancerHasMaxAssignedOffers = false;
    let disableOffers = false;

    if (forReservations) {
      selfServiceOffers = jobs.filter(job => !OfferUtils.isExclusiveOffer(job));
      exclusiveOffers = jobs.filter(OfferUtils.isExclusiveOffer);
      allSelfServiceOffersExpired =
        selfServiceOffers.length > 0 &&
        selfServiceOffers.every(job => OfferUtils.isExpired(job, timeNow));
      freelancerHasMaxAssignedOffers =
        maxAcceptedWeightedWorkUnits != null && maxAcceptedWeightedWorkUnits > 0
          ? (maxAssignedJobsCount > 0 && assignedJobsCount >= maxAssignedJobsCount) ||
            acceptedWeightedWorkUnits >= maxAcceptedWeightedWorkUnits
          : maxAssignedJobsCount > 0 && assignedJobsCount >= maxAssignedJobsCount;
      disableOffers =
        (!isFetching && (freelancerHasMaxAssignedOffers || allSelfServiceOffersExpired)) || false;
    }

    const DisableOffersOverlay = () => {
      const disabledOffersMessage = freelancerHasMaxAssignedOffers
        ? "You have plenty of work to do. Come back when you've completed some of your current jobs."
        : 'These offers have expired. Reload this page to see new offers.';
      const disabledOffersButtonText = freelancerHasMaxAssignedOffers
        ? 'Go to current jobs'
        : 'Reload page';
      const disabledOffersButtonAction = freelancerHasMaxAssignedOffers
        ? showJobsPage
        : fetchStatsAndOffers;
      const variant = freelancerHasMaxAssignedOffers ? 'ShowJobs' : 'RefreshOffers';

      return (
        <div className="disable-offers-overlay">
          <div className="disable-offers-overlay-message">
            <h3 id="DisabledOffersText">{disabledOffersMessage}</h3>
          </div>
          <Button
            id="DisabledOffersButton"
            text={disabledOffersButtonText}
            onClick={() => {
              const actionMetricsPublisher = publishCountForMethod(
                initialMetricsPublisher,
                'NoMoreOffersButtonClick'
              );
              actionMetricsPublisher.publishString('Variant', variant);
              disabledOffersButtonAction();
            }}
            variant="primary"
          />
        </div>
      );
    };

    const MoreButton = () => {
      let text = '';
      let buttonText = 'Skip All Offers';
      let buttonName = 'SkipAllOffersButton';
      let onClick;

      if (jobs.length < 1) {
        text = 'You have no job offers at this time. Check back later to find more.';
        buttonText = 'Refresh';
        buttonName = 'RefreshOffersButton';
        onClick = () => {
          const actionMetricsPublisher = publishCountForMethod(
            initialMetricsPublisher,
            'MoreOffersButtonClick'
          );
          actionMetricsPublisher.publishString('Variant', buttonName);

          fetchStatsAndOffers();
        };
      } else {
        const areNonExclusiveOffers = jobs.some(
          job => !inflightStatusUpdates.get(job.id) && !OfferUtils.isExclusiveOffer(job)
        );
        const areUpdatedOffers = jobs.some(job => inflightStatusUpdates.get(job.id));

        if (areUpdatedOffers) {
          if (!areNonExclusiveOffers) {
            buttonName = 'LoadMoreOffersButton';
            buttonText = 'Load More Offers';
          } else {
            buttonName = 'SkipRemainingOffersButton';
            buttonText = 'Skip Remaining Offers';
          }
        }
        onClick = () => {
          const actionMetricsPublisher = publishCountForMethod(
            initialMetricsPublisher,
            'MoreOffersButtonClick'
          );
          actionMetricsPublisher.publishString('Variant', buttonName);

          rejectAndReloadOffers();
        };
      }

      return (
        <div style={{ textAlign: 'center' }}>
          {text && (
            <h3 style={{ marginBottom: '15px' }} id="MoreOffersText">
              {text}
            </h3>
          )}
          <Button
            key="MoreOffersButton"
            id="MoreOffersButton"
            text={buttonText}
            variant={'normal'}
            onClick={onClick}
          />
        </div>
      );
    };

    const showExclusiveOffers = !isFetching && forReservations && exclusiveOffers.length > 0;

    return (
      <div className="awsui offers-flex-container">
        <Alerts alerts={alerts} />
        <Header
          forReservations={forReservations}
          assignedJobsCount={assignedJobsCount}
          maxAssignedJobsCount={maxAssignedJobsCount}
          acceptedWeightedWorkUnits={acceptedWeightedWorkUnits}
          maxAcceptedWeightedWorkUnits={maxAcceptedWeightedWorkUnits}
          numOffers={jobs.length}
          isFetching={isFetching}
          jobs={jobs}
          updateStatusForAll={updateStatusForAll}
          isUpdatingOfferMap={isUpdatingOfferMap}
          profile={globalState.profile}
        />
        <section id="main" className="offers-main-section">
          {showExclusiveOffers &&
            exclusiveOffers.map(job => (
              <OfferCard
                key={job.id}
                offer={job}
                updateStatus={updateStatus}
                inflightStatusUpdate={inflightStatusUpdates.get(job.id)}
                timeNow={timeNow}
                disableAllButtons={false}
                globalState={globalState}
                isUpdatingOffer={isUpdatingOfferMap.get(job.id)}
                isUpdatingOfferForClaim={isUpdatingOfferForClaim}
                segmentMetadataByJob={segmentMetadataByJob}
                postMortemScoringParentOfferMetadata={
                  postMortemScoringParentOfferMetadata &&
                  postMortemScoringParentOfferMetadata[job.id]
                }
                postMortemScoringParentJobMetadata={
                  postMortemScoringParentJobMetadata && postMortemScoringParentJobMetadata[job.id]
                }
              />
            ))}

          {disableOffers && <DisableOffersOverlay />}
          <div className={disableOffers ? 'offers-disabled-background' : ''}>
            {!forReservations && !loadError && !isFetching && jobs.length === 0 && (
              <Alert
                header="You're not working on any jobs."
                content="Check your job offers to find more."
              />
            )}
            {!isFetching &&
              (forReservations ? selfServiceOffers : jobs).map(job => (
                <OfferCard
                  key={job.id}
                  offer={job}
                  updateStatus={updateStatus}
                  inflightStatusUpdate={inflightStatusUpdates.get(job.id)}
                  timeNow={timeNow}
                  disableAllButtons={disableOffers}
                  globalState={globalState}
                  isUpdatingOffer={isUpdatingOfferMap.get(job.id)}
                  isUpdatingOfferForClaim={isUpdatingOfferForClaim}
                  segmentMetadataByJob={segmentMetadataByJob}
                  postMortemScoringParentOfferMetadata={
                    postMortemScoringParentOfferMetadata &&
                    postMortemScoringParentOfferMetadata[job.id]
                  }
                  postMortemScoringParentJobMetadata={
                    postMortemScoringParentJobMetadata && postMortemScoringParentJobMetadata[job.id]
                  }
                />
              ))}
            {!isFetching &&
              forReservations &&
              jobs &&
              !freelancerHasMaxAssignedOffers &&
              !allSelfServiceOffersExpired &&
              // no jobs, or at least 1 non-exclusive job, or all jobs have been "actioned" 💩
              (jobs.length === 0 ||
                jobs.some(job => !OfferUtils.isExclusiveOffer(job)) ||
                jobs.every(job => inflightStatusUpdates.get(job.id))) && <MoreButton />}
            {isFetching && (
              <div style={{ textAlign: 'center' }}>
                <Spinner id="fetching-spinner" size="large" />
              </div>
            )}
          </div>
        </section>
        <br />
      </div>
    );
  }
);

TranslatorJobsView.propTypes = {
  forReservations: PropTypes.bool,
  fetchStatsOffers: PropTypes.func,
  updateStatus: PropTypes.func,
  jobs: PropTypes.array,
  segmentMetadataByJob: PropTypes.object,
  assignedJobsCount: PropTypes.number,
  maxAssignedJobsCount: PropTypes.number,
  acceptedWeightedWorkUnits: PropTypes.number,
  maxAcceptedWeightedWorkUnits: PropTypes.number,
  isFetching: PropTypes.bool,
  isUpdatingOfferMap: PropTypes.object,
  isUpdatingOfferForClaim: PropTypes.bool,
  loadError: PropTypes.bool,
  inflightStatusUpdates: PropTypes.object,
  alerts: PropTypes.array,
  features: PropTypes.object,
  globalState: PropTypes.object,
  timeNow: PropTypes.number,
  history: PropTypes.object,
  updateStatusForAll: PropTypes.func,
  postMortemScoringParentOfferMetadata: PropTypes.object,
  postMortemScoringParentJobMetadata: PropTypes.object,
};

export default TranslatorJobsView;
