import React, { useEffect, useState } from 'react';
import { getYearMonthRange, QualityScore } from '../../utils/linguistFeedbackUtils';
import styled from 'react-emotion';
import ApiClient from '../../ApiClient';
import { Header, Grid, SpaceBetween, Flashbar } from '@amzn/awsui-components-react-v3/polaris';
import PropTypes from 'prop-types';

import QualityScoreResults from './QualityScoreResults';
import ErrorCategories from './ErrorCategories';

type ErrorType = string | null;

const PageWrapper = styled('div')`
  .large-margin-bottom {
    margin-bottom: 60px;
  }

  [class*='awsui_list_'] {
    justify-content: center;
  }
`;

const statusMessage = (qualityStatus: number | undefined): string => {
  if (qualityStatus) {
    switch (true) {
      case qualityStatus > 94:
        return 'You are raising the bar for quality - great job!';
      case qualityStatus > 0:
        return "Your projects will be QA'ed more frequently until the quality improves to 95% or more.";
      default:
        return "This page will update when one of your jobs has been QA'ed.";
    }
  } else {
    return "This page will update when one of your jobs has been QA'ed.";
  }
};

const LinguistFeedbackView = ({
  user,
  error,
  setError,
  linguistMessage,
  qualityScores,
  issueCounts,
}: {
  user;
  error: ErrorType;
  setError: React.Dispatch<React.SetStateAction<ErrorType>>;
  linguistMessage: string;
  qualityScores: Array<QualityScore>;
  issueCounts: Map<string, number>;
}): JSX.Element => {
  return (
    <PageWrapper>
      {error !== null ? (
        <Flashbar
          items={[
            {
              header: 'Error',
              type: 'error',
              content: error,
              onDismiss: (): void => setError(null),
              dismissible: true,
            },
          ]}
        />
      ) : null}
      <SpaceBetween size="l">
        <Header variant="h1">Feedback</Header>
        <Header id="user-message" variant="h3">
          {`Welcome back, ${user.firstName}! `}
          {linguistMessage}
        </Header>
      </SpaceBetween>
      <Grid
        gridDefinition={[{ colspan: { default: 12, m: 6 } }, { colspan: { default: 12, m: 6 } }]}
      >
        {qualityScores.length > 0 && <QualityScoreResults qualityScores={qualityScores} />}
        {issueCounts.size > 0 && <ErrorCategories issueCounts={issueCounts} />}
      </Grid>
    </PageWrapper>
  );
};

const LinguistFeedback = ({ profile }): JSX.Element => {
  const [reload, setReload] = useState(true);
  const [error, setError] = useState<ErrorType>(null);
  // qualityScores is an array of QualityScore values sorted by date in ascending order
  const [qualityScores, setQualityScores] = useState<Array<QualityScore>>([]);
  const [issueCounts, setIssueCounts] = useState<Map<string, number>>(new Map());
  const [linguistMessage, setLinguistMessage] = useState<string>('');
  // Get the year/month range
  // default is 4 month offset
  const yearMonthRange = getYearMonthRange();

  useEffect(() => {
    if (reload) {
      const fetchLinguistScores = async (
        startYear: string,
        startMonth: string,
        endYear: string,
        endMonth: string
      ): Promise<void> => {
        const url = `/scoring/getQualityScoreRange?startYear=${startYear}&startMonth=${startMonth}&endYear=${endYear}&endMonth=${endMonth}`;
        ApiClient.httpGet(url)
          .then(resp => {
            const qs = resp
              .map(val => {
                const qs = val as QualityScore;
                return qs;
              })
              .sort((a: QualityScore, b: QualityScore) => {
                if (a.year === b.year) {
                  return Number(a.month) - Number(b.month);
                } else {
                  return Number(a.year) - Number(b.year);
                }
              });
            setQualityScores(qs);
          })
          .catch(error => {
            console.log(error);
            setError('Failed to get quality score data');
          });
        return Promise.resolve();
      };
      fetchLinguistScores(
        yearMonthRange.startYear,
        yearMonthRange.startMonth,
        yearMonthRange.endYear,
        yearMonthRange.endMonth
      );
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    const combinedIssueCounts: Map<string, number> = new Map();
    qualityScores.forEach(qualityScore => {
      for (const [key, value] of Object.entries(qualityScore.issueCounts)) {
        const initialVal = combinedIssueCounts.get(key);
        combinedIssueCounts.set(key, initialVal ? initialVal + value : value);
      }
    });
    setIssueCounts(combinedIssueCounts);
    setLinguistMessage(statusMessage(qualityScores[qualityScores.length - 1]?.score));
  }, [qualityScores]);

  return LinguistFeedbackView({
    user: profile.user,
    error: error,
    setError: setError,
    linguistMessage: linguistMessage,
    qualityScores: qualityScores,
    issueCounts: issueCounts,
  });
};

LinguistFeedback.propTypes = {
  profile: PropTypes.any,
};

export default LinguistFeedback;
