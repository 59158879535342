import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withRefreshIfStale } from '@amzn/et-console-components';
import { destroySession, acceptTermsOfService, authenticate } from '../actions/Session';
import { fetchAvailableLocales } from '../actions/Locales';
import App from '../components/App';
import { getFeatures } from '../actions/Features';
import { getTranslationSkills } from '../actions/TranslationSkills';
import { getMediaTypes } from '../actions/MediaTypes';
import { getSubjects } from '../actions/Subjects';
import { loadMyProfile } from '../actions/Profile';

const mapStateToProps = state => {
  return {
    session: state.session,
    availableLocales: state.locales,
    features: state.features,
    translationSkills: state.translationSkills,
    mediaTypes: state.mediaTypes,
    subjects: state.subjects,
    profile: state.profile,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    authenticate: () => {
      dispatch(authenticate());
    },

    onSignOut: () => {
      dispatch(destroySession());
    },

    fetchAvailableLocales: () => {
      dispatch(fetchAvailableLocales());
    },

    onGetFeatures: () => {
      return dispatch(getFeatures());
    },

    onGetTranslationSkills: () => {
      return dispatch(getTranslationSkills());
    },

    onGetMediaTypes: () => {
      return dispatch(getMediaTypes());
    },

    onGetSubjects: () => {
      return dispatch(getSubjects());
    },

    onAcceptTermsOfService: () => {
      dispatch(acceptTermsOfService());
    },

    onLoadMyProfile: () => {
      return dispatch(loadMyProfile());
    },
  };
};

export default withRouter(withRefreshIfStale(connect(mapStateToProps, mapDispatchToProps)(App)));
