import { css } from '@emotion/core';
import React from 'react';
import { globe } from 'react-icons-kit/entypo/globe';
import SvgIcon from 'react-icons-kit/SvgIcon';
import tinycolor2 from 'tinycolor2';

const size = 24;
const brandBorderFocus = tinycolor2('#f4d078')
  .spin(-12)
  .saturate(18)
  .toString();
const brandBackground = '#ec7211';
const brandBackgroundSuperDark = tinycolor2('#f4d078')
  .darken(60)
  .toString();

const Logo = () => (
  <span
    css={css`
      & svg {
        width: ${size}px;
        height: ${size}px;
        filter: drop-shadow(0 0 3px ${brandBorderFocus});
        background: linear-gradient(to bottom, ${brandBackground} 0%, ${brandBorderFocus} 100%);
        border-radius: 32px;
      }
      & path {
        fill: ${brandBackgroundSuperDark};
      }
    `}
  >
    <SvgIcon icon={globe} size={size} />
  </span>
);

export default Logo;
