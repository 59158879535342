import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import MediaTypeSubjectSettingsActions from '../../actions/MediaTypeSubjectSettingsActions';
import GenericNextTokenCrudActions from '../../actions/GenericNextTokenCrudActions';
import MediaTypeSubjectView from '../../components/Settings/MediaTypeSubject/MediaTypeSubjectView';

const mediaTypeSubjectSettingsActions = new MediaTypeSubjectSettingsActions();
const pricingActions = new GenericNextTokenCrudActions('priceSchemes', 'id');
const weightingActions = new GenericNextTokenCrudActions('weightSchemes', 'id');

const mapStateToProps = state => {
  return {
    session: state.session,
    priceSchemes: state.priceSchemes,
    weightSchemes: state.weightSchemes,
    translationSkills: state.translationSkills,
    mediaTypes: state.mediaTypes,
    subjects: state.subjects,
    mediaTypeSubjectSettings: state.mediaTypeSubjectSettings,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onReloadPriceSchemes: () => {
      return dispatch(pricingActions.resetAndLoadAll(true));
    },
    onReloadWeightSchemes: () => {
      return dispatch(weightingActions.resetAndLoadAll(true));
    },
    onLoadAll: () => {
      return dispatch(mediaTypeSubjectSettingsActions.loadAll());
    },
    onCreateOrUpdateEvent: (mediaType, mediaTypeSubjectSettings) => {
      return dispatch(
        mediaTypeSubjectSettingsActions.createOrUpdate(mediaType, mediaTypeSubjectSettings)
      );
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MediaTypeSubjectView));
