import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  DateRangePicker,
  Flashbar,
  Header,
  PropertyFilter,
  Pagination,
  SpaceBetween,
  Table,
} from '@amzn/awsui-components-react-v3/polaris';
import ApiClient from '../../ApiClient';
import {
  convertDate,
  convertDateTime,
  convertLanguagePair,
  convertPrice,
  readableLookup,
  readableList,
  valueOrDash,
  keyLookupFromReadable,
} from '../../utils/ReadableConverter';
import moment from 'moment';
import { OfferUtils } from '../../utils/offerUtils';
import { addHours, addSeconds, addMinutes, addWeeks, addMonths, addYears, addDays } from 'date-fns';

const PAGE_SIZE = 25;
const columnIdMap = {
  wordCount: 'workUnits',
  weightedWordCount: 'weightedWorkUnits',
  language: 'sourceLocale',
  skill: 'translationSkill',
};

function convertToAbsoluteRange(range) {
  if (range.type === 'absolute') {
    return {
      start: new Date(range.startDate).toISOString(),
      end: new Date(range.endDate).toISOString(),
    };
  } else {
    const now = new Date();
    const start = (() => {
      switch (range.unit) {
        case 'second':
          return addSeconds(now, -range.amount);
        case 'minute':
          return addMinutes(now, -range.amount);
        case 'hour':
          return addHours(now, -range.amount);
        case 'day':
          return addDays(now, -range.amount);
        case 'week':
          return addWeeks(now, -range.amount);
        case 'month':
          return addMonths(now, -range.amount);
        case 'year':
          return addYears(now, -range.amount);
      }
    })();
    return {
      start: start.toISOString(),
      end: now.toISOString(),
    };
  }
}

function formatRelativeRange(range) {
  const unit = range.amount === 1 ? range.unit : `${range.unit}s`;
  return `Last ${range.amount} ${unit}`;
}

const isValidRangeFunction = range => {
  if (range.type === 'absolute') {
    const [startDateWithoutTime] = range.startDate.split('T');
    const [endDateWithoutTime] = range.endDate.split('T');

    if (!startDateWithoutTime || !endDateWithoutTime) {
      return {
        valid: false,
        errorMessage:
          'The selected date range is incomplete. Select a start and end date for the date range.',
      };
    }
  } else if (range.type === 'relative') {
    if (isNaN(range.amount)) {
      return {
        valid: false,
        errorMessage:
          'The selected date range is incomplete. Specify a duration for the date range.',
      };
    }
  }
  return { valid: true };
};

const getFilterTextUrl = (filterText, completedDateRangeFilterText) => {
  let text = ``;
  if (filterText) {
    text += `&filterText=${encodeURIComponent(filterText)}`;
    if (completedDateRangeFilterText) {
      text += `|${encodeURIComponent(completedDateRangeFilterText)}`;
    }
  } else if (completedDateRangeFilterText) {
    text += `&filterText=${encodeURIComponent(completedDateRangeFilterText)}`;
  }
  return text;
};

const downloadOffers = (
  setDownloading,
  setFlashBarItems,
  filterText,
  completedDateRangeFilterText,
  sort
) => {
  setDownloading(true);
  let url = `/me/offer/export?status=COMPLETED`;
  url += getFilterTextUrl(filterText, completedDateRangeFilterText);
  if (sort) {
    const sortColumn = columnIdMap[sort.columnId] || sort.columnId;
    url += `&sortColumn=${sortColumn}&sortDescending=${sort.descending === true}`;
  }

  return ApiClient.download(url)
    .catch(error => {
      console.error(error);
      setFlashBarItems([
        {
          content: error.details?.errors?.[0]?.message || 'Something went wrong',
          type: 'error',
          header: 'Problem downloading offers',
          dismissible: true,
          dismissLabel: 'Dismiss',
          onDismiss: () => setFlashBarItems([]),
        },
      ]);
    })
    .finally(() => setDownloading(false));
};

const fetchOffers = (
  page,
  setLoading,
  setFlashBarItems,
  setOffers,
  setTotalPages,
  filterText,
  completedDateRangeFilterText,
  sort
) => () => {
  setLoading(true);
  let url = `/v2/me/offer?status=COMPLETED&pageNum=${page - 1}&pageSize=${PAGE_SIZE}`;
  url += getFilterTextUrl(filterText, completedDateRangeFilterText);

  if (sort) {
    const sortColumn = columnIdMap[sort.columnId] || sort.columnId;
    url += `&sortColumn=${sortColumn}&sortDescending=${sort.descending === true}`;
  }
  ApiClient.httpGet(url)
    .then(resp => {
      setOffers(resp.offers);
      setTotalPages(resp.totalPages);
    })
    .catch(error => {
      const loadFailedMessage = error.message || 'Something went wrong';
      setFlashBarItems([
        {
          content: 'Reload the page to try again, if the problem persists please contact support.',
          type: 'error',
          header: loadFailedMessage,
          dismissible: true,
          dismissLabel: 'Dismiss',
          onDismiss: () => setFlashBarItems([]),
        },
      ]);
    })
    .finally(() => setLoading(false));
};

const rightAlignedCell = content => <div style={{ textAlign: 'right' }}>{content}</div>;

const dateCell = (date, showTime) => (
  <div title={date && moment(date).fromNow()} style={(date && { cursor: 'help' }) || {}}>
    {showTime ? convertDateTime(date) : convertDate(date)}
  </div>
);

// exporting so we can use in tests (e.g. know if a column should be sortable or not)
export const columnDefinitionsFunc = globalState => [
  {
    id: 'dateCompleted',
    header: 'Completed Date',
    cell: item => dateCell(item.dateCompleted, true),
    sortingField: 'dateCompleted',
  },
  {
    id: 'jobPartId',
    header: 'Job Part ID',
    cell: item => valueOrDash(OfferUtils.getJobPartId(item)),
  },
  {
    id: 'name',
    header: 'Name',
    cell: item => valueOrDash(item.name),
    sortingField: 'name',
  },
  {
    id: 'clientUri',
    header: 'Client',
    cell: item => valueOrDash(item.clientName),
    sortingField: 'clientUri',
  },
  {
    id: 'wordCount',
    header: 'Raw Words',
    cell: item => rightAlignedCell(valueOrDash(item.workUnits)),
    sortingField: 'wordCount',
  },
  {
    id: 'weightedWordCount',
    header: 'Weighted Words',
    cell: item => rightAlignedCell(Math.round(item.weightedWorkUnits) || '-'),
    sortingField: 'weightedWordCount',
  },
  {
    id: 'price',
    header: 'Price',
    cell: item => convertPrice(item.price, item.currency),
    sortingField: 'price',
  },
  {
    id: 'language',
    header: 'Language',
    cell: item => convertLanguagePair(item.sourceLocale, item.targetLocale),
    sortingField: 'language',
  },
  {
    id: 'skill',
    header: 'Skill',
    cell: item => readableLookup(item.translationSkill, globalState, 'translationSkills'),
    sortingField: 'skill',
  },
];

export const OffersHistoryView = ({
  loading,
  downloading,
  flashBarItems,
  offers,
  page,
  setPage,
  setFilterText,
  setCompletedDateRangeFilterText,
  doDownloadOffers,
  totalPages,
  globalState,
  setSort,
}) => {
  const columnDefinitions = columnDefinitionsFunc(globalState);
  const empty = <div>No jobs found.</div>;
  const emptyWithNoDateFilter = (
    <div>
      No jobs found.{' '}
      <p>
        <i>Hint: A date range is required.</i>
      </p>
    </div>
  );

  const [propertyFilterQuery, setPropertyFilterQuery] = useState({
    tokens: [],
    operation: 'and',
  });
  const [completedDateRangeFilter, setCompletedDateRangeFilter] = useState({
    type: 'relative',
    key: 'one-month',
    amount: 1,
    unit: 'month',
  });
  const [sortingColumn, setSortingColumn] = useState(null);
  const [sortingDescending, setSortingDescending] = useState(false);
  const [downloadButtonDisabled, setDownloadButtonDisabled] = useState(true);

  const onPaginationChange = event => {
    setPage(event.detail.currentPageIndex);
  };

  const onPropertyFilteringChange = detail => {
    // For the 'anything' filter, force it to search in the name
    detail.tokens.forEach(token => {
      if (token.propertyKey == null) {
        token.propertyKey = 'name';
      }
    });
    setPropertyFilterQuery(detail);
    const addToMapOfArrays = (map, key, value) => {
      key in map || (map[key] = []);
      map[key].push(value);
    };

    const filterMap = {};
    detail.tokens.forEach(token => {
      const key = token.propertyKey;
      let value = token.value;

      if (token.isFreeText && !key) {
        // User typed in text without selecting attribute, filter on name in this case
        addToMapOfArrays(filterMap, 'name', value);
      } else if (key && value) {
        if (key === 'translationSkill') {
          value = keyLookupFromReadable(value, globalState, 'translationSkills');
        } else if (key === 'clientUri') {
          value = 'web:clientName:' + value;
        }
        addToMapOfArrays(filterMap, key, value);
      }
    });

    const filterArray = [];
    for (const key in filterMap) {
      filterArray.push(`${key}=${filterMap[key].join(' ')}`);
    }

    setFilterText(filterArray.join('|'));
  };

  const onCompletedDateRangeFilteringChange = range => {
    if (range == null) {
      setDownloadButtonDisabled(true);
      setCompletedDateRangeFilterText(null);
      return;
    }
    const completedDateRange = convertToAbsoluteRange(range);
    const differenceInDays =
      (new Date(completedDateRange.end) - new Date(completedDateRange.start)) / (1000 * 3600 * 24);
    // disable the export button when, complete date range is more than a month
    if (differenceInDays > 32) {
      setDownloadButtonDisabled(true);
    } else {
      setDownloadButtonDisabled(false);
    }
    const completedDateFilterText =
      'startDateCompleted=' +
      completedDateRange.start +
      '|endDateCompleted=' +
      completedDateRange.end;
    setCompletedDateRangeFilterText(completedDateFilterText);
  };

  const onSortingChange = event => {
    //this sort property is used by fetch offers
    setSort({
      columnId: event.detail.sortingColumn.id,
      descending: event.detail.isDescending,
    });
    //these properties are used by
    setSortingColumn(
      columnDefinitions.find(element => element.id === event.detail.sortingColumn.id)
    );
    setSortingDescending(event.detail.isDescending);
  };

  const prepareFilteringOptions = () => {
    // only Translation Skills have options
    const filteringOptions = [];
    const translationSkills = readableList(globalState, 'translationSkills');
    for (const translationSkill of translationSkills) {
      filteringOptions.push({
        propertyKey: 'translationSkill',
        value: translationSkill,
      });
    }
    return filteringOptions;
  };

  useEffect(() => {
    onCompletedDateRangeFilteringChange(completedDateRangeFilter);
  }, [completedDateRangeFilter]);

  return (
    <>
      <Flashbar items={flashBarItems} />
      <header id="header">
        <h1 style={{ marginBottom: 15, padding: 0 }}>Job history</h1>
      </header>
      <Table
        loadingText="Loading job history"
        columnDefinitions={columnDefinitions}
        loading={loading}
        items={offers}
        wrapLines={false}
        header={
          <Header
            variant="h2"
            actions={
              <SpaceBetween size="xs" direction="horizontal">
                <Button
                  loading={downloading}
                  onClick={doDownloadOffers}
                  disabled={downloadButtonDisabled}
                  id="exportButton"
                >
                  Export
                </Button>
              </SpaceBetween>
            }
          >
            Jobs
          </Header>
        }
        stickyHeader={true}
        empty={completedDateRangeFilter ? empty : emptyWithNoDateFilter}
        onSortingChange={onSortingChange}
        sortingColumn={sortingColumn}
        sortingDescending={sortingDescending}
        pagination={
          <Pagination
            currentPageIndex={page}
            pagesCount={totalPages}
            onChange={onPaginationChange}
            ariaLabels={{
              nextPageLabel: 'Next page',
              previousPageLabel: 'Previous page',
              pageLabel: pageNumber => `Page ${pageNumber} of all pages`,
            }}
          />
        }
        filter={
          <div className="filter-container">
            <div className="input-filter">
              <PropertyFilter
                hideOperations={true}
                onChange={({ detail }) => onPropertyFilteringChange(detail)}
                query={propertyFilterQuery}
                i18nStrings={{
                  filteringAriaLabel: 'your choice',
                  dismissAriaLabel: 'Dismiss',
                  filteringPlaceholder: 'Filter jobs',
                  groupValuesText: 'Values',
                  groupPropertiesText: 'Properties',
                  operatorsText: 'Operators',
                  operationAndText: 'and',
                  operationOrText: 'or',
                  operatorContainsText: 'Contains',
                  operatorDoesNotContainText: 'Does not contain',
                  operatorEqualsText: 'Equals',
                  operatorDoesNotEqualText: 'Does not equal',
                  editTokenHeader: 'Edit filter',
                  propertyText: 'Property',
                  operatorText: 'Operator',
                  valueText: 'Value',
                  cancelActionText: 'Cancel',
                  applyActionText: 'Apply',
                  allPropertiesLabel: 'All properties',
                  tokenLimitShowMore: 'Show more',
                  tokenLimitShowFewer: 'Show fewer',
                  clearFiltersText: 'Clear filters',
                  removeTokenButtonAriaLabel: () => 'Remove token',
                  enteredTextLabel: text => `Use: "${text}"`,
                }}
                filteringOptions={prepareFilteringOptions()}
                filteringProperties={[
                  {
                    key: 'name',
                    operators: ['='],
                    propertyLabel: 'Name',
                    groupValuesLabel: 'Name Values',
                  },
                  {
                    key: 'translationSkill',
                    operators: ['='],
                    propertyLabel: 'Skill',
                    groupValuesLabel: 'Skill Values',
                  },
                  {
                    key: 'clientUri',
                    operators: ['='],
                    propertyLabel: 'Client',
                    groupValuesLabel: 'Client Values',
                  },
                ]}
              />
            </div>

            <DateRangePicker
              i18nStrings={{
                todayAriaLabel: 'Today',
                nextMonthAriaLabel: 'Next month',
                previousMonthAriaLabel: 'Previous month',
                customRelativeRangeDurationLabel: 'Duration',
                customRelativeRangeDurationPlaceholder: 'Enter duration',
                customRelativeRangeOptionLabel: 'Custom duration',
                customRelativeRangeOptionDescription: 'Set a custom range in the past',
                customRelativeRangeUnitLabel: 'Unit of time',
                formatUnit: (unit, value) => (value === 1 ? unit : `${unit}s`),
                formatRelativeRange: formatRelativeRange,
                dateTimeConstraintText: '',
                relativeModeTitle: 'Relative range',
                absoluteModeTitle: 'Absolute range',
                relativeRangeSelectionHeading: 'Choose a range',
                startDateLabel: 'Start date',
                endDateLabel: 'End date',
                startTimeLabel: 'Start time',
                endTimeLabel: 'End time',
                clearButtonLabel: 'Clear',
                cancelButtonLabel: 'Cancel',
                applyButtonLabel: 'Apply',
              }}
              value={completedDateRangeFilter}
              onChange={e => setCompletedDateRangeFilter(e.detail.value)}
              placeholder="Filter by Completed date and time range"
              className="date-range-filter"
              isValidRange={isValidRangeFunction}
              relativeOptions={[
                {
                  type: 'relative',
                  amount: 1,
                  unit: 'month',
                  key: 'one-month',
                },
                {
                  type: 'relative',
                  amount: 6,
                  unit: 'month',
                  key: 'six-months',
                },
                {
                  type: 'relative',
                  amount: 1,
                  unit: 'year',
                  key: 'one-year',
                },
              ]}
            />
          </div>
        }
      ></Table>
    </>
  );
};

OffersHistoryView.propTypes = {
  loading: PropTypes.bool,
  downloading: PropTypes.bool,
  flashBarItems: PropTypes.array,
  offers: PropTypes.array,
  page: PropTypes.number,
  setPage: PropTypes.func,
  totalPages: PropTypes.number,
  globalState: PropTypes.object,
  setFilterText: PropTypes.func,
  setCompletedDateRangeFilterText: PropTypes.func,
  doDownloadOffers: PropTypes.func,
  setSort: PropTypes.func,
};

const HistoryOffers = ({ globalState }) => {
  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [flashBarItems, setFlashBarItems] = useState([]);
  const [page, setPage] = useState(1);
  const [offers, setOffers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [filterText, setFilterText] = useState(null);
  const [completedDateRangeFilterText, setCompletedDateRangeFilterText] = useState(null);
  const [sort, setSort] = useState({
    columnId: 'dateCompleted',
    descending: true,
  });

  const doFetchOffers = fetchOffers(
    page,
    setLoading,
    setFlashBarItems,
    setOffers,
    setTotalPages,
    filterText,
    completedDateRangeFilterText,
    sort
  );
  const doDownloadOffers = useCallback(() => {
    downloadOffers(
      setDownloading,
      setFlashBarItems,
      filterText,
      completedDateRangeFilterText,
      sort
    );
  }, [filterText, completedDateRangeFilterText, sort]);

  useEffect(() => {
    if (completedDateRangeFilterText) {
      doFetchOffers();
    } else {
      setOffers([]);
    }
  }, [page, filterText, sort, completedDateRangeFilterText, setOffers]);

  return OffersHistoryView({
    loading,
    downloading,
    flashBarItems,
    offers,
    page,
    setPage,
    setFilterText,
    setCompletedDateRangeFilterText,
    doDownloadOffers,
    totalPages,
    globalState,
    setSort,
  });
};

export default HistoryOffers;
