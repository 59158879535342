import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Box,
  Flashbar,
  FormField,
  Header,
  SpaceBetween,
  Modal,
  Cards,
} from '@amzn/awsui-components-react-v3/polaris';
import ApiClient from '../../../ApiClient';

interface ErrorInfo {
  header: string;
  message: string;
}

export const AutoReassignment = ({ apiClient }) => {
  const [flashBarItems] = useState([]);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<ErrorInfo | null>(null);
  const [planFile, setPlanFile] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState('');
  const [downloading, setDownloading] = useState(false);

  const handleSubmit = async (): Promise<void> => {
    setError(null);
    setIsSubmitting(true);
    await apiClient
      .multipartUpload('/offerAutoReassignment/update', { file: planFile })
      .then(response => {
        setSuccessAlert(
          `Configuration updated: ${response['updates']} items updated, ${response['deletes']} deleted`
        );
        setShowSuccessAlert(true);
      })
      .catch(error => {
        setError({
          header: 'Error uploading file',
          message: `There was a problem uploading the file: ${error.details.errors[0].message}`,
        });
      })
      .finally(() => {
        setShowUploadFileModal(false);
        setIsSubmitting(false);
      });
  };

  const handleFileChange = event => {
    setPlanFile(event.target.files[0]);
  };

  const downloadConfig = async () => {
    setError(null);
    setDownloading(true);
    await apiClient
      .download('/offerAutoReassignment/latest', {}, 'autoReassignment.csv')
      .catch(error => {
        setError({
          header: 'Error downloading file',
          message: `There was a problem downloading the file: ${error.details?.errors[0]?.message ||
            'Error'}`,
        });
      })
      .finally(() => setDownloading(false));
  };

  return (
    <>
      <Modal
        id="uploadPlansFileModal"
        onDismiss={() => {
          setShowUploadFileModal(false);
        }}
        visible={showUploadFileModal}
        closeAriaLabel="Close modal"
        size="medium"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                id="cancelButton"
                variant="link"
                onClick={() => {
                  setShowUploadFileModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                id={'submitButton'}
                variant="primary"
                disabled={planFile == null || isSubmitting}
                onClick={() => handleSubmit()}
                loading={isSubmitting}
              >
                Submit
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={<Header variant="h2">Upload an Auto Reassignment Configuration file</Header>}
      >
        <FormField>
          <input type="file" name="planFile" accept=".csv" onChange={handleFileChange} />
        </FormField>
      </Modal>
      <SpaceBetween size="m" direction="vertical">
        {showSuccessAlert && (
          <Flashbar
            id={'successAlert'}
            items={[
              {
                type: 'success',
                content: successAlert,
                dismissible: true,
                onDismiss: () => setShowSuccessAlert(false),
              },
            ]}
          />
        )}
        <Flashbar items={flashBarItems} />
        {error != null && (
          <Flashbar
            id={'errorFlashbar'}
            items={[
              {
                header: error.header,
                type: 'error',
                content: error.message,
                dismissible: true,
                onDismiss: () => setError(null),
              },
            ]}
          />
        )}
      </SpaceBetween>
      <header id="header">
        <h1 style={{ marginBottom: 15, padding: 0 }}>Configure Dynamic Assignment</h1>
      </header>
      <Cards
        cardDefinition={{
          header: item => item.header,
          sections: [
            {
              content: item => item.description,
            },
            {
              content: item => item.content,
            },
          ],
        }}
        cardsPerRow={[{ cards: 1 }, { minWidth: 500, cards: 2 }]}
        items={[
          {
            header: 'Step 1: Download',
            description: (
              <>
                Download the current configuration file to see the current auto-assignment settings
                for specific subject/media type/skill/source/target/client combinations.
                <br />
                For more information{' '}
                <a
                  href="https://w.amazon.com/bin/view/TranslationTeam/SDPillar/ProjectManagement/ATMSTraport/Dynamic_Assignments/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  click here
                </a>
                .
                <br />
              </>
            ),
            content: (
              <>
                <Button
                  id="downloadButton"
                  variant="primary"
                  loading={downloading}
                  iconName="download"
                  onClick={() => downloadConfig()}
                >
                  Download config
                </Button>
              </>
            ),
          },
          {
            header: 'Step 2: Upload',
            description:
              'Upload an edited configuration file with actions (update/pause/resume/delete) to update the auto-assignment settings for specific subject/media type/skill/source/target/client combinations.',
            content: (
              <>
                <Button
                  id="replacePlanButton"
                  variant="primary"
                  iconName="upload"
                  onClick={() => setShowUploadFileModal(true)}
                >
                  Upload config
                </Button>
              </>
            ),
          },
        ]}
      />
    </>
  );
};

AutoReassignment.propTypes = {
  apiClient: PropTypes.object,
};

const AutoReassignmentView = () => {
  return AutoReassignment({
    apiClient: ApiClient,
  });
};

export default AutoReassignmentView;
