import ApiClient from '../ApiClient';

const ACTION_TYPES = {
  GET_TRANSLATION_SKILLS: 'GET_TRANSLATION_SKILLS',
  FINISHED_GET_TRANSLATION_SKILLS: 'FINISHED_GET_TRANSLATION_SKILLS',
  FAILED_GET_TRANSLATION_SKILLS: 'FAILED_GET_TRANSLATION_SKILLS',
};

function getTranslationSkillsEvent() {
  return {
    type: ACTION_TYPES.GET_TRANSLATION_SKILLS,
  };
}

function finishedGetTranslationSkillsEvent(resp) {
  return {
    type: ACTION_TYPES.FINISHED_GET_TRANSLATION_SKILLS,
    translationSkills: resp,
  };
}

function failedGetTranslationSkillsEvent(error) {
  return {
    type: ACTION_TYPES.FAILED_GET_TRANSLATION_SKILLS,
    error,
  };
}

export function getTranslationSkills() {
  return dispatch => {
    dispatch(getTranslationSkillsEvent());
    return ApiClient.httpGet(`/translationSkills`)
      .then(resp => {
        resp.constructor === Object
          ? dispatch(finishedGetTranslationSkillsEvent(resp))
          : dispatch(failedGetTranslationSkillsEvent(resp));
      })
      .catch(err => {
        dispatch(failedGetTranslationSkillsEvent(err));
      });
  };
}

export default ACTION_TYPES;
