import ActionTypes from '../actions/Translators';
import { PAGE_SIZE } from '../actions/Translators';
import { SortOrder } from '../utils/Enums';

const translators = (state = translators.initialState, action) => {
  switch (action.type) {
    case ActionTypes.TRANSLATOR_PAGE_RESET:
      return {
        ...state,
        pagesRequested: 1,
        fullyLoaded: false,
        searchPattern: action.clearSearch ? '' : state.searchPattern,
      };
    case ActionTypes.TRANSLATOR_RELOAD_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case ActionTypes.TRANSLATOR_NEXT_PAGE_REQUEST:
      return {
        ...state,
        pagesRequested: state.pagesRequested + 1,
        isFetching: true,
      };
    case ActionTypes.TRANSLATOR_RELOAD_RECEIVE:
      return {
        ...state,
        isFetching: false,
        items: action.translators,
        lastUpdated: action.receivedAt,
        lastError: null,
      };
    case ActionTypes.TRANSLATOR_NEXT_PAGE_RECEIVE:
      return {
        ...state,
        isFetching: false,
        fullyLoaded: action.translators.length < PAGE_SIZE,
        // append new page of items to existing list.
        items: [...state.items, ...action.translators],
        lastUpdated: action.receivedAt,
        lastError: null,
      };
    case ActionTypes.TRANSLATOR_RELOAD_RECEIVE_FAILURE:
    case ActionTypes.TRANSLATOR_NEXT_PAGE_RECEIVE_FAILURE:
      return {
        ...state,
        isFetching: false,
        items: [],
        lastUpdated: action.receivedAt,
        lastError: action.error,
      };
    case ActionTypes.TRANSLATOR_CREATED:
      return {
        ...state,
        items: [...state.items, action.translator],
      };
    case ActionTypes.TRANSLATOR_GRID_SORT_CHANGED:
      return {
        ...state,
        sortOrder: action.order,
        sortField: action.field,
      };
    case ActionTypes.TRANSLATOR_SEARCH:
      return {
        ...state,
        searchPattern: action.searchPattern,
      };
    case ActionTypes.PROFILE_RELOAD_RECEIVE:
      return {
        ...state,
        profile: action.profile,
      };
    default:
      return state;
  }
};

translators.initialState = {
  pagesRequested: 0,
  sortField: null,
  sortOrder: SortOrder.UNSORTED,
  isFetching: false,
  fullyLoaded: false,
  lastUpdated: null,
  lastError: null,
  items: [],
  searchPattern: '',
  profile: {},
};

export default translators;
