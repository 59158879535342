import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Header,
  TextContent,
  Tabs,
  Spinner,
  Flashbar,
} from '@amzn/awsui-components-react-v3/polaris';
import Planning from './Planning';
import EstimatedCapacity from './EstimatedCapacity';
import { convertDateTimePretty } from '../../utils/ReadableConverter';
import ApiClient from '../../ApiClient';

const timestampOrDash = timestamp => {
  return timestamp ? convertDateTimePretty(timestamp) : '-';
};

export const CapacityManagementView = ({
  globalState,
  isLoadingTS,
  isLoadingCR,
  plansUpdatedTS,
  projectPlanID,
  capacityReport,
  setLoadCapacityReport,
  setCrIdToBeFetched,
  setLoadProjectPlan,
  error,
}) => {
  return (
    <>
      {error !== null ? (
        <Flashbar
          items={[
            {
              header: 'Error',
              type: 'error',
              content: error,
              dismissible: true,
            },
          ]}
        />
      ) : null}
      <Header variant="h1">Capacity Management</Header>
      <TextContent>
        <p>
          {isLoadingTS || isLoadingCR ? (
            <Spinner />
          ) : (
            <small>
              &emsp; Plans Last updated on: {timestampOrDash(plansUpdatedTS)} <br />
              &emsp; Capacity Last updated on:{' '}
              {timestampOrDash(capacityReport?.lastUpdatedTimestamp)}
            </small>
          )}
        </p>
      </TextContent>

      <Tabs
        tabs={[
          {
            label: 'Estimated Capacity',
            id: 'capacity',
            content: (
              <EstimatedCapacity
                isLoading={isLoadingCR}
                capacityReport={capacityReport}
                globalState={globalState}
              />
            ),
          },
          {
            label: 'Planning',
            id: 'planning',
            content: (
              <Planning
                globalState={globalState}
                loading={isLoadingCR}
                capacityReport={capacityReport}
                projectPlanID={projectPlanID}
                reloadCapacityReport={setLoadCapacityReport}
                setCrIdToBeFetched={setCrIdToBeFetched}
                reloadProjectPlan={setLoadProjectPlan}
                plansUpdatedTS={plansUpdatedTS}
                loadingTimestamp={isLoadingTS}
              />
            ),
          },
        ]}
      />
    </>
  );
};

const fetchLatestProjectPlan = (setLoadingTS, setPlansUpdatedTS, setError, setPlansUpdatedId) => {
  setLoadingTS(true);
  const url = '/projectPlans/latest';

  ApiClient.httpGet(url)
    .then(resp => {
      setPlansUpdatedTS(resp.processedTimestamp);
      setPlansUpdatedId(resp.id);
    })
    .catch(error => {
      setError(error.details.errors[0].message || 'Error fetching latest update timestamp');
    })
    .finally(() => setLoadingTS(false));
};

const fetchCapacityReport = (
  setLoadingCR,
  setCapacityReport,
  crIdToBeFetched,
  setCrIdToBeFetched,
  setError,
  retried = 0
) => {
  setLoadingCR(true);
  let url = 'capacityReport/latest';
  if (crIdToBeFetched !== 'latest') {
    url = 'capacityReports/' + crIdToBeFetched;
  }

  ApiClient.httpGet(url)
    .then(resp => {
      if (resp.reportStatus == 'In-Progress' && retried < 2) {
        setTimeout(() => {
          fetchCapacityReport(
            setLoadingCR,
            setCapacityReport,
            crIdToBeFetched,
            setCrIdToBeFetched,
            setError,
            retried + 1
          );
        }, 5000);
      } else {
        setCapacityReport(resp);
        setLoadingCR(false);
      }
    })
    .catch(error => {
      setError(error.details.errors[0].message || 'Something went wrong');
      setLoadingCR(false);
    })
    .finally(() => {
      setCrIdToBeFetched('latest');
    });
};

CapacityManagementView.propTypes = {
  globalState: PropTypes.object,
  isLoadingTS: PropTypes.bool,
  isLoadingCR: PropTypes.bool,
  plansUpdatedTS: PropTypes.string,
  projectPlanID: PropTypes.string,
  capacityReport: PropTypes.object,
  setLoadCapacityReport: PropTypes.func,
  setCrIdToBeFetched: PropTypes.func,
  setLoadProjectPlan: PropTypes.func,
  error: PropTypes.string,
};

const CapacityManagement = ({ globalState }) => {
  const [isLoadingTS, setLoadingTS] = useState(false);
  const [isLoadingCR, setLoadingCR] = useState(false);
  const [plansUpdatedTS, setPlansUpdatedTS] = useState(null);
  const [projectPlanID, setProjectPlanID] = useState('');
  const [error, setError] = useState(null);
  const [capacityReport, setCapacityReport] = useState(null);
  const [loadCapacityReport, setLoadCapacityReport] = useState(true);
  const [crIdToBeFetched, setCrIdToBeFetched] = useState('latest');
  const [loadProjectPlan, setLoadProjectPlan] = useState(true);

  useEffect(() => {
    if (!isLoadingTS && loadProjectPlan) {
      fetchLatestProjectPlan(setLoadingTS, setPlansUpdatedTS, setError, setProjectPlanID);
      setLoadProjectPlan(false);
    }
  }, [loadProjectPlan]);

  useEffect(() => {
    if (!isLoadingCR && loadCapacityReport) {
      fetchCapacityReport(
        setLoadingCR,
        setCapacityReport,
        crIdToBeFetched,
        setCrIdToBeFetched,
        setError
      );
      setLoadCapacityReport(false);
    }
  }, [loadCapacityReport]);

  return CapacityManagementView({
    globalState,
    isLoadingTS,
    isLoadingCR,
    plansUpdatedTS,
    projectPlanID,
    capacityReport,
    setLoadCapacityReport,
    setCrIdToBeFetched,
    setLoadProjectPlan,
    error,
  });
};

export default CapacityManagement;
