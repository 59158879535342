import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { css, cx } from 'react-emotion';

import InfiniteScroll from './InfiniteScroll.js';
import Spinner from './Spinner';
import SortableColumnHeaders from './SortableColumnHeaders';
import { SortOrder } from '../utils/Enums';
import ValidatedRow from './ValidatedRow';

/**
 * A react-bootstrap Table that supports:
 *
 *  * Sorting
 *  * Row validation
 *  * Infinite scroll
 */
class Grid extends React.Component {
  static propTypes = {
    getId: PropTypes.func,
    sortField: PropTypes.string,
    sortOrder: PropTypes.number,
    onSortChange: PropTypes.func,
    onLoadMore: PropTypes.func,
    scrollable: PropTypes.bool,
    isFetching: PropTypes.bool,
    fullyLoaded: PropTypes.bool,
    columns: PropTypes.array.isRequired,
    rowClassNames: PropTypes.array,
    items: PropTypes.array.isRequired,
    renderRow: PropTypes.func.isRequired,
    validateRow: PropTypes.func.isRequired,
  };

  static defaultProps = {
    getId: (item, _) => item['id'],
    onSortChange: Function.prototype,
    onLoadMore: Function.prototype,
    sortOrder: SortOrder.UNSORTED,
    rowClassNames: [],
    validateRow: () => ({ valid: true }),
  };

  render() {
    const {
      columns,
      getId,
      sortField,
      sortOrder,
      onSortChange,
      scrollable,
      items,
      renderRow,
      isFetching,
      fullyLoaded,
      rowClassNames,
      validateRow,
    } = this.props;

    return (
      <>
        {scrollable && (
          <InfiniteScroll
            onInfiniteScroll={this.props.onLoadMore}
            isLoading={isFetching}
            fullyLoaded={fullyLoaded}
            fireOnChange={items.length}
          />
        )}

        <Table striped condensed hover>
          <SortableColumnHeaders
            scrollable={scrollable}
            columns={columns}
            sortField={sortField}
            sortOrder={sortOrder}
            onSortChanged={onSortChange}
          />
          <tbody>
            {items.map((item, index) => (
              <ValidatedRow
                key={getId(item, index)}
                classNames={rowClassNames}
                validationResult={validateRow(item, index)}
              >
                {renderRow(item, index)}
              </ValidatedRow>
            ))}
          </tbody>
        </Table>
        {isFetching && (
          <div
            className={cx(
              css`
                text-align: center;
              `,
              items.length === 0 && css(`padding-top: 2em`)
            )}
          >
            <Spinner size={30} />
          </div>
        )}
      </>
    );
  }
}

export default Grid;
