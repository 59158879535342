import React from 'react';
import { render } from 'react-dom';
import './globalStyles';
import Root from './components/Root';
import store from './store';
import { shim } from 'promise.prototype.finally';

shim();

render(<Root store={store} />, document.getElementById('app'));
