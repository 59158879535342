import React from 'react';
import PropTypes from 'prop-types';
import WeightingList from './WeightingList';
import { Route, Switch } from 'react-router-dom';
import WeightSchemeDetail from '../Weighting/WeightSchemeDetail';

function WeightingView(props) {
  return (
    <Switch>
      <Route
        path="/settings/weighting/:id"
        render={({ match }) => <WeightSchemeDetail {...props} weightSchemeId={match.params.id} />}
      />
      <Route render={() => <WeightingList {...props} />} />
    </Switch>
  );
}

WeightingView.propTypes = {
  onLoadMore: PropTypes.func,
  onReload: PropTypes.func,
  onSearch: PropTypes.func,
  onSortChange: PropTypes.func,
  onCreateEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onRemoveEvent: PropTypes.func,
  session: PropTypes.object.isRequired,
  weightSchemes: PropTypes.object.isRequired,
  features: PropTypes.object,
};

WeightingView.defaultProps = {
  onLoadMore: Function.prototype,
  onReload: Function.prototype,
  onSearch: Function.prototype,
  onSortChange: Function.prototype,
  onCreateEvent: Function.prototype,
  onUpdateEvent: Function.prototype,
  onRemoveEvent: Function.prototype,
  session: Object.prototype,
  weightSchemes: Object.prototype,
  features: Object.prototype,
};

export default WeightingView;
