import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import GenericNextTokenCrudActions from '../../actions/GenericNextTokenCrudActions';
import WeightingView from '../../components/Settings/Weighting/WeightingView';

const weightingActions = new GenericNextTokenCrudActions('weightSchemes', 'id');

const mapStateToProps = state => {
  return {
    session: state.session,
    weightSchemes: state.weightSchemes,
    features: state.features,
    availableLocales: state.locales,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCreateEvent: weightScheme => {
      return dispatch(weightingActions.createNew(weightScheme));
    },
    onUpdateEvent: (reload = true) => weightScheme => {
      return dispatch(weightingActions.update(weightScheme, reload));
    },
    onRemoveEvent: weightScheme => {
      return dispatch(weightingActions.remove(weightScheme));
    },
    onReload: (clearSearch = false) => {
      return dispatch(weightingActions.resetAndReload(clearSearch));
    },
    onSortChange: (field, order) => {
      return dispatch(weightingActions.sortGridBy(field, order));
    },
    onLoadMore: () => {
      return dispatch(weightingActions.loadMore());
    },
    onFetchOne: weightSchemeId => {
      return dispatch(weightingActions.fetchOne(weightSchemeId));
    },
    onSearch: searchPattern => {
      return dispatch(weightingActions.search(searchPattern));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WeightingView));
