import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { inferEnvironment, useLocalStorage } from '@amzn/et-console-components';
import ApiClient from '../ApiClient';
import TranslatorJobsView from './TranslatorJobsView';
import { logGroup } from '../utils/logUtils';
import initialMetricsPublisher from '../utils/metricsPublisher';
import { publishCountForMethod } from '@amzn/ts-ui-metrics';
import { OfferUtils } from '../utils/offerUtils';
import moment from 'moment';
import { PROD_ENV } from '@amzn/et-console-components/dist/utils/inferEnvironment';

const validationError = new Error();
validationError.status = 520;
validationError.details = {};
validationError.details.errors = [{ message: 'Unexpected response from server' }];

const fetchStats = () => {
  const url = `/me/stats`;

  return ApiClient.httpGet(url).then(resp => {
    return resp;
  });
};

const fetchOffers = offerStatus => {
  const url = `/me/offer/?status=${offerStatus}`;

  return ApiClient.httpGet(url).then(offers => {
    if (offers.constructor !== Array) {
      throw validationError;
    }
    // Emit UX metrics for the offer count displayed to linguist
    if (offerStatus === 'EMAILED' && offers.length !== 0) {
      let metricsPublisher = initialMetricsPublisher.newChildActionPublisherForMethod(
        'OffersAvailableToLinguist'
      );
      // all offer items in the list have same userId
      let user = offers[0].freelancerUri;
      metricsPublisher.publishString('User', user);
      metricsPublisher.publishString('OffersCount', offers.length);
    }
    return offers;
  });
};

export const updateOfferStatus = (job, status) => {
  // emit UX metrics: offer is accepted by linguist
  if (status === 'ACCEPTED') {
    let metricsPublisher = initialMetricsPublisher.newChildActionPublisherForMethod(
      'OfferAcceptedByLinguist'
    );
    metricsPublisher.publishString('OfferId', job.id);
    metricsPublisher.publishString('AcceptedBy', job.freelancerUri);
    metricsPublisher.publishString('JobUri', job.jobUri);
  }
  return ApiClient.httpPut('/me/offer/status', {
    jobUri: job.jobUri,
    status: status,
    offerId: job.id,
    freelancerUri: job.freelancerUri,
  });
};

const getErrorMessage = errorDetails => {
  const name = errorDetails['name'];
  const message = errorDetails['message'];

  if (name === 'JobSegmentsUnconfirmed') {
    return 'Job is not finished. Please confirm all segments prior to completing job.';
  }

  if (name === 'JobNotDownloadable') {
    return 'There is a problem preventing the creation of the translated file, please check for QA errors in TWE and try again.';
  }

  return name + ': ' + message;
};

const publishStatsMetrics = (actionMetricsPublisher, stats) => {
  actionMetricsPublisher.publishCounter('AcceptedOffersCount', stats.acceptedOffersCount);
  actionMetricsPublisher.publishCounter('MaxAcceptableOffers', stats.maxAcceptableOffers);
  actionMetricsPublisher.publishCounter(
    'AcceptedWeightedWorkUnits',
    stats.acceptedWeightedWorkUnits
  );
  actionMetricsPublisher.publishCounter(
    'MaxAcceptedWeightedWorkUnits',
    stats.maxAcceptedWeightedWorkUnits
  );
};

const bundleOffers = offers => {
  // divide array into offers that are eligible/ineligible for bundling
  const [eligibleOffers, retOffers] = offers.reduce(
    ([eligibleOffers, retOffers], offer) => {
      return offer.workUnits < 500 &&
        offer.subject === 'GENERAL' &&
        offer.mediaType === 'SOFTWARE' &&
        // I'm making this change in non-prod envs to fix the functional tests; I'm working with @afsin
        // to extend the change to prod as well
        (!OfferUtils.isExclusiveOffer(offer) || inferEnvironment() !== PROD_ENV)
        ? [[...eligibleOffers, offer], retOffers]
        : [eligibleOffers, [...retOffers, offer]];
    },
    [[], []]
  );

  // group offers by Client|DueDate(Date Only)|Skill|SourceLocale|TargetLocale
  const groupedOffers = eligibleOffers
    .map(offer => {
      offer.key = `${offer.clientUri || '-'}|${moment(offer.dateDue).format('MM/DD/YYYY')}|${
        offer.translationSkill
      }|${offer.sourceLocale}|${offer.targetLocale}`;
      return offer;
    })
    .reduce((groups, offer) => {
      (groups[offer.key] = groups[offer.key] || []).push(offer);
      return groups;
    }, {});

  // if more than one offer in group bundle the offers, add single or bundle to retOffers
  Object.values(groupedOffers).forEach(group => {
    retOffers.push(group.length > 1 ? OfferUtils.createBundle(group) : group[0]);
  });

  const actionMetricsPublisher = publishCountForMethod(initialMetricsPublisher, 'bundleOffers');

  let numBundlesCreated = 0;
  let numOffersUnbundled = 0;
  retOffers.forEach(offer => {
    if (!offer.isBundle) {
      numOffersUnbundled++;
    } else {
      numBundlesCreated++;
      actionMetricsPublisher.publishCounter(
        'CreatedBundleSize',
        Object.keys(offer.childOffers).length
      );
    }
  });
  actionMetricsPublisher.publishCounter('NumBundlesCreated', numBundlesCreated);
  actionMetricsPublisher.publishCounter('NumOffersUnbundled', numOffersUnbundled);

  return retOffers;
};

const TranslatorJobs = ({ forReservations, features, globalState }) => {
  const [jobs, setJobs] = useState([]);
  const [segmentMetadataByJob, setSegmentMetadataByJob] = useState({});
  const [postMortemScoringParentOfferMetadata, setPostMortemScoringParentOfferMetadata] = useState(
    {}
  );
  const [postMortemScoringParentJobMetadata, setPostMortemScoringParentJobMetadata] = useState({});
  const [assignedJobsCount, setAssignedJobsCount] = useState(0);
  const [maxAssignedJobsCount, setMaxAssignedJobsCount] = useState(0);
  const [acceptedWeightedWorkUnits, setAcceptedWeightedWorkUnits] = useState(0);
  const [maxAcceptedWeightedWorkUnits, setMaxAcceptedWeightedWorkUnits] = useState(0);
  const [isFetching, setIsFetching] = useState(true);
  const [loadError, setLoadError] = useState(false);
  const [inflightStatusUpdates, setInflightStatusUpdates] = useState(new Map());
  const [alerts, setAlerts] = useState([]);
  const [timeNow, setTimeNow] = useState(Date.now());
  const [isUpdatingOfferMap, setIsUpdatingOfferMap] = useState(new Map());
  const [isUpdatingOfferForClaim, setIsUpdatingOfferForClaim] = useState(false);
  const [ackedStyleguideMove, setAckedStyleguideMove] = useLocalStorage(
    'ackedStyleguideMove',
    false
  );

  useEffect(() => {
    if (!ackedStyleguideMove) {
      publishCountForMethod(initialMetricsPublisher, 'StyleguideMovedAlertShown');

      setAlerts(prev => [
        ...prev,
        {
          header: 'Style guides have moved!',
          content:
            'Style guides are now available in the ATMS Web Editor. Find them in the References tab of the bottom panel.',
          type: 'warning',
          onDismiss: () => {
            publishCountForMethod(initialMetricsPublisher, 'StyleguideMovedAlertDismissed');

            setAckedStyleguideMove(true);
          },
        },
      ]);
    }
  }, []);

  const jobStatus = forReservations ? 'EMAILED' : 'ASSIGNED';

  const fetchStatsAndOffers = () => {
    // reset state
    setIsFetching(true);
    setInflightStatusUpdates(new Map());
    setIsUpdatingOfferMap(new Map());
    const actionMetricsPublisher = publishCountForMethod(
      initialMetricsPublisher,
      'FetchStatsAndOffers'
    );
    Promise.all([fetchOffers(jobStatus), fetchStats()])
      .then(([offers, stats]) => {
        actionMetricsPublisher.publishCounter('SuccessCount', 1);
        actionMetricsPublisher.publishCounter('OffersCount', offers.length);
        publishStatsMetrics(actionMetricsPublisher, stats);
        logGroup(`fetchOffers (${jobStatus})`, { offers, stats });
        // bundle offers if status == EMAILED
        if (jobStatus === 'EMAILED') {
          offers = bundleOffers(offers);
        }
        setJobs(offers);
        setAssignedJobsCount(stats.acceptedOffersCount);
        setMaxAssignedJobsCount(stats.maxAcceptableOffers);
        setAcceptedWeightedWorkUnits(stats.acceptedWeightedWorkUnits);
        setMaxAcceptedWeightedWorkUnits(stats.maxAcceptedWeightedWorkUnits);
        setIsFetching(false);
        setLoadError(false);
      })
      .catch(error => {
        actionMetricsPublisher.publishCounter('FailureCount', 1);
        setIsFetching(false);
        setLoadError(true);
        console.error(error);
        setAlerts(prev => [
          ...prev,
          { content: 'Failed to load jobs for translator: ' + error, type: 'error' },
        ]);
      });
  };

  const fetchSegmentMetadata = async jobPartIds => {
    if (jobPartIds.length === 0) {
      return {};
    }

    try {
      return await ApiClient.httpPost(`/me/getJobPartSegmentCounts`, { jobPartIds });
    } catch (e) {
      return jobPartIds.reduce((acc, id) => {
        return { ...acc, [id]: e.toString() };
      }, {});
    }
  };

  const fetchPostMortemScoringParentOfferMetadata = async parentToChildOfferIds => {
    const parentOfferIds = Object.keys(parentToChildOfferIds);
    if (parentOfferIds.length === 0) {
      console.info(
        'No parent offer ids found; skipping fetching postmortem scoring parent offer metadata'
      );
      return {};
    }

    try {
      const parentOfferMetadataResult = await ApiClient.httpPost(
        '/me/postMortemScoringParentOffer',
        { parentOfferIds }
      );
      return parentOfferMetadataResult['parentOfferMetadata'].reduce((acc, val) => {
        acc[parentToChildOfferIds[val['id']]] = val;
        return acc;
      }, {});
    } catch (e) {
      // TODO: Show error to user
      console.warn('Failed to load parent offer metadata');
    }
  };

  const fetchPostMortemScoringParentJobMetadata = async jobUrisToOfferIds => {
    const jobUris = Object.keys(jobUrisToOfferIds);
    if (jobUris.length === 0) {
      console.info('No job URIs found; skipping fetching postmortem scoring parent job metadata');
      return {};
    }

    try {
      const parentOfferMetadataResult = await ApiClient.httpPost('/me/postMortemScoringParentJob', {
        jobUris,
      });
      return parentOfferMetadataResult['parentJobMetadata'].reduce((acc, val) => {
        acc[jobUrisToOfferIds[val['jobUri']]] = val;
        return acc;
      }, {});
    } catch (e) {
      // TODO: Show error to user
      console.warn('Failed to load parent job metadata');
    }
  };

  const updateStatus = (job, status, updateStats = true) => {
    // don't allow any status updates if previous update has been initiated
    if (
      inflightStatusUpdates.get(job.id) &&
      !inflightStatusUpdates.get(job.id).error &&
      !inflightStatusUpdates.get(job.id).warning
    ) {
      return;
    }

    const actionMetricsPublisher = publishCountForMethod(
      initialMetricsPublisher,
      'UpdateOfferStatusAndGetStats'
    );
    actionMetricsPublisher.publishString(
      'OfferCount',
      !job.isBundle ? 1 : Object.values(job.childOffers).length
    );
    actionMetricsPublisher.publishString('TargetStatus', status);

    // Set the status update as being inflight
    setInflightStatusUpdates(prev => {
      prev.set(job.id, { status, complete: false, error: false, warning: false });
      return prev;
    });

    // Set the status update of offer for Complete All Button
    setIsUpdatingOfferMap(prev => {
      prev.set(job.id, true);
      return prev;
    });

    //set the status for Claim Button
    setIsUpdatingOfferForClaim(true);

    const fetchStatsIfNeeded = () => {
      if (updateStats) {
        return fetchStats();
      }
    };

    const handleFetchedStatsIfNeeded = stats => {
      if (updateStats) {
        actionMetricsPublisher.publishCounter('StatsSuccessCount', 1);
        publishStatsMetrics(actionMetricsPublisher, stats);
        setAssignedJobsCount(stats.acceptedOffersCount);
        setMaxAssignedJobsCount(stats.maxAcceptableOffers);
        setAcceptedWeightedWorkUnits(stats.acceptedWeightedWorkUnits);
        setMaxAcceptedWeightedWorkUnits(stats.maxAcceptedWeightedWorkUnits);
      }
    };

    if (job.isBundle) {
      const bundle = job;

      const childOffers = Object.values(bundle.childOffers);
      return (
        Promise.all(childOffers.map(childOffer => updateStatus(childOffer, status, false)))
          // update stats because they could have changed
          .then(fetchStatsIfNeeded)
          .then(handleFetchedStatsIfNeeded)
          .finally(() => {
            const bundleErrorMessages = childOffers.reduce((errorMessages, offer) => {
              if (inflightStatusUpdates.get(offer.id).error) {
                errorMessages.push(inflightStatusUpdates.get(offer.id).error);
              }
              return errorMessages;
            }, []);

            actionMetricsPublisher.publishCounter(
              'BundledOfferUpdateSuccessCount',
              childOffers.length - bundleErrorMessages.length
            );
            actionMetricsPublisher.publishCounter(
              'BundledOfferUpdateFailureCount',
              bundleErrorMessages.length
            );

            let bundleUpdateFinalState = 'Success';
            if (bundleErrorMessages.length === childOffers.length) {
              bundleUpdateFinalState = 'Failed';
            } else if (bundleErrorMessages.length > 0) {
              bundleUpdateFinalState = 'Partial';
            }
            actionMetricsPublisher.publishString('BundleUpdateFinalState', bundleUpdateFinalState);

            if (bundleErrorMessages.length > 0) {
              actionMetricsPublisher.publishStringTruncate(
                'BundleUpdateErrors',
                bundleErrorMessages.join(',')
              );
              if (bundleErrorMessages.length === childOffers.length) {
                setInflightStatusUpdates(prev => {
                  prev.get(bundle.id).error = 'Error occurred updating jobs';
                  return prev;
                });
              } else {
                setInflightStatusUpdates(prev => {
                  prev.get(
                    bundle.id
                  ).warning = `Error occurred updating jobs, ${childOffers.length -
                    bundleErrorMessages.length}/${childOffers.length} job(s) claimed successfully`;
                  return prev;
                });
              }
            }

            // mark update as complete for the bundle
            setInflightStatusUpdates(prev => {
              prev.get(job.id).complete = true;
              return prev;
            });

            // Set the status of offer
            setIsUpdatingOfferMap(prev => {
              prev.set(job.id, false);
              return prev;
            });

            //set the status for Claim Button
            setIsUpdatingOfferForClaim(false);
          })
      );
    } else {
      // update offer status
      return (
        updateOfferStatus(job, status)
          .then(() => {
            actionMetricsPublisher.publishCounter('UpdateSuccessCount', 1);
          })
          // update stats because they could have changed
          .then(fetchStatsIfNeeded)
          .then(handleFetchedStatsIfNeeded)
          .catch(error => {
            actionMetricsPublisher.publishCounter('FailureCount', 1);
            error.details['errors'].forEach(errorDetails => {
              setInflightStatusUpdates(prev => {
                prev.get(job.id).error = getErrorMessage(errorDetails);
                return prev;
              });
            });
          })
          .finally(() => {
            // mark update as complete
            setInflightStatusUpdates(prev => {
              prev.get(job.id).complete = true;
              return prev;
            });

            // Set the status of offer
            setIsUpdatingOfferMap(prev => {
              prev.set(job.id, false);
              return prev;
            });

            //set the status for Claim Button
            setIsUpdatingOfferForClaim(false);
          })
      );
    }
  };

  const updateStatusForAll = (jobs, status) => {
    jobs.map(job => updateStatus(job, status));
  };

  useEffect(() => {
    fetchStatsAndOffers();
    const id = setInterval(() => setTimeNow(Date.now()), 1000);
    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    fetchSegmentMetadata(jobs.map(j => j.jobPartId)).then(result =>
      setSegmentMetadataByJob(result)
    );
    fetchPostMortemScoringParentOfferMetadata(
      jobs
        .filter(j => j.isPostMortemScoring && j.parentOfferId)
        .reduce((acc, val) => {
          acc[val['parentOfferId']] = val['id'];
          return acc;
        }, {})
    ).then(setPostMortemScoringParentOfferMetadata);
    fetchPostMortemScoringParentJobMetadata(
      jobs
        .filter(j => j.isPostMortemScoring && !j.parentOfferId)
        .reduce((acc, val) => {
          acc[val['jobUri']] = val['id'];
          return acc;
        }, {})
    ).then(setPostMortemScoringParentJobMetadata);
  }, [jobs]);

  return (
    <TranslatorJobsView
      forReservations={forReservations}
      fetchStatsAndOffers={fetchStatsAndOffers}
      updateStatus={updateStatus}
      jobs={jobs}
      assignedJobsCount={assignedJobsCount}
      maxAssignedJobsCount={maxAssignedJobsCount}
      acceptedWeightedWorkUnits={acceptedWeightedWorkUnits}
      maxAcceptedWeightedWorkUnits={maxAcceptedWeightedWorkUnits}
      isFetching={isFetching || globalState.profile.isFetching}
      isUpdatingOfferMap={isUpdatingOfferMap}
      isUpdatingOfferForClaim={isUpdatingOfferForClaim}
      loadError={loadError}
      inflightStatusUpdates={inflightStatusUpdates}
      alerts={alerts}
      features={features}
      globalState={globalState}
      timeNow={timeNow}
      updateStatusForAll={updateStatusForAll}
      segmentMetadataByJob={segmentMetadataByJob}
      postMortemScoringParentOfferMetadata={postMortemScoringParentOfferMetadata}
      postMortemScoringParentJobMetadata={postMortemScoringParentJobMetadata}
    />
  );
};

TranslatorJobs.propTypes = {
  forReservations: PropTypes.bool,
  features: PropTypes.object,
  globalState: PropTypes.object,
};

export default TranslatorJobs;
