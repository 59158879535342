import styled from '@emotion/styled';

export default styled('button')`
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
  outline: none;
  text-decoration: none;
  color: #0073bb;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: thin dotted !important;
    outline: 5px auto -webkit-focus-ring-color !important;
    outline-offset: 2px;
  }

  &::after {
    display: none !important;
  }
`;
