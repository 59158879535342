import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import { formatCurrency } from '../../../utils/formatNumber';

import { editField, readField } from './editorCss';
import { CURRENCIES } from './constants';

const BasePrice = ({ value, onChange }) => (
  <div>
    <div className={readField} id="readonlyPrice">
      {value.amount ? formatCurrency(value.amount, value.currency, 3) : '-'}
    </div>{' '}
    <div className={editField}>
      <FormControl
        componentClass="select"
        value={value.currency}
        onChange={e => onChange('price.currency', e.target.value)}
      >
        {CURRENCIES.map(currency => (
          <option key={currency} value={currency}>
            {currency}
          </option>
        ))}
      </FormControl>
      <FormControl
        style={{ width: 100 }}
        onChange={e => onChange('price.amount', e.target.value)}
        value={value.amount}
      />
    </div>
  </div>
);

BasePrice.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default BasePrice;
