import { css } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import { minusCircle } from 'react-icons-kit/fa/minusCircle';
import { checkCircle } from 'react-icons-kit/fa/checkCircle';
import SvgIcon from 'react-icons-kit/SvgIcon';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const CertificationIcon = props => {
  const { isCertified } = props;
  const certifiedTooltip = (
    <Tooltip id="tooltip">Translator is certified for the job&apos;s work type.</Tooltip>
  );
  const uncertifiedTooltip = (
    <Tooltip id="tooltip">Translator is not certified for the job&apos;s work type.</Tooltip>
  );

  return (
    <OverlayTrigger placement="left" overlay={isCertified ? certifiedTooltip : uncertifiedTooltip}>
      <span
        style={{ color: isCertified ? 'green' : 'red', paddingRight: '3px' }}
        css={css`
          > svg {
            vertical-align: text-bottom !important;
          }
        `}
      >
        <SvgIcon icon={isCertified ? checkCircle : minusCircle} size={18} />
      </span>
    </OverlayTrigger>
  );
};
CertificationIcon.propTypes = {
  isCertified: PropTypes.bool,
};
CertificationIcon.defaultProps = {
  isCertified: false,
};
export default CertificationIcon;
