const logGroup = (title, items) => {
  console.group(title);
  for (const key in items) {
    console.groupCollapsed(key);
    console.log(items[key]);
    console.groupEnd();
  }
  console.groupEnd();
};

export { logGroup };
