import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import ErrorAlert from '../ErrorAlert';
import applySchema from '../../utils/applySchema';

const VALIDATION_SCHEMA = yup.object().shape({
  userId: yup.number(),
  sourceLocale: yup.string().required(),
  targetLocale: yup.string().required(),
  certificationId: yup.string(),
  maxTranslationSkillId: yup.string().required(),
  mediaTypeId: yup.string().required(),
  subjectId: yup.string().required(),
});

class CertificationDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.renderForm = this.renderForm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values, formik) {
    return this.props.onCreateEvent(applySchema(values, VALIDATION_SCHEMA)).then(
      () => {
        this.setState({
          lastError: null,
        });
        if (this.afterSubmitAction === 'close') {
          this.props.closeHandler();
        } else {
          formik.resetForm();
        }
        this.props.onReloadProfile(this.props.userId);
      },
      err => {
        this.setState({ lastError: err });
      }
    );
  }

  renderForm({ errors, touched, handleSubmit, handleChange, handleBlur }) {
    const { lastError } = this.state;
    const { closeHandler, translationSkills, mediaTypes, subjects, availableLocales } = this.props;
    const saveEnabled = Object.keys(touched).length > 0 && Object.keys(errors).length === 0;

    const validationState = name => touched[name] && (errors[name] ? 'error' : 'success');

    const buildSubmitHandler = (afterSubmitAction = 'continue') => e => {
      this.afterSubmitAction = afterSubmitAction;
      handleSubmit(e);
    };

    return (
      <div>
        <Modal.Header closeButton>
          <Modal.Title>Create certification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {lastError && <ErrorAlert title="Failed to save Certification" errorInfo={lastError} />}
          <Form horizontal onSubmit={buildSubmitHandler()}>
            <FormGroup controlId="sourceLocale" validationState={validationState('sourceLocale')}>
              <Col componentClass={ControlLabel} sm={4}>
                Source Language
              </Col>
              <Col sm={8}>
                <FormControl
                  name="sourceLocale"
                  componentClass="select"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="">Select the Source Language</option>
                  {Object.values(availableLocales.items).map(locale => {
                    return (
                      <option key={locale.code} value={locale.code}>
                        {locale.name}
                      </option>
                    );
                  })}
                </FormControl>
                <FormControl.Feedback />
              </Col>
            </FormGroup>
            <FormGroup controlId="targetLocale" validationState={validationState('targetLocale')}>
              <Col componentClass={ControlLabel} sm={4}>
                Target Language
              </Col>
              <Col sm={8}>
                <FormControl
                  name="targetLocale"
                  componentClass="select"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="">Select the Target Language</option>
                  {Object.values(availableLocales.items).map(locale => {
                    return (
                      <option key={locale.code} value={locale.code}>
                        {locale.name}
                      </option>
                    );
                  })}
                </FormControl>
                <FormControl.Feedback />
              </Col>
            </FormGroup>
            <FormGroup controlId="mediaTypeId" validationState={validationState('mediaTypeId')}>
              <Col componentClass={ControlLabel} sm={4}>
                Media Type
              </Col>
              <Col sm={8}>
                <FormControl
                  name="mediaTypeId"
                  componentClass="select"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="">Select a Media Type</option>
                  {Object.keys(mediaTypes).map(mediaType => (
                    <option key={mediaType} value={mediaType}>
                      {mediaTypes[mediaType]}
                    </option>
                  ))}
                </FormControl>
                <FormControl.Feedback />
              </Col>
            </FormGroup>
            <FormGroup controlId="subjectId" validationState={validationState('subjectId')}>
              <Col componentClass={ControlLabel} sm={4}>
                Subject
              </Col>
              <Col sm={8}>
                <FormControl
                  name="subjectId"
                  componentClass="select"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="">Select a Subject</option>
                  {Object.keys(subjects).map(subject => (
                    <option key={subject} value={subject}>
                      {subjects[subject]}
                    </option>
                  ))}
                </FormControl>
                <FormControl.Feedback />
              </Col>
            </FormGroup>
            <FormGroup
              controlId="maxTranslationSkillId"
              validationState={validationState('maxTranslationSkillId')}
            >
              <Col componentClass={ControlLabel} sm={4}>
                Maximum Skill Level
              </Col>
              <Col sm={8}>
                <FormControl
                  name="maxTranslationSkillId"
                  componentClass="select"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="">Select a Skill</option>
                  {Object.keys(translationSkills).map(skill => (
                    <option key={skill} value={skill}>
                      {translationSkills[skill]}
                    </option>
                  ))}
                </FormControl>
                <FormControl.Feedback />
              </Col>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeHandler}>Cancel</Button>
          <Button bsStyle={'default'} disabled={!saveEnabled} onClick={buildSubmitHandler('close')}>
            Save
          </Button>
        </Modal.Footer>
      </div>
    );
  }

  getEmptyState(userId) {
    return {
      userId: userId,
      sourceLocale: '',
      targetLocale: '',
      mediaTypeId: '',
      subjectId: '',
      maxTranslationSkillId: '',
    };
  }

  render() {
    const { userId } = this.props;

    return (
      <Formik
        initialValues={this.getEmptyState(userId)}
        validationSchema={VALIDATION_SCHEMA}
        validateOnChange={true}
        onSubmit={this.handleSubmit}
        render={this.renderForm}
      />
    );
  }
}

CertificationDialog.propTypes = {
  closeHandler: PropTypes.func,
  onCreateEvent: PropTypes.func,
  onReloadProfile: PropTypes.func,
  userId: PropTypes.number.isRequired,
  mediaTypes: PropTypes.object.isRequired,
  subjects: PropTypes.object.isRequired,
  translationSkills: PropTypes.object.isRequired,
  availableLocales: PropTypes.object.isRequired,
};

CertificationDialog.defaultProps = {
  closeHandler: Function.prototype,
  onCreateEvent: Function.prototype,
  onReloadProfile: Function.prototype,
};

export default CertificationDialog;
