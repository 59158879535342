import jwt from 'jsonwebtoken';
import HSC from 'http-status-codes';
import ApiClient from '../ApiClient';
import Cookies from 'js-cookie';
import { loginUrl, logoutUrl } from '../config/urls';

export const TP_JWT_COOKIE_NAME = 'devPortalJWT';

const ActionTypes = {
  // Set/update JWT
  SESSION_UPDATE: 'SESSION_UPDATE',
  // tp-web-service offline, that kind of thing
  SESSION_UPDATE_FAILURE: 'SESSION_UPDATE_FAILURE',
};

let jwtValue;

export function updateSession(data) {
  return (dispatch, getState) => {
    const mergedData = {
      ...getState().session,
      ...data,
    };
    dispatch({ type: ActionTypes.SESSION_UPDATE, data: mergedData });
  };
}

export function updateSessionFailure(error) {
  return { type: ActionTypes.SESSION_UPDATE_FAILURE, error };
}

export const fetchWithAuth = (origFetch, dispatch) => {
  return (url, options) => {
    return origFetch(url, options).then(async response => {
      // apparently we are no longer logged in
      if (response.status === HSC.UNAUTHORIZED) {
        window.location.assign(loginUrl());
      } else {
        // any other response could potentially give us a (new) JWT
        const payload = Cookies.get(TP_JWT_COOKIE_NAME);
        const data = payload && jwt.decode(payload);
        if (data && jwtValue !== payload) {
          jwtValue = payload;

          const cachedLanguagePairRaw = localStorage.getItem('languagePair');
          // TODO: prompt the user for the initial pair if we have nothing in sessionStorage
          let languagePair = { source: 'en_us', target: 'es_es' };
          if (cachedLanguagePairRaw) {
            languagePair = JSON.parse(cachedLanguagePairRaw);
          }

          try {
            data.availableProfiles = await ApiClient.httpGet('/me/profiles');
          } catch (err) {
            console.error('Error getting profiles', err);
          }

          dispatch(updateSession({ ...data, jwt: payload, languagePair }));
        }
      }
      return response;
    });
  };
};

/**
 *  authenticate should only get called once in the entire app lifecycle; it:
 *  1. Ensures you are logged in (redirecting to web if not)
 *  2. Adds ApiClient hooks so that in future calls to the API it will either
 *     a. Refresh its token or
 *     b. Redirect to web if your session has expired
 */
const authenticate = () => {
  return dispatch => {
    // add our response handler to the singleton for all API calls
    const origFetch = ApiClient.fetch;
    ApiClient.fetch = fetchWithAuth(origFetch, dispatch);

    // NOTE: ApiClient will dispatch normal SESSION_ events for us,
    // thanks to addResponseHandlers above
    ApiClient.httpGet('/authenticate').catch(error => {
      if (error.status !== HSC.UNAUTHORIZED) {
        dispatch(updateSessionFailure(error));
      }
    });
  };
};

/**
 * Fake out authentication, setting up a session with the permissions
 * specified in REACT_APP_FAKE_PERMISSIONS
 *
 * Note that other calls to tp-web-service will fail, since we don't
 * have a real JWT
 */
const fakeAuthenticate = () => {
  const fakeSession = {
    permissions: process.env.REACT_APP_FAKE_PERMISSIONS.split(' '),
  };
  return dispatch => {
    dispatch(updateSession(fakeSession));
  };
};

const authenticateExport = process.env.REACT_APP_FAKE_PERMISSIONS ? fakeAuthenticate : authenticate;
export { authenticateExport as authenticate };

export function destroySession() {
  return (dispatch, getState) => {
    const { session } = getState();
    window.location = logoutUrl(session.csrfToken);
  };
}

export function acceptTermsOfService() {
  return dispatch => {
    try {
      ApiClient.httpGet('/me/updateDateAcceptedTermsOfService')
        .then(resp => {
          dispatch(
            updateSession({
              acceptedTermsOfServiceTimestamp: new Date().getTime(),
            })
          );
        })
        .catch(err => {
          console.error('Error updating the accepted terms of service date', err);
        });
    } catch (err) {
      console.error('Error updating the accepted terms of service date', err);
    }
  };
}

export default ActionTypes;
