import ActionTypes from '../actions/TranslationSkills';

const translationSkills = (state = translationSkills.initialState, action) => {
  switch (action.type) {
    case ActionTypes.FINISHED_GET_TRANSLATION_SKILLS: {
      return {
        ...state,
        skills: action.translationSkills,
      };
    }
    case ActionTypes.FAILED_GET_TRANSLATION_SKILLS: {
      return {
        ...state,
        lastError: action.error,
      };
    }
    default:
      return state;
  }
};

translationSkills.initialState = {
  skills: {},
};

export default translationSkills;
