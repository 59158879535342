import ApiClient from '../ApiClient';

const ACTION_TYPES = {
  GET_SUBJECTS: 'GET_SUBJECTS',
  FINISHED_GET_SUBJECTS: 'FINISHED_GET_SUBJECTS',
  FAILED_GET_SUBJECTS: 'FAILED_GET_SUBJECTS',
};

function getSubjectsEvent() {
  return {
    type: ACTION_TYPES.GET_SUBJECTS,
  };
}

function finishedGetSubjectsEvent(resp) {
  return {
    type: ACTION_TYPES.FINISHED_GET_SUBJECTS,
    subjects: resp,
  };
}

function failedGetSubjectsEvent(error) {
  return {
    type: ACTION_TYPES.FAILED_GET_SUBJECTS,
    error,
  };
}

export function getSubjects() {
  return dispatch => {
    dispatch(getSubjectsEvent());
    return ApiClient.httpGet(`/subjects`)
      .then(resp => {
        resp.constructor === Object
          ? dispatch(finishedGetSubjectsEvent(resp))
          : dispatch(failedGetSubjectsEvent(resp));
      })
      .catch(err => {
        dispatch(failedGetSubjectsEvent(err));
      });
  };
}

export default ACTION_TYPES;
