export const WEB_HOSTNAME =
  process.env.REACT_APP_WEB_HOSTNAME || window.location.hostname.replace('portal.', 'web.');

/**
 * Gets the web login URL with a redirect link back to the current window location
 * @returns {string} web login URL
 */
export const loginUrl = () => {
  return `//${WEB_HOSTNAME}/web/login/auth?backUrl=${encodeURIComponent(window.location)}`;
};

/**
 * Get the log out URL, structured to redirect the user back to translator portal once they re-login.
 * @param csrfToken the current session csrfToken
 * @returns {string} web logout URL
 */
export const logoutUrl = csrfToken => {
  return `//${WEB_HOSTNAME}/web/logout/index?csrfToken=${encodeURIComponent(
    csrfToken
  )}&backUrl=${encodeURIComponent(window.location)}`;
};

/**
 * Get the assume profile URL, structured to redirect the user back to their current page in translator portal.
 * @param csrfToken the current session csrfToken
 * @returns {string} web assume profile URL
 */
export const assumeProfileUrl = csrfToken => {
  return `//${WEB_HOSTNAME}/web/assumeProfile?csrfToken=${encodeURIComponent(
    csrfToken
  )}&name={username}&backUrl=${encodeURIComponent(window.location)}`;
};
