import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import Papa from 'papaparse';
import ImportPriceExample from './ImportPriceExample';

const VALIDATION_SCHEMA = yup.object().shape({
  priceSchemeImportFile: yup.mixed().required(),
});

class PriceSchemeImportDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.renderForm = this.renderForm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values, formik) {
    const onDataImport = this.props.onUpdateEvent;
    Papa.parse(values.priceSchemeImportFile, {
      skipEmptyLines: true,
      header: true,
      complete: function(results) {
        const data = results.data;

        // send result to state
        const prices = data.map(row => {
          return {
            languagePair: {
              sourceLocale: row.sourceLocale,
              targetLocale: row.targetLocale,
            },
            per: 'WORD',
            price: { amount: row.amount, currency: row.currency },
          };
        });
        onDataImport(prices);
      },
    });
    this.props.closeHandler();
  }

  renderForm({ values, errors, touched, handleSubmit, handleChange, handleBlur, setFieldValue }) {
    const { closeHandler, title } = this.props;
    const handleCancel = () => {
      closeHandler(undefined);
    };

    return (
      <div>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImportPriceExample />
          <Form>
            <FormGroup controlId="priceSchemeImportFile" style={{ height: 'max-content' }}>
              <Col componentClass={ControlLabel} sm={3}>
                File
              </Col>
              <Col sm={8}>
                <FormControl
                  name="priceSchemeImportFile"
                  type="file"
                  accept={'.csv'}
                  onChange={event => {
                    setFieldValue('priceSchemeImportFile', event.currentTarget.files[0]);
                  }}
                  onBlur={handleBlur}
                />
                <FormControl.Feedback />
              </Col>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button bsStyle={'primary'} onClick={handleSubmit}>
            Import
          </Button>
        </Modal.Footer>
      </div>
    );
  }

  render() {
    return (
      <Formik
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={this.handleSubmit}
        validateOnChange={true}
        render={this.renderForm}
      />
    );
  }
}

PriceSchemeImportDialog.propTypes = {
  closeHandler: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  priceScheme: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

PriceSchemeImportDialog.defaultProps = {
  closeHandler: Function.prototype,
  onUpdateEvent: Function.prototype,
};

export default PriceSchemeImportDialog;
