import { parse } from 'content-disposition';
const SVC_HOSTNAME_PREFIX = 'portalapi';
const SVC_HOSTNAME =
  process.env.REACT_APP_SVC_HOSTNAME ||
  window.location.hostname.replace('portal.', SVC_HOSTNAME_PREFIX + '.');

class ApiClient {
  constructor() {
    this.fetch = fetch;
  }

  httpRequest(path, options) {
    const url = `//${SVC_HOSTNAME}/${path.replace(/^\//, '')}`;
    options = {
      method: 'GET',
      ...options,
    };

    // fetch and resolve to the json (if success), or a useful error (if not)
    return this.fetch(url, options).then(
      response => {
        if (response.ok) {
          // if the response is successful but non-JSON, still return an empty object
          return response.json().catch(() => ({}));
        }
        return response.text().then(details => {
          try {
            details = JSON.parse(details);
          } catch (e) {
            // we'll just use the original text
          }
          const err = new Error();
          err.status = response.status;
          // TODO: maybe just get what we need from details
          // if it's an object
          err.details = details;
          throw err;
        });
      },
      originalError => {
        // conn refused, other unhandled stuff, etc
        const err = new Error();
        err.status = ApiClient.UNKNOWN_ERROR;
        err.details = originalError;

        if (originalError instanceof TypeError && originalError.message === 'Failed to fetch') {
          err.details.errors = [{ message: 'Failed to connect to the server' }];
        }

        throw err;
      }
    );
  }

  httpGet(path) {
    const options = {
      credentials: 'include',
    };

    return this.httpRequest(path, options);
  }

  httpPost(path, dataDictionary) {
    return this.httpUpdate('POST', path, dataDictionary);
  }

  httpPut(path, dataDictionary) {
    return this.httpUpdate('PUT', path, dataDictionary);
  }

  httpDelete(path, dataDictionary) {
    return this.httpUpdate('DELETE', path, dataDictionary);
  }

  httpUpdate(method, path, dataDictionary) {
    const options = {
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataDictionary),
      credentials: 'include',
    };

    return this.httpRequest(path, options);
  }

  multipartUpload(path, dataDictionary) {
    const form = new FormData();
    Object.keys(dataDictionary).forEach(key => {
      form.append(key, dataDictionary[key]);
    });
    const options = {
      method: 'POST',
      body: form,
      credentials: 'include',
    };

    return this.httpRequest(path, options);
  }

  download(path, options, defaultFilename) {
    const url = `//${SVC_HOSTNAME}/${path.replace(/^\//, '')}`;
    options = {
      method: 'GET',
      credentials: 'include',
      ...options,
    };

    let filename;
    return fetch(url, options)
      .then(
        response => {
          if (response.ok) {
            for (const pair of response.headers.entries()) {
              console.log(pair[0] + ': ' + pair[1]);
            }
            const cd = response.headers.get('content-disposition');
            console.log('CD = ' + cd);
            if (cd) {
              filename = parse(cd).parameters.filename;
            } else {
              filename = defaultFilename;
            }
            return response.blob();
          }

          return response.text().then(details => {
            try {
              details = JSON.parse(details);
            } catch (e) {
              // we'll just use the original text
            }
            const err = new Error();
            err.status = response.status;
            // TODO: maybe just get what we need from details
            // if it's an object
            err.details = details;
            throw err;
          });
        },
        // conn refused, other unhandled stuff, etc
        originalError => {
          // conn refused, other unhandled stuff, etc
          const err = new Error();
          err.status = ApiClient.UNKNOWN_ERROR;
          err.details = originalError;

          if (originalError instanceof TypeError && originalError.message === 'Failed to fetch') {
            err.details.errors = [{ message: 'Failed to connect to the server' }];
          }

          throw err;
        }
      )
      .then(blob => {
        console.log('BLOB');
        console.log(blob);
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        return filename;
      });
  }
}

// unofficial, but commonly used for `Unknown Error`
ApiClient.UNKNOWN_ERROR = 520;

// export a singleton (though we can get at its constructor for tests)
export default new ApiClient();
