import * as React from 'react';

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import styled from '@emotion/styled';

const HelpText = styled('div')`
  margin-bottom: 1em;
`;

class ImportWeightsExample extends React.Component {
  render() {
    const exampleData = [
      {
        sourceLocale: 'en-US',
        targetLocale: 'es-MX',
        p0: '1',
        p50: '1',
        p75: '1',
        p85: '1',
        p95: '1',
        p100: '1',
        p101: '0',
        repetition: '0',
      },
      {
        sourceLocale: 'en-GB',
        targetLocale: 'es-MX',
        p0: '1',
        p50: '.9',
        p75: '.8',
        p85: '.5',
        p95: '.4',
        p100: '.3',
        p101: '0',
        repetition: '0',
      },
    ];
    return (
      <div>
        <HelpText>
          Upload your weights list in CSV format. <br /> <b>WARNING:</b> This action will overwrite
          all weights in this scheme.
        </HelpText>
        Example:
        <BootstrapTable data={exampleData} condensed striped>
          <TableHeaderColumn dataField="sourceLocale" isKey={true}>
            sourceLocale
          </TableHeaderColumn>
          <TableHeaderColumn dataField="targetLocale">targetLocale</TableHeaderColumn>
          <TableHeaderColumn dataField="p0" dataAlign="center">
            p0
          </TableHeaderColumn>
          <TableHeaderColumn dataField="p50" dataAlign="center">
            p50
          </TableHeaderColumn>
          <TableHeaderColumn dataField="p75" dataAlign="center">
            p75
          </TableHeaderColumn>
          <TableHeaderColumn dataField="p85" dataAlign="center">
            p85
          </TableHeaderColumn>
          <TableHeaderColumn dataField="p95" dataAlign="center">
            p95
          </TableHeaderColumn>
          <TableHeaderColumn dataField="p100" dataAlign="center">
            p100
          </TableHeaderColumn>
          <TableHeaderColumn dataField="p101" dataAlign="center">
            p101
          </TableHeaderColumn>
          <TableHeaderColumn dataField="repetition" dataAlign="center">
            repetition
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }
}

export default ImportWeightsExample;
