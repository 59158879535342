import * as React from 'react';
import { ReactElement } from 'react';
import { css, cx } from 'react-emotion';

import { theme } from '../config/constants';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const validationErrorClass = css`
  background-color: ${theme.brandBackgroundError} !important;
`;

interface ValidationResult {
  valid: boolean;
  errorMsg?: string;
}

interface Props {
  selected?: boolean;
  onSelect?: (shiftKey: boolean) => void;
  classNames: string[];
  children?: any;
  validationResult: ValidationResult;
}

const ValidatedRow = (props: Props): ReactElement => {
  const { selected, onSelect, children, classNames, validationResult, ...remainingProps } = props;
  let selectionNode;
  const handleSelect = (e: React.MouseEvent | React.ChangeEvent) => {
    if (onSelect) {
      selectionNode.focus();
      onSelect(e['shiftKey'] || false);
    }
  };

  const classes = cx(
    selected &&
      validationResult.valid &&
      css`
        background-color: ${theme.brandBackgroundPale} !important;
      `,
    !validationResult.valid && validationErrorClass,
    ...classNames
  );

  return (
    <React.Fragment>
      <OverlayTrigger
        placement="top"
        overlay={
          validationResult.valid ? (
            <div />
          ) : (
            <Tooltip id="validation-failure-tooltip">{validationResult.errorMsg || ''}</Tooltip>
          )
        }
      >
        <tr {...remainingProps} className={classes} onClick={handleSelect}>
          {onSelect && (
            <td
              css={css`
                user-select: none;
                text-align: center;
              `}
            >
              <input
                type="checkbox"
                checked={selected}
                ref={node => node && (selectionNode = node)}
                onChange={handleSelect}
                // onKeyPress={this.keyPress}
              />
            </td>
          )}
          {children}
        </tr>
      </OverlayTrigger>
    </React.Fragment>
  );
};

export default ValidatedRow;
