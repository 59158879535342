import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@amzn/awsui-components-react/polaris';
import { formatPercent } from '../utils/formatNumber';

const matchPercentDisplayTextMapping = {
  0: '0-49%',
  0.5: '50-74%',
  0.75: '75-84%',
  0.85: '85-94%',
  0.95: '95-99%',
  1: '100%',
  1.01: '101%',
};

const extractTier = scopingReportEntry => {
  if (scopingReportEntry.matchType === 'REPETITION') {
    return 'Repetition';
  }
  return matchPercentDisplayTextMapping[scopingReportEntry.matchPercent];
};

const ScopingReportView = props => {
  const { scopingReport, wordCount } = props;

  const columnDefinitions = [
    {
      id: 'rate',
      header: 'Rate',
      cell: item => item.rate || '-',
    },
    {
      id: 'words',
      header: 'Words',
      cell: item => item.words || '-',
    },
    {
      id: 'percentOfTotal',
      header: '% of Total',
      cell: item => (item.words && formatPercent(item.words / wordCount)) || '-',
    },
  ];

  const items = scopingReport.map(scEntry => ({
    rate: extractTier(scEntry),
    words: scEntry.wordCount,
  }));
  return (
    <Table
      loadingText="Loading Scoping Report"
      columnDefinitions={columnDefinitions}
      items={items}
      variant="borderless"
    />
  );
};

ScopingReportView.propTypes = {
  scopingReport: PropTypes.array,
  wordCount: PropTypes.number,
};

ScopingReportView.defaultProps = {
  scopingReport: [],
};

export default ScopingReportView;
