import React from 'react';
import PropTypes from 'prop-types';
import { css, keyframes } from 'react-emotion';
import SvgIcon from 'react-icons-kit/SvgIcon';
import { spinner } from 'react-icons-kit/fa/spinner';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
export const spinning = css`
  animation: ${spin} 0.5s steps(8) infinite;
  display: inline-block;

  > svg {
    position: relative;
    top: -1px;
  }
`;

const Spinner = props => {
  const { size } = props;

  return (
    <span
      className={spinning}
      css={css`
        & > svg {
          vertical-align: text-top !important;
        }
      `}
    >
      <SvgIcon icon={spinner} size={size} />
    </span>
  );
};

Spinner.propTypes = {
  size: PropTypes.number.isRequired,
};
Spinner.defaultProps = {
  size: 15,
};

export default Spinner;
