import { connect } from 'react-redux';
import {
  createNewTranslator,
  reloadTranslators,
  loadMoreTranslators,
  searchTranslators,
  sortGridBy,
  updateTranslator,
} from '../actions/Translators';
import { loadProfile } from '../actions/Profile';
import TranslatorView from '../components/translator/TranslatorView';
import Certification from '../actions/Certification';

const certificationActions = new Certification();

const mapStateToProps = state => {
  return {
    translators: state.translators,
    session: state.session,
    features: state.features,
    profile: state.profile,
    translationSkills: state.translationSkills,
    mediaTypes: state.mediaTypes,
    subjects: state.subjects,
    availableLocales: state.locales,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCreateEvent: translator => {
      return dispatch(createNewTranslator(translator));
    },
    onUpdateEvent: translator => {
      return dispatch(updateTranslator(translator));
    },
    onReload: (clearSearch = false) => {
      dispatch(reloadTranslators(clearSearch));
    },
    onLoadMore: () => {
      dispatch(loadMoreTranslators());
    },
    onSearch: searchPattern => {
      dispatch(searchTranslators(searchPattern));
    },
    onSortChange: (field, order) => {
      dispatch(sortGridBy(field, order));
    },
    onLoadProfile: userId => {
      return dispatch(loadProfile(userId));
    },
    onCreateCertificationEvent: certification => {
      return dispatch(certificationActions.createNew(certification));
    },
    onChangeCertificationEvent: certification => {
      return dispatch(certificationActions.update(certification));
    },
    onRemoveCertificationEvent: certification => {
      return dispatch(certificationActions.remove(certification));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  // without this, clicking links doesn't update the view because it doesn't involve a redux change
  { pure: false }
)(TranslatorView);
