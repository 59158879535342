import { css } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

import Logo from './Logo';
import { ProfileDropdown, SystemStatusFlash } from '@amzn/et-console-components';
import { assumeProfileUrl, logoutUrl } from '../../config/urls';

const Content = styled('div')`
  height: 40px;
  background-color: #232f3e;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
`;

// We need to override the .awsui h2 style or it will override some settings
const AppTitle = styled('h2')`
  .awsui div & {
    display: flex;
    align-items: center;
    padding: 0 0 0 30px;
    margin: 0;
    flex-grow: 1;
    height: 24px;
    line-height: 24px;
    color: #fff;
    font-size: 1.8rem;
  }
`;

export class Header extends React.Component {
  UNSAFE_componentWillMount() {
    const { fetchAvailableLocales } = this.props;
    fetchAvailableLocales();
  }

  render() {
    const { session } = this.props;

    return (
      <>
        <SystemStatusFlash session={session} />
        <Content>
          <AppTitle>
            <Logo />
            <Link
              id="title-link"
              to="/"
              css={css({
                '.awsui div &': {
                  fontWeight: 500,
                  color: '#fff',
                  paddingLeft: '0.5em',
                  '&:hover': { color: '#fff', textDecoration: 'none' },
                },
              })}
            >
              Translator Portal
            </Link>
          </AppTitle>
          <div>
            <ProfileDropdown
              user={{
                firstName: session.firstName,
                lastName: session.lastName,
                role: session.role,
                userName: session.username,
              }}
              availableProfiles={session.availableProfiles}
              switchProfileUri={assumeProfileUrl(session.csrfToken)}
              signOutUri={logoutUrl(session.csrfToken)}
            />
          </div>
        </Content>
      </>
    );
  }
}

Header.defaultProps = {
  fetchAvailableLocales: () => {},
};

Header.propTypes = {
  availableLocales: PropTypes.object,
  fetchAvailableLocales: PropTypes.func,
  session: PropTypes.object.isRequired,
};

export default Header;
