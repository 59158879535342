import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import SvgIcon from 'react-icons-kit/SvgIcon';
import { unsorted } from 'react-icons-kit/fa/unsorted';
import { sortDesc } from 'react-icons-kit/fa/sortDesc';
import { sortAsc } from 'react-icons-kit/fa/sortAsc';

import { SortOrder } from '../utils/Enums';

const SortableHeader = styled('button')`
  background: transparent;
  border: none;
  padding: 0.25rem 1rem;
  margin: -0.25rem -1rem;
  cursor: pointer;
  display: inline;
  user-select: none; /* Don't allow user to select text in column headers. */

  &:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: 2px;

    &::after {
      content: '';
      display: none;
    }
  }
`;

const IconPadding = styled('div')`
  padding-left: 4px;
  display: inline;
`;

class SortableColumnHeader extends React.Component {
  render() {
    const { title, sortName, sortOrder, onClick } = this.props;
    return (
      <SortableHeader
        onClick={() => onClick(sortName, (sortOrder + 1) % Object.keys(SortOrder).length)}
      >
        {title}
        <IconPadding>
          {sortOrder === SortOrder.UNSORTED && <SvgIcon icon={unsorted} size={16} />}
          {sortOrder === SortOrder.DESC && <SvgIcon icon={sortDesc} size={16} />}
          {sortOrder === SortOrder.ASC && <SvgIcon icon={sortAsc} size={16} />}
        </IconPadding>
      </SortableHeader>
    );
  }
}

SortableColumnHeader.propTypes = {
  title: PropTypes.string.isRequired,
  sortName: PropTypes.string.isRequired,
  sortOrder: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

SortableColumnHeader.defaultProps = {
  sortOrder: SortOrder.UNSORTED,
  onClick: Function.prototype,
};

export default SortableColumnHeader;
