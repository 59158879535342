import { css } from 'react-emotion';

export const readField = css`
  tr.row-editable:hover &,
  tr.row-editable:focus-within & {
    height: 0;
    overflow: hidden;
  }
`;

export const editField = css`
  height: 0;
  overflow: hidden;

  .form-control {
    margin: -5px 0 -5px 0;
    height: 30px;
    vertical-align: bottom;
  }
  .form-control:first-child {
    margin-left: -5px;
  }
  .form-control:last-child {
    margin-right: -5px;
  }

  tr.row-editable:hover &,
  tr.row-editable:focus-within & {
    height: auto;
    overflow: visible;
  }
`;

export const validationError = css`
  margin-left: 1em;
  color: red;
`;
