import formatLocaleCode from './formatLocaleCode';
import { formatCurrency } from './formatNumber';
import moment from 'moment-timezone';

let timezone = null;

export const setTimezone = tz => {
  timezone = tz;
};

export const readableLookup = (code, config, mapping) => {
  if (!code || !config || !config[mapping]) {
    return '-';
  } else if (config[mapping][code]) {
    return config[mapping][code];
  } else {
    return code;
  }
};

export const readableList = (config, mapping) => {
  const list = [];
  if (config && config[mapping]) {
    for (const key in config[mapping]) {
      list.push(config[mapping][key]);
    }
  }
  return list;
};

export const keyLookupFromReadable = (readable, config, mapping) => {
  if (readable && config && config[mapping]) {
    for (const key in config[mapping]) {
      if (config[mapping][key] === readable) {
        return key;
      }
    }
  }
  return readable;
};

export const valueOrDash = value => value || '-';

export const convertDate = date => (date && date.substring ? date.substring(0, 10) : '-');

export const getDaysTilDate = date => {
  const daysTilDate = date ? moment(date).diff(moment.now(), 'days') : 0;
  return daysTilDate > 0 ? daysTilDate : daysTilDate < 0 ? 0 : '-';
};

// Can also use Intl.DateTimeFormat().resolvedOptions().timeZone instead of moment.tz.guess() if that doesn't work well
export const convertDateTime = date =>
  date
    ? moment.tz(date, timezone ? timezone : moment.tz.guess()).format('YYYY-MM-DD HH:mm z')
    : '-';
export const convertDateTimePretty = date =>
  date ? moment.tz(date, timezone ? timezone : moment.tz.guess()).format('LLL z') : '-';

export const convertLanguagePair = (sourceLocale, targetLocale) => {
  try {
    return formatLocaleCode(sourceLocale) + ' ➡ ' + formatLocaleCode(targetLocale);
  } catch {
    return '-';
  }
};

export const convertPrice = (price, currency) => {
  if (price === null || price === undefined) {
    return '-';
  }

  if (price > 1e-10) {
    return formatCurrency(price, currency);
  }

  return formatCurrency(0, currency);
};
