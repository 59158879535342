import React from 'react';
import Button from '@amzn/awsui-components-react/polaris/button';
import PropTypes from 'prop-types';

export class SettingsButtons extends React.Component {
  render() {
    const { writePermission, onCreate, onReload, createTitle, isFetching } = this.props;
    return (
      <>
        <Button id="Refresh" disabled={isFetching} onClick={onReload}>
          Refresh
        </Button>
        {writePermission && (
          <Button variant="primary" onClick={onCreate} autoFocus>
            {createTitle}
          </Button>
        )}
      </>
    );
  }
}

SettingsButtons.propTypes = {
  writePermission: PropTypes.bool,
  onCreate: PropTypes.func,
  onReload: PropTypes.func,
  createTitle: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

SettingsButtons.defaultProps = {
  onCreate: Function.prototype,
  onReload: Function.prototype,
};

export default SettingsButtons;
