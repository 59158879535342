import React from 'react';
import PropTypes from 'prop-types';
import { cx } from 'react-emotion';
import { Spinner } from '@amzn/awsui-components-react/polaris';

import { DropdownButton, MenuItem, Dropdown, Checkbox } from 'react-bootstrap';

import CertificationIcon from '../CertificationIcon';

class AssignButton extends React.Component {
  constructor() {
    super();

    this.search = this.search.bind(this);
    this.handleTranslatorSelection = this.handleTranslatorSelection.bind(this);
  }

  search(e) {
    const { onSearchChange } = this.props;
    onSearchChange(e.target.value);
  }

  handleTranslatorSelection(translator) {
    const { onSelectTranslator } = this.props;
    onSelectTranslator(translator.id);
  }

  handleVendorSelection(vendor) {
    const { onSelectVendor } = this.props;
    onSelectVendor(vendor.id);
  }

  render() {
    const {
      currentSearch,
      availableTranslators,
      availableVendors,
      userCertifications,
      disabled,
      variant,
      loading,
      shouldAssignNextWorkflowStep,
      setAssignNextWorkflowStep,
    } = this.props;

    const onDropdownClick = () => {
      setTimeout(() => this.searchNode && this.searchNode.focus());
    };

    const sortTranslatorsByCertificationStatus = translators => {
      if (!userCertifications) {
        return translators;
      }
      return translators.sort((a, b) => {
        if (userCertifications[a.id] && !userCertifications[b.id]) {
          return -1;
        }
        if (!userCertifications[a.id] && userCertifications[b.id]) {
          return 1;
        }
        return 0;
      });
    };

    let classname = cx('awsui-button');
    if (variant === 'primary' && !loading && !disabled) {
      classname = cx('awsui-button', 'awsui-button-variant-primary');
    }

    return (
      <span onClick={e => e.stopPropagation()}>
        <DropdownButton
          className={classname}
          pullRight
          title={
            <>
              {loading && <Spinner variant={'disabled'} />}
              Assign
            </>
          }
          id="bg-nested-dropdown"
          bsSize="xsmall"
          onClick={onDropdownClick}
          disabled={disabled || loading}
        >
          <Dropdown id={'assignment-dropdown'} eventKey="1" disabled style={{ width: '300px' }}>
            <input
              ref={node => node && (this.searchNode = node)}
              type="text"
              className="form-control"
              value={currentSearch}
              onChange={this.search}
              placeholder="Search translators..."
            />
          </Dropdown>
          {availableTranslators.length > 0 && <MenuItem header={true}>Freelancers</MenuItem>}
          {availableTranslators &&
            sortTranslatorsByCertificationStatus(availableTranslators).map(translator => (
              <MenuItem
                key={translator.id}
                onClick={() => {
                  this.handleTranslatorSelection(translator);
                }}
              >
                {userCertifications && (
                  <CertificationIcon isCertified={userCertifications[translator.id] != null} />
                )}
                {translator.firstName} {translator.lastName}
              </MenuItem>
            ))}
          {availableVendors.length > 0 && <MenuItem header={true}>Agencies</MenuItem>}
          {availableVendors.length > 0 && (
            <MenuItem
              onClick={() => {
                setAssignNextWorkflowStep(!shouldAssignNextWorkflowStep);
                throw "Throwing this exception because the Dropdown doesn't respect stopPropagation; it can be safely ignored";
              }}
            >
              <Checkbox
                checked={shouldAssignNextWorkflowStep}
                onClick={e => {
                  // We don't want the dropdown to close on this click
                  e.stopPropagation();
                  setAssignNextWorkflowStep(!shouldAssignNextWorkflowStep);
                }}
                inline={true}
              >
                Assign next workflow step
              </Checkbox>
            </MenuItem>
          )}
          {availableVendors &&
            availableVendors.map(vendor => (
              <MenuItem
                key={vendor.id}
                onClick={() => {
                  this.handleVendorSelection(vendor);
                }}
              >
                {vendor.name}
              </MenuItem>
            ))}
        </DropdownButton>
      </span>
    );
  }
}

AssignButton.propTypes = {
  availableTranslators: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  availableVendors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  userCertifications: PropTypes.object,
  onSelectTranslator: PropTypes.func,
  onSelectVendor: PropTypes.func,
  onSearchChange: PropTypes.func,
  currentSearch: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  loading: PropTypes.bool,
  shouldAssignNextWorkflowStep: PropTypes.bool,
  setAssignNextWorkflowStep: PropTypes.func,
};

AssignButton.defaultProps = {
  onSelect: Function.prototype,
  disabled: false,
  loading: false,
};

export default AssignButton;
