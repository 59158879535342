const STATUS_LIST = {
  ACTIVE: 'Active',
  REVIEW: 'Review',
  ONBOARDING: 'Onboarding',
  READY_TO_ONBOARD: 'Ready to Onboard',
  QUIT: 'Quit',
  BLOCKLISTED: 'Blocklisted',
  INACTIVE: 'Inactive',
};

export function displayStatus(status) {
  return STATUS_LIST[status] || STATUS_LIST.INACTIVE;
}

export default STATUS_LIST;
