import React, { useState } from 'react';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';

import { Container, KeyValuePane } from '@amzn/et-polaris-utils';
import { Button, Flash } from '@amzn/awsui-components-react/polaris';
import ApiClient from '../../ApiClient';
import Spinner from '../Spinner';
import { gunzip } from '../../utils/stringUtils';

const listToDisplay = values => {
  if (values == null || values.length === 0) {
    return <span css={css({ fontStyle: 'italic' })}>None</span>;
  }
  return (
    <ul>
      {values.map(value => (
        <li key={value}>{value}</li>
      ))}
    </ul>
  );
};

const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

const getErrorMessage = error => {
  if (error.status && error.details) {
    // Errors with string bodies
    if (typeof error.details === 'string') {
      return error.details;
    }
    // Web errors
    if (Array.isArray(error.details.errors) && error.details.errors.length > 0) {
      const errorDetails = error.details.errors[0];
      if (errorDetails.message != null) {
        return errorDetails.message;
      }
      return errorDetails.toString();
    }
  }

  return error.toString();
};

export const CreatePostMortemJobsView = ({ apiClient, sleepMs }) => {
  const [file, setFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [uploaded, setUploaded] = useState(false);
  const [backgroundTaskResults, setBackgroundTaskResults] = useState({});

  const handleFileChange = event => {
    setError(null);
    setBackgroundTaskResults({});
    setFile(event.target.files[0]);
  };

  const handleSubmit = async () => {
    let createResult;
    setError(null);
    setUploaded(false);
    setBackgroundTaskResults({});
    setIsSubmitting(true);
    try {
      createResult = await apiClient.multipartUpload(`/postMortemScoring/crossOrgJob`, {
        file: file,
      });
      setUploaded(true);
    } catch (error) {
      setIsSubmitting(false);
      setError(`Problem uploading file: ${getErrorMessage(error)}`);
      return;
    }
    let hasError = false;
    let errorText = '';

    for (const [orgId, backgroundTaskSummary] of Object.entries(createResult)) {
      const backgroundTaskId = backgroundTaskSummary['id'];
      while (true) {
        try {
          const backgroundTaskResult = await apiClient.httpGet(
            `/backgroundTask/${backgroundTaskId}`
          );
          const finishedDataText = backgroundTaskResult.finishedDataText
            ? JSON.parse(gunzip(backgroundTaskResult.finishedDataText))
            : null;
          if (backgroundTaskResult.status === 'OK') {
            setBackgroundTaskResults(oldBackgroundTaskResults => ({
              ...oldBackgroundTaskResults,
              [orgId]: finishedDataText,
            }));
            break;
          } else if (backgroundTaskResult.status === 'ERROR') {
            setBackgroundTaskResults(oldBackgroundTaskResults => ({
              ...oldBackgroundTaskResults,
              [orgId]: finishedDataText,
            }));
            hasError = true;
            errorText = 'Failed to create all of the requested scoring jobs';
            break;
          }
        } catch (error) {
          setError(`Problem checking task: ${getErrorMessage(error)}`);
        }

        await sleep(sleepMs);
      }
    }

    setIsSubmitting(false);
    if (hasError) {
      setError(errorText);
    }
  };

  return (
    <>
      <Container
        title="Create Sampled Post-Delivery QA jobs"
        description="Create Sampled Post-Delivery QA jobs from job parts to be scored."
      >
        <KeyValuePane
          width={1}
          columns={[
            [
              {
                key: 'List of jobs to create Sampled Post-Delivery QA jobs',
                value: (
                  <input
                    type="file"
                    name="postMortemJobsFile"
                    accept=".csv"
                    onChange={handleFileChange}
                  />
                ),
              },
            ],
          ]}
        />
      </Container>
      <div className="awsui-util-action-stripe">
        <div className="awsui-util-action-stripe-title" />
        <div className="awsui-util-action-stripe-group">
          <Button
            text="Submit"
            id="submitButton"
            variant="primary"
            disabled={file == null || isSubmitting}
            onClick={() => handleSubmit()}
          />
        </div>
      </div>
      {isSubmitting && (
        <div id="create-post-mortem-job-running">
          <Spinner size={30} />
          <div css={css({ display: 'flex', flexDirection: 'column' })}>
            <div id="create-post-mortem-job-running-loading">
              Uploading file...{uploaded && 'Done'}
            </div>
            {uploaded && <div>Waiting for jobs to be created...</div>}
          </div>
        </div>
      )}
      {Object.keys(backgroundTaskResults).length > 0 && (
        <div id="create-post-mortem-job-results">
          <h3>Results</h3>
          <hr css={css({ border: '1px solid #b5b5b5' })} />
          <table
            css={css({
              width: '100%',
              '& td': {
                verticalAlign: 'top',
              },
            })}
          >
            <thead>
              <tr>
                <th>Organization</th>
                <th>Created</th>
                <th>Skipped</th>
                <th>Failed</th>
                <th>Errors</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(backgroundTaskResults).map(([orgId, results]) => (
                <tr key={orgId}>
                  <td>{orgId}</td>
                  <td>{listToDisplay(results.createdJobPartIds)}</td>
                  <td>{listToDisplay(results.skippedJobPartIds)}</td>
                  <td>{listToDisplay(results.failedJobPartIds)}</td>
                  <td>{listToDisplay(results.errors)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {error && (
        <div
          id="create-post-mortem-job-errored"
          className={css({
            paddingTop: '14px',
          })}
        >
          <Flash type="error" content={error} header="Error" dismissible={false} />
        </div>
      )}
    </>
  );
};

CreatePostMortemJobsView.propTypes = {
  apiClient: PropTypes.object,
  sleepMs: PropTypes.number,
};

export const CreatePostMortemJobs = () => {
  return CreatePostMortemJobsView({
    apiClient: ApiClient,
    sleepMs: 1000,
  });
};
