import { makePublisher, DriverBehavior, DriverBehaviorType } from '@amzn/ts-ui-metrics';
import { inferEnvironment } from '@amzn/et-console-components';

const detectDriverBehavior = (): DriverBehaviorType => {
  if (process.env.NODE_ENV === 'test') {
    return DriverBehavior.CollectForTests;
  }
  switch (inferEnvironment()) {
    case 'staging':
      return DriverBehavior.PublishToTest;
    case 'prod':
      return DriverBehavior.PublishToProd;
    default:
      return DriverBehavior.LogToConsole;
  }
};

export default makePublisher('TPWebUi', 'TPWebUi', detectDriverBehavior());
