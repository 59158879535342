import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import ErrorAlert from '../ErrorAlert';
import TranslatorStatuses from './TranslatorStatuses';
import applySchema from '../../utils/applySchema';

const VALIDATION_SCHEMA = yup.object().shape({
  id: yup.number(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup
    .string()
    .email()
    .required(),
  username: yup.string().required(),
  status: yup
    .string()
    .required()
    .oneOf(Object.keys(TranslatorStatuses)),
});

class TranslatorDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.renderForm = this.renderForm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values, formik) {
    return this.props.onUpdateEvent(applySchema(values, VALIDATION_SCHEMA)).then(
      () => {
        this.setState({
          lastError: null,
        });
        if (this.afterSubmitAction === 'close') {
          this.props.closeHandler();
        } else {
          formik.resetForm();
        }
      },
      err => {
        this.setState({ lastError: err });
      }
    );
  }

  renderForm({ values, errors, touched, handleSubmit, handleChange, handleBlur }) {
    const { lastError } = this.state;
    const { closeHandler, title, translator } = this.props;
    const saveEnabled = Object.keys(touched).length > 0 && Object.keys(errors).length === 0;

    const validationState = name => touched[name] && (errors[name] ? 'error' : 'success');

    const buildSubmitHandler = (afterSubmitAction = 'continue') => e => {
      this.afterSubmitAction = afterSubmitAction;
      handleSubmit(e);
    };

    return (
      <div>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {lastError && <ErrorAlert title="Failed to save Translator" errorInfo={lastError} />}
          <Form horizontal onSubmit={buildSubmitHandler()}>
            <FormGroup controlId="firstName" validationState={validationState('firstName')}>
              <Col componentClass={ControlLabel} sm={3}>
                First Name
              </Col>
              <Col sm={8}>
                <FormControl
                  name="firstName"
                  type="text"
                  value={values.firstName}
                  placeholder="First"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormControl.Feedback />
              </Col>
            </FormGroup>
            <FormGroup controlId="lastName" validationState={validationState('lastName')}>
              <Col componentClass={ControlLabel} sm={3}>
                Last Name
              </Col>
              <Col sm={8}>
                <FormControl
                  name="lastName"
                  type="text"
                  value={values.lastName}
                  placeholder="Last"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormControl.Feedback />
              </Col>
            </FormGroup>
            <FormGroup controlId="username" validationState={validationState('username')}>
              <Col componentClass={ControlLabel} sm={3}>
                Username
              </Col>
              <Col sm={8}>
                <FormControl
                  name="username"
                  type="text"
                  value={values.username}
                  placeholder="username"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormControl.Feedback />
              </Col>
            </FormGroup>
            <FormGroup controlId="email" validationState={validationState('email')}>
              <Col componentClass={ControlLabel} sm={3}>
                Email
              </Col>
              <Col sm={8}>
                <FormControl
                  name="email"
                  type="text"
                  value={values.email}
                  placeholder="username@example.com"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormControl.Feedback />
              </Col>
            </FormGroup>
            <FormGroup controlId="status" validationState={validationState('status')}>
              <Col componentClass={ControlLabel} sm={3}>
                Status
              </Col>
              <Col sm={8}>
                <FormControl
                  name="status"
                  componentClass="select"
                  value={values.status}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {Object.keys(TranslatorStatuses).map(status => (
                    <option key={status} value={status}>
                      {TranslatorStatuses[status]}
                    </option>
                  ))}
                </FormControl>
                <FormControl.Feedback />
              </Col>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeHandler}>Cancel</Button>
          <Button
            bsStyle={'id' in translator ? 'primary' : 'default'}
            disabled={!('id' in translator) && !saveEnabled}
            onClick={buildSubmitHandler('close')}
          >
            Save & Close
          </Button>
          {!('id' in translator) && (
            <Button bsStyle="primary" disabled={!saveEnabled} onClick={buildSubmitHandler()}>
              Save & Continue
            </Button>
          )}
        </Modal.Footer>
      </div>
    );
  }

  getEmptyState() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      username: '',
      status: 'ACTIVE',
    };
  }

  render() {
    return (
      <Formik
        initialValues={'id' in this.props.translator ? this.props.translator : this.getEmptyState()}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={this.handleSubmit}
        render={this.renderForm}
      />
    );
  }
}

TranslatorDialog.propTypes = {
  closeHandler: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  translator: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

TranslatorDialog.defaultProps = {
  closeHandler: Function.prototype,
  onUpdateEvent: Function.prototype,
};

export default TranslatorDialog;
