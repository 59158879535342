import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import applySchema from '../../utils/applySchema';
import { Alert, Button, Form, FormField, Modal, Select } from '@amzn/awsui-components-react-v3';
import SpaceBetween from '@amzn/awsui-components-react-v3/polaris/space-between';
import { Formik } from 'formik';

const VALIDATION_SCHEMA = yup.object().shape({
  userId: yup.number(),
  sourceLocale: yup.string().required(),
  targetLocale: yup.string().required(),
  certificationId: yup.string(),
  maxTranslationSkillId: yup.string().required(),
  mediaTypeId: yup.string().required(),
  subjectId: yup.string().required(),
});

const CertificationDialogV2 = ({
  onCreateEvent,
  closeHandler,
  translationSkills,
  mediaTypes,
  subjects,
  availableLocales,
  isVisible,
  userId,
  setReload,
}) => {
  const [lastError, setLastError] = useState(null);
  const localesMap = availableLocales.reduce((map, obj) => {
    map[obj.code] = obj.name;
    return map;
  }, {});

  const handleSubmit = async (values, formik) => {
    await onCreateEvent(applySchema(values, VALIDATION_SCHEMA))
      .then(() => {
        setLastError(null);
        closeHandler();
        setReload(true);
        formik.reset();
      })
      .catch(error => {
        setLastError(error.message);
      });
  };

  const renderForm = ({
    values,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    handleReset,
    setFieldValue,
    setFieldTouched,
  }) => {
    const saveEnabled = Object.keys(touched).length > 0 && Object.keys(errors).length === 0;

    return (
      <Modal
        id={'certification-dialog-modal'}
        visible={isVisible}
        onDismiss={() => {
          closeHandler();
          handleReset();
        }}
        closeAriaLabel={'Close'}
        header={'Create certification'}
      >
        <Alert header={'Failed to save Certification'} visible={lastError !== null} type={'error'}>
          {lastError}
        </Alert>
        <Form
          actions={
            <SpaceBetween direction={'horizontal'} size={'xs'}>
              <Button
                id={'cancelButton'}
                variant={'normal'}
                onClick={() => {
                  closeHandler();
                  handleReset();
                }}
              >
                Cancel
              </Button>
              <Button
                id={'saveButton'}
                variant={'normal'}
                disabled={!saveEnabled}
                onClick={handleSubmit}
              >
                Save
              </Button>
            </SpaceBetween>
          }
        >
          <SpaceBetween size={'m'} direction={'vertical'}>
            <FormField
              label={'Source Language'}
              id={'sourceLocale'}
              errorText={
                touched.sourceLocale && errors.sourceLocale
                  ? 'You must select a source language'
                  : null
              }
            >
              <Select
                id={'sourceLocale'}
                selectedOption={
                  values.sourceLocale
                    ? {
                        label: localesMap[values.sourceLocale],
                        value: values.sourceLocale,
                      }
                    : null
                }
                onChange={({ detail }) => {
                  setFieldValue('sourceLocale', detail.selectedOption.value);
                  setFieldTouched('sourceLocale');
                }}
                onBlur={handleBlur('sourceLocale')}
                options={Object.keys(localesMap).map(locale => ({
                  label: localesMap[locale],
                  value: locale,
                }))}
                placeholder={'Select the Source Language'}
              />
            </FormField>
            <FormField
              label={'Target Language'}
              id={'targetLocale'}
              errorText={
                touched.targetLocale && errors.targetLocale
                  ? 'You must select a target language'
                  : null
              }
            >
              <Select
                id={'targetLocale'}
                selectedOption={
                  values.targetLocale
                    ? {
                        label: localesMap[values.targetLocale],
                        value: values.targetLocale,
                      }
                    : null
                }
                onChange={({ detail }) => {
                  setFieldValue('targetLocale', detail.selectedOption.value);
                  setFieldTouched('targetLocale');
                }}
                onBlur={handleBlur('targetLocale')}
                options={Object.keys(localesMap).map(locale => ({
                  label: localesMap[locale],
                  value: locale,
                }))}
                placeholder={'Select the Target Language'}
              />
            </FormField>
            <FormField
              label={'Media Type'}
              id={'mediaTypeId'}
              errorText={
                touched.mediaTypeId && errors.mediaTypeId ? 'You must select a media type' : null
              }
            >
              <Select
                id={'mediaTypeId'}
                selectedOption={
                  values.mediaTypeId
                    ? {
                        label: mediaTypes[values.mediaTypeId],
                        value: values.mediaTypeId,
                      }
                    : null
                }
                onChange={({ detail }) => {
                  setFieldValue('mediaTypeId', detail.selectedOption.value);
                  setFieldTouched('mediaTypeId');
                }}
                onBlur={handleBlur('mediaTypeId')}
                options={Object.keys(mediaTypes).map(mediaType => ({
                  label: mediaTypes[mediaType],
                  value: mediaType,
                }))}
                placeholder={'Select a Media Type'}
              />
            </FormField>
            <FormField
              label={'Subject'}
              id={'subjectId'}
              errorText={touched.subjectId && errors.subjectId ? 'You must select a subject' : null}
            >
              <Select
                id={'subjectId'}
                selectedOption={
                  values.subjectId
                    ? {
                        label: subjects[values.subjectId],
                        value: values.subjectId,
                      }
                    : null
                }
                onChange={({ detail }) => {
                  setFieldValue('subjectId', detail.selectedOption.value);
                  setFieldTouched('subjectId');
                }}
                onBlur={handleBlur('subjectId')}
                options={Object.keys(subjects).map(subject => ({
                  label: subjects[subject],
                  value: subject,
                }))}
                placeholder={'Select a Subject'}
              />
            </FormField>
            <FormField
              label={'Maximum Skill Level'}
              id={'maxTranslationSkillId'}
              errorText={
                touched.maxTranslationSkillId && errors.maxTranslationSkillId
                  ? 'You must select a max skill level'
                  : null
              }
            >
              <Select
                id={'maxTranslationSkillId'}
                selectedOption={
                  values.maxTranslationSkillId
                    ? {
                        label: translationSkills[values.maxTranslationSkillId],
                        value: values.maxTranslationSkillId,
                      }
                    : null
                }
                onChange={({ detail }) => {
                  setFieldValue('maxTranslationSkillId', detail.selectedOption.value);
                  setFieldTouched('maxTranslationSkillId');
                }}
                onBlur={handleBlur('maxTranslationSkillId')}
                options={Object.keys(translationSkills).map(skill => ({
                  label: translationSkills[skill],
                  value: skill,
                }))}
                placeholder={'Select a Skill'}
              />
            </FormField>
          </SpaceBetween>
        </Form>
      </Modal>
    );
  };

  const emptyState = {
    userId: userId,
    sourceLocale: null,
    targetLocale: null,
    mediaTypeId: null,
    subjectId: null,
    maxTranslationSkillId: null,
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={emptyState}
      validationSchema={VALIDATION_SCHEMA}
      validateOnChange={true}
      render={renderForm}
    />
  );
};

CertificationDialogV2.propTypes = {
  onCreateEvent: PropTypes.func,
  closeHandler: PropTypes.func,
  translationSkills: PropTypes.object,
  mediaTypes: PropTypes.object,
  subjects: PropTypes.object,
  availableLocales: PropTypes.array,
  isVisible: PropTypes.bool,
  userId: PropTypes.number,
  setReload: PropTypes.func,
};

export default CertificationDialogV2;
