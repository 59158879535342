import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  Form,
  FormField,
  Input,
  Modal,
  Select,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import TranslatorStatuses from './TranslatorStatuses';
import { Formik } from 'formik';
import * as yup from 'yup';
import applySchema from '../../utils/applySchema';

const VALIDATION_SCHEMA = yup.object().shape({
  id: yup.number(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup
    .string()
    .email()
    .required(),
  username: yup.string().required(),
  status: yup
    .string()
    .required()
    .oneOf(Object.keys(TranslatorStatuses)),
});

const TranslatorDialogV2 = ({ title, translator, closeHandler, onUpdateEvent, isVisible }) => {
  const [lastError, setLastError] = useState(null);

  const handleSubmit = async (values, formik) => {
    await onUpdateEvent(applySchema(values, VALIDATION_SCHEMA))
      .then(() => {
        setLastError(null);
        closeHandler();
      })
      .catch(error => {
        setLastError(error.message);
      });
  };

  const renderForm = ({
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    handleBlur,
    handleReset,
  }) => {
    return (
      <Modal
        id={'translator-dialog-modal'}
        visible={isVisible}
        onDismiss={() => {
          closeHandler();
          handleReset();
        }}
        closeAriaLabel={'Close modal'}
        header={title}
      >
        <Alert header={'Failed to save Translator'} visible={lastError !== null} type={'error'}>
          {lastError}
        </Alert>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                id={'cancelButton'}
                variant="normal"
                onClick={() => {
                  closeHandler();
                  handleReset();
                }}
              >
                Cancel
              </Button>
              <Button id={'saveAndCloseButton'} onClick={handleSubmit} variant={'primary'}>
                Save & Close
              </Button>
            </SpaceBetween>
          }
        >
          <SpaceBetween direction="vertical" size="m">
            <FormField
              label={'First Name'}
              id={'firstName'}
              errorText={touched.firstName ? errors.firstName : null}
            >
              <Input
                id={'firstName'}
                name={'firstName'}
                value={values.firstName}
                placeHolder={'First'}
                onChange={({ detail }) => setFieldValue('firstName', detail.value)}
                onFocus={handleBlur('firstName')}
              />
            </FormField>
            <FormField
              label={'Last Name'}
              id={'lastName'}
              errorText={touched.lastName ? errors.lastName : null}
            >
              <Input
                id={'lastName'}
                name={'lastName'}
                value={values.lastName}
                placeHolder={'Last'}
                onChange={({ detail }) => setFieldValue('lastName', detail.value)}
                onFocus={handleBlur('lastName')}
              />
            </FormField>
            <FormField
              label={'Username'}
              id={'username'}
              errorText={touched.username ? errors.username : null}
            >
              <Input
                id={'username'}
                name={'username'}
                value={values.username}
                placeHolder={'username'}
                onChange={({ detail }) => setFieldValue('username', detail.value)}
                onFocus={handleBlur('username')}
              />
            </FormField>

            <FormField label={'Email'} id={'email'} errorText={touched.email ? errors.email : null}>
              <Input
                id={'email'}
                name={'email'}
                value={values.email}
                placeHolder={'username@example.com'}
                onChange={({ detail }) => setFieldValue('email', detail.value)}
                onFocus={handleBlur('email')}
                type={'email'}
              />
            </FormField>
            <FormField
              label={'Status'}
              id={'status'}
              errorText={touched.status ? errors.status : null}
            >
              <Select
                id={'status'}
                selectedOption={{
                  label: TranslatorStatuses[values.status],
                  value: values.status,
                }}
                onChange={({ detail }) => setFieldValue('status', detail.selectedOption.value)}
                onFocus={handleBlur('status')}
                options={Object.keys(TranslatorStatuses).map(status => ({
                  label: TranslatorStatuses[status],
                  value: status,
                }))}
              />
            </FormField>
          </SpaceBetween>
        </Form>
      </Modal>
    );
  };

  const emptyState = {
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    status: 'ACTIVE',
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={translator}
      validationSchema={VALIDATION_SCHEMA}
      render={renderForm}
    />
  );
};

TranslatorDialogV2.propTypes = {
  closeHandler: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  translator: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
};

export default TranslatorDialogV2;
