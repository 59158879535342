import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Container } from '@amzn/et-polaris-utils';
import {
  Button,
  ColumnLayout,
  Icon,
  Spinner,
  ExpandableSection,
} from '@amzn/awsui-components-react/polaris';
import { cubes } from 'react-icons-kit/fa/cubes';
import SvgIcon from 'react-icons-kit/SvgIcon';
import ScopingReportView from '../ScopingReportView';
import {
  readableLookup,
  convertDateTimePretty,
  convertLanguagePair,
  convertPrice,
  valueOrDash,
  getDaysTilDate,
} from '../../utils/ReadableConverter';
import { OfferUtils } from '../../utils/offerUtils';
import initialMetricsPublisher from '../../utils/metricsPublisher';
import { publishCountForMethod } from '@amzn/ts-ui-metrics';
import styled from '@emotion/styled';
import { ProgressView } from '../ProgressView';
import { inferEnvironment } from '@amzn/et-console-components';

const ErrorMessage = styled('div')`
  margin-bottom: 15px;
`;

const shouldShowJobDetails = inflightStatusUpdate => {
  return !inflightStatusUpdate || inflightStatusUpdate.error;
};

const renderStatus = (inflightStatusUpdate, isExclusiveOffer) => {
  if (inflightStatusUpdate.error) {
    return (
      <ErrorMessage>
        <span className="awsui-util-status-negative">
          <h2 id="HeaderMessage">
            <Icon id="HeaderIcon" name="status-negative" />
            &nbsp;{inflightStatusUpdate.error}
          </h2>
        </span>
      </ErrorMessage>
    );
  } else if (inflightStatusUpdate.warning) {
    return (
      <span className="awsui-util-status-negative">
        <h2 id="HeaderMessage">
          <Icon id="HeaderIcon" name="status-warning" />
          &nbsp;{inflightStatusUpdate.warning}
        </h2>
      </span>
    );
  } else if (inflightStatusUpdate.complete) {
    let className = 'awsui-util-status-positive';
    let message = '';

    switch (inflightStatusUpdate.status) {
      case 'OPEN':
        className = 'awsui-util-status-negative';
        message = (
          <h2 id="HeaderMessage">
            <Icon id="HeaderIcon" name="status-negative" />
            {isExclusiveOffer ? ' Rejected' : ' Skipped'}
          </h2>
        );
        break;
      case 'ACCEPTED':
        className = 'awsui-util-status-positive';
        message = (
          <h2 id="HeaderMessage">
            <Icon id="HeaderIcon" name="status-positive" />
            &nbsp;Claimed
          </h2>
        );
        break;
      case 'COMPLETED':
        className = 'awsui-util-status-positive';
        message = (
          <h2 id="HeaderMessage">
            <Icon id="HeaderIcon" name="status-positive" />
            &nbsp;Completed
          </h2>
        );
        break;
      // we shouldn't make it to default...
      default:
        className = 'awsui-util-status-positive';
        message = (
          <h2 id="HeaderMessage">
            <Icon id="HeaderIcon" name="status-positive" />
            &nbsp;{inflightStatusUpdate.status}
          </h2>
        );
        break;
    }

    return <span className={className}>{message}</span>;
  } else {
    return (
      <span className="awsui-util-status-inactive">
        <Spinner />
      </span>
    );
  }
};

export const OfferCard = props => {
  const {
    offer,
    updateStatus,
    inflightStatusUpdate,
    timeNow,
    disableAllButtons,
    globalState,
    isUpdatingOffer,
    isUpdatingOfferForClaim,
    segmentMetadataByJob,
    postMortemScoringParentOfferMetadata,
    postMortemScoringParentJobMetadata,
  } = props;

  const renderActionButtons = () => {
    if (inflightStatusUpdate && !inflightStatusUpdate.error) {
      return <></>;
    }

    const actions = [];
    const disabled =
      disableAllButtons || offer.priceError != null || OfferUtils.isExpired(offer, timeNow);

    if (OfferUtils.isAccepted(offer)) {
      actions.push(
        <Button
          key="Complete"
          id={`Complete-${offer.id}`}
          text="Complete"
          disabled={disabled}
          onClick={() => {
            const actionMetricsPublisher = publishCountForMethod(
              initialMetricsPublisher,
              'CompleteOfferButtonClick'
            );
            actionMetricsPublisher.publishString('OfferId', offer.id);

            updateStatus(offer, 'COMPLETED');
          }}
        />,
        <Button
          key="Open"
          id={`Open-${offer.id}`}
          text="Open"
          disabled={disableAllButtons}
          variant="primary"
          target={offer.editLink}
          href={offer.editLink}
          onClick={() => {
            const actionMetricsPublisher = publishCountForMethod(
              initialMetricsPublisher,
              'OpenOfferButtonClick'
            );
            actionMetricsPublisher.publishString('OfferId', offer.id);
            actionMetricsPublisher.publishString('JobUri', offer.jobUri);
            actionMetricsPublisher.publishString('FreelancerUri', offer.freelancerUri);
          }}
        />
      );
    } else if (OfferUtils.isReserved(offer)) {
      actions.push(
        <Button
          key="Preview"
          id={`Preview-${offer.id}`}
          text="Preview"
          disabled={disabled}
          target={offer.editLink}
          href={offer.editLink}
          onClick={() => {
            const actionMetricsPublisher = publishCountForMethod(
              initialMetricsPublisher,
              'PreviewOfferButtonClick'
            );
            actionMetricsPublisher.publishString('OfferId', offer.id);
          }}
        />,
        <Button
          key="Reject"
          id={`Reject-${offer.id}`}
          text={OfferUtils.isExclusiveOffer(offer) ? 'Reject' : 'Skip'}
          disabled={disabled}
          onClick={() => {
            const actionMetricsPublisher = publishCountForMethod(
              initialMetricsPublisher,
              'SkipOfferButtonClick'
            );
            actionMetricsPublisher.publishString('OfferId', offer.id);

            updateStatus(offer, 'OPEN');
          }}
        />,
        <Button
          key="Claim"
          id={`Claim-${offer.id}`}
          text="Claim"
          disabled={disabled || isUpdatingOfferForClaim}
          loading={isUpdatingOffer === true}
          variant="primary"
          onClick={() => {
            const actionMetricsPublisher = publishCountForMethod(
              initialMetricsPublisher,
              'ClaimOfferButtonClick'
            );
            actionMetricsPublisher.publishString('OfferId', offer.id);

            updateStatus(offer, 'ACCEPTED');
          }}
        />
      );
    }

    return actions;
  };

  const renderOfferExpires = () => {
    if (!offer.dateReservationExpires || !OfferUtils.isReserved(offer)) {
      return null;
    }

    const dateReservationExpiresMoment = moment(offer.dateReservationExpires);
    const timeDifference = moment(offer.dateReservationExpires).from(timeNow);

    if (dateReservationExpiresMoment.isBefore(timeNow)) {
      return (
        <span className="awsui-util-status-inactive">
          <Icon name="status-pending" />
          &nbsp;This offer has expired
        </span>
      );
    } else {
      return (
        <span className="awsui-util-status-positive">
          <Icon name="status-pending" />
          &nbsp;This offer expires {timeDifference}
        </span>
      );
    }
  };

  const renderExclusiveOffer = () => {
    return (
      <span className="exclusive-offer-pill">
        {/*TODO: We want a star icon here, but its currently not in polaris*/}
        {/*<Icon name="star"/>*/}
        &nbsp;Exclusive Offer
      </span>
    );
  };

  const getDescription = () => {
    const nextToTitle = [];
    // show status if there is one
    if (inflightStatusUpdate) {
      nextToTitle.push(renderStatus(inflightStatusUpdate, OfferUtils.isExclusiveOffer(offer)));
    }
    // don't show buttons if there is a status, unless its an error, so they can retry their action
    if (!inflightStatusUpdate || inflightStatusUpdate.error) {
      nextToTitle.push(renderActionButtons());
    }

    return (
      <div>
        {!inflightStatusUpdate && (
          <div style={{ display: 'flex' }}>
            <div style={{ color: 'black' }}>
              {OfferUtils.isExclusiveOffer(offer) && renderExclusiveOffer()}
            </div>
            <div
              style={{
                flexGrow: 1,
                textAlign: 'right',
                fontSize: '1.2rem',
                fontWeight: 400,
                marginTop: '5px',
              }}
            >
              {renderOfferExpires()}
            </div>
          </div>
        )}
        <div style={{ textAlign: 'right', marginBlock: '5px' }}>{nextToTitle}</div>
      </div>
    );
  };

  const getScopingReport = () => {
    return offer.scopingReport ? (
      <ExpandableSection
        header="Scoping Report"
        onChange={event => {
          const actionMetricsPublisher = publishCountForMethod(
            initialMetricsPublisher,
            'ToggleScopingReportView'
          );
          actionMetricsPublisher.publishCounter('Expanded', event.detail.expanded ? 1 : 0);
          actionMetricsPublisher.publishString('OfferId', offer.id);
        }}
      >
        <ScopingReportView scopingReport={offer.scopingReport} wordCount={offer.workUnits} />
      </ExpandableSection>
    ) : null;
  };

  const getTitle = () => {
    return offer.isBundle ? (
      <div>
        <SvgIcon icon={cubes} size={20} />
        {offer.name || offer.fileName}
      </div>
    ) : (
      `${offer.name || offer.fileName}`
    );
  };

  const isProdStage = inferEnvironment() === 'prod';

  return (
    <div id={`job-${offer.id}`}>
      <Container
        title={getTitle()}
        description={`${offer.workUnits ||
          offer.wordsCount ||
          '-'} words / ${OfferUtils.getFileCount(offer)} files / ${getDaysTilDate(
          offer.dateDue
        )} days`}
        rawButtons={getDescription()}
        footer={shouldShowJobDetails(inflightStatusUpdate) && getScopingReport()}
      >
        {shouldShowJobDetails(inflightStatusUpdate) && (
          <ColumnLayout columns={3} variant="text-grid">
            <div data-awsui-column-layout-root="true">
              <div>
                <div className="awsui-util-spacing-v-s">
                  <div>
                    <div className="awsui-util-label">Due Date</div>
                    <div id={`job-${offer.id}-due-date`}>
                      {convertDateTimePretty(offer.dateDue)}
                    </div>
                  </div>
                  <div>
                    <div className="awsui-util-label">Language Arc</div>
                    <div id={`job-${offer.id}-language-arc`}>
                      {convertLanguagePair(offer.sourceLocale, offer.targetLocale)}
                    </div>
                  </div>
                  {!offer.isPostMortemScoring && (
                    <div>
                      <div className="awsui-util-label">Skill</div>
                      <div id={`job-${offer.id}-skill`}>
                        {readableLookup(offer.translationSkill, globalState, 'translationSkills')}
                      </div>
                    </div>
                  )}
                  {offer.isPostMortemScoring && offer.parentOfferId && (
                    <div>
                      <div className="awsui-util-label">Skill (Previous Job/Current Job)</div>
                      <div id={`job-${offer.id}-skill`}>
                        {postMortemScoringParentOfferMetadata ? (
                          readableLookup(
                            postMortemScoringParentOfferMetadata.translationSkill,
                            globalState,
                            'translationSkills'
                          )
                        ) : (
                          <Spinner />
                        )}
                        {' / '}
                        {readableLookup(offer.translationSkill, globalState, 'translationSkills')}
                      </div>
                    </div>
                  )}
                  {offer.isPostMortemScoring && !offer.parentOfferId && (
                    <div>
                      <div className="awsui-util-label">Skill (Previous Job/Current Job)</div>
                      <div id={`job-${offer.id}-skill`}>
                        {postMortemScoringParentJobMetadata ? (
                          readableLookup(
                            postMortemScoringParentJobMetadata.workflowStepType,
                            globalState,
                            'translationSkills'
                          )
                        ) : (
                          <Spinner />
                        )}
                        {' / '}
                        {readableLookup(offer.translationSkill, globalState, 'translationSkills')}
                      </div>
                    </div>
                  )}
                  {!isProdStage && (
                    <div>
                      <div className="awsui-util-label">Progress</div>
                      <div id={`job-${offer.id}-progress`}>
                        <ProgressView segmentMetadata={segmentMetadataByJob[offer.jobPartId]} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className="awsui-util-spacing-v-s">
                  <div>
                    <div className="awsui-util-label">Price</div>
                    <div id={`job-${offer.id}-price`}>
                      {!offer.priceError && convertPrice(offer.price, offer.currency)}
                      {offer.priceError && (
                        <div className="awsui-util-status-negative">
                          <Icon name="status-negative" /> Price not available: {offer.priceError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="awsui-util-label">Wordcount (Raw/Weighted)</div>
                    <div id={`job-${offer.id}-wordcount`}>
                      {`${offer.workUnits || offer.wordsCount || '-'} / ${Math.round(
                        offer.weightedWorkUnits
                      ) || '-'}`}
                    </div>
                  </div>
                  <div>
                    <div className="awsui-util-label">Files</div>
                    <div id={`job-${offer.id}-filecount`}>{OfferUtils.getFileCount(offer)}</div>
                  </div>
                </div>
              </div>
              <div>
                <div className="awsui-util-spacing-v-s">
                  <div>
                    <div className="awsui-util-label">Media Type</div>
                    <div id={`job-${offer.id}-mediaType`}>
                      {readableLookup(offer.mediaType, globalState, 'mediaTypes')}
                    </div>
                  </div>
                  <div>
                    <div className="awsui-util-label">Subject</div>
                    <div id={`job-${offer.id}-subject`}>
                      {readableLookup(offer.subject, globalState, 'subjects')}
                    </div>
                  </div>
                  <div>
                    <div className="awsui-util-label">Client</div>
                    <div id={`job-${offer.id}-client-name`}>{valueOrDash(offer.clientName)}</div>
                  </div>
                </div>
              </div>
            </div>
          </ColumnLayout>
        )}
      </Container>
    </div>
  );
};

OfferCard.propTypes = {
  offer: PropTypes.object,
  postMortemScoringParentOfferMetadata: PropTypes.object,
  postMortemScoringParentJobMetadata: PropTypes.object,
  exclusiveOffer: PropTypes.bool,
  updateStatus: PropTypes.func,
  inflightStatusUpdate: PropTypes.object,
  disableAllButtons: PropTypes.bool,
  timeNow: PropTypes.number,
  globalState: PropTypes.object,
  isUpdatingOffer: PropTypes.bool,
  isUpdatingOfferForClaim: PropTypes.bool,
  segmentMetadataByJob: PropTypes.object,
};

export default OfferCard;
