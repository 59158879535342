import ApiClient from '../ApiClient';

const ActionTypes = {
  // Request list of available locales from the server
  LOCALES_FETCH_START: 'LOCALES_FETCH_START',
  // Receive list of available locales from the server
  LOCALES_FETCH_COMPLETE: 'LOCALES_FETCH_COMPLETE',
  // Failure fetching list of available locales
  LOCALES_FETCH_ERROR: 'LOCALES_FETCH_ERROR',
};
export default ActionTypes;

function fetchAvailableLocalesStart() {
  return {
    type: ActionTypes.LOCALES_FETCH_START,
  };
}

function fetchAvailableLocalesComplete(items) {
  items.sort((a, b) => {
    if (a.code < b.code) return -1;
    if (a.code > b.code) return 1;
    return 0;
  });
  return {
    type: ActionTypes.LOCALES_FETCH_COMPLETE,
    receivedAt: Date.now(),
    items,
  };
}

function fetchAvailableLocalesError(lastError) {
  return {
    type: ActionTypes.LOCALES_FETCH_ERROR,
    receivedAt: Date.now(),
    lastError,
  };
}

export function fetchAvailableLocales() {
  return dispatch => {
    dispatch(fetchAvailableLocalesStart());
    ApiClient.httpGet('/locale')
      .then(resp => dispatch(fetchAvailableLocalesComplete(resp)))
      .catch(err => dispatch(fetchAvailableLocalesError(err)));
  };
}
