import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

import extractApiErrors from '../utils/extractApiErrors';
/**
 * TODO:
 * I18n, and have untranslated <=> translated mapping passed in as a prop
 */

class ErrorAlert extends React.Component {
  render() {
    const { title, errorInfo } = this.props;
    const messages = extractApiErrors(errorInfo);
    return (
      <Alert bsStyle="danger">
        <strong>{title}</strong>:{' '}
        {messages.length === 1 ? (
          messages[0]
        ) : (
          <ul>
            {messages.map(message => (
              <li key={message}>{message}</li>
            ))}
          </ul>
        )}
      </Alert>
    );
  }
}
ErrorAlert.propTypes = {
  title: PropTypes.string,
  errorInfo: PropTypes.object.isRequired,
};

export default ErrorAlert;
