import React from 'react';
import PropTypes from 'prop-types';

import formatLocaleCode from '../../../utils/formatLocaleCode';
import { editField, readField } from './editorCss';
import { FormControl } from 'react-bootstrap';

const formatPair = languagePair => {
  return (
    formatLocaleCode(languagePair.sourceLocale) +
    ' ➡ ' +
    formatLocaleCode(languagePair.targetLocale)
  );
};

export class LanguagePair extends React.Component {
  render() {
    const { value, availableLocaleCodes, onChange } = this.props;

    return (
      <div>
        <div className={readField} id="readonlyLanguagePair">
          {value ? formatPair(value) : <i>ANY</i>}
        </div>
        <div className={editField}>
          <FormControl
            componentClass="select"
            value={value.sourceLocale}
            onChange={e => onChange('languagePair.sourceLocale', e.target.value)}
          >
            {availableLocaleCodes.map(code => (
              <option key={code} value={code}>
                {code}
              </option>
            ))}
          </FormControl>
          {' ➡ '}
          <FormControl
            componentClass="select"
            value={value.targetLocale}
            onChange={e => onChange('languagePair.targetLocale', e.target.value)}
          >
            {availableLocaleCodes.map(code => (
              <option key={code} value={code}>
                {code}
              </option>
            ))}
          </FormControl>
        </div>
      </div>
    );
  }
}

LanguagePair.propTypes = {
  value: PropTypes.shape({
    sourceLocale: PropTypes.string,
    targetLocale: PropTypes.string,
  }),
  availableLocaleCodes: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default LanguagePair;
