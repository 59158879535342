import ActionTypes from '../actions/Session';

const session = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.SESSION_DESTROY:
      return null;
    case ActionTypes.SESSION_UPDATE:
      return action.data;
    case ActionTypes.SESSION_UPDATE_FAILURE:
      return { error: action.error };
    default:
      return state;
  }
};

session.initialState = null;

export default session;
